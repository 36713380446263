import { useMemo } from "react"
import { useSelector } from "react-redux"
import { selectChatPreviewById } from "../selectors/preview"

export const useChat = (chatId: string) => {
  const chatSelector = useMemo(() => selectChatPreviewById(chatId), [chatId])
  const chat = useSelector(chatSelector)
  return {
    chat,
  }
}

import { selectChannelPreviewIdList } from "@features/messaging/selectors/preview"
import { RootState } from "@modules/store/redux/rootReducer"
import { createSelector } from "@reduxjs/toolkit"
import {
  getChannelTopicId,
  getEveryoneTopicId,
} from "@common/common/features/messaging/topics"
import { differenceInDays } from "date-fns"
import {
  NotificationChannelEvent,
  NotificationData,
} from "../components/Notification"
import { uniqBy } from "ramda"

const notificationsSelector = (state: RootState) =>
  state?.notifications.notifications

const userDataSelector = (state: RootState) => state.userProfile?.user?.data

const OLD_NOTIFICATIONS_THRESHOLD_DAYS = 7

export const selectNotifications = createSelector(
  notificationsSelector,
  (notificationsData) => {
    const recentNotifications: NotificationData<any>[] = []
    const oldNotifications: NotificationData<any>[] = []
    const now = new Date()
    for (const data of notificationsData?.data ?? []) {
      const dateTime = new Date(data.ts)
      let bucket =
        differenceInDays(now, dateTime) > OLD_NOTIFICATIONS_THRESHOLD_DAYS
          ? oldNotifications
          : recentNotifications
      bucket.push({
        id: data.id,
        text: data.c.s,
        title: data.c.t,
        dateTime,
        data: data.p,
        isNew: !data.r,
        type: data.et as NotificationData<any>["type"],
      })
    }
    return {
      recentNotifications,
      oldNotifications,
      unreadNotifications: notificationsData?.data?.filter((x) => !x.r),
    }
  }
)

export const selectExistsUnreadNotifications = createSelector(
  selectNotifications,
  (notificationsData) => {
    return (
      notificationsData.oldNotifications.some((x) => x.isNew) ||
      notificationsData.recentNotifications.some((x) => x.isNew)
    )
  }
)
export const selectNotificationTopics = createSelector(
  selectChannelPreviewIdList,
  userDataSelector,
  (channelIdList, user) => {
    const channelTopics = channelIdList.map((x) => getChannelTopicId(x))
    const everyoneTopic = [getEveryoneTopicId()]
    return [...channelTopics, ...everyoneTopic]
    // .filter(x=>x=== "")
    // .concat(user?.context.storeId != null ? [getStoreTopicId(user?.context.storeId) ?? ""] : []).concat(getEveryoneTopicId() ?? "").filter(x=>x=== "")
  }
)
export const selectLastChannelCommunications = createSelector(
  selectNotifications,
  (n) => {
    return uniqBy(
      (x) => x.data.channelScope,
      n.recentNotifications.filter(
        (x) => x.type === "channelMessage"
      ) as NotificationData<NotificationChannelEvent>[]
    )
  }
)

import { ImMessage } from "../common/message.entity"
export type MessageDirection = "in" | "out"

export interface Chat {
  active: boolean
  senderId: string
  receiverId: string
  lastMessageTime?: any
}

export enum ImChatMessageState {
  Pending = "p",
  Sent = "s",
}

export interface ImChatMessage extends ImMessage {
  // messageDirection (used for chat only)
  d?: MessageDirection
  // state
  st?: ImChatMessageState
}

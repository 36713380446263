import { selectChapterIdList } from "@features/courses/state/selectors"
import classNames from "classnames"
import React from "react"
import { useSelector } from "react-redux"

import CourseDetailVideoListItem from "./CourseDetailVideoListItem"

interface Props {
  isVisible: boolean
}

const CourseDetailVideoList = ({ isVisible }: Props) => {
  const chapters = useSelector(selectChapterIdList)
  return (
    <div
      className={classNames(
        !isVisible && "hidden",
        "flex flex-col border-t border-t-grey"
      )}
    >
      {chapters.map((id, index) => {
        return (
          <CourseDetailVideoListItem
            key={id}
            chapterId={id}
            index={index}
            chapterLength={chapters.length}
          />
        )
      })}
    </div>
  )
}

export default React.memo(CourseDetailVideoList)

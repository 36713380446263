import { Icon, InlineLoader } from "@atoms"
import { getFileSizeLabel } from "@helpers/ui"
import classNames from "classnames"

export interface AttachmentItem {
  id: string
  uploading: boolean
  type: string
  name: string
  sizeMB: number
  asset?: {
    url: string
  }
}

export interface MessagingToolbarAttachmentsProps {
  items: AttachmentItem[]
  onAttachmentRemove: (item: AttachmentItem) => void
}

export const MessagingToolbarAttachments = ({
  items,
  onAttachmentRemove,
}: MessagingToolbarAttachmentsProps) => {
  return (
    <div
      className={classNames(
        items.length > 0 &&
          "max-h-[400px] overflow-auto flex flex-col mb-3 gap-2"
      )}
    >
      {items.map((file) => (
        <div
          key={file.id}
          className="h-[100px] px-4 py-3 border border-black/20 rounded-2xl"
        >
          {file.uploading && (
            <div className="flex items-center justify-center w-full h-full">
              <InlineLoader key={file.id} />
            </div>
          )}
          {!file.uploading && (
            <div
              className="flex justify-between h-full gap-2"
              onClick={() => onAttachmentRemove(file)}
            >
              {file.type == "image" ? (
                <img
                  className="w-[90px] object-cover h-full"
                  key={file.id}
                  src={file.asset?.url}
                />
              ) : (
                <div className="w-[40px] mr-2">
                  <Icon iconName="document" sizeFull />
                </div>
              )}
              <div className="flex-1 ">
                <div className="text-dark">{file.name}</div>
                <div>{getFileSizeLabel(file.sizeMB)}</div>
              </div>
              <div className="text-red w-[26px] h-[26px]">
                <Icon iconName="close" sizeFull />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

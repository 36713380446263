import { put, call } from "typed-redux-saga"
import { contactsSlice } from "../state"
import { PayloadAction } from "@reduxjs/toolkit"

export function* contactsSearchSaga(action: PayloadAction<string>) {
  const query = action.payload
  try {
  } catch (error) {
    console.error(
      `contactsSearchSaga -> contacts search error - query:${query}`,
      error
    )
    yield* put(contactsSlice.actions.contactsSearchError(error))
  }
}

import { BundleInfo, CapacitorUpdater } from "@capgo/capacitor-updater"
import { useEffect, useState } from "react"
import {
  clearCurrentBuildHash,
  getCurrentBuildHash,
  setCurrentBuildHash,
  setCurrentBuildVersion,
} from "../services/buildVersion"
import { firebase } from "@services/index"
import { getDeploymentChannelId } from "../features"

export const useAppInfo = () => {
  const [bundleVersion, setBundleVersion] = useState<BundleInfo>()
  const [buildHash, setBuildHash] = useState<string>()

  const initData = async () => {
    const current = await CapacitorUpdater.current()
    setBundleVersion(current.bundle)
    setBuildHash((await getCurrentBuildHash()) ?? undefined)
  }

  useEffect(() => {
    initData()
  }, [])

  return {
    bundleVersion,
    buildHash,
  }
}

export const useAppReset = () => {
  const [loading, setLoading] = useState(false)

  const reset = async () => {
    try {
      setLoading(true)
      await CapacitorUpdater.reset()
      await clearCurrentBuildHash()
    } finally {
      setLoading(false)
    }
  }

  return {
    invoke: reset,
    loading,
  }
}

export const useAppUpdate = () => {
  const [loading, setLoading] = useState(false)

  const update = async () => {
    try {
      setLoading(true)
      const channels =
        await firebase.collections.appDeploymentController.getChannels()
      const currentChannel = await getDeploymentChannelId()
      const channel = channels.find((c) => c.data.channelId === currentChannel)
      if (!channel) {
        console.error(`MANUAL UPDATE | no channel found for ${currentChannel}`)
        return
      }

      const current = channel.data.current
      if (!current) {
        console.error(
          `MANUAL UPDATE | no current version found for ${currentChannel}`
        )
        return
      }

      // dispatch(appActions.showSplashScreen("UPDATING"))
      const version = await CapacitorUpdater.download({
        url: current.data.artifactUrl,
        version: current.data.version,
      })

      console.log(`MANUAL UPDATE | newer versions downloaded`)

      await CapacitorUpdater.set(version)
      console.log(`MANUAL UPDATE | newer versions set`)

      // await CapacitorUpdater.notifyAppReady()
      console.log(`MANUAL UPDATE | app ready`)

      await setCurrentBuildHash(current.data.commitHash)
      await setCurrentBuildVersion(current.data.version)

      console.log(`MANUAL UPDATE | completed`)
    } finally {
      setLoading(false)
      // dispatch(appActions.showSplashScreen("NONE"))
    }
  }

  return {
    invoke: update,
    loading,
  }
}

import { PartialExcept } from "@helpers/types"
import { RouteComponentProps } from "react-router"
import {
  CoursesPage,
  CommunicationPage,
  CourseDetailPage,
  HomePage,
  OptionsPage,
  PerformancePage,
  CourseDetailParams,
  CommunicationParams,
} from "@pages/private"
import {
  ChannelDetailPage,
  ChannelDetailParams,
} from "@pages/private/Communication/ChannelDetail"
import {
  ChatDetailPage,
  ChatDetailParams,
} from "@pages/private/Communication/ChatDetail"
import {
  DailyEventPage,
  DailyEventParams,
} from "@pages/private/Communication/DailyEvent"
import { TestPage, TestParams } from "@pages/private/Courses/Test"
import { NotificationsPage } from "@pages/private/Home/Notifications"
import { ProfilePage } from "@pages/private/Options/Profile"
import { IconType } from "@ui/atoms/Icon"
import { PasswordChangePage } from "@pages/private/PasswordChange"
import { PasswordResetPage } from "@pages/public/PasswordReset"
import { LoginPage } from "@pages/public"
import { IntroCarouselPage } from "@pages/public/IntroCarousel"
import { getRouteWithParams, withRouteContext } from "@modules/routing/context"
import MainPage from "@pages/public/Main"
import { AppInfoPage } from "@pages/private/AppInfo"
import { AppStoreUpdatePage } from "@pages/private/AppStoreUpdatePage"
import AndroidAppUpdate from "@pages/shared/AndroidAppUpdate"
import AppleStoreUpdate from "@pages/shared/AppleStoreUpdate"
import { UserImpersonatePage } from "@pages/private/UserImpersonate"

export interface TabInfo {
  route: string
  icon: IconType
  iconSelected: IconType
  alternate?: {
    icon: IconType
    iconSelected: IconType
  }
}

export interface RouteOptions<T> {
  rawRoute: string
  component: (props: RouteComponentProps<any>) => JSX.Element
  exact?: boolean
  tab?: TabInfo
  defaultParam?: T
  getRouteWithParams: T extends Object
    ? (data: T) => string
    : (data?: any) => string
}

const getRoute = <T>(
  props: PartialExcept<RouteOptions<T>, "rawRoute" | "component">
) => {
  return {
    rawRoute: props.rawRoute,
    component: props.component,
    exact: props.exact ?? true,
    tab: props.tab,
    getRouteWithParams: (data: T) =>
      getRouteWithParams<T>({
        rawRoute: props.rawRoute,
        data,
        defaultParam: props.defaultParam,
      }),
  } as RouteOptions<T>
}

interface a {
  chatId: string
}

const privateRoutes = {
  home: getRoute({
    rawRoute: "/home",
    component: withRouteContext(HomePage),
    tab: {
      route: "/home",
      icon: "menuHome",
      iconSelected: "menuHomeActive",
    },
  }),
  notifications: getRoute({
    rawRoute: "/notifications",
    component: withRouteContext(NotificationsPage),
  }),
  chat: getRoute<CommunicationParams>({
    rawRoute: "/communication/:communicationTab",
    component: withRouteContext(CommunicationPage),
    tab: {
      route: "/communication",
      icon: "menuMessaging",
      iconSelected: "menuMessagingActive",
      alternate: {
        icon: "menuMessagingNotification",
        iconSelected: "menuMessagingNotificationActive",
      },
    },
    defaultParam: {
      communicationTab: "previews",
    },
  }),
  chatDetail: getRoute<ChatDetailParams>({
    rawRoute: "/communication/chat/:chatId",
    component: withRouteContext(ChatDetailPage),
  }),
  channelDetail: getRoute<ChannelDetailParams>({
    rawRoute: "/communication/channel/:channelId",
    component: withRouteContext(ChannelDetailPage),
  }),
  calendarDailyEvent: getRoute<Omit<DailyEventParams, "eventId">>({
    rawRoute: "/communication/dailyEvents/:date",
    component: withRouteContext(DailyEventPage),
  }),
  calendarDailyEventWithId: getRoute<DailyEventParams>({
    rawRoute: "/communication/dailyEvents/:date/:eventId",
    component: withRouteContext(DailyEventPage),
  }),
  courses: getRoute({
    rawRoute: "/courses",
    component: withRouteContext(CoursesPage),
    tab: {
      route: "/courses",
      icon: "menuCourses",
      iconSelected: "menuCoursesActive",
    },
  }),
  courseDetail: getRoute<CourseDetailParams>({
    rawRoute: "/course/:courseId",
    component: withRouteContext(CourseDetailPage),
  }),
  testDetail: getRoute<TestParams>({
    rawRoute: "/course/:courseId/tests/:testId",
    component: withRouteContext(TestPage),
  }),
  passwordChange: getRoute({
    rawRoute: "/password-change",
    component: withRouteContext(PasswordChangePage),
  }),
  appInfo: getRoute({
    rawRoute: "/app-info",
    component: withRouteContext(AppInfoPage),
  }),
  userImpersonate: getRoute({
    rawRoute: "/user-impersonate",
    component: withRouteContext(UserImpersonatePage),
  }),
  performances: getRoute({
    rawRoute: "/performance",
    component: withRouteContext(PerformancePage),
    tab: {
      route: "/performance",
      icon: "menuPerformance",
      iconSelected: "menuPerformanceActive",
    },
  }),
  options: getRoute({
    rawRoute: "/options",
    component: withRouteContext(OptionsPage),
    tab: {
      route: "/options",
      icon: "menuUser",
      iconSelected: "menuUserActive",
    },
  }),
  profile: getRoute({
    rawRoute: "/options/profile",
    component: withRouteContext(ProfilePage),
  }),
  main: getRoute({
    rawRoute: "/main",
    component: MainPage,
    exact: false,
  }),
  passwordReset: getRoute({
    rawRoute: "/app-update",
    component: AppStoreUpdatePage,
    exact: false,
  }),
}

const publicRoutes = {
  login: getRoute({
    rawRoute: "/login",
    component: LoginPage,
    exact: false,
  }),
  passwordReset: getRoute({
    rawRoute: "/password-reset",
    component: PasswordResetPage,
    exact: false,
  }),
  introCarousel: getRoute({
    rawRoute: "/introcarousel",
    component: withRouteContext(IntroCarouselPage),
  }),
  index: getRoute({
    rawRoute: "/",
    component: withRouteContext(MainPage),
  }),
  home: getRoute({
    rawRoute: "/home",
    component: withRouteContext(MainPage),
  }),
}

const sharedRoutes = {
  androidAppUpdate: getRoute({
    rawRoute: "/android-app-update",
    component: withRouteContext(AndroidAppUpdate),
  }),
  iosAppUpdate: getRoute({
    rawRoute: "/ios-app-update",
    component: withRouteContext(AppleStoreUpdate),
  }),
}

const allRoutes = {
  ...privateRoutes,
  ...publicRoutes,
  ...sharedRoutes,
}
// const routeList = Object.values(appRoutes) as AppRoute[];
const privateRouteList = Object.values(privateRoutes)
const publicRouteList = Object.values(publicRoutes)
const sharedRouteList = Object.values(sharedRoutes)
const AllRouteList = Object.values(allRoutes)

export {
  privateRoutes,
  publicRoutes,
  sharedRoutes,
  privateRouteList,
  publicRouteList,
  sharedRouteList,
  allRoutes,
  AllRouteList,
}

import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { deploymentsSlice } from "./state/store"
import { useRootSelector } from "@modules/store/redux"

type Props = {
  children: React.ReactNode
}

const DEPLOYMENTS_WATCH_INTERVAL_MINUTES = 5

export const DeploymentsProvider = (props: Props) => {
  const dispatch = useDispatch()
  const { isInitialized } = useRootSelector((state) => state.app)

  useEffect(() => {
    if (isInitialized) {
      dispatch(deploymentsSlice.actions.updatesCheckTrigger())
    }

    const interval = setInterval(() => {
      dispatch(deploymentsSlice.actions.updatesCheckTrigger())
    }, DEPLOYMENTS_WATCH_INTERVAL_MINUTES * 60 * 1000)

    return () => {
      clearInterval(interval)
    }
  }, [isInitialized])

  return <>{props.children}</>
}

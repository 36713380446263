import { createSelector } from "@reduxjs/toolkit"
import { messagingSelector } from "./common"

export interface SelectDecodedChatMessages {
  chatId?: string
}

export const selectChatMessages = createSelector(
  messagingSelector,
  (_: any, input: SelectDecodedChatMessages) => input,
  ({ chatMessages }, input) => {
    if (!input?.chatId) {
      return undefined
    }

    const chat = chatMessages?.[input.chatId]
    if (!chat) {
      return undefined
    }
    return {
      messages: chat.messages.data?.filter((x) => !x.del) ?? [],
      hasMoreMessages: !!chat.messages.cursor,
      loading: chat.messages.loading,
    }
  }
)

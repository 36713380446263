import { newDocId } from "../../../../../infrastructure/firebase/utils"
import {
  bytesToMB,
  getFileExtension,
  isImage,
} from "../../../../../utils/files"
import {
  AttachFileItem,
  MessageAttachment,
  MessageAttachmentType,
  MessageContent,
  MessageDraft,
} from "../types"

export const newDraft = (): MessageDraft => ({
  messageId: newDocId(),
  content: {
    message: "",
  },
  sending: false,
  attachments: {
    items: [],
    uploadInProgress: false,
  },
})

export const messageSent = () => ({
  ...newDraft(),
  success: true,
  error: undefined,
})

export const messageSendError = (current: MessageDraft, error: any) => ({
  ...current,
  sending: false,
  error,
  success: false,
})

export const messageContentUpdate = (
  current: MessageDraft,
  content: MessageContent
) => ({
  ...current,
  content,
})

export const messageSendTrigger = (current: MessageDraft) => ({
  ...current,
  sending: true,
  error: undefined,
  success: undefined,
})

const getFileType = (extension: string): MessageAttachmentType => {
  if (isImage(extension)) {
    return "image"
  }

  return "file"
}

export const toMessageAttachment = (
  item: AttachFileItem
): MessageAttachment => ({
  id: item.id,
  name: item.file.name,
  sizeMB: bytesToMB(item.file.size),
  extension: getFileExtension(item.file.name) ?? "",
  type: getFileType(getFileExtension(item.file.name) ?? ""),
  uploaded: false,
  uploading: true,
  error: false,
})

import { ChannelDetailContainer } from "@containers/messaging/ChannelDetail"
import PageRoot from "@root/pages/PageRoot"

export interface ChannelDetailParams {
  channelId: string
}

export const ChannelDetailPage = () => {
  return (
    <PageRoot fullscreen>
      <ChannelDetailContainer />
    </PageRoot>
  )
}

import { RootState } from "@modules/store/redux/rootReducer"
import { createSelector as cs } from "@reduxjs/toolkit"
import { keys, last } from "ramda"
import { selectCourseProgress } from "../selectorsNew"
import { selectTests } from "./lists"

export const selectTestProgressAttemptId = cs(
  ({ courses }: RootState) => {
    const attempts =
      courses.coursesProgress[courses.selectedCourseId!]?.progress.tests?.[
        courses.selectedTestId!
      ]?.attempts ?? {}
    const attempt = last(
      keys(attempts)
        .map((x) => {
          return {
            passed: attempts[x].passed,
            index: Number.parseInt(x),
          }
        })
        .sort((a, b) => a.index - b.index)
    )
    if (attempt == null) {
      return "0"
    }
    if (attempt.passed) {
      return attempt.index.toString()
    }
    if (attempt.passed != null && !attempt.passed) {
      return (attempt.index + 1).toString()
    }
    return attempt.index.toString()
  },
  (id) => id
)

export const selectTestViewIndex = ({ courses }: RootState) => {
  const attempt = courses.coursesProgress[courses.selectedCourseId!]
  return attempt?.viewIndex ?? 0
}
export const selectTestId = ({ courses }: RootState) => courses.selectedTestId
export const selectTestById = (testId: string) =>
  cs(selectTests, (data) => data.find((x) => testId === x.id)!)

export const selectTestAttemptIndex = ({ courses }: RootState) => {
  const attempt = courses.coursesProgress[courses.selectedCourseId!]
  return (
    Object.keys(
      attempt?.progress?.tests?.[courses.selectedTestId!]?.attempts ?? {}
    ).length - 1
  )
}

export const selectTest = cs(
  selectTests,
  selectTestId,
  (data, id) => data?.find((x) => id === x.id)!
)
export const selectTestProgress = cs(
  selectCourseProgress,
  selectTestId,
  selectTestProgressAttemptId,
  (data, testId, attemptId) =>
    data?.progress.tests?.[testId ?? ""]?.attempts?.[attemptId]
)

export const selectAreTestsEnabled = ({ courses }: RootState) => {
  const course = courses.courses[courses.selectedCourseId!]
  const courseProgress = courses.coursesProgress[courses.selectedCourseId!]
  if (courseProgress && courseProgress.viewIndex > 0) {
    return true
  }

  const numFiles = course.data.attachments.items.length
  const numVideos = course.data.contents.chapters.length
  const numCompletedFiles =
    Object.values(courseProgress?.progress.attachments ?? {}).filter(
      (x) => x.completed
    ).length ?? 0
  const numCompletedVideos =
    Object.values(courseProgress?.progress.chapters ?? {}).filter(
      (x) => x.completed
    ).length ?? 0
  return numFiles === numCompletedFiles && numVideos === numCompletedVideos
}

export const selectIsTestCompleteById = (testId: string) =>
  cs(
    selectCourseProgress,
    (progress) =>
      Object.values(progress?.progress.tests?.[testId]?.attempts ?? {}).find(
        (x) => x.passed
      ) != null
  )

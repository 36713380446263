import { PayloadAction } from "@reduxjs/toolkit"
import { buffers, eventChannel } from "redux-saga"
import { put, take, cancelled, takeLatest } from "typed-redux-saga"
import { getSagaContext } from "../../../state/context"
import { performanceRoutines } from "../state"
import { StoreRef, WatchStoreSalesProgressResult } from "../state/types"

const DAYS_TO_WATCH = 7

export function* watchStoreSalesProgressSaga(action: PayloadAction<StoreRef>) {
  const { payload } = action

  const firebase = yield* getSagaContext("firebase")
  const storeRef = {
    ...payload,
    daysToWatch: DAYS_TO_WATCH,
  }
  const channel = eventChannel<WatchStoreSalesProgressResult>((emitter) => {
    const unsubscribe =
      firebase.collections.objectivesStoreController.subscribeLastAggregationItems(
        storeRef.storeCode.toLowerCase(),
        storeRef.daysToWatch,
        (data) => {
          emitter({
            store: storeRef,
            items: data,
          })
        }
      )
    return () => unsubscribe()
  }, buffers.sliding(1))

  try {
    yield* takeLatest(
      performanceRoutines.watchStoreSalesProgress.fulfill,
      function* () {
        channel.close()
      }
    )
    while (true) {
      const data = yield* take(channel)
      yield* put(performanceRoutines.watchStoreSalesProgress.success(data))
    }
  } catch (error) {
    yield* put(performanceRoutines.watchStoreSalesProgress.failure(error))
  } finally {
    if (yield* cancelled()) {
      channel.close()
    }
  }
}

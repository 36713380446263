import React from "react"
import { createPortal } from "react-dom"
import { useRootSelector } from "@modules/store/redux"
import classNames from "classnames"
import { useTranslations } from "../hooks/useTranslations"
import LoaderBar from "@ui/molecules/LoaderBar"

type Props = {
  children: React.ReactNode
}

const SplashScreenProvider = ({ children }: Props) => {
  const { t } = useTranslations()
  const splashScreen = useRootSelector((state) => state.app.splashScreen)

  const rootElemRef = React.useRef(document.querySelector("body"))
  if (rootElemRef.current == null) {
    return null
  }
  rootElemRef.current.focus()

  return (
    <>
      {children}
      {createPortal(
        <div
          className={classNames(
            "fixed inset-0 z-50 transition-opacity duration-1000 w-full h-full",
            splashScreen === "NONE"
              ? "opacity-0 touch-none pointer-events-none"
              : "opacity-100"
          )}
        >
          <div
            className="relative bg-cover bg-center !max-w-[var(--max-container-width)] w-full h-full mx-auto"
            style={{
              backgroundImage: "url(/assets/images/splash-mobile.png)",
            }}
          >
            {splashScreen === "UPDATING" && (
              <LoaderBar absolute>
                {t("common:splash.updating.message")}
              </LoaderBar>
            )}
          </div>
        </div>,
        rootElemRef.current
      )}
    </>
  )
}

export { SplashScreenProvider }

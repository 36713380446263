import { FunctionsClient } from "@common/common/api"
import { UserDeviceRegistrationData } from "@common/common/api/functions"
import { buildAppVersionPayload, buildDeviceInfoPayload } from "./converters"

export const deviceRegister = async (input: { fcmToken: string }) => {
  try {
    console.log("deviceRegister -> started")
    const data: UserDeviceRegistrationData = {
      fcmToken: input.fcmToken,
      info: await buildDeviceInfoPayload(),
      version: await buildAppVersionPayload(),
    }
    console.log("deviceRegister -> sending", data)

    await FunctionsClient.deviceRegister(data)

    console.log("deviceRegister -> completed")
  } catch (e) {
    console.error("deviceRegister -> error", e)
  }
}

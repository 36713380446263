import { FunctionsClient } from "@common/common/api"
import { useOperation } from "@punks/ui-core"

export type ChatMessagesMarkDeletedInput = {
  chatId: string
  messageIds: string[]
}

export const useChatMessagesDelete = () => {
  return useOperation({
    operation: async (input: ChatMessagesMarkDeletedInput) => {
      for (const messageId of input.messageIds) {
        await FunctionsClient.markDeletedChatMessage({
          senderChatId: input.chatId,
          messageId: messageId,
        })
      }
    },
  })
}

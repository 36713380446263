import { createSelector } from "@reduxjs/toolkit"
import { messagingSelector } from "./common"
import { orderBy } from "lodash"
import { MessagingPreview } from "../types"

export const selectChannels = createSelector(
  messagingSelector,
  (state) => state.channels
)

export interface MessagingPreviewItems {
  loading: boolean
  items: MessagingPreview[]
}

export const selectMessagingPreviews = createSelector(
  messagingSelector,
  ({ chatPreviews, channelPreviews }): MessagingPreviewItems => {
    if (chatPreviews.loading || channelPreviews.loading) {
      return {
        loading: true,
        items: [],
      }
    }

    return {
      loading: false,
      items: orderBy(
        [
          ...(chatPreviews.data ?? [])
            .filter((x) => !x.data.hidden)
            .map(
              (x) =>
                ({
                  item: x,
                  ref: {
                    id: x.id,
                    type: "chat",
                  },
                  updateTime: x.data.lastMessageTime,
                  displayName: [
                    x.data.receiverInfo.profile.firstName,
                    x.data.receiverInfo.profile.lastName,
                  ].join(" "),
                  recipientId: x.data.receiverId,
                } as MessagingPreview)
            ),
          ...(channelPreviews.data ?? [])
            .filter((x) => !x.data.hidden)
            .map(
              (x) =>
                ({
                  item: x,
                  ref: {
                    id: x.data.channelId,
                    type: "channel",
                  },
                  updateTime: x.data.lastMessageTime,
                  displayName: x.data.displayName,
                  recipientId: x.data.channelId,
                } as MessagingPreview)
            ),
        ],
        (x) => x.updateTime ?? 0,
        "desc"
      ),
    }
  }
)

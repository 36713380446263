import CourseTestContainer from "@containers/courses/CourseTest"
import PageRoot from "@root/pages/PageRoot"

export interface TestParams {
  courseId: string
  testId: string
}

//todo CHORE
//USE QUERY PARAMS?
const TestPage = () => {
  return (
    <PageRoot fullscreen>
      <CourseTestContainer />
    </PageRoot>
  )
}

export { TestPage }

import { Header } from "@molecules"
import classNames from "classnames"
import React, { useEffect, useRef, useState } from "react"
import { useChapterProgress } from "../../../hooks/chapterProgress"
import PlayButton from "../PlayButton"
import { CourseVideo } from "@package/common/api/functions"

type Props = {
  onBackArrowClick: () => void
}

const isVideoPlaying = (video: HTMLVideoElement) =>
  !!(
    video.currentTime > 0 &&
    !video.paused &&
    !video.ended &&
    video.readyState > 2
  )

const CourseDetailVideoBox = ({ onBackArrowClick }: Props) => {
  const {
    onEnded,
    onPause,
    onPlay,
    onLoaded,
    // onSeeking,
    onTimeUpdate,
    // setVideoRef,
    isVideoLoaded,
    isPlaying,
    // play,
    video,
  } = useChapterProgress()

  const ref = useRef<HTMLVideoElement>(null)
  const [currentVideo, setCurrentVideo] = useState<CourseVideo>()

  useEffect(() => {
    if (ref.current && !isVideoPlaying(ref.current) && isPlaying) {
      ref.current?.play()
    }
  }, [isPlaying])

  useEffect(() => {
    setCurrentVideo(video)
    if (currentVideo && ref.current) {
      ref.current.load()
    }
  }, [video])

  return (
    <div
      className={classNames(
        "relative flex items-center justify-center w-full",
        isVideoLoaded
          ? "bg-dark"
          : "h-[269px] bg-gradient-to-b from-black/50 to-dark"
      )}
    >
      <div
        className={classNames(
          isPlaying && "!hidden",
          "flex justify-center items-center absolute top-0 z-50 w-full h-full bg-gradient-to-b from-black/50 to-dark"
        )}
      >
        <Header
          showArrow
          onClick={onBackArrowClick}
          paddings={{ bottom: 0 }}
          className={
            "bg-[transparent !text-white !absolute top-0 left-0 w-full"
          }
        />
        <PlayButton
          classes={{ container: "w-[56px] h-[56px]" }}
          // onClick={play}
          onClick={onPlay}
        />
      </div>
      <div className={classNames("relative")}>
        <video
          ref={ref}
          // ref={setVideoRef}
          onLoadStart={onLoaded}
          onTimeUpdate={onTimeUpdate}
          // onSeeking={onSeeking}
          onEnded={onEnded}
          onPause={onPause}
          onPlay={onPlay}
          className={classNames(
            "w-full h-full  max-h-[269px]",
            !isVideoLoaded && "hidden"
          )}
          controls
          controlsList="nodownload"
        >
          <source src={video?.url} />
        </video>
      </div>
    </div>
  )
}

export default React.memo(CourseDetailVideoBox)

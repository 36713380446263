import AppStoreUpdateContainer from "@containers/update/AndroidUpdateContainer"
import PageRoot from "@root/pages/PageRoot"

export const AppStoreUpdatePage = () => {
  return (
    <PageRoot fullscreen>
      <AppStoreUpdateContainer />
    </PageRoot>
  )
}

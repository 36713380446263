import { compose, concat, converge, head, join, map, split, tail, toUpper } from "ramda";

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const trimEnd = (value: string, char: string): string => {
  if (!value.endsWith(char)) {
    return value
  }

  return trimEnd(value.substring(0, value.length - 1), char)
}

export const base64ToFile = (base64: string, mime: string, fileName: string) => {
  var bs = window.atob(base64);
    var buffer = new ArrayBuffer(bs.length);
    var ba = new Uint8Array(buffer);
    for (var i = 0; i < bs.length; i++) {
        ba[i] = bs.charCodeAt(i);
    }
    var blob = new Blob([ba], { type: mime }) as any;
    return new File([blob], fileName, {
      lastModified: new Date().getTime(),
      type: mime
    })
}

export const capitalize =
  (converge(
    //@ts-expect-error
    concat,
    [
      compose(
        toUpper,
        head
      ),
      tail,
    ]
  ) as any) as (d:string)=>string;

export const toTitleCase =
  (compose(
    join(' '),
    map(capitalize),
    split(' ')
  ) as any)  as (d:string)=>string;
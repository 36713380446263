import NotificationsListContainer from "@containers/notifications/NotificationsList/NotificationsContainer"
import PageRoot from "@root/pages/PageRoot"

export interface CourseDetailParams {
  courseId: string
}

const NotificationsPage = () => {
  return (
    <PageRoot fullscreen>
      <NotificationsListContainer />
    </PageRoot>
  )
}

export { NotificationsPage }

import { ChatPreviewItem } from "./chat-preview.controller"
import { ChatPreviewEntity } from "./chat-preview.entity"

export const normalizeChatPreviewEntity = (
  item: ChatPreviewEntity
): ChatPreviewEntity => ({
  ...item,
  data: {
    ...item.data,
    lastMessageTime: item.data.lastMessageTime?.toMillis(),
    lastMessage: item.data.lastMessage
      ? {
          ...item.data.lastMessage,
          t: item.data.lastMessage.t?.toMillis(),
        }
      : undefined,
  },
})

export const normalizeChatPreviewItem = (
  item: ChatPreviewItem
): ChatPreviewItem => ({
  ...item,
  entity: normalizeChatPreviewEntity(item.entity),
})

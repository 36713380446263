import classNames from "classnames"
import { ThemeColors } from "@helpers/ui"
import { IonProgressBar } from "@ionic/react"

type BaseProps = Omit<
  React.ComponentProps<typeof IonProgressBar>,
  "class" | "className" | "color" | "style" | "children"
>

interface Props extends BaseProps {
  color?: ThemeColors
  value: number
  classes?: {
    container?: string
    bar?: string
  }
  styles?: {
    "--background"?: string
    "--progress-background"?: string
  }
}

const ProgressBar = ({ classes, value, color, styles, ...rest }: Props) => {
  return (
    <div
      className={classNames("flex gap-[14px] items-center", classes?.container)}
    >
      <IonProgressBar
        className={classNames(classes?.bar)}
        style={styles}
        value={value}
        {...rest}
      />
      <div className="text-sm leading-[1.33] text-light ">{`${Math.round(
        value * 100
      )}%`}</div>
    </div>
  )
}

export default ProgressBar

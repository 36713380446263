import { decodeKey } from "../../modules/encryption/common"
import {
  encryptMessage as encrypt,
  decryptMessage as decrypt,
} from "../../modules/encryption/symmetric"
import { decodeBase64, encodeBase64 } from "../../utils/encoding"

export interface EncryptMessageInput {
  message: string
  subject?: string
  privateKey: string
}

export const encryptMessage = (input: EncryptMessageInput) => {
  const privateKey = decodeKey(input.privateKey)
  const messageData = encrypt({
    message: input.message,
    privateKey,
  })

  const subjectData = input.subject
    ? encrypt({
        message: input.subject,
        privateKey,
        oneTimeCode: messageData.oneTimeCode,
      })
    : undefined

  return {
    message: encodeBase64(messageData.encryptedMessage),
    subject: subjectData ? encodeBase64(subjectData.encryptedMessage) : null,
    oneTimeCode: encodeBase64(messageData.oneTimeCode),
  }
}

export interface DecryptMessageInput {
  message: string
  subject?: string
  privateKey: string
  oneTimeCode: string
}

const decryptMessageBody = (
  message: string,
  privateKey: Buffer,
  oneTimeCode: Buffer
) => {
  try {
    return decrypt({
      encryptedMessage: decodeBase64(message),
      privateKey,
      oneTimeCode,
    })
  } catch (e) {
    console.error(
      `Error decrypting message -> ${message} -> ${privateKey} -> ${oneTimeCode}`,
      e
    )
    return undefined
  }
}

const decryptMessageSubject = (
  subject: string,
  privateKey: Buffer,
  oneTimeCode: Buffer
) => {
  try {
    return decrypt({
      encryptedMessage: decodeBase64(subject),
      privateKey,
      oneTimeCode,
    })
  } catch (e) {
    console.error(
      `Error decrypting subject -> ${subject} -> ${privateKey} -> ${oneTimeCode}`,
      e
    )
    return undefined
  }
}

export const decryptMessage = (input: DecryptMessageInput) => {
  const privateKey = decodeKey(input.privateKey)
  const oneTimeCode = decodeBase64(input.oneTimeCode)

  const messageData = decryptMessageBody(input.message, privateKey, oneTimeCode)

  const subjectData = input.subject
    ? decryptMessageSubject(input.subject, privateKey, oneTimeCode)
    : undefined

  // const messageData = input.message
  //   ? decrypt({
  //       encryptedMessage: decodeBase64(input.message),
  //       privateKey,
  //       oneTimeCode,
  //     })
  //   : undefined

  // const subjectData = input.subject
  //   ? decrypt({
  //       encryptedMessage: decodeBase64(input.subject),
  //       privateKey,
  //       oneTimeCode,
  //     })
  //   : undefined

  return {
    message: messageData?.message ?? "",
    subject: subjectData?.message,
  }
}

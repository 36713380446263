import { ChatDetailParams } from "@pages/private/Communication/ChatDetail"
import { useRouteContext } from "@modules/routing/context"
import {
  ChatHeader,
  ChatMessageList,
  ChatToolbar,
} from "../../../features/messaging/components/Chat"
import {
  MessagingDetailHeader,
  Footer,
  Body,
} from "../../../features/messaging/components/common"
import { useMessagingMode } from "@features/messaging/components/common/MessageListItem/hooks/useMessagingMode"
import { useMessagesSelect } from "@features/messaging/components/common/MessageListItem/hooks/useMessagesSelect"
import { useEffect } from "react"
import { useChatMessagesDelete } from "@features/messaging/hooks/useChatMessagesDelete"
import LoaderBar from "@ui/molecules/LoaderBar"
import { useTranslations } from "@modules/app/hooks/useTranslations"

const ChatDetailContainer = () => {
  const { t } = useTranslations()
  const {
    match: {
      params: { chatId },
    },
  } = useRouteContext<ChatDetailParams>()

  const messagingMode = useMessagingMode()
  const selectedMessages = useMessagesSelect()

  useEffect(() => {
    selectedMessages.clear()
  }, [chatId])

  const messagesDelete = useChatMessagesDelete()

  const handleDelete = async () => {
    await messagesDelete.invoke({
      chatId,
      messageIds: selectedMessages.selected.map((m) => m.id),
    })
    messagingMode.setDefaultMode()
  }

  return (
    <>
      <MessagingDetailHeader hideArrow={messagingMode.mode === "select"}>
        <ChatHeader
          chatId={chatId}
          messagingMode={messagingMode}
          selectedMessages={selectedMessages}
          onDeleteConfirmed={handleDelete}
          deleteInProgress={messagesDelete.state.loading}
        />
      </MessagingDetailHeader>
      <Body
        backgroundPattern={{
          imageUrl: "/assets/images/chat-bg-pattern-2.jpeg",
          size: "320px 320px",
          opacity: 0.15,
        }}
      >
        <ChatMessageList
          chatId={chatId}
          messagingMode={messagingMode}
          selectedMessages={selectedMessages}
        />
      </Body>
      <Footer noRounding>
        <ChatToolbar chatId={chatId} />
      </Footer>
      <LoaderBar show={messagesDelete.state.loading}>
        {t("messaging:chat.messages.deleting")}
      </LoaderBar>
    </>
  )
}

export { ChatDetailContainer }

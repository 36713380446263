import { MessageItem } from "@features/messaging/types"
import { useState } from "react"

export const useMessagesSelect = () => {
  const [selectedMessages, setSelectedMessages] = useState<MessageItem[]>([])

  const toggleMessageSelect = (message: MessageItem) => {
    if (selectedMessages?.includes(message)) {
      setSelectedMessages(selectedMessages.filter((m) => m !== message))
    } else {
      setSelectedMessages([...selectedMessages, message])
    }
  }

  const isMessageSelected = (message: MessageItem) => {
    return selectedMessages?.includes(message)
  }

  const clearSelectedMessages = () => {
    setSelectedMessages([])
  }

  return {
    selected: selectedMessages,
    toggle: toggleMessageSelect,
    isSelected: isMessageSelected,
    clear: clearSelectedMessages,
  }
}

export type UseMessagesSelectData = ReturnType<typeof useMessagesSelect>

import CoursesContainer from "@containers/courses/Courses"
import PageRoot from "@root/pages/PageRoot"

export const CoursesPage = () => {
  return (
    <PageRoot>
      <CoursesContainer />
    </PageRoot>
  )
}

import { getSagaContext } from "@modules/store/redux/sagaContext"
import { call, put } from "typed-redux-saga"
import { authActions } from "../state"

export function* logoutSaga() {
  const firebase = yield* getSagaContext("firebase")
  const routine = authActions.logout
  try {
    yield* call(firebase.logout)
    yield* put(routine.success())
  } catch (error) {
    yield* put(routine.failure())
  }
}

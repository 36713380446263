import { IonContent } from "@ionic/react"

export interface WidgetsContainerProps {
  children: any
}

export const WidgetsContainer = ({ children }: WidgetsContainerProps) => {
  return (
    <IonContent fullscreen>
      <div className="flex flex-col gap-[32px] p-container mb-[var(--footer-height)]">
        {children}
      </div>
    </IonContent>
  )
}

import { PayloadAction } from "@reduxjs/toolkit"
import { call } from "typed-redux-saga"
import { DefaultClient } from "../../../../api/functions"
import { ChannelSendReadNotificationInput } from "../state/types"

export function* sendChannelReadNotification(
  action: PayloadAction<ChannelSendReadNotificationInput>
) {
  const { payload } = action
  yield* call(DefaultClient.sendChannelReadNotification, {
    channelId: payload.channelId,
    lastMessageId: payload.lastMessageId,
    timestamp: new Date().getTime(),
    type: payload.type,
  })
}

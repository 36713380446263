import { useRootSelector } from "@modules/store/redux"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { calendarActions } from "./state"

type Props = {
  children: React.ReactNode
}

const CalendarProvider = (props: Props) => {
  const dispatch = useDispatch()
  const user = useRootSelector((state) => state.userProfile.user.data)
  useEffect(() => {
    if (user) {
      console.log("CalendarProvider -> watching user calendars", user)
      dispatch(calendarActions.watchCalendars.trigger(user))
    }
    return () => {
      if (user) {
        dispatch(calendarActions.watchCalendars.fulfill())
      }
    }
  }, [user])

  return <>{props.children}</>
}

export { CalendarProvider }

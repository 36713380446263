import { useTranslations } from "./useTranslations"

const MONTH_KEYS: Record<number, string> = {
  1: "january",
  2: "february",
  3: "march",
  4: "april",
  5: "may",
  6: "june",
  7: "july",
  8: "august",
  9: "september",
  10: "october",
  11: "november",
  12: "december",
}

export const useMonthNames = () => {
  const { t } = useTranslations()

  const getMonthFullName = (month: number) =>
    t(`common:months.${MONTH_KEYS[month]}.full`)

  const getMonthShortName = (month: number) =>
    t(`common:months.${MONTH_KEYS[month]}.initial`)

  return {
    getMonthFullName,
    getMonthShortName,
  }
}

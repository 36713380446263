import { PushNotifications, Token } from "@capacitor/push-notifications"
import {
  INotificationsProvider,
  ServiceListener,
  ReceivePermissionState,
} from "../abstractions"

export class CapacitorPushHandler implements INotificationsProvider {
  async requestPermissions(): Promise<ReceivePermissionState> {
    const permissions = await PushNotifications.requestPermissions()
    console.log(`CapacitorPush | requestPermissions -> ${permissions.receive}`)
    return permissions.receive
  }

  async checkReceivePermissions(): Promise<ReceivePermissionState> {
    const permissions = await PushNotifications.checkPermissions()
    console.log(`CapacitorPush | checkPermissions -> ${permissions.receive}`)
    return permissions.receive
  }

  async register(): Promise<void> {
    try {
      console.log(`CapacitorPush | register -> start`)
      await PushNotifications.register()
      console.log(`CapacitorPush | register -> completed`)
    } catch (e) {
      console.error(`CapacitorPush | register -> error`, e)
    }
  }

  async unregister(): Promise<void> {
    console.log(`CapacitorPush | unregister -> not implemented`)
    // await PushNotifications.
    // console.log(`CapacitorPush | unregister -> completed`)
  }

  async removeAllListeners(): Promise<void> {
    console.log(`CapacitorPush | removeAllListeners -> start`)
    await PushNotifications.removeAllListeners()
    console.log(`CapacitorPush | removeAllListeners -> completed`)
  }

  async subscribeToken(
    handler: (token: string) => void
  ): Promise<ServiceListener> {
    const listener = await PushNotifications.addListener(
      "registration",
      (token: Token) => {
        console.log(
          `CapacitorPush | subscribeToken -> new token: ${token.value}`
        )
        handler(token.value)
      }
    )
    console.log(`CapacitorPush | subscribeToken -> completed`)
    return listener
  }

  async subscribeNotifications(
    handler: (notification: any) => void
  ): Promise<ServiceListener> {
    const listener = await PushNotifications.addListener(
      "pushNotificationReceived",
      (x) => {
        console.log(`CapacitorPush | subscribeNotifications -> mew message`, x)
        handler(x.data)
      }
    )
    console.log(`CapacitorPush | subscribeNotifications -> completed`)
    return listener
  }
}

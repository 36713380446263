/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CourseData } from './CourseData';
import type { CourseStats } from './CourseStats';

export type CourseEntity = {
    id: string;
    data: CourseData;
    metadata: CourseStats;
    status: CourseEntity.status;
    createdAt: string;
    updatedAt: string;
};

export namespace CourseEntity {

    export enum status {
        DRAFT = 'draft',
        SUBMITTED = 'submitted',
        PUBLISHED = 'published',
        REJECTED = 'rejected',
        ARCHIVED = 'archived',
    }


}


import PerformanceOverviewContainer from "@containers/performances/PerformanceOverview"
import PageRoot from "@root/pages/PageRoot"

const PerformancePage = () => {
  return (
    <PageRoot>
      <PerformanceOverviewContainer />
    </PageRoot>
  )
}

export { PerformancePage }

import { PageTitle } from "@atoms"
import { setCssVar } from "@helpers/ui"
import { IonContent, IonHeader, IonToolbar } from "@ionic/react"
import { Header, Tabs } from "@molecules"
import {
  DailySalesPerformances,
  WeeklySalesPerformances,
} from "../../../features/performance/components"
import { ObjectiveBoxesGrid } from "../../../features/performance/components/ObjectiveBoxesGrid"
import PerformanceStoreSelector from "../../../features/performance/components/PerformanceStoreSelector"

type Props = {}

const PerformanceOverviewContainer = (props: Props) => {
  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar
          className="flex items-end"
          style={{
            ...setCssVar("--min-height", 0),
          }}
        >
          <Header>
            <PageTitle text="Performance" />
          </Header>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="flex flex-col gap-[32px] p-container mb-[var(--footer-height)]">
          <PerformanceStoreSelector />
          <DailySalesPerformances />
          <WeeklySalesPerformances />
          <ObjectiveBoxesGrid />
          {/* <UsagePerformances /> */}
        </div>
      </IonContent>
    </>
  )
}

export default PerformanceOverviewContainer

import { AppCarousel } from "@package/common/types/contents/carousel"
import { EditorialCarousel } from "@organisms"

type IntroCarouselProps = {
  carouselData?: AppCarousel
}

const IntroCarousel = ({ carouselData }: IntroCarouselProps) => {
  if (!carouselData?.cta || !carouselData?.slides) {
    return <></>
  }

  return (
    <EditorialCarousel cta={carouselData.cta} slides={carouselData.slides} />
  )
}

export default IntroCarousel

import { PayloadAction } from "@reduxjs/toolkit"
import { call } from "typed-redux-saga"
import { DefaultClient } from "../../../../api/functions"
import { ChatSendReadNotificationInput } from "../state/types"

export function* sendChatReadNotification(
  action: PayloadAction<ChatSendReadNotificationInput>
) {
  const { payload } = action
  if (!payload.lastMessageId) {
    return
  }

  yield* call(DefaultClient.sendChatReadNotification, {
    chatId: payload.chatId,
    lastMessageId: payload.lastMessageId,
    timestamp: new Date().getTime(),
    type: payload.type,
  })
}

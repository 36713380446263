import { IonFooter, IonToolbar } from "@ionic/react"
import classNames from "classnames"
import React, { ReactNode } from "react"

interface Props {
  children: ReactNode
  noRounding?: boolean
}

const Footer = ({ children, noRounding }: Props) => {
  return (
    <IonFooter className="ion-no-border">
      <IonToolbar
        className={classNames("footer-toolbar ion-no-border", {
          "!rounded-none": noRounding,
        })}
      >
        {children}
      </IonToolbar>
    </IonFooter>
  )
}

export { Footer }

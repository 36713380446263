import { calendarActions } from "@features/calendar/state"
import { selectCalendarStores } from "@features/calendar/selectors"
import { CalendarStoreRef } from "@features/calendar/selectors/types"
import { useRootSelector } from "@modules/store/redux"
import { ItemsSelector } from "@molecules"
import { useDispatch } from "react-redux"
import { useTranslations } from "@modules/app/hooks/useTranslations"

const CalendarStoreSelector = () => {
  const { t } = useTranslations()
  const { allStores, filteredStores } = useRootSelector(selectCalendarStores)
  const selectedStore = filteredStores?.[0]
  const dispatch = useDispatch()

  console.log("calendars", allStores, filteredStores, selectedStore)

  // const [selectedStore, setSelectedStore] = useState<
  //   CalendarStoreRef | undefined
  // >(stores.find(x => x.storeId === selectedCalendarIds[0]))

  // React.useEffect(() => {
  //   setSelectedStore(stores[0])
  // }, [stores])

  if (allStores.length <= 1) {
    return <></>
  }

  const handleSelectStore = (store?: CalendarStoreRef) => {
    if (!store) {
      return
    }

    dispatch(
      calendarActions.selectStoreCalendars({
        storeIds: [store.storeId],
      })
    )
  }

  return (
    <ItemsSelector
      id="calendars-store-selector"
      items={
        allStores?.map((x) => ({
          id: x.storeId,
          label: x.storeName,
        })) ?? []
      }
      onSelect={(store) =>
        handleSelectStore(allStores.find((x) => x.storeId === store.id))
      }
      title={t("calendars:store.select.cta")}
      selected={
        selectedStore
          ? {
              id: selectedStore.storeId,
              label: selectedStore.storeName,
            }
          : undefined
      }
    />
  )
}

export default CalendarStoreSelector

import { CourseEntity } from "@common/common/api/functions"

export const getCourseThumbUrl = (course: CourseEntity, width = 600) => {
  const url = course.data.contents.chapters[0]?.video?.thumbnail?.url
  if (!url) {
    return url
  }

  return url.split("?")[0] + `?w=${width}`
}

import { useRootSelector } from "@modules/store/redux"
import { useRouting } from "@root/routing/useRouting"
import { useEffect } from "react"

const MainPage = () => {
  const isIntroCarouselChecked = useRootSelector(
    (state) => state.app.isIntroCarouselChecked
  )

  const { goTo } = useRouting()
  useEffect(() => {
    goTo(isIntroCarouselChecked ? "login" : "introCarousel")
  }, [])
  return <></>
}

export default MainPage

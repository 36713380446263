import { ActionSheetButton, useIonActionSheet } from "@ionic/react"
import MenuButton from "@ui/atoms/MenuButton"

export interface ContextMenuProps {
  actions: ActionSheetButton[]
  title: string
}

const ContextMenu = ({ actions, title }: ContextMenuProps) => {
  const [present] = useIonActionSheet()

  const handleOpen = () => {
    present({
      buttons: actions,
      header: title,
    })
  }

  return (
    <>
      <MenuButton onClick={handleOpen} />
    </>
  )
}

export default ContextMenu

import { orderBy, uniqBy } from "lodash"

export interface SortableMessage {
  id: string
  t: number
}

export const mergeMessages = <T extends SortableMessage>(
  list1: T[],
  list2: T[]
): T[] => {
  return orderBy(
    Object.values<T>(
      list1.concat(list2).reduce((o, v) => {
        ;(o as any)[v.id] = { ...v }
        return o
      }, {})
    ),
    (x) => x.t
  )

  // const messages = orderBy(
  //   uniqBy([...list1, ...list2], (x) => x.id),
  //   (x) => x.t
  // )
  // return messages
}

import classNames from "classnames"
import { IonIcon } from "@ionic/react"

const iconsMapping = {
  login: "/assets/icons/login.svg",
  menuHome: "/assets/icons/menu/home.svg",
  menuHomeActive: "/assets/icons/menu/home-active.svg",
  menuMessaging: "/assets/icons/menu/messaging.svg",
  menuMessagingActive: "/assets/icons/menu/messaging-active.svg",
  menuMessagingNotification: "/assets/icons/menu/messaging-notification.svg",
  menuMessagingNotificationActive:
    "/assets/icons/menu/messaging-notification-active.svg",
  menuCourses: "/assets/icons/menu/courses.svg",
  menuCoursesActive: "/assets/icons/menu/courses-active.svg",
  menuPerformance: "/assets/icons/menu/performance.svg",
  menuPerformanceActive: "/assets/icons/menu/performance-active.svg",
  menuUser: "/assets/icons/menu/user.svg",
  menuUserActive: "/assets/icons/menu/user-active.svg",
  bannerFormazione: "/assets/icons/banner-formazione.svg",
  bannerPerformance: "/assets/icons/banner-performance.svg",
  bannerComunicazioni: "/assets/icons/banner-comunicazioni.svg",
  bannerNuoviCorsi: "/assets/icons/banner-nuovi-corsi.svg",
  bannerCategoria: "/assets/icons/banner-categoria.svg",
  arrowForward: "/assets/icons/arrow-forward-outline.svg",
  arrowBack: "/assets/icons/arrow-back-outline.svg",
  check: "/assets/icons/check.svg",
  clock: "/assets/icons/clock.svg",
  play: "/assets/icons/play.svg",
  check2: "/assets/icons/check2.svg",
  locked: "/assets/icons/lock-closed.svg",
  send: "/assets/icons/send.svg",
  download: "/assets/icons/download.svg",
  download2: "/assets/icons/download2.svg",
  attachment: "/assets/icons/attachment.svg",
  camera: "/assets/icons/camera.svg",
  document: "/assets/icons/document-outline.svg",
  close: "/assets/icons/close-circle-outline.svg",
  leftCaret: "/assets/icons/left-caret.svg",
  rightCaret: "/assets/icons/right-caret.svg",
  downCaret: "/assets/icons/down-caret.svg",
  profile: "/assets/icons/profile.svg",
  logout: "/assets/icons/logout.svg",
  location: "/assets/icons/location.svg",
  user: "/assets/icons/user.svg",
  notification: "/assets/icons/notification.svg",
  notificationActive: "/assets/icons/notification-active.svg",
  shield: "/assets/icons/shield.svg",
  calendar: "/assets/icons/calendar.svg",
  support: "/assets/icons/support.svg",
  camera2: "/assets/icons/camera2.svg",
  key: "/assets/icons/key.svg",
  eyeOpen: "/assets/icons/eye-open.svg",
  eyeClosed: "/assets/icons/eye-closed.svg",
  trash: "/assets/icons/trash.svg",
  closeX: "/assets/icons/close.svg",
  "": "",
}
export type IconNames = keyof typeof iconsMapping
type BaseProps = Omit<
  React.ComponentProps<typeof IonIcon>,
  "class" | "color" | "style" | "children" | "name"
>

interface Props extends BaseProps {
  iconName?: IconNames
  wFull?: boolean
  hFull?: boolean
  sizeFull?: boolean
}

const Icon = ({
  iconName,
  src,
  className,
  wFull,
  hFull,
  sizeFull,
  ...rest
}: Props) => {
  return (
    <IonIcon
      className={classNames(
        "block",
        {
          "w-full": wFull || sizeFull,
          "h-full": hFull || sizeFull,
        },
        className
      )}
      src={src ?? iconsMapping[iconName ?? ""]}
      {...rest}
    />
  )
}

export default Icon

export type IconType = Props["iconName"]

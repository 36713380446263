import { PayloadAction } from "@reduxjs/toolkit"
import { buffers, eventChannel } from "redux-saga"
import { put, call, cancelled, take, takeLatest } from "typed-redux-saga"
import { UserPrivateKey } from "../../../../database/security/userKeys/privateKey.entity"
import { getSagaContext } from "../../../state/context"
import { UserKey, WatchUserKeysInput } from "../state/types"

const USER_KEY_KEY = "_messaging:user_key"

export function* watchUserKey(payload: PayloadAction<WatchUserKeysInput>) {
  const state = yield* getSagaContext("state")
  const store = yield* getSagaContext("store")
  const firebase = yield* getSagaContext("firebase")
  const cachedKeys = yield* call(
    (key: string) => store.get<UserKey>(key),
    USER_KEY_KEY
  )
  if (cachedKeys) {
    yield* put(state.messaging.actions.setUserKey(cachedKeys))
  }

  const channel = eventChannel<UserPrivateKey>((emitter) => {
    const unsubscribe = firebase.collections.userPrivateKeys.subscribeUserKey(
      payload.payload.uid,
      (data) => emitter(data)
    )
    return () => unsubscribe()
  }, buffers.sliding(1))

  try {
    yield* takeLatest(
      state.messaging.actions.watchChannelPreviews.fulfill,
      function* () {
        channel.close()
      }
    )

    while (true) {
      const data = yield* take(channel)
      yield* put(
        state.messaging.actions.setUserKey({
          personalPrivateKey: data.privateKey,
        })
      )
    }
  } finally {
    if (yield* cancelled()) {
      channel.close()
    }
  }
}

import { useRootSelector } from "@modules/store/redux"
import { AppUserStoreInfo } from "@package/common/database/company/userResources/user-resources.entity"
import { ItemsSelector } from "@molecules"
import React from "react"
import { useDispatch } from "react-redux"
import { performanceActions } from "../state"
import { useTranslations } from "@modules/app/hooks/useTranslations"

const PerformanceStoreSelector = () => {
  const { userResources } = useRootSelector((state) => state.userProfile)
  const { selectedStore } = useRootSelector((state) => state.performances)
  const { t } = useTranslations()
  const dispatch = useDispatch()

  const selectStore = (store?: AppUserStoreInfo) => {
    if (!store) {
      return
    }
    dispatch(
      performanceActions.selectStore({
        storeCode: store.code,
        storeName: store.name,
      })
    )
  }

  React.useEffect(() => {
    if (
      !selectedStore &&
      userResources.data?.stores &&
      userResources.data?.stores.length > 0
    ) {
      selectStore(userResources.data.stores[0])
    }
  }, [userResources])

  React.useEffect(() => {
    return () => {
      dispatch(performanceActions.watchStoreSales.fulfill())
    }
  }, [])

  console.log("stores", userResources.data?.stores)

  if ((userResources.data?.stores?.length ?? 0) <= 1) {
    return <></>
  }

  return (
    <div>
      <ItemsSelector
        id="performance-store-selector"
        items={
          userResources.data?.stores?.map((x) => ({
            id: x.code,
            label: x.name,
          })) ?? []
        }
        title={<p className="text-md">{t("performance:store.select.cta")}</p>}
        onSelect={(item) =>
          selectStore(
            userResources.data?.stores?.find((x) => x.code === item.id)
          )
        }
        selected={
          selectedStore
            ? {
                id: selectedStore.storeCode,
                label: userResources.data?.stores?.find(
                  (x) => x.code === selectedStore.storeCode
                )?.name,
              }
            : undefined
        }
      />
    </div>
  )
}

export default PerformanceStoreSelector

import { DeepPartial } from "@helpers/types"
import { CourseDetailParams } from "@pages/private"
import { useRouteContext } from "@modules/routing/context"
import { useRouting } from "@root/routing/useRouting"
import { CoursesProgressEntity } from "@common/common/types/firebase/coursesProgress"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { coursesActions } from "../state"
import {
  selectFilteredCompletedCourseIdList,
  selectFilteredCourseIdList,
  selectNewestCourseIdList,
  selectRunningCourseIdList,
} from "../state/selectors/lists"
import {
  selectCourseHasAttachments,
  selectCourseId,
  selectExistsProgress,
  selectIsCourseCompleted,
  selectViewIndex,
} from "../state/selectorsNew"
import { CoursesDetailsTab, CourseTab } from "../types/courses"

const coursesDetailsTabs: CoursesDetailsTab[] = [
  {
    id: "description",
    label: "Descrizione",
  },
  {
    id: "attachments",
    label: "File",
  },
  {
    id: "test",
    label: "Test",
  },
]
const coursesTabs: CourseTab[] = [
  {
    id: "news",
    label: "Novità",
  },
  {
    id: "list",
    label: "Lista",
  },
]

export const useCourseDetail = () => {
  const [selectedTab, setSelectedTab] = useState<CoursesDetailsTab>(
    coursesDetailsTabs[0]
  )

  const {
    match: { params },
  } = useRouteContext<CourseDetailParams>()
  const dispatch = useDispatch()
  const courseId = useSelector(selectCourseId)
  const viewIndex = useSelector(selectViewIndex)
  const existsProgress = useSelector(selectExistsProgress)
  const hasAttachments = useSelector(selectCourseHasAttachments)
  const isCourseCompleted = useSelector(selectIsCourseCompleted)
  const { goBack } = useRouting()

  useEffect(() => {
    if (courseId && (!existsProgress || isCourseCompleted)) {
      dispatch(
        coursesActions.initCourseProgress({
          courseId,
          viewIndex,
        })
      )
    }
  }, [courseId, existsProgress])

  useEffect(() => {
    dispatch(coursesActions.setCourseId(params.courseId))
    return () => {
      dispatch(coursesActions.reset())
    }
  }, [])

  return {
    selectedTab,
    isLoading: courseId == null || !existsProgress,
    goBack,
    setSelectedTab,
    coursesDetailsTabs: [
      coursesDetailsTabs[0],
      {
        ...coursesDetailsTabs[1],
        hidden: !hasAttachments,
      },
      coursesDetailsTabs[2],
    ],
  }
}

export const useCourses = () => {
  const courses = useSelector(selectFilteredCourseIdList)
  const runningCourses = useSelector(selectRunningCourseIdList)
  const completedCourses = useSelector(selectFilteredCompletedCourseIdList)
  const courseIdList = useSelector(selectNewestCourseIdList)
  const hasNewCourses = courseIdList.length > 0
  const [selectedTab, setSelectedTab] = useState<CourseTab>(
    hasNewCourses ? coursesTabs[0] : coursesTabs[1]
  )
  return {
    courses,
    runningCourses: runningCourses?.filter((x) =>
      Object.keys(courses ?? {}).includes(x)
    ),
    completedCourses,
    selectedTab,
    setSelectedTab,
    coursesTabs,
    hasNewCourses,
  }
}

export const useSaveCourseProgress = () => {
  const courseId = useSelector(selectCourseId)!
  const viewIndex = useSelector(selectViewIndex)
  const dispatch = useDispatch()
  return {
    viewIndex,
    courseId,
    saveCourseProgress: (
      data: Omit<
        DeepPartial<CoursesProgressEntity<number>>,
        "courseId" | "viewIndex"
      >
    ) => {
      dispatch(
        coursesActions.saveCourseProgress({
          courseId,
          viewIndex,
          ...data,
        })
      )
    },
  }
}

// export const useSaveCourseAttachmentProgress = (e) => {
//   const saveCourseProgress = useSaveCourseProgress();
// };

import {
  getCurrentBuildHash,
  getCurrentBuildVersion,
} from "@modules/deployments/services/buildVersion"
import { getDeviceId, getDeviceInfo } from "./queries"
import { getAppInfo } from "./info"

export const buildDeviceInfoPayload = async () => {
  const deviceInfo = await getDeviceInfo()
  const deviceId = await getDeviceId()

  return {
    deviceId,
    isVirtual: deviceInfo.isVirtual ?? false,
    manufacturer: deviceInfo.manufacturer ?? "",
    model: deviceInfo.model ?? "",
    platform: deviceInfo.platform ?? "",
    name: deviceInfo.name ?? "",
    osVersion: deviceInfo.osVersion ?? "",
    operatingSystem: deviceInfo.operatingSystem ?? "",
    webViewVersion: deviceInfo.webViewVersion ?? "",
  }
}

export const buildAppVersionPayload = async () => {
  const buildVersion = await getCurrentBuildVersion()
  const buildHash = await getCurrentBuildHash()
  const appInfo = await getAppInfo()
  const deviceInfo = await getDeviceInfo()

  return {
    buildHash: buildHash ?? "",
    buildVersion: buildVersion ?? "",
    storeAppPlatform: deviceInfo.platform ?? "",
    storeAppVersion: appInfo.version ?? "",
    storeAppBuild: appInfo.build ?? "",
    storeAppId: appInfo.id ?? "",
    storeAppName: appInfo.name ?? "",
  }
}

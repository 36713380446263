export const isEmptyMessage = (message: string) => {
  if (!message?.trim()) {
    return true
  }

  if (message?.trim() === "<p><br/></p>") {
    return true
  }

  if (message?.trim() === "<p><br></p>") {
    return true
  }

  if (message?.trim() === "<p></p>") {
    return true
  }

  return false
}

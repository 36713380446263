import { useTranslations } from "@modules/app/hooks/useTranslations"
import { MessagingPanel } from "@organisms"
import { useChatMessages } from "../../../hooks/useChatMessages"
import { UseMessagingModeData } from "../../common/MessageListItem/hooks/useMessagingMode"
import { UseMessagesSelectData } from "../../common/MessageListItem/hooks/useMessagesSelect"
import ChatMessageListItem from "../ChatMessageListItem"
import { useDispatch } from "react-redux"
import { messagingActions } from "@features/messaging/state"

interface Props {
  chatId: string
  messagingMode: UseMessagingModeData
  selectedMessages: UseMessagesSelectData
}

const PAGE_SIZE = 10

const ChatMessageList = ({
  chatId,
  messagingMode,
  selectedMessages,
}: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslations()
  const { hasMoreMessages, isLoading, lastReadMessageId, messageList } =
    useChatMessages(chatId)

  const handleLoadMore = () => {
    if (!chatId || messageList?.length === 0 || isLoading) {
      return
    }

    dispatch(
      messagingActions.chatMessagesLoadMore({
        chatId,
        pageSize: PAGE_SIZE,
      })
    )
  }

  return (
    <MessagingPanel
      isLoading={isLoading}
      hasMoreMessages={hasMoreMessages}
      totMessages={messageList?.length}
      firstMessageId={messageList?.[0]?.id}
      lastMessageId={messageList?.[messageList.length - 1]?.id}
      noMessagesPlaceholder={t("messaging:chat.empty.message")}
      onLoadMore={handleLoadMore}
    >
      {messageList?.map((m, i) => {
        return (
          <ChatMessageListItem
            message={m}
            previousMessage={messageList[i - 1]}
            nextMessage={messageList[i + 1]}
            key={m.id}
            lastReadMessageId={lastReadMessageId}
            isLastMessage={i === messageList.length - 1}
            onSelectToggle={() => selectedMessages.toggle(m)}
            selected={selectedMessages.isSelected(m)}
            mode={messagingMode.mode}
            onLongPress={() => messagingMode.setSelectMode()}
          />
        )
      })}
    </MessagingPanel>
  )
}

export { ChatMessageList }

import { IonRouterOutlet } from "@ionic/react"
import { Redirect, Route, useHistory } from "react-router-dom"
import React, { useEffect } from "react"
import {
  privateRouteList,
  privateRoutes,
  sharedRouteList,
  TabInfo,
} from "../routes"
import { useSelector } from "react-redux"
import { selectIsFullscreen } from "@modules/app/state/selectors"
import { selectNewMessagesCount } from "@features/messaging/selectors/preview"
import TabsLayout from "@ui/layouts/TabsLayout"
import { useHasTemporaryPwd } from "@features/profile/state/hooks"

interface Props {
  selectedTab: string
  setTabsRef: React.Ref<any>
}

const getTabIcons = (
  tab: TabInfo,
  {
    rawRoute,
    hasNewMessages,
  }: {
    rawRoute: string
    hasNewMessages: boolean
  }
) => {
  if (rawRoute === privateRoutes.chat.rawRoute && hasNewMessages) {
    return {
      icon: tab.alternate?.icon,
      iconSelected: tab.alternate?.iconSelected,
    }
  }

  return {
    icon: tab.icon,
    iconSelected: tab.iconSelected,
  }
}

export const PrivateRoutes = ({ selectedTab, setTabsRef }: Props) => {
  const isFullscreen = useSelector(selectIsFullscreen)
  const newMessages = useSelector(selectNewMessagesCount)
  const hasNewMessages = newMessages > 0
  const temporaryPassword = useHasTemporaryPwd()

  const router = useHistory()

  useEffect(() => {
    console.log("temporaryPassword", temporaryPassword)
    if (temporaryPassword) {
      router.push(privateRoutes.passwordChange.rawRoute)
    }
  }, [temporaryPassword])

  return (
    <TabsLayout
      fullScreen={isFullscreen}
      setTabsRef={setTabsRef}
      tabs={privateRouteList
        .filter((x) => x.tab != null)
        .map(({ rawRoute, tab, getRouteWithParams, defaultParam }) => ({
          id: getRouteWithParams(defaultParam),
          href: getRouteWithParams(defaultParam),
          selected: selectedTab === getRouteWithParams(defaultParam),
          ...getTabIcons(tab as TabInfo, { rawRoute, hasNewMessages }),
        }))}
    >
      <IonRouterOutlet>
        {[...sharedRouteList, ...privateRouteList].map(
          ({ component, rawRoute, exact }) => (
            <Route
              path={rawRoute}
              key={rawRoute}
              component={component}
              exact={exact}
            />
          )
        )}

        <Redirect exact from="/" to={privateRoutes.home.rawRoute} />
        <Redirect exact from="/login" to={privateRoutes.home.rawRoute} />
        <Redirect to={"/"} />
      </IonRouterOutlet>
    </TabsLayout>
  )
}

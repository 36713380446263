import { Image } from "@atoms"
import { useTranslations } from "@modules/app/hooks/useTranslations"

export interface CourseTestResultPanelProps {
  isSuccess: boolean
}

const CourseTestResultPanel = ({ isSuccess }: CourseTestResultPanelProps) => {
  const { t } = useTranslations()

  return (
    <>
      <div className="w-full aspect-square mb-[20px]">
        <Image
          src={
            isSuccess
              ? "/assets/images/completed-test.png"
              : "/assets/images/failed-test.png"
          }
        />
      </div>

      <div className="text-center text-dark">
        <div className="text-2xl font-bold leading-[1.45] mb-[17px]">
          {isSuccess
            ? t("courses:test.success.title")
            : t("courses:test.failed.title")}
        </div>
        <div className="text-base leading-[1.71] mb-[36px]">
          {isSuccess
            ? t("courses:test.success.message")
            : t("courses:test.failed.message")}
        </div>
      </div>
    </>
  )
}

export default CourseTestResultPanel

import { DocumentData, limit, orderBy, query } from "firebase/firestore"
import {
  Firebase,
  FirestoreController,
} from "../../../infrastructure/firebase/controller"
import {
  ObjectivesAggregationGroup,
  ObjectivesAggregationHistoryItem,
} from "./objectives-aggregation.entity"

const AGGREGATIONS_HISTORY_SUB_COLLECTION = "day_history"

export class ObjectivesAggregationController extends FirestoreController<ObjectivesAggregationGroup> {
  constructor(firebase: Firebase) {
    super(firebase, "ObjectivesAggregationRepo")
  }

  subscribeLastAggregationItems(
    parentId: string,
    maxDays: number,
    callback: (items: ObjectivesAggregationHistoryItem[]) => void
  ) {
    return this.subscribeSubCollectionQuery(
      parentId,
      AGGREGATIONS_HISTORY_SUB_COLLECTION,
      (collection) =>
        query(collection, orderBy("date", "desc"), limit(maxDays)),
      (items: DocumentData[]) => {
        return callback(items as ObjectivesAggregationHistoryItem[])
      }
    )
  }
}

import ProfileSettingsContainer from "@containers/profile/ProfileSettings"
import PageRoot from "@root/pages/PageRoot"

const OptionsPage = () => {
  return (
    <PageRoot>
      <ProfileSettingsContainer />
    </PageRoot>
  )
}

export { OptionsPage }

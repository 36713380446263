import { createRoutine } from "redux-saga-routines"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  CourseAttachments,
  CourseAttachmentPages,
  Courses,
  OrderType,
} from "../types/courses"
import { CoursesProgress } from "@services/firebase/coursesProgress"
import { CoursesProgressEntity } from "@common/common/types/firebase/coursesProgress"
import { DeepPartialExcept } from "@helpers/types"
import { mergeDeepRight } from "ramda"

const name = "courses"

export type CoursesState = {
  courses: Courses
  courseAttachments: CourseAttachments
  courseAttachmentsPages: CourseAttachmentPages
  coursesLoaded: boolean
  coursesProgress: CoursesProgress
  coursesProgressLoaded: boolean
  isWatchingCourses: boolean
  isLoadingCoursesProgress: boolean
  isWatchingCoursesProgress: boolean
  selectedCourseId: string | null
  selectedChapterId: string | null
  playChapterId: string | null
  selectedTestId: string | null
  selectedTestQuestionId: string | null
  showQuestionError: boolean
  selectedCategoryCode: string | null
  selectedCompletedCategoryCode: string | null
  filteredBrands: Record<string, boolean>
  orderType: OrderType
}

const initialState: CoursesState = {
  courses: {},
  courseAttachments: {},
  courseAttachmentsPages: {},
  coursesProgress: {},
  coursesLoaded: false,
  coursesProgressLoaded: false,
  isWatchingCourses: false,
  isLoadingCoursesProgress: true,
  isWatchingCoursesProgress: false,
  selectedCourseId: null,
  selectedChapterId: null,
  playChapterId: null,
  selectedTestId: null,
  selectedTestQuestionId: null,
  selectedCategoryCode: null,
  selectedCompletedCategoryCode: null,
  showQuestionError: false,
  filteredBrands: {},
  orderType: "NEWEST_FIRST",
}

const watchCourses = createRoutine("COURSES_WATCH", {
  SUCCESS: (payload: Courses) => payload,
})

const watchCoursesAttachments = createRoutine("COURSES_ATTACHMENTS_WATCH", {
  SUCCESS: (payload: CourseAttachments) => payload,
})

const watchCoursesAttachmentPages = createRoutine(
  "COURSES_ATTACHMENT_PAGES_WATCH",
  {
    SUCCESS: (payload: CourseAttachmentPages) => payload,
  }
)

const watchCoursesProgress = createRoutine("COURSES_PROGRESS_WATCH", {
  SUCCESS: (payload: CoursesProgress) => payload,
})

const getCoursesProgress = createRoutine("COURSES_PROGRESS_GET", {
  SUCCESS: (payload: CoursesProgress) => payload,
})

const saveCourseProgress = createRoutine("COURSES_PROGRESS_SAVE", {
  TRIGGER: (
    payload: DeepPartialExcept<
      CoursesProgressEntity<number>,
      "courseId" | "viewIndex"
    >
  ) => payload,
})

const initCourseProgress = createRoutine("COURSES_PROGRESS_INIT", {
  TRIGGER: (payload: { courseId: string; viewIndex: number }) => payload,
})

const routines = {
  getCoursesProgress,
  watchCourses,
  watchCoursesProgress,
  watchCoursesAttachments,
  watchCoursesAttachmentPages,
  saveCourseProgress,
  initCourseProgress,
}

const slice = createSlice({
  name,
  reducers: {
    setOrderTypeFilters(state, action: PayloadAction<OrderType>) {
      state.orderType = action.payload
    },
    setBrandsFilters(state, action: PayloadAction<Record<string, boolean>>) {
      state.filteredBrands = action.payload
    },
    setCourseId(state, action: PayloadAction<string | null>) {
      state.selectedCourseId = action.payload
    },
    setCategoryCode(state, action: PayloadAction<string | null>) {
      state.selectedCategoryCode = action.payload
    },
    setCompletedCategoryCode(state, action: PayloadAction<string | null>) {
      state.selectedCompletedCategoryCode = action.payload
    },
    setChapterId(state, action: PayloadAction<string | null>) {
      state.selectedChapterId = action.payload
      state.playChapterId = null
    },
    setChapterIdAndPlay(state, action: PayloadAction<string | null>) {
      state.selectedChapterId = action.payload
      state.playChapterId = action.payload
    },
    setTestId(state, action: PayloadAction<string | null>) {
      state.selectedTestId = action.payload
      state.showQuestionError = false
    },
    setTestQuestionId(state, action: PayloadAction<string | null>) {
      state.selectedTestQuestionId = action.payload
      state.showQuestionError = false
    },
    setShowTestQuestionError(state, action: PayloadAction<boolean>) {
      state.showQuestionError = action.payload
    },
    saveCourseProgressOnlyLocally(
      state,
      action: PayloadAction<
        DeepPartialExcept<
          CoursesProgressEntity<number>,
          "courseId" | "viewIndex"
        >
      >
    ) {
      //@ts-expect-error
      state.coursesProgress[state.selectedCourseId!] = mergeDeepRight(
        state.coursesProgress[state.selectedCourseId!]!,
        action.payload
      )
    },
    reset(state) {
      state.selectedCourseId = null
      state.selectedChapterId = null
      state.playChapterId = null
      state.selectedCategoryCode = null
      state.selectedCompletedCategoryCode = null
      state.selectedTestId = null
      state.selectedTestQuestionId = null
    },
  },
  initialState,
  extraReducers: (builder) => {
    //WATCH
    builder
      .addCase(watchCourses.TRIGGER, (state) => {
        state.isWatchingCourses = true
      })
      .addCase(
        watchCourses.SUCCESS,
        (state, action: ReturnType<typeof watchCourses.success>) => {
          state.courses = action.payload
          state.coursesLoaded = true
        }
      )
      .addCase(watchCourses.FAILURE, (state) => {
        state.isWatchingCourses = false
        state.courses = {}
      })
      .addCase(watchCourses.FULFILL, (state) => {
        state.isWatchingCourses = false
        state.courses = {}
      })
    builder
      .addCase(
        watchCoursesAttachments.SUCCESS,
        (state, action: ReturnType<typeof watchCoursesAttachments.success>) => {
          state.courseAttachments = action.payload
        }
      )
      .addCase(watchCoursesAttachments.FULFILL, (state) => {
        state.courseAttachments = {}
      })
    builder
      .addCase(
        watchCoursesAttachmentPages.SUCCESS,
        (
          state,
          action: ReturnType<typeof watchCoursesAttachmentPages.success>
        ) => {
          state.courseAttachmentsPages = action.payload
        }
      )
      .addCase(watchCoursesAttachmentPages.FULFILL, (state) => {
        state.courseAttachmentsPages = {}
      })
    builder
      .addCase(watchCoursesProgress.TRIGGER, (state) => {
        state.isWatchingCoursesProgress = true
      })
      .addCase(
        watchCoursesProgress.SUCCESS,
        (state, action: ReturnType<typeof watchCoursesProgress.success>) => {
          state.coursesProgress = action.payload
          state.coursesProgressLoaded = true
        }
      )
      .addCase(watchCoursesProgress.FAILURE, (state) => {
        state.isWatchingCoursesProgress = false
        state.coursesProgress = {}
      })
      .addCase(watchCoursesProgress.FULFILL, (state) => {
        state.isWatchingCoursesProgress = false
        state.coursesProgress = {}
      })
    builder
      .addCase(getCoursesProgress.TRIGGER, (state) => {
        state.isLoadingCoursesProgress = true
      })
      .addCase(
        getCoursesProgress.SUCCESS,
        (state, action: ReturnType<typeof getCoursesProgress.success>) => {
          state.coursesProgress = action.payload
          state.coursesProgressLoaded = true
        }
      )
      .addCase(getCoursesProgress.FAILURE, (state) => {
        state.coursesProgress = {}
      })
      .addCase(getCoursesProgress.FULFILL, (state) => {
        state.isLoadingCoursesProgress = false
      })
    builder.addCase(
      saveCourseProgress.TRIGGER,
      (state, action: ReturnType<typeof saveCourseProgress.trigger>) => {
        //@ts-expect-error
        state.coursesProgress[state.selectedCourseId!] = mergeDeepRight(
          state.coursesProgress[state.selectedCourseId!]!,
          action.payload
        )
      }
    )
    builder.addCase(
      initCourseProgress.TRIGGER,
      (state, action: ReturnType<typeof initCourseProgress.trigger>) => {
        state.coursesProgress[state.selectedCourseId!] = {
          courseId: action.payload.courseId,
          viewIndex: action.payload.viewIndex,
          id: "",
          progress: {
            attachments: {},
            chapters: {},
            tests: {},
          },
          startedTime: new Date().getTime(),
          status: "running",
          userId: "",
          completedTime: new Date().getTime(),
        }
      }
    )
  },
})

const {
  name: coursesName,
  actions: sliceActions,
  reducer: coursesReducer,
} = slice

const coursesActions = {
  ...sliceActions,
  ...routines,
}

export { coursesName, coursesReducer, coursesActions }

import Firebase from "."
import {
  collection,
  Firestore,
  onSnapshot,
  QueryDocumentSnapshot,
} from "firebase/firestore"
import { CourseEntity } from "@common/common/api/functions"

export type Courses = Record<string, CourseEntity>

const converter = {
  toFirestore: (data: CourseEntity) => {
    // const { id, ...rest } = data;
    return data
  },
  fromFirestore: (snap: QueryDocumentSnapshot<CourseEntity>) => snap.data(),
}

function coursesRef(db: Firestore) {
  return collection(db, `CoursesRepo`).withConverter(converter)
}

export function watchCourses(
  this: Firebase,
  callback: (courses: Courses) => void
) {
  const unsubscribe = onSnapshot(coursesRef(this.firestore), (snap) => {
    // const data = snap.docs.map((x) => ({ ...x.data(), id: x.id }));
    // snap.docChanges()
    const data = snap.docs.reduce<Courses>((o, v) => {
      o[v.id] = v.data()
      return o
    }, {})
    callback(data)
  })
  return () => unsubscribe()
}

import { RootState } from "@modules/store/redux/rootReducer"
import { PayloadAction } from "@reduxjs/toolkit"
import { call, put, select } from "typed-redux-saga"
import { deploymentsSlice } from "../store"
import { saveCustomChannelId } from "@modules/deployments/features"

export function* switchChannelSaga(action: PayloadAction<string>) {
  const { channels } = yield* select((state: RootState) => state.deployments)

  console.log(`SWITCH CHANNEL -> channels loaded`, channels)
  const channel = channels?.find((c) => c.data.channelId === action.payload)
  if (!channel) {
    throw new Error(`Channel ${action.payload} not found`)
  }

  yield* call(saveCustomChannelId, channel.data.channelId)
  yield* put(deploymentsSlice.actions.setChannel(channel))
  console.log(`SWITCH CHANNEL -> selected channel ${channel.id}`, channel)
}

import { watchCourses } from "./courses"
import {
  getCoursesProgress,
  initCourseProgress,
  saveCourseProgress,
  watchCoursesProgress,
} from "./coursesProgress"
import {
  getClaims,
  getFirebaseToken,
  getToken,
  getUid,
  getUser,
  login,
  logout,
  refreshFirebaseToken,
  setTokenAndClaims,
  watchIsLoggedIn,
} from "./auth"

import { FirebaseApp, initializeApp } from "firebase/app"
import {
  Firestore,
  enableIndexedDbPersistence,
  initializeFirestore,
  CACHE_SIZE_UNLIMITED,
} from "firebase/firestore"
import {
  Auth,
  User,
  indexedDBLocalPersistence,
  initializeAuth,
} from "firebase/auth"
import { CustomClaims } from "@common/common/types/api"
import { FirestoreCollections } from "@common/common/frontend/database/collections"
import { FirebaseStorage, getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}
class Firebase {
  protected app!: FirebaseApp
  protected firestore!: Firestore
  protected auth!: Auth
  protected impersonatedUid?: string

  protected token?: string
  protected user?: User
  protected claims?: CustomClaims

  public collections!: FirestoreCollections
  public storage!: FirebaseStorage

  init = async () => {
    try {
      console.log("FIREBASE INIT", firebaseConfig)
      this.app = initializeApp(firebaseConfig)
      this.firestore = initializeFirestore(this.app, {
        cacheSizeBytes: CACHE_SIZE_UNLIMITED,
        ...(process.env.REACT_APP_FIRESTORE_EMULATOR_HOST === "" ||
        process.env.REACT_APP_FIRESTORE_EMULATOR_HOST == null
          ? {}
          : {
              host: process.env.REACT_APP_FIRESTORE_EMULATOR_HOST,
              ssl: false,
              experimentalForceLongPolling: true,
            }),
      })
      if (process.env.REACT_APP_FIRESTORE_ENABLE_PERSISTENCE === "true") {
        await enableIndexedDbPersistence(this.firestore)
      }
      this.auth = initializeAuth(this.app, {
        persistence: indexedDBLocalPersistence,
      })
      this.collections = new FirestoreCollections(this.firestore)
      this.storage = getStorage(this.app)
    } catch (error: any) {
      console.log("INIT FIREBASE ERROR", error)
      if (error.code == "failed-precondition") {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (error.code == "unimplemented") {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    }
  }
  getFirebaseToken = getFirebaseToken.bind(this)
  getToken = getToken.bind(this)
  getClaims = getClaims.bind(this)
  getUser = getUser.bind(this)
  getUid = getUid.bind(this)
  login = login.bind(this)
  logout = logout.bind(this)

  getImpersonatedUid = () => this.impersonatedUid
  setUserImpersonation = (uid: string) => {
    this.impersonatedUid = uid
  }
  clearUserImpersonation = () => {
    this.impersonatedUid = undefined
  }

  watchIsLoggedIn = watchIsLoggedIn.bind(this)
  watchCourses = watchCourses.bind(this)

  watchCoursesProgress = watchCoursesProgress.bind(this)
  saveCourseProgress = saveCourseProgress.bind(this)
  initCourseProgress = initCourseProgress.bind(this)
  getCoursesProgress = getCoursesProgress.bind(this)

  refreshFirebaseToken = refreshFirebaseToken.bind(this)
  protected setTokenAndClaims = setTokenAndClaims.bind(this)
}

export default Firebase

import { createRoutine } from "redux-saga-routines"
import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit"
import { NoInfer } from "@reduxjs/toolkit/dist/tsHelpers"
import {
  completed,
  empty,
  failure,
  faulted,
  idle,
  loading,
  running,
  success,
} from "../../../../utils/state"
import { UpdateUserPasswordInput, UserProfileState } from "./types"
import { AppUser } from "../../../../database/company/user/user.entity"
import { getSliceContextCreator } from "../../../state/context"
import { AppUserActiveResources } from "../../../../database/company/userResources/user-resources.entity"
import { orderBy } from "lodash"

const watchUserProfile = createRoutine("PROFILE|WATCH_USER_PROFILE", {
  success: (payload: AppUser) => payload,
})

const watchUserResources = createRoutine("PROFILE|WATCH_USER_RESOURCES", {
  success: (payload: AppUserActiveResources) => payload,
})

const updateUserProfileImage = createRoutine("PROFILE|UPDATE_IMAGE", {
  trigger: (payload: File) => payload,
})

const updateUserPassword = createRoutine("PROFILE|UPDATE_PASSWORD", {
  trigger: (payload: UpdateUserPasswordInput) => payload,
})

const initialState: UserProfileState = {
  user: empty(),
  passwordReset: idle(),
  updateProfileImage: idle(),
  userResources: empty(),
}

type CreateUserProfileSlice<T extends {} | undefined> = {
  extendState?: T
  extendExtraReducers?: (
    builder: ActionReducerMapBuilder<NoInfer<UserProfileState & T>>
  ) => void
}

const createUserProfileSlice = <T extends {}>(
  prop: CreateUserProfileSlice<T>
) =>
  createSlice({
    name: "userProfile",
    initialState: {
      ...initialState,
      ...prop.extendState,
    } as UserProfileState & T,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(watchUserProfile.TRIGGER, (state) => {
          state.user = loading()
        })
        .addCase(watchUserProfile.FULFILL, (state) => {})
        .addCase(
          watchUserProfile.SUCCESS,
          (state, action: ReturnType<typeof watchUserProfile.success>) => {
            state.user = completed(action.payload)
          }
        )
        .addCase(
          watchUserProfile.FAILURE,
          (state, action: ReturnType<typeof watchUserProfile.failure>) => {
            state.user = failure(action.payload)
          }
        )
      builder
        .addCase(watchUserResources.TRIGGER, (state) => {
          state.userResources = loading()
        })
        .addCase(watchUserResources.FULFILL, (state) => {})
        .addCase(
          watchUserResources.SUCCESS,
          (state, action: ReturnType<typeof watchUserResources.success>) => {
            state.userResources = completed({
              storeIds: action.payload.storeIds,
              stores: orderBy(action.payload.stores ?? [], (x) => x.name),
              areaIds: action.payload.areaIds,
              areas: orderBy(action.payload.areas ?? [], (x) => x.name),
            })
          }
        )
        .addCase(
          watchUserResources.FAILURE,
          (state, action: ReturnType<typeof watchUserResources.failure>) => {
            state.userResources = failure(action.payload)
          }
        )
      builder
        .addCase(updateUserProfileImage.TRIGGER, (state) => {
          state.updateProfileImage = running()
        })
        .addCase(updateUserProfileImage.FULFILL, (state) => {
          state.updateProfileImage = success()
        })
        .addCase(updateUserProfileImage.FAILURE, (state, error) => {
          state.updateProfileImage = faulted(error)
        })
      builder
        .addCase(updateUserPassword.TRIGGER, (state) => {
          state.passwordReset = running()
        })
        .addCase(updateUserPassword.FULFILL, (state) => {
          state.passwordReset = success()
        })
        .addCase(updateUserPassword.FAILURE, (state, error) => {
          state.passwordReset = faulted(error)
        })

      prop?.extendExtraReducers?.(builder as any)
    },
  })

const userProfileRoutines = {
  watchUserProfile,
  updateUserProfileImage,
  updateUserPassword,
  watchUserResources,
}

export const createProfileSliceContext =
  getSliceContextCreator(userProfileRoutines)

export { createUserProfileSlice, userProfileRoutines }

import { useTranslations } from "@modules/app/hooks/useTranslations"
import classNames from "classnames"
import React from "react"
import { useSelector } from "react-redux"
import { selectAttachmentIdList } from "../../../state/selectors/lists"
import CourseDetailFileListItem from "./CourseDetailFileListItem"

interface Props {
  isVisible: boolean
}

const CourseDetailFileList = ({ isVisible }: Props) => {
  const { tPluralized } = useTranslations()
  const ids = useSelector(selectAttachmentIdList)
  return (
    <div className={classNames(!isVisible && "hidden", "p-courses-container")}>
      <div className="border-b border-b-grey  text-base leading-[1.5] mb-[16px]">
        <div>{`${ids.length} ${tPluralized(
          {
            singular: "courses:attachments.tot.single",
            plural: "courses:attachments.tot.plural",
          },
          ids.length
        )}`}</div>
      </div>
      <div className="flex flex-col gap-[8px]">
        {ids.map((id) => {
          return <CourseDetailFileListItem key={id} fileId={id} />
        })}
      </div>
    </div>
  )
}

export default React.memo(CourseDetailFileList)

import { Icon } from "@atoms"
import { getChannelLogo } from "@helpers/channels"
import { useRouting } from "@root/routing/useRouting"
import classNames from "classnames"
import { format } from "date-fns"
import { IconNames } from "@ui/atoms/Icon"
import { formatDayLabel } from "@utils/dateLabels"
import { isoToItaDate } from "@utils/dates"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { useFullWeekDays, useWeekDays } from "@modules/app/hooks/useWeekDays"

export type NotificationCourse = {
  courseId: string
}
export type NotificationCalendarEvent = {
  calendar: {
    id: string
  }
  reservation: {
    id: string
    date: string
    startTime: string
    endTime: string
  }
}

export type NotificationChannelEvent = {
  channelId: string
  channelScope: string
  channelPreviewId: string
}

export type NotificationChatEvent = {
  chatId: string
  chatPreviewId: string
}

export type NotificationData<T> = {
  id: string
  title: string
  text: string
  dateTime: Date
  isNew?: boolean
  type:
    | "newCalendarEvent"
    | "newCourse"
    | "chatMessage"
    | "channelMessage"
    | "calendarEventCancellation"
  data: T
}

type Props = {} & NotificationData<any>

const Notification = ({ text, dateTime, title, isNew, data, type }: Props) => {
  const { goTo } = useRouting()
  const { t } = useTranslations()
  const { weekDays } = useFullWeekDays()

  let iconName: IconNames | undefined
  let routeAction = () => {}
  let notificationTitle = title
  let subtitle = text
  switch (type) {
    case "channelMessage":
      const { channelScope, channelPreviewId } =
        data as NotificationChannelEvent
      iconName = getChannelLogo(channelScope)
      routeAction = async () => {
        goTo("channelDetail", { params: { channelId: channelPreviewId } })
      }
      break
    case "chatMessage":
      const { chatId } = data as NotificationChatEvent
      iconName = "menuMessagingActive"
      routeAction = async () => {
        goTo("chatDetail", { params: { chatId } })
      }
      break
    case "calendarEventCancellation":
      const { reservation: cancellation } = data as NotificationCalendarEvent
      iconName = "calendar"
      if (cancellation.date) {
        subtitle = `${isoToItaDate(cancellation.date)} | ${
          cancellation.startTime
        } - ${cancellation.endTime}`
      }
      notificationTitle = `${t(
        "notifications:calendarEventCancellation.prefix"
      )}: ${title}`
      break
    case "newCalendarEvent":
      const { reservation } = data as NotificationCalendarEvent
      iconName = "calendar"
      if (reservation.date) {
        subtitle = `${isoToItaDate(reservation.date)} | ${
          reservation.startTime
        } - ${reservation.endTime}`
      }
      routeAction = async () => {
        // push(
        //   privateRoutes.chat.getRouteWithParams({
        //     communicationTab: "calendar",
        //   }),
        //   "root",
        //   "push",
        //   { unmount: true }
        // )
        // await delay(0)
        if (reservation.date) {
          if (reservation.id != null) {
            goTo("calendarDailyEventWithId", {
              params: { date: reservation.date, eventId: reservation.id },
            })
          } else {
            goTo("calendarDailyEvent", { params: { date: reservation.date } })
          }
        }
      }
      break
    case "newCourse":
      iconName = "camera2"
      routeAction = async () => {
        goTo("courseDetail", {
          params: { courseId: (data as NotificationCourse).courseId },
        })
      }
      break

    default:
      console.error("event not mapped", type)
      break
  }
  const dayLabel = dateTime
    ? formatDayLabel(dateTime, {
        weekDays,
        yesterday: t("notifications:labels.yesterday"),
      })
    : undefined
  return (
    <div
      className={classNames(
        "flex px-container h-[70px] items-center w-full relative",
        isNew &&
          "after:block after:left-0 after:top-0 after:w-[5px] after:h-full after:bg-red after:rounded-[0px_100px_100px_0px] after:absolute"
      )}
    >
      <div
        onClick={routeAction}
        className={`flex gap-[14px] w-full ${
          type !== "calendarEventCancellation" ? "cursor-pointer" : ""
        }`}
      >
        <div className="w-[54px] h-[54px] rounded-full bg-grey shrink-0 flex items-center justify-center">
          {iconName && (
            <Icon iconName={iconName} className="w-[28px] h-[28px] text-red" />
          )}
        </div>
        <div className="flex-1 leading-[1.5] overflow-hidden flex gap-x-[14px] justify-between items-center">
          <div className="flex-1">
            <div className="flex items-center gap-[6px] line-clamp-1">
              <div>{notificationTitle}</div>
            </div>
            <div
              className="flex items-center overflow-hidden text-light text-ellipsis line-clamp-1"
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          </div>
          <div>
            <div className="text-right">{dayLabel}</div>
            <div
              className={`text-right ${
                dayLabel && "text-light"
              } whitespace-nowrap`}
            >
              {dateTime && format(dateTime, "HH:mm")}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Notification }

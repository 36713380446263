import { useMemo } from "react"
import { useSelector } from "react-redux"
import { selectChatPreviewById } from "../selectors/preview"

export const useChatData = (chatId: string) => {
  const selector = useMemo(() => selectChatPreviewById(chatId), [chatId])
  const chatData = useSelector(
    selector,
    (before, after) =>
      before?.entity.id === after?.entity.id &&
      after?.privateKey === before?.privateKey &&
      before?.active === after?.active
  )
  return chatData
}

import { Icon } from "@atoms"
import { ThemeColors } from "@helpers/ui"
import classNames from "classnames"
import React from "react"

interface Props {
  onClick?: () => void
  classes?: {
    container?: string
    icon?: string
  }
  iconColor?: ThemeColors
}
const CompletedButton = ({ onClick, classes, iconColor }: Props) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-center  w-full h-full rounded-full border-4 border-red",
        classes?.container
      )}
      onClick={onClick}
    >
      <Icon
        iconName="check2"
        className={classNames("!w-[50%] !h-[50%]", classes?.icon)}
      />
    </div>
  )
}

export default CompletedButton

import { Button, Chip, Icon } from "@atoms"
import React from "react"
import { getChannelLogo } from "@helpers/channels"
import {
  NotificationChannelEvent,
  NotificationData,
} from "@features/notifications/components/Notification"
import { Swiper, SwiperSlide } from "swiper/react"
import classNames from "classnames"
import { useRouting } from "@root/routing/useRouting"
import { useTranslations } from "@modules/app/hooks/useTranslations"

type Props = {
  channelNotifications: NotificationData<NotificationChannelEvent>[]
}

const NewestChannelCommunications = ({ channelNotifications }: Props) => {
  const { goTo } = useRouting()
  const { t } = useTranslations()

  const getChannelData = ({
    data,
  }: NotificationData<NotificationChannelEvent>) => {
    const onClick = async () => {
      goTo("channelDetail", { params: { channelId: data.channelPreviewId } })
    }
    switch (data.channelScope) {
      case "area":
        return {
          title: t("news:communications.area.title"),
          subtitle: t("news:communications.area.subtitle"),
          onClick,
        }
      case "company":
        return {
          title: t("news:communications.company.title"),
          subtitle: t("news:communications.company.subtitle"),
          onClick,
        }
      case "store":
        return {
          title: t("news:communications.store.title"),
          subtitle: t("news:communications.store.subtitle"),
          onClick,
        }
      default:
        return null
    }
  }

  return (
    <div>
      <Swiper className="h-[142px]" observer spaceBetween={8} slidesPerView={2}>
        {channelNotifications.map((x, i) => {
          const channelData = getChannelData(x)
          return (
            <SwiperSlide
              key={x.id}
              className="!h-full"
              onClick={channelData?.onClick}
            >
              <div className="w-full pt-[13px] pb-[10px] text-left flex flex-col justify-between h-full  whitespace-pre">
                <div>
                  <div className="flex gap-[12px] items-center mb-[5px]">
                    <Icon
                      iconName={getChannelLogo(x.data.channelScope)}
                      className="w-[28px] h-[28px]"
                    />
                    <Chip
                      className={classNames("text-white bg-dark")}
                      fullWidth
                      text={x.title}
                    />
                  </div>

                  <div className="text-sm leading-[1.43] mb-[3px]">
                    {channelData?.title}
                  </div>
                  <div className="text-base text-light mb-[3px]">
                    {channelData?.subtitle}
                  </div>
                </div>
                <Button className="!bg-[transparent] h-auto w-full flex !justify-start text-red">
                  <div className="text-sm leading-[1.5]">
                    {t("news:communications.cta.open")}
                  </div>
                  <Icon
                    iconName="arrowForward"
                    className="!w-[26px] !h-[20px]"
                  ></Icon>
                </Button>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export { NewestChannelCommunications }

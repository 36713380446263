import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppDeploymentStatus, DeploymentsState } from "./types"
import { Entity } from "@common/common/infrastructure/firebase/types"
import { AppDeploymentChannel } from "@common/common/database/deployments/app-deployment.entity"
import { faulted, idle, loading, success } from "@punks/ui-core"

const initialState: DeploymentsState = {
  updateState: "uninitialized",
  initialCheckCompleted: false,
  liveUpdate: idle(),
}

export const deploymentsSlice = createSlice({
  name: "deployments",
  initialState: {
    ...initialState,
  } as DeploymentsState,
  reducers: {
    // updates check
    updatesCheckTrigger: () => {},
    updatesCheckCompleted: (state) => {
      state.initialCheckCompleted = true
    },
    // set data
    setLiveUpdateEnabled: (state, action: PayloadAction<boolean>) => {
      state.liveUpdateEnabled = action.payload
    },
    setChannel: (
      state,
      action: PayloadAction<Entity<AppDeploymentChannel>>
    ) => {
      state.channel = action.payload
    },
    setChannels: (
      state,
      action: PayloadAction<Entity<AppDeploymentChannel>[]>
    ) => {
      state.channels = action.payload
    },
    setUpdateStatus: (state, action: PayloadAction<AppDeploymentStatus>) => {
      state.updateState = action.payload
    },
    setCurrentBuildHash: (state, action: PayloadAction<string>) => {
      state.currentBuildHash = action.payload
    },
    setCurrentIosAppVersion: (state, action: PayloadAction<string>) => {
      state.currentIosAppVersion = action.payload
    },
    setCurrentAndroidAppVersion: (state, action: PayloadAction<string>) => {
      state.currentAndroidAppVersion = action.payload
    },
    // live update
    liveUpdateTrigger: (
      state,
      action: PayloadAction<Entity<AppDeploymentChannel>>
    ) => {
      state.liveUpdate = loading()
    },
    liveUpdateCompleted: (state) => {
      state.liveUpdate = success()
    },
    liveUpdateFailed: (state) => {
      state.liveUpdate = faulted()
    },
    // switch channel
    switchChannelTrigger: (state, action: PayloadAction<string>) => {},
  },
})

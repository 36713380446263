import { App } from "@capacitor/app"
import { isWebPlatform } from "@modules/app/utils/device"

export const getAppInfo = async () =>
  !isWebPlatform()
    ? await App.getInfo()
    : {
        name: "Modus Learning Platform Web",
        id: "modusLearningPlatformWeb",
        build: "",
        version: "",
      }

import { SectionTitle } from "@atoms"
import {
  selectCourseCompletionStatus,
  selectCoursesTestStatus,
} from "@features/courses/state/selectors"
import { ProgressBox } from "@molecules"
import React from "react"
import { useSelector } from "react-redux"
import { selectStoreSalesPercentage } from "../selectors"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode } from "swiper"
import { useTranslations } from "@modules/app/hooks/useTranslations"

const ProgressBoxes = () => {
  const storeProgress = useSelector(selectStoreSalesPercentage)
  const { t } = useTranslations()

  const { completedCourses, courses } = useSelector(
    selectCourseCompletionStatus
  )
  const { completedTests, tests } = useSelector(selectCoursesTestStatus)

  return (
    <div>
      <SectionTitle text={t("performance:progress.personal.title")} />
      <Swiper
        observer
        freeMode
        modules={[FreeMode]}
        spaceBetween={12}
        slidesPerView={"auto"}
      >
        <SwiperSlide className="!w-auto">
          <ProgressBox
            text={t("performance:progress.personal.sales.title")}
            className="bg-dark"
            value={Math.round(Math.min(storeProgress, 100)) / 100}
          />
        </SwiperSlide>
        <SwiperSlide className="!w-auto">
          <ProgressBox
            text={t("performance:progress.personal.courses.title")}
            className="bg-red"
            value={{
              current: completedCourses,
              total: courses,
            }}
          />
        </SwiperSlide>
        <SwiperSlide className="!w-auto">
          <ProgressBox
            text={t("performance:progress.personal.test.title")}
            className="bg-pink"
            value={{
              current: completedTests,
              total: tests,
            }}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export { ProgressBoxes }

import { Icon, Image, InlineLoader } from "@atoms"
import {
  Camera,
  CameraDirection,
  CameraResultType,
  CameraSource,
} from "@capacitor/camera"
import { base64ToFile } from "@helpers"
import { setCssVar } from "@helpers/ui"
import { IonHeader, IonToolbar, IonContent, isPlatform } from "@ionic/react"
import { useRootSelector } from "@modules/store/redux"
import { Header, FooterCta } from "@molecules"
import classNames from "classnames"
import { useCallback } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { profileActions } from "../../../features/profile/state"
import joi from "joi"
import { joiResolver } from "@hookform/resolvers/joi"
import { userProfileRoutines } from "@package/common/frontend/features/profile/state/state"
import { useRouting } from "@root/routing/useRouting"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { DEFAULT_PROFILE_IMAGE } from "../consts"
import FormTextInput from "@ui/forms/FormTextInput"

type Inputs = {
  password: string
}

const ProfileInfoContainer = () => {
  const { t } = useTranslations()
  const dispatch = useDispatch()
  const { goTo } = useRouting()
  const userData = useRootSelector((state) => state.userProfile?.user?.data)
  const { loading: isUploadingImage } = useRootSelector(
    (state) => state.userProfile.updateProfileImage
  )
  const passwordReset = useRootSelector(
    (state) => state.userProfile.passwordReset
  )
  const { user } = useRootSelector((state) => state.auth)

  const changePicture = useCallback(async () => {
    console.log("changePicture -> called")
    const image = await Camera.getPhoto({
      quality: 70,
      allowEditing: false,
      saveToGallery: true,
      promptLabelCancel: t("profile:changePicture.cancel.label"),
      source: CameraSource.Camera,
      direction: CameraDirection.Front,
      promptLabelHeader: t("profile:changePicture.prompt.header"),
      promptLabelPhoto: t("profile:changePicture.prompt.photo"),
      promptLabelPicture: t("profile:changePicture.prompt.select"),
      resultType: CameraResultType.Base64,
    })
    const file = base64ToFile(
      image.base64String!,
      `image/${image.format}`,
      new Date().getTime() + "." + image.format
    )
    //   profileImageUpdate
    dispatch(profileActions.updateUserProfileImage.trigger(file))
  }, [])

  const schema = joi.object<Inputs>({
    password: joi.string().messages({
      "string.empty": t("auth:pwdModify.emptyPwd"),
    }),
  })
  const { control, handleSubmit, formState } = useForm<Inputs>({
    resolver: joiResolver(schema),
  })

  const handleSave = (data: Inputs) => {
    if (!user?.email) {
      throw new Error("Missing user email")
    }
    dispatch(
      userProfileRoutines.updateUserPassword({
        email: user.email,
        newPassword: data.password,
      })
    )
  }

  const updateDisabled = () => !formState?.touchedFields?.password

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar
          className="flex items-end"
          style={{
            ...setCssVar("--min-height", 0),
          }}
        >
          <Header onClick={() => goTo("options")} showArrow center>
            <div className="font-semibold text-center text-md">
              {t("profile:section.title")}
            </div>
          </Header>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="p-[32px]">
          <div className="flex justify-center">
            <div
              className="rounded-[100px_64.2188px_64.2188px_64.2188px] overflow-hidden relative w-[135px] h-[135px] cursor-pointer"
              onClick={changePicture}
            >
              <div className="absolute inset-0 w-full z-[1]">
                <Image
                  src={
                    userData?.profile?.avatarUrl
                      ? userData?.profile?.avatarUrl
                      : DEFAULT_PROFILE_IMAGE
                  }
                  classes={{
                    img: " w-[135px] h-[135px] object-cover relative z-10",
                  }}
                />
              </div>
              <div
                className={classNames(
                  "absolute bottom-0 left-0 w-full opacity-50 bg-dark z-[20] h-[40px] flex justify-center items-center",
                  isUploadingImage && "!h-full inset-0"
                )}
              >
                {!isUploadingImage && (
                  <Icon
                    iconName="camera"
                    className="text-white w-[20px] h-[18px]"
                  />
                )}
                {isUploadingImage && <InlineLoader />}
              </div>
            </div>
          </div>
          {/* <FormTextInput
            control={control}
            name="username"
            label="Username"
            type="text"
            classes={{
              inputWrapper: "flex gap-[15px] items-center",
              input: "order-2"
            }}
          >
            <Icon className="w-[16px] h-[16px] order-1" iconName="user"/>
          </FormTextInput> */}
          <div className="my-8">
            <FormTextInput
              control={control}
              name="password"
              label={t("auth:pwdModify.label")}
              type="password"
              placeholder={t("auth:pwdModify.placeholder")}
            />
          </div>
          {passwordReset.success && (
            <div>{t("auth:pwdChange.messages.success")}</div>
          )}
        </div>
      </IonContent>
      <FooterCta
        onSave={handleSubmit(handleSave)}
        loading={passwordReset.loading}
        disabled={updateDisabled()}
      >
        {t("auth:pwdModify.save")}
      </FooterCta>
    </>
  )
}

export default ProfileInfoContainer

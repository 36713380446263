import { RootState } from "@modules/store/redux/rootReducer"
import { createSelector } from "@reduxjs/toolkit"
import { calendarName } from "../state"
import {
  parse,
  minTime,
  maxTime,
  min,
  max,
  compareAsc,
  formatDuration,
  isSameDay,
  intervalToDuration,
  subDays,
} from "date-fns"
import { it } from "date-fns/locale"
import { toTitleCase } from "@helpers"
import { CalendarStoreRef } from "./types"
import { deduplicateArray } from "@utils/array"
import { orderBy, uniqBy } from "lodash"

const EVENTS_BACK_DAYS = 60

export const state = (state: RootState) => state && state[calendarName]

export const selectCalendarStores = createSelector(state, (state) => {
  const data = state.calendars.data?.map(
    (x) =>
      ({
        storeId: x.definition.data.store?.id,
        storeName: x.definition.data.store?.name,
        calendarId: x.definition.id,
      } as CalendarStoreRef)
  )

  const selectedStoreIds = state.calendars.data
    ?.filter(
      (x) =>
        x.definition.data.store?.id &&
        state.selectedCalendarIds.includes(x.definition.id)
    )
    ?.map((x) => x.definition.data.store?.id)
    .filter((x) => x)

  const stores = uniqBy(
    orderBy(
      deduplicateArray(
        data?.filter((x) => x.storeName && x.storeId) ?? [],
        (x) => x.storeId
      ),
      (x) => x.storeName.toLowerCase()
    ),
    (x) => x.storeId
  )

  return {
    allStores: stores,
    filteredStores: stores.filter((x) => selectedStoreIds?.includes(x.storeId)),
  }
})

const selectFilteredCalendars = createSelector(
  state,
  (state) =>
    state.calendars.data?.filter((x) =>
      state.selectedCalendarIds?.includes(x.definition.id)
    ) ?? []
)

export const selectCalendarData = createSelector(
  selectFilteredCalendars,
  (data) => {
    let minDate = new Date(maxTime)
    let maxDate = new Date(minTime)

    const calendarInfo = data
      ?.flatMap((cal) => {
        return cal.reservations.map((res) => {
          const startDate = parse(
            `${res.data.date}_${res.data.startHour}:${res.data.startMinute}`,
            "yyyy-MM-dd_H:m",
            new Date()
          )

          const endDate = parse(
            `${res.data.date}_${res.data.endHour}:${res.data.endMinute}`,
            "yyyy-MM-dd_H:m",
            new Date()
          )
          const duration = intervalToDuration({
            start: startDate,
            end: endDate,
          })
          minDate = min([startDate, minDate])
          maxDate = max([startDate, maxDate])
          const extraFields: string[] = []
          if (res.data.extraFields?.name) {
            extraFields.push(
              `Nome: ${toTitleCase(res.data.extraFields.name ?? "")}`
            )
          }
          if (res.data.extraFields?.email) {
            extraFields.push(`Email: ${res.data.extraFields?.email}`)
          }
          if (res.data.extraFields?.phone) {
            extraFields.push(`Telefono: ${res.data.extraFields?.phone}`)
          }
          if (res.data.extraFields?.notes) {
            extraFields.push(`Note: ${res.data.extraFields?.notes}`)
          }
          return {
            ...res,
            date: startDate,
            formattedDuration: formatDuration(
              { hours: duration.hours, minutes: duration.minutes },
              { locale: it, format: ["hours", "minutes"], delimiter: " e " }
            ),
            duration: duration,
            storeCode: cal.definition.data.store?.code,
            storeId: cal.definition.data.store?.id,
            storeName: cal.definition.data.store?.name,
            name:
              cal.definition.data.reservations?.eventName ??
              cal.definition.data.name,
            type: cal.definition.data.type,
            extraFields,
            isPassed: startDate < new Date(),
            ratings: res.data.ratings,
            rating: res.data.rating,
          }
        })
      })
      .sort((a, b) => compareAsc(a.date, b.date))
      .filter((x) => x.date >= subDays(new Date(), EVENTS_BACK_DAYS))

    return {
      calendarInfo: calendarInfo ?? [],
      minDate,
      maxDate,
    }
  }
)

export const selectCalendarDataByDate = (date: Date) =>
  createSelector(selectCalendarData, (data) => {
    return data.calendarInfo
      .filter((x) => isSameDay(x.date, date))
      .map((res) => {
        return {
          ...res,
          durationInMinutes:
            (res.duration.hours ?? 0) * 60 + (res.duration.minutes ?? 0),
          startMinute: res.data.startHour * 60 + res.data.startMinute,
        }
      })
  })

import { call, put, select } from "typed-redux-saga"
import { PayloadAction } from "@reduxjs/toolkit"
import { getSagaContext } from "../../../state/context"
import { contactsSlice } from "../state"
import { toContactInfo } from "../converters/profile"
import { getProfileFromStore, saveProfileToStore } from "../storage/profiles"
import { ContactsState } from "../state/types"

export function* contactFetchSaga(action: PayloadAction<string>) {
  const uid = action.payload
  const current = yield* select(
    (state) => (state.contacts as ContactsState["contacts"])[uid]
  )

  if (current?.loading) {
    return
  }

  try {
    const store = yield* getSagaContext("store")
    const cachedProfile = yield* call(getProfileFromStore, store, uid)
    if (cachedProfile) {
      contactsSlice.actions.contactFetchCompleted(cachedProfile)
    }

    const firebase = yield* getSagaContext("firebase")
    const user = yield* call(
      (uid) => firebase.collections.users.getUser(uid),
      uid
    )

    if (!user) {
      throw new Error(`User not found: ${uid}`)
    }
    const contact = toContactInfo(user)
    yield* call(saveProfileToStore, store, contact)
    yield* put(contactsSlice.actions.contactFetchCompleted(contact))
  } catch (error) {
    console.error(`contactFetchSaga -> contact fetch error - uid:${uid}`, error)
    yield* put(contactsSlice.actions.contactFetchError(error))
  }
}

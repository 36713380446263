import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectAuthStatus } from "@modules/auth/state/selectors"
import { messagingActions } from "./state"

type Props = {
  children: React.ReactNode
}

const ChatProvider = (props: Props) => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector(selectAuthStatus)
  // const channelList = useSelector(selectChannelPreviewIdList);
  React.useEffect(() => {
    if (isAuthenticated) {
      dispatch(messagingActions.watchChannels.trigger())
      dispatch(messagingActions.watchUsers.trigger())
      dispatch(messagingActions.watchChannelPreviews.trigger())
      dispatch(messagingActions.watchChatPreviews.trigger())
    }
    return () => {
      if (isAuthenticated) {
        dispatch(messagingActions.watchChannels.fulfill())
        dispatch(messagingActions.watchUsers.fulfill())
        dispatch(messagingActions.watchChannelPreviews.fulfill())
        dispatch(messagingActions.watchChatPreviews.fulfill())
      }
    }
  }, [isAuthenticated])
  return <>{props.children}</>
}

export { ChatProvider }

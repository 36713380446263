import { buffers, eventChannel } from "redux-saga"
import { put, take, cancelled, takeLatest, call } from "typed-redux-saga"
import { DocumentData } from "firebase/firestore"
import { PayloadAction } from "@reduxjs/toolkit"
import { getSagaContext } from "../../../state/context"
import { ChatPreviewEntity } from "../../../../database/messaging/chat-preview/chat-preview.entity"
import { ChatMessagesSubscribeInput } from "../state/types"
import { decodeAndNormalizeChatMsg } from "../state/converters/encryption"
import { fetchChatInitialMessages } from "./fetchChatInitialMessages"
import { last } from "lodash"

export function* watchChatMessages(
  action: PayloadAction<ChatMessagesSubscribeInput>
) {
  console.log("watchChatMessages -> start", action.payload)
  const state = yield* getSagaContext("state")
  const { chat, privateKey } = action.payload
  const firebase = yield* getSagaContext("firebase")
  const channel = eventChannel<DocumentData[]>((emitter) => {
    const unsubscribe = firebase.collections.chat.subscribeChatNewMessages(
      chat.id,
      (data) => {
        emitter(data)
      }
    )
    return () => unsubscribe()
  }, buffers.sliding(1))
  try {
    yield* takeLatest(
      state.messaging.actions.chatMessagesFulfill,
      function* (action: PayloadAction<ChatPreviewEntity>) {
        if (action.payload.id === chat.id) {
          channel.close()
        }
      }
    )
    // let firstResult = true
    while (true) {
      const data = yield* take(channel)
      console.log(`watchChatMessages -> ${data.length} messages received`, data)
      yield* put(
        state.messaging.actions.chatMessagesAppend({
          chatId: chat.id,
          messages: data.map((x) =>
            decodeAndNormalizeChatMsg(privateKey, x.data())
          ),
          cursor: last(data)?.id,
          isNewMessage: true,
        })
      )

      yield* put(
        state.messaging.actions.chatSendReadNotification({
          chatId: chat.id,
          type: "read",
          lastMessageId: data[0]?.id,
        })
      )

      // if (firstResult && data.length > 0) {
      //   firstResult = false
      //   console.log("watchChatMessages -> fetch initial messages")
      //   yield* call(fetchChatInitialMessages, {
      //     chatId: chat.id,
      //   })
      // }
    }
  } catch (error) {
    yield* put(
      state.messaging.actions.chatMessagesSetFaulted({
        chatId: chat.id,
        error,
      })
    )
  } finally {
    if (yield* cancelled()) {
      channel.close()
    }
    console.log("watchChatMessages -> closed", action.payload)
  }
}

import CourseDetailContainer from "@containers/courses/CourseDetail"
import PageRoot from "@root/pages/PageRoot"

export interface CourseDetailParams {
  courseId: string
}

const CourseDetailPage = () => {
  return (
    <PageRoot fullscreen>
      <CourseDetailContainer />
    </PageRoot>
  )
}

export { CourseDetailPage }

import { createRoutine } from "redux-saga-routines"
import { createSlice } from "@reduxjs/toolkit"
import {
  AppCarousel,
  LabelCategoryDocument,
} from "@common/common/api/functions"
import { IconsMap } from "@services/sanity/contents/icons/types"
import { indexBy } from "ramda"
import { AppContentsData } from "./types"

const name = "contents"

export interface ContentsState {
  isLoading: boolean
  error: boolean
  labelsData: {
    brandsMap: Record<string, LabelCategoryDocument>
    categoriesMap: Record<string, LabelCategoryDocument>
  }
  iconsData: IconsMap
  carouselData: AppCarousel[]
}
const initialState: ContentsState = {
  isLoading: true,
  error: false,
  labelsData: {
    brandsMap: {},
    categoriesMap: {},
  },
  iconsData: {},
  carouselData: [],
}

const load = createRoutine("CONTENTS_LOAD", {
  SUCCESS: (payload: AppContentsData) => payload,
})

const routines = {
  load,
}

const slice = createSlice({
  name,
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(load.TRIGGER, (state) => {
        state.isLoading = true
        state.error = false
      })
      .addCase(
        load.SUCCESS,
        (state, action: ReturnType<typeof load.success>) => {
          state.isLoading = false
          state.labelsData = convertLabelsData(action.payload)
          state.carouselData = action.payload.carouselData
          state.error = false
        }
      )
      .addCase(load.FULFILL, (state) => {
        state.isLoading = false
        state.error = false
      })
      .addCase(load.FAILURE, (state) => {
        state.isLoading = false
        state.error = true
      })
  },
})

const {
  name: contentsName,
  actions: sliceActions,
  reducer: contentsReducer,
} = slice

const contentsActions = {
  ...sliceActions,
  ...routines,
}

export { contentsName, contentsReducer, contentsActions }

export const convertLabelsData = (
  data: ReturnType<typeof load.success>["payload"]
): ContentsState["labelsData"] => {
  return {
    brandsMap: indexBy((b) => b.code, data.labelsData.brands ?? []),
    categoriesMap: indexBy((b) => b.code, data.labelsData.categories ?? []),
  }
}

import { CoursePdf } from "./course-attachment.entity"
import {
  Firebase,
  FirestoreController,
} from "../../../infrastructure/firebase/controller"
import { Entity } from "../../../infrastructure/firebase/types"

export class CourseAttachmentsController extends FirestoreController<CoursePdf> {
  constructor(firebase: Firebase) {
    super(firebase, "CourseAttachmentsRepo")
  }

  subscribeAll(callback: (items: Entity<CoursePdf>[]) => void) {
    return this.subscribeCollection(callback)
  }
}

import { useTranslation } from "react-i18next"

export const useTranslations = () => {
  const { t, i18n } = useTranslation()

  // todo: fix typing
  const tPluralized = (
    {
      singular,
      plural,
    }: {
      singular: string
      plural: string
    },
    count: number
  ) => {
    return count === 1 ? t(singular as any) : t(plural as any)
  }

  const changeLanguage = (lang: string) => i18n.changeLanguage(lang)

  return {
    t,
    tPluralized,
    changeLanguage,
  }
}

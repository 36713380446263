import { trimEnd } from "@helpers"
import { getSagaContext } from "@modules/store/redux/sagaContext"
import { FunctionsOpenAPI } from "@common/common/api"
import { call, put } from "typed-redux-saga"
import { appActions } from ".."
import { Storage } from "@capacitor/storage"

export function* appLoadSaga() {
  const firebase = yield* getSagaContext("firebase")
  FunctionsOpenAPI.TOKEN = async function () {
    return firebase.getFirebaseToken() ?? ""
  }
  FunctionsOpenAPI.BASE = trimEnd(process.env.REACT_APP_BACKEND_URL, "/")

  try {
    yield* call(firebase.init)
    yield* put(appActions.setIsInitialized())
    const res = yield* call(Storage.get, { key: "isIntroCarouselChecked" })
    yield* put(
      appActions.load.success({
        isIntroCarouselChecked: res.value != null && /true/i.test(res.value),
      })
    )
  } catch (error) {
    yield* put(appActions.load.failure())
  } finally {
    yield* put(appActions.load.fulfill())
  }
}

import { selectExistsUnreadNotifications } from "@features/notifications/selectors"
import { useRootSelector } from "@modules/store/redux"
import { useRouting } from "../../../root/routing/useRouting"
import { AppHeader } from "@organisms"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { PreviewAvatar } from "@molecules"

const MainHeader = () => {
  // const user = useRootSelector((state) => state.auth.user)
  const { user: profile } = useRootSelector((state) => state.userProfile)
  const existsUnreadNotifications = useRootSelector(
    selectExistsUnreadNotifications
  )
  const { t } = useTranslations()
  const { goTo } = useRouting()

  return (
    <AppHeader
      onNotificationsClick={() =>
        goTo("notifications", { animation: "rightToLeftSlide" })
      }
      hasUnreadNotifications={existsUnreadNotifications}
      title={
        profile?.data?.profile.firstName
          ? `${t("common:hello")} ${profile?.data?.profile.firstName},`
          : ""
      }
      icon={
        profile?.data?.profile?.avatarUrl ? (
          <PreviewAvatar
            size="xs"
            variant="filled"
            backgroundImageUrl={profile.data.profile.avatarUrl}
          />
        ) : undefined
      }
    />
  )
}

export { MainHeader }

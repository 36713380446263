export const formatCalendarTime = (hour: number, minute: number) =>
  `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`

export interface MediaDuration {
  hours: number
  minutes: number
  seconds: number
}

export const formatMediaDuration = (input: MediaDuration) => {
  const p = []
  if (input.hours > 0) {
    p.push(input.hours)
  }
  p.push(input.minutes)
  p.push(input.seconds)
  return p.map((x) => x.toString().padStart(2, "0")).join(":")
}

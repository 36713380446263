import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Autoplay } from "swiper"
import { ObjectiveBox } from "@molecules"
import { SectionTitle } from "@atoms"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { useUserRole } from "@modules/auth/hooks/useUserRole"

interface ObjectiveBoxData {
  title: string
  typology: string
  dueDate: Date
  currentPercentage: number
  currentValue: number
  maxValue: number
}

interface Props {
  title: string
  items: ObjectiveBoxData[]
  className?: string
}

export const ObjectiveBoxes = ({ items, title, className }: Props) => {
  const { t } = useTranslations()
  const { isManager } = useUserRole()

  return (
    <div className={className}>
      <SectionTitle text={title} />
      <Swiper
        observer
        pagination
        slidesPerView={"auto"}
        spaceBetween={12}
        modules={[Pagination, Autoplay]}
        className="swiper-objectives"
      >
        {items.map((x, i) => {
          return (
            <SwiperSlide key={i} className="!w-auto">
              <ObjectiveBox
                title={x.title}
                typology={x.typology}
                dueDate={x.dueDate}
                currentValue={x.currentValue}
                currentPercentage={Math.min(x.currentPercentage, 100)}
                maxValue={x.maxValue}
                labels={{
                  expiration: t("performance:objectives.box.expiration"),
                  progress: isManager
                    ? t("performance:objectives.box.progress", {
                        currentValue: x.currentValue,
                        maxValue: x.maxValue,
                      })
                    : undefined,
                }}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

import React from "react"
import classNames from "classnames"
import CoursePreviewBox from "./CoursePreviewBox"

interface Prop {
  isHidden?: boolean
  courseIdList: string[]
}

const CoursePreviewBoxes = ({ isHidden, courseIdList }: Prop) => {
  return (
    <div className={classNames(isHidden && "hidden")}>
      <div className="flex flex-col gap-[40px]">
        {courseIdList.map((courseId) => {
          return <CoursePreviewBox key={courseId} courseId={courseId} />
        })}
        {courseIdList.length === 0 && (
          <div>Nessun corso corrispondente ai filtri selezionati</div>
        )}
      </div>
    </div>
  )
}

export default React.memo(CoursePreviewBoxes)

import { useMemo } from "react"
import { useSelector } from "react-redux"
import { selectChatMessagesById } from "../selectors/chat/detail"

export const useChatMessages = (chatId: string) => {
  const messagesSelector = useMemo(
    () => selectChatMessagesById(chatId),
    [chatId]
  )
  const { messageList, isLoading, hasMoreMessages, preview } =
    useSelector(messagesSelector)

  const initLastReadMessageId = useMemo(() => {
    return preview?.entity.data.lastReadMessageId
  }, [preview?.entity.data.lastReadMessageId != null])

  const lastReadMessageId = useMemo(() => {
    if (messageList == null) {
      return undefined
    }
    const lastIndex = messageList.findIndex(
      (x) => x.id === initLastReadMessageId
    )

    if (lastIndex + 1 === messageList.length) {
      return undefined
    }
    return initLastReadMessageId
  }, [messageList != null && messageList?.length, initLastReadMessageId])

  return {
    messageList,
    isLoading,
    hasMoreMessages,
    preview,
    lastReadMessageId,
  }
}

import classNames from "classnames"
import React, { CSSProperties } from "react"
import { Icon } from "@atoms"
import ContextMenu from "@ui/molecules/ContextMenu"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { EventReviewModal } from "./EventReviewModal"
import { RatingScores } from "@package/common/database/calendars/evaluationAggregations/evaluation-aggregation.entity"
import {
  MediumRatingIcon,
  NegativeRatingIcon,
  PositiveRatingIcon,
} from "@ui/icons/ratings"
import { EvaluationRate } from "@package/common/database/calendars/evaluation/evaluation.entity"

interface Props {
  appointmentId: string
  rating?: EvaluationRate
  ratings?: RatingScores
  name: string
  duration: string
  className?: string
  startTime: string
  endTime: string
  extraFields: string[]
  onClick?: () => void
  style?: CSSProperties
  canReview?: boolean
}

const Event = ({
  appointmentId,
  ratings,
  name,
  duration,
  startTime,
  endTime,
  extraFields,
  className,
  onClick,
  style,
  canReview,
}: Props) => {
  const { t } = useTranslations()
  const [reviewModalOpen, setReviewModalOpen] = React.useState(false)

  const handleReviewModalClose = () => setReviewModalOpen(false)
  const handleReviewModalOpen = () => setReviewModalOpen(true)

  return (
    <>
      <div
        onClick={onClick}
        style={style}
        className={classNames(
          "rounded-[12px] p-[16px] leading-[1.14] min-h-[134px]",
          className
        )}
      >
        <div className="flex justify-between">
          <div className="mb-[8px]">{name}</div>
          {canReview && (
            <ContextMenu
              title={t("calendars:event.actions.title")}
              actions={[
                {
                  text: t("calendars:event.actions.review"),
                  handler: () => handleReviewModalOpen(),
                },
                {
                  text: t("calendars:event.actions.close"),
                  role: "cancel",
                  data: {
                    action: "cancel",
                  },
                },
              ]}
            />
          )}
        </div>
        <div className="flex gap-[15px] items-center mb-[26px]">
          <Icon iconName="clock" className="w-[16px] h-[16px]" />
          <div className="capitalize">
            {startTime} - {endTime} ({duration})
          </div>
        </div>
        <div className="flex gap-2">
          <div className="flex flex-col gap-[8px]">
            {extraFields.map((x, i) => (
              <div key={i}>{x}</div>
            ))}
          </div>
        </div>
        {ratings && (
          <div className="flex gap-2 items-center">
            {ratings.negative ? (
              <div className="flex gap-2 items-center">
                <NegativeRatingIcon width={20} /> {ratings.negative}
              </div>
            ) : undefined}
            {ratings.neutral ? (
              <div className="flex gap-2 items-center">
                <MediumRatingIcon width={20} />
                {ratings.neutral}
              </div>
            ) : undefined}
            {ratings.positive ? (
              <div className="flex gap-2 items-center">
                <PositiveRatingIcon width={20} />
                {ratings.positive}
              </div>
            ) : undefined}
          </div>
        )}
      </div>
      <EventReviewModal
        open={reviewModalOpen}
        onClose={handleReviewModalClose}
        onSubmitted={handleReviewModalClose}
        appointmentId={appointmentId}
      />
    </>
  )
}

export { Event }

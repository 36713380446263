import { Device } from "@capacitor/device"

export const getDeviceId = async () => {
  const device = await Device.getId()
  return device.uuid
}

export const getDeviceInfo = async () => {
  return await Device.getInfo()
}

import { useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react"
import { useDispatch } from "react-redux"
import { appActions } from "../state"

export default () => {
  const dispatch = useDispatch()
  useIonViewWillEnter(() => {
    dispatch(appActions.setFullscreen(true))
    console.log("full screen -> enter")
  })
  useIonViewWillLeave(() => {
    dispatch(appActions.setFullscreen(false))
    console.log("full screen -> leave")
  })
}

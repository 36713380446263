import { limit, orderBy, query, where } from "firebase/firestore"
import {
  Firebase,
  FirestoreController,
} from "../../infrastructure/firebase/controller"
import { Entity } from "../../infrastructure/firebase/types"
import { NotificationEvent } from "./notification-event.entity"

export interface SubscribeUserNotificationsInput {
  uid: string
  limit: number
}

export class NotificationEventsController extends FirestoreController<NotificationEvent> {
  constructor(firebase: Firebase) {
    super(firebase, "NotificationEventRepo")
  }

  subscribeUserNotifications(
    input: SubscribeUserNotificationsInput,
    callback: (items: Entity<NotificationEvent>[]) => void
  ) {
    return this.subscribeQuery(
      (collection) =>
        query(
          collection,
          where("u", "==", input.uid),
          orderBy("ts", "desc"),
          limit(input.limit)
        ),
      (data) => callback(data)
    )
  }
}

import { appRootSaga } from "@modules/app/state/sagas"
import { calendarRootSaga } from "@features/calendar/state/sagas"
import { coursesRootSaga } from "@features/courses/state/sagas"
import { dataRootSaga } from "@modules/contents/state/sagas"
import { messagingRootSaga } from "@features/messaging/state/sagas"
import { performanceRootSaga } from "@features/performance/state/sagas"
import { profileRootSaga } from "@features/profile/state/sagas"
import { notificationsRootSaga } from "@features/notifications/state/sagas"
import { deploymentsRootSaga } from "@modules/deployments/state/sagas"
import { authRootSaga } from "@modules/auth/sagas"
import { contactsRootSaga } from "@common/common/frontend/features/contacts/sagas"

export const sagas = [
  authRootSaga,
  coursesRootSaga,
  appRootSaga,
  dataRootSaga,
  messagingRootSaga,
  calendarRootSaga,
  profileRootSaga,
  performanceRootSaga,
  notificationsRootSaga,
  deploymentsRootSaga,
  contactsRootSaga,
]

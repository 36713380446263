import { createRoutine } from "redux-saga-routines"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppState, SplashScreen } from "./types"

const name = "app"

const initialState: AppState = {
  localeId: "it",
  isFullscreen: false,
  isInitialized: false,
  isloading: true,
  isIntroCarouselChecked: false,
  splashScreen: "INITIAL",
}

const load = createRoutine("APP_LOAD", {
  SUCCESS: (payload: { isIntroCarouselChecked: boolean }) => payload,
})
const watchLiveUpdates = createRoutine("APP_WATCH_LIVEUPDATES")

const checkIntroCarousel = createRoutine("APP_CHECK_INTRO_CAROUSEL")

const routines = {
  load,
  watchLiveUpdates,
  checkIntroCarousel,
}

const slice = createSlice({
  name,
  reducers: {
    navigate(state, action: PayloadAction<string | undefined>) {
      state.navigate = action.payload
    },
    setFullscreen(state, action: PayloadAction<boolean>) {
      state.isFullscreen = action.payload
    },
    setIsInitialized(state) {
      state.isInitialized = true
    },
    showSplashScreen(state, action: PayloadAction<SplashScreen>) {
      state.splashScreen = action.payload
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      load.SUCCESS,
      (state, action: ReturnType<typeof load.success>) => {
        state.isIntroCarouselChecked = action.payload.isIntroCarouselChecked
      }
    )
    builder.addCase(load.FULFILL, (state) => {
      state.isloading = false
    })

    builder.addCase(checkIntroCarousel.SUCCESS, (state) => {
      state.isIntroCarouselChecked = true
    })
  },
})

const { name: appName, actions: sliceActions, reducer: appReducer } = slice

const appActions = {
  ...sliceActions,
  ...routines,
}

export { appName, appReducer, appActions }

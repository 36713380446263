import { MessageDraft, MessagingState } from "../state/types"
import { DraftRef } from "../types"

export const selectDraft = (
  state: MessagingState,
  ref: DraftRef
): MessageDraft => {
  switch (ref.scope) {
    case "global":
      return state.globalOutbox.draft
    case "channel":
      if (!ref.id) {
        throw new Error("Missing channel id")
      }
      return state.channelMessages[ref.id].draft
    case "chat":
      if (!ref.id) {
        throw new Error("Missing chat id")
      }
      return state.chatMessages[ref.id].draft
  }
}

export const selectAttachment = (
  state: MessagingState,
  ref: DraftRef,
  attachmentId: string
) => {
  const draft = selectDraft(state, ref)
  const item = draft.attachments.items.find((x) => x.id === attachmentId)
  if (!item) {
    throw new Error(
      `Attachment ${attachmentId} not found -> ${ref.scope} ${ref.id ?? ""}`
    )
  }
  return item
}

export const allUploadsCompleted = (draft: MessageDraft) =>
  draft.attachments.items.every((x) => x.uploaded)

export const hasUploadsInProgress = (draft: MessageDraft) =>
  draft.attachments.items.some((x) => x.uploading)

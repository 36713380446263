import { Button, Image } from "@atoms"
import { appActions } from "@modules/app/state"
import classNames from "classnames"
import { useCallback, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { SlidePosition } from "@ui/atoms/SlidePosition"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperClass from "swiper/types/swiper-class"
import { EditorialCarouselCta, EditorialCarouselSlide } from "./types"
import { useRouting } from "@root/routing/useRouting"

export interface EditorialCarouselProps {
  slides: EditorialCarouselSlide[]
  cta: EditorialCarouselCta
}

export const EditorialCarousel = ({ cta, slides }: EditorialCarouselProps) => {
  const [currentSlideNumber, setCurrentSlideNumber] = useState(0)
  const { goTo } = useRouting()
  const currentSlide = slides?.[currentSlideNumber]
  const swiperRef = useRef<SwiperClass>()
  const dispatch = useDispatch()
  const login = useCallback(() => {
    dispatch(appActions.checkIntroCarousel.trigger())
    goTo("login")
  }, [])

  const nextSlide = () => {
    swiperRef.current?.slideNext()
  }
  const previousSlide = () => {
    swiperRef.current?.slidePrev()
  }
  const isFirstSlide = currentSlideNumber === 0
  const isLastSlide = currentSlideNumber === (slides.length ?? 1) - 1

  if (!slides || !currentSlide) {
    return <></>
  }
  return (
    <div className="h-full py-[32px] grid grid-cols-1 grid-rows-[78fr_22fr] ">
      <Swiper
        spaceBetween={32}
        preventInteractionOnTransition={true}
        onSlideNextTransitionStart={() => {
          setCurrentSlideNumber(currentSlideNumber + 1)
        }}
        onSlidePrevTransitionStart={() => {
          setCurrentSlideNumber(currentSlideNumber - 1)
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper
        }}
        className="w-full !overflow-hidden"
        slidesPerView={1}
      >
        {slides.map((x, i) => {
          return (
            <SwiperSlide key={i}>
              <div className="grid grid-cols-1 grid-rows-[64fr_36fr] h-full gap-[12px] px-[32px]">
                <div className="self-end">
                  <Image
                    src={x.imageUrl}
                    classes={{ img: "w-[75%] max-w-xl mx-auto" }}
                  />
                </div>
                <div className="">
                  <div className=" text-3xl leading-[1.45] text-center mb-[22px] mt-[8px]">
                    {x.title}
                  </div>
                  <div className=" text-md leading-[1.5] text-light text-center mb-[38px]">
                    {x.text}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>

      <div className="flex flex-col justify-between px-[32px] ">
        <div className={classNames("flex items-center justify-center")}>
          <SlidePosition
            currentSlideNumber={currentSlideNumber}
            slidesCount={slides.length}
            type="BARS"
          />
        </div>
        {isLastSlide && (
          <div className="text-center text-light text-md">
            {currentSlide.subtitle}
          </div>
        )}
        <div className="flex gap-[22px]">
          {slides.length > 1 && (
            <Button
              onClick={isFirstSlide ? login : previousSlide}
              variant="light"
              fullWidth
            >
              {isFirstSlide ? cta.skip : cta.previous}
            </Button>
          )}
          <Button
            className="text-white"
            onClick={isLastSlide ? login : nextSlide}
            fullWidth
          >
            {isLastSlide ? cta.finish : cta.next}
          </Button>
        </div>
      </div>
    </div>
  )
}

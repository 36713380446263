import { Icon } from "@atoms"
import { setCssVar } from "@helpers/ui"
import { IonTextarea } from "@ionic/react"

export interface MessagingToolbarInputProps {
  placeholder: string
  onSend: () => void
  onCameraClick: () => void
  // todo: typings fix
  inputAdditionalProps: {
    textArea: any
    cameraUpload: any
  }
}

const MessagingToolbarInput = ({
  placeholder,
  onSend,
  onCameraClick,
  inputAdditionalProps,
}: MessagingToolbarInputProps) => {
  return (
    <div className="flex items-end gap-[12px]">
      <div className="flex-1 grid grid-cols-[1fr_auto] py-[12px] px-[12px] bg-grey rounded-[10px]">
        <IonTextarea
          placeholder={placeholder}
          style={{
            ...setCssVar("--padding-bottom", 0),
            ...setCssVar("--padding-start", 0),
            ...setCssVar("--padding-top", 0),
            ...setCssVar("--padding-end", 0),
          }}
          rows={1}
          autoGrow
          className="flex items-center w-full min-w-full m-0 max-h-36  pr-[12px] text-dark"
          {...inputAdditionalProps.textArea}
        />
        <div className="flex items-end h-full">
          <div className="flex gap-[10px]">
            <Icon
              iconName="camera"
              className="w-[28px] h-[28px]"
              onClick={onCameraClick}
            />
            <label>
              <Icon iconName="attachment" className="w-[24px] h-[24px]" />
              <input
                className="hidden"
                multiple
                type="file"
                {...inputAdditionalProps.cameraUpload}
              />
            </label>
          </div>
        </div>
      </div>
      <div className="h-[48px] pt-[12px]">
        <Icon onClick={onSend} iconName="send" className="w-[30px] h-[30px]" />
      </div>
    </div>
  )
}

export default MessagingToolbarInput

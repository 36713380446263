import { useTranslations } from "@modules/app/hooks/useTranslations"
import { CourseTestQuestion } from "@package/common/api/functions"
import { classNames } from "@punks/ui-core"

export interface CourseTestQuestionPanelProps {
  question: CourseTestQuestion
  selectAnswer: (checked: boolean, id: string, isMulti: boolean) => void
  currentAnswers?: string[]
  showQuestionError?: boolean
}

const CourseTestQuestionPanel = ({
  question,
  selectAnswer,
  currentAnswers,
  showQuestionError,
}: CourseTestQuestionPanelProps) => {
  const { t } = useTranslations()

  const isMulti =
    question.questionType === CourseTestQuestion.questionType.MULTIPLE_CHOICES

  return (
    <div className="flex flex-col px-container py-[28px] min-h-full">
      <div className=" font-bold text-xl leading-[1.15] mb-[24px]">
        {question.text}
      </div>
      <div className="flex flex-col w-full gap-[25px] flex-1 justify-center">
        {question.image && (
          <img className="rounded-[30px]" src={question.image.url} />
        )}
        {isMulti && (
          <div className="mt-4">{t("courses:test.question.multi")}</div>
        )}
        {question.answers.map((ans) => {
          return (
            <label
              key={ans.id}
              htmlFor={ans.id}
              className="flex items-center w-full gap-[12px] py-[16px] border border-dark px-[25px] rounded-[12px]"
            >
              <input
                checked={
                  currentAnswers?.find((x) => x === ans.id) == null
                    ? false
                    : true
                }
                className="hidden peer"
                type={isMulti ? "checkbox" : "radio"}
                id={ans.id}
                name={isMulti ? ans.id : "radio"}
                onChange={(e) => {
                  selectAnswer(e.target.checked, ans.id, isMulti)
                }}
              />
              <div
                className={classNames(
                  "relative block min-w-[16px] min-h-[16px] w-[16px] h-[16px] m-[4px] border border-dark peer-checked:border-dark peer-checked:after:bg-dark after:content-[''] after:w-[8px] after:h-[8px] after:absolute after:inset-0 after:m-auto",
                  {
                    "rounded-full after:rounded-full": !isMulti,
                    "rounded-[4px]": isMulti,
                  }
                )}
              />
              <div className="font-bold text-base leading-[1.43]">
                {ans.answer}
              </div>
            </label>
          )
        })}
        <div className="min-h-[24px]">
          {showQuestionError && (
            <div className="font-bold text-red leading-[1.43] text-center">
              {t("courses:test.question.wrong")}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CourseTestQuestionPanel

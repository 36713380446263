import { IconNames } from "@ui/atoms/Icon"

export const getChannelLogo = (scope: string): IconNames => {
  if (scope == null) {
    return ""
  }
  switch (scope) {
    case "area":
      return "key"
    case "company":
      return "shield"
    case "store":
      return "support"
    default:
      return ""
  }
}

import { useRouting } from "@root/routing/useRouting"
import { format, isSameDay, startOfDay } from "date-fns"
import { useSelector } from "react-redux"
import { formatCalendarTime } from "../../../../utils/time"
import { selectCalendarData } from "../../selectors"
import { Event } from "../Event"
import EventDayText from "../Event/EventDayText"
import { useTranslations } from "@modules/app/hooks/useTranslations"

type Props = {}

const UpcomingEvents = (props: Props) => {
  const { calendarInfo } = useSelector(selectCalendarData)

  const filteredCalendarInfo = calendarInfo.filter(
    (x) => x.date >= startOfDay(new Date())
  )

  const { goTo } = useRouting()
  const { t } = useTranslations()

  return (
    <div>
      <div className=" font-semibold mb-[22px] leading-normal">
        {t("calendars:events.next")}
      </div>
      {filteredCalendarInfo.length === 0 && (
        <div className="text-light">{t("calendars:events.empty")}</div>
      )}
      <div className="flex flex-col gap-[26px]">
        {filteredCalendarInfo.map((d, index) => {
          return (
            <div key={d.id}>
              {(index === 0 ||
                !isSameDay(filteredCalendarInfo[index - 1].date, d.date)) && (
                <EventDayText startDate={d.date} />
              )}
              <Event
                appointmentId={d.id}
                ratings={d.ratings}
                rating={d.rating}
                onClick={() =>
                  goTo("calendarDailyEventWithId", {
                    params: {
                      date: format(d.date, "yyyy-MM-dd"),
                      eventId: d.id,
                    },
                  })
                }
                className="bg-green/[0.45]"
                duration={d.formattedDuration}
                canReview={d.isPassed}
                startTime={formatCalendarTime(
                  d.data.startHour,
                  d.data.startMinute
                )}
                endTime={formatCalendarTime(d.data.endHour, d.data.endMinute)}
                name={d.name}
                extraFields={d.extraFields}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export { UpcomingEvents }

import { getSagaContext } from "../../../state/context"
import { call } from "typed-redux-saga"
import { NotificationsMarkReadInput } from "../../../../api/functions"
import { PayloadAction } from "@reduxjs/toolkit"

export function* notificationsMarkReadSaga(
  action: PayloadAction<NotificationsMarkReadInput>
) {
  const api = yield* getSagaContext("api")
  yield* call(api.notificationsMarkRead, action.payload)
}

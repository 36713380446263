import { getAvatarUserColor } from "@helpers/profiles"
import { useChatData } from "@features/messaging/hooks/useChatData"
import { messagingActions } from "@features/messaging/state"
import { PreviewAvatar } from "@molecules"
import React from "react"
import { useDispatch } from "react-redux"
import { useUserTypeLabel } from "@modules/app/hooks/useUserTypeLabel"
import { UseMessagingModeData } from "../../common/MessageListItem/hooks/useMessagingMode"
import { UseMessagesSelectData } from "../../common/MessageListItem/hooks/useMessagesSelect"
import { classNames } from "@punks/ui-core"
import { Icon } from "@atoms"
import { useUserPreviewLabel } from "@modules/app/hooks/useUserPreviewLabel"

interface Props {
  chatId: string
  messagingMode: UseMessagingModeData
  selectedMessages: UseMessagesSelectData
  onDeleteConfirmed: () => void
  deleteInProgress?: boolean
}

const ChatHeader = ({
  chatId,
  messagingMode,
  onDeleteConfirmed,
  selectedMessages,
  deleteInProgress,
}: Props) => {
  const chatData = useChatData(chatId)
  const dispatch = useDispatch()
  const { getUserLabel } = useUserPreviewLabel()

  React.useEffect(() => {
    if (chatData?.entity.id) {
      dispatch(
        messagingActions.chatMessagesSubscribe({
          chat: chatData.entity,
          privateKey: chatData.privateKey,
        })
      )
    }
    return () => {
      if (chatData?.entity.id) {
        dispatch(messagingActions.chatMessagesFulfill(chatData?.entity))
      }
    }
  }, [chatData?.entity.id])

  if (!chatData) {
    return <></>
  }

  return (
    <div
      className={classNames(
        "flex items-center text-dark text-base leading-[1.5] font-[500]",
        {
          "justify-between": messagingMode.mode === "select",
        }
      )}
    >
      {messagingMode.mode === "select" && (
        <>
          <div
            className="cursor-pointer"
            onClick={() => {
              messagingMode.setDefaultMode()
              selectedMessages.clear()
            }}
          >
            <Icon iconName={"closeX"} className="w-[28px] h-[28px]" />
          </div>
          {selectedMessages.selected.length > 0 && (
            <button
              className="cursor-pointer mr-4"
              disabled={deleteInProgress}
              onClick={onDeleteConfirmed}
            >
              <Icon iconName={"trash"} className="w-[28px] h-[28px] text-red" />
            </button>
          )}
        </>
      )}
      {messagingMode.mode === "default" && (
        <>
          <PreviewAvatar
            size="small"
            variant="outlined"
            backgroundImageUrl={
              chatData.entity.data.receiverInfo?.profile?.avatarUrl
            }
            backgroundColor={getAvatarUserColor(
              chatData.entity.data.receiverInfo?.profile?.firstName
            )}
          />
          <div className="ml-6">
            <div className="text-lg font-medium">{chatData?.title}</div>
            <div className="text-muted text-light">
              {getUserLabel(chatData?.entity.data.receiverInfo)}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export { ChatHeader }

import "./style.css"

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css"

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/display.css"

import "swiper/css"
import "swiper/css/pagination"
import "@ionic/react/css/ionic-swiper.css"

import { setupIonicReact } from "@ionic/react"
import { initializeStorage } from "./services/storage"
import { AppRoot } from "./root"
import { i18nInitialize } from "./i18n"
import { CapacitorUpdater } from "@capgo/capacitor-updater"

setupIonicReact({
  mode: "md",
  rippleEffect: false,
})

initializeStorage()
i18nInitialize()

CapacitorUpdater.notifyAppReady()

const App: React.FC = () => {
  return <AppRoot />
}

export default App

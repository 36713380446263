import { Courses } from "../types/courses"

export const filterCourses = (courses: Courses): Courses => {
  const filteredCourses: Courses = {}
  for (const courseId in courses) {
    const course = courses[courseId]
    if (
      course.status === "published" &&
      course.data?.info?.brandCode &&
      course.data?.contents?.chapters?.length > 0
    ) {
      filteredCourses[courseId] = course
    }
  }
  return filteredCourses
}

import { groupBy } from "lodash"

export const deduplicateArray = <T>(
  values: T[],
  key: (item: T) => any
): T[] => {
  const groups = groupBy(values, (x) => key(x))
  const normalized = []
  for (const key in groups) {
    normalized.push(groups[key][0])
  }
  return normalized
}

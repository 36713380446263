import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode } from "swiper"
import {
  selectCompletedCategoryCodeList,
  selectCourseCategoryCodeList,
} from "../../state/selectors/lists"
import CourseCategoryButton from "./CourseCategoryButton"
import { T } from "ramda"
import { useTranslations } from "@modules/app/hooks/useTranslations"

interface Props {
  onlyCompleted?: boolean
  className?: string
}

const CourseCategoriesSelector = ({ onlyCompleted, className }: Props) => {
  const { t } = useTranslations()
  const ids = useSelector(
    onlyCompleted
      ? selectCompletedCategoryCodeList
      : selectCourseCategoryCodeList
  )
  return (
    <Swiper
      className={className}
      observer
      freeMode
      modules={[FreeMode]}
      spaceBetween={8}
      slidesPerView="auto"
      slidesOffsetBefore={16}
      slidesOffsetAfter={16}
    >
      <SwiperSlide className="!w-auto">
        <CourseCategoryButton
          categoryCode={null}
          isCompletedCategory={!!onlyCompleted}
          label={t("courses:categories.all")}
        />
      </SwiperSlide>
      {ids.map((id) => {
        return (
          <SwiperSlide key={id} className="!w-auto">
            <CourseCategoryButton
              categoryCode={id}
              isCompletedCategory={!!onlyCompleted}
            />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default React.memo(CourseCategoriesSelector)

import { Button, Footer } from "@atoms"
import { useTestProgress } from "@features/courses/hooks/testProgress"
import CourseTestHeader from "./CourseTestHeader"
import { IonContent } from "@ionic/react"
import { useRouting } from "@root/routing/useRouting"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import CourseTestQuestionPanel from "./CourseTestQuestion"
import CourseTestResultPanel from "./CourseTestResultPanel"

const CourseTestDetail = () => {
  const { t } = useTranslations()
  const { goTo } = useRouting()
  const {
    next,
    question,
    selectAnswer,
    currentAnswers,
    isSuccess,
    isDisabled,
    retry,
    showQuestionError,
  } = useTestProgress()

  return (
    <>
      {isSuccess == null && (
        <>
          <CourseTestHeader />
          <IonContent fullscreen>
            <CourseTestQuestionPanel
              question={question}
              selectAnswer={selectAnswer}
              currentAnswers={currentAnswers}
              showQuestionError={showQuestionError}
            />
          </IonContent>
          <Footer>
            <div className="px-[32px] pt-[14px] pb-[15px] ">
              <Button
                className="text-white ob"
                onClick={() => !isDisabled && next()}
              >
                {t("courses:test.next")}
              </Button>
            </div>
          </Footer>
        </>
      )}

      {isSuccess != null && (
        <div className="px-[32px] mt-[71px]">
          <CourseTestResultPanel isSuccess={isSuccess} />
          <Button
            className="capitalize h-[50px] bottom-0"
            variant="primary"
            onClick={() => {
              if (isSuccess) {
                goTo("courses")
              } else {
                retry()
                // dispatch(coursesActions.setTestId(null))
              }
            }}
          >
            {t("courses:test.continue")}
          </Button>
        </div>
      )}
    </>
  )
}

export default CourseTestDetail

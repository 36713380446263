import classNames from "classnames"
import React from "react"

interface Props {
  message: string
  isMine: boolean
  extraContentTop?: React.ReactNode
}
const Text = ({ isMine, message, extraContentTop }: Props) => {
  return (
    <div
      className={classNames(
        "rounded-t-[10px] p-[14px] text-base leading-normal mb-[8px]",
        isMine
          ? "bg-dark rounded-bl-[10px] text-white"
          : "bg-white rounded-br-[10px] shadow-[0_6px_25px_#EBF0F5] border-[1px] border-solid border-[#EBF0F5] text-greyDark"
      )}
    >
      {extraContentTop && <div>{extraContentTop}</div>}
      <div
        className={classNames(
          "whitespace-pre-wrap",
          isMine ? "text-grey" : "text-step-450"
        )}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      </div>
    </div>
  )
}

export { Text }

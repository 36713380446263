import classNames from "classnames"
import React from "react"
import { DivProps } from "@ui/types"

interface Props extends DivProps {
  text: string
}
const SectionTitle = ({ text, className, ...rest }: Props) => {
  return (
    <div
      className={classNames(
        "text-md leading-[1.5] tracking-[1px] text-dark font-bold mb-[23px]",
        className
      )}
      {...rest}
    >
      {text}
    </div>
  )
}

export default SectionTitle

import { createRoutine } from "redux-saga-routines"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { LoginInfo } from "../../../services/firebase/auth"
import { CurrentUser } from "../types"
import { LoginError, PasswordChangeError, PasswordChangeInput } from "./types"
import {
  faulted,
  idle,
  OperationalState,
  running,
  success,
} from "@common/common/utils/state"

const name = "auth"

export type UserImpersonationData = {
  uid: string
}

export type AuthState = {
  isLogging: boolean
  isAuthenticated: boolean
  user: CurrentUser | null
  isWatching: boolean
  isLoading: boolean
  initialized: boolean
  error: LoginError | null
  passwordChange: OperationalState<PasswordChangeError>
  impersonation?: UserImpersonationData
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  initialized: false,
  isLogging: false,
  isWatching: false,
  isLoading: false,
  error: null,
  passwordChange: idle(),
}

const logout = createRoutine("AUTH_LOGOUT")
const login = createRoutine("AUTH_LOGIN", {
  SUCCESS: (payload: CurrentUser) => payload,
  trigger: (payload: LoginInfo) => payload,
  failure: (payload: LoginError) => payload,
})

const passwordChange = createRoutine("AUTH_PWD_CHANGE", {
  SUCCESS: (payload: CurrentUser) => payload,
  trigger: (payload: PasswordChangeInput) => payload,
  failure: (payload: PasswordChangeError) => payload,
})

const watch = createRoutine("AUTH_WATCH")

const routines = {
  logout,
  login,
  watch,
  passwordChange,
}

const slice = createSlice({
  name,
  reducers: {
    setInitialized: (state) => {
      state.initialized = true
    },
    setUserImpersonationUid: (state, action: PayloadAction<string>) => {
      state.impersonation = { uid: action.payload }
    },
    clearUserImpersonationUid: (state) => {
      state.impersonation = undefined
    },
  },
  initialState,
  extraReducers: (builder) => {
    // LOGIN
    builder
      .addCase(
        login.TRIGGER,
        (state, action: ReturnType<typeof login.trigger>) => {
          state.isLogging = true
        }
      )
      .addCase(
        login.SUCCESS,
        (state, action: ReturnType<typeof login.success>) => {
          state.isAuthenticated = true
          state.isLogging = false
          state.user = action.payload
          state.isLoading = false
          state.error = null
          state.initialized = true
        }
      )
      .addCase(
        login.FAILURE,
        (state, action: ReturnType<typeof login.failure>) => {
          state.isLoading = false
          state.isLogging = false
          state.error = action.payload
        }
      )
    //LOGOUT
    builder
      .addCase(logout.SUCCESS, (state) => {
        state.isAuthenticated = false
        state.user = null
        state.isLogging = false
        state.isLoading = false
      })
      // PASSWORD CHANGE
      .addCase(
        passwordChange.TRIGGER,
        (state, action: ReturnType<typeof passwordChange.trigger>) => {
          state.passwordChange = running()
        }
      )
      .addCase(
        passwordChange.SUCCESS,
        (state, action: ReturnType<typeof passwordChange.success>) => {
          state.passwordChange = success()
          state.user = action.payload
        }
      )
      .addCase(
        passwordChange.FAILURE,
        (state, action: ReturnType<typeof passwordChange.failure>) => {
          state.passwordChange = faulted(action.payload)
        }
      )
    // WATCH
    builder
      .addCase(watch.SUCCESS, (state) => {
        state.isWatching = true
      })
      .addCase(watch.FAILURE, (state) => {
        state.isWatching = false
      })
      .addCase(watch.FULFILL, (state) => {
        state.isWatching = false
      })
  },
})

const { name: authName, actions: sliceActions, reducer: authReducer } = slice

const authActions = {
  ...sliceActions,
  ...routines,
}

export { authName, authReducer, authActions }

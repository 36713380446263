import { useChatToolbar } from "@features/messaging/hooks/useChatToolbar"
import { MessagingToolbar } from "@organisms"
import { useTranslations } from "@modules/app/hooks/useTranslations"

interface Props {
  chatId: string
}

const ChatToolbar = ({ chatId }: Props) => {
  const { onSendMessage, register, draft, removeFile, takePicture } =
    useChatToolbar(chatId)

  const { t } = useTranslations()

  return (
    <MessagingToolbar>
      <MessagingToolbar.Attachments
        items={draft?.draft?.attachments?.items ?? []}
        onAttachmentRemove={(file) => removeFile(file.id)}
      />
      <MessagingToolbar.Input
        placeholder={t("messaging:chat.input.placeholder")}
        onSend={onSendMessage}
        onCameraClick={takePicture}
        inputAdditionalProps={{
          cameraUpload: register("files"),
          textArea: register("text"),
        }}
      />
    </MessagingToolbar>
  )
}

export { ChatToolbar }

import { call, put } from "typed-redux-saga"
import { getSagaContext } from "@modules/store/redux/sagaContext"
import { authActions } from "../state"
import { fetchUserData } from "./utils/fetchUser"

export function* passwordChangeSaga({
  payload,
}: ReturnType<typeof authActions.passwordChange.trigger>) {
  const api = yield* getSagaContext("api")
  const firebase = yield* getSagaContext("firebase")
  const currentUser = yield* call(firebase.getUser)
  if (!currentUser) {
    throw new Error("User not found")
  }

  try {
    yield* call(api.selfPasswordReset, {
      password: payload.newPassword,
    })

    yield* call(firebase.login, {
      email: currentUser.email ?? "",
      password: payload.newPassword,
    })
    const user = yield* call(fetchUserData, firebase)
    yield* put(authActions.passwordChange.success(user))
  } catch (error) {
    console.error("password change error", error)
    yield* put(authActions.passwordChange.failure("other"))
  }
}

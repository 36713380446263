import { isIosDevice } from "@modules/app/utils/device"
import { classNames } from "@punks/ui-core"

export interface SafeTopAreaContainerProps {
  children: any
  top?: boolean
  className?: string
}

const SafeTopAreaContainer = ({
  children,
  className,
  top,
}: SafeTopAreaContainerProps) => {
  return (
    <div
      className={classNames(
        {
          "pt-[46px]": top && isIosDevice(),
        },
        className
      )}
    >
      {children}
    </div>
  )
}

SafeTopAreaContainer.defaultProps = {
  top: true,
}

export default SafeTopAreaContainer

import { ImAttachment } from "../../database/messaging/common/message.entity"
import { MessageAttachment } from "../../frontend/features/messaging/state/types"

export const toImAttachment = (
  attachment: MessageAttachment
): ImAttachment => ({
  id: attachment.id,
  n: attachment.name,
  e: attachment.extension,
  at: attachment.type,
  mb: attachment.sizeMB,
  u: (attachment.asset?.url ?? null) as string,
  tu: attachment.asset?.thumbnailUrl ?? null,
  c: (attachment.asset?.contentType ?? null) as string,
})

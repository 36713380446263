/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CourseMetadata } from './CourseMetadata';

export type CourseItemReference = {
    id: string;
    title: string;
    metadata: CourseMetadata;
    status: CourseItemReference.status;
    createdDate: string;
};

export namespace CourseItemReference {

    export enum status {
        DRAFT = 'draft',
        SUBMITTED = 'submitted',
        PUBLISHED = 'published',
        REJECTED = 'rejected',
        ARCHIVED = 'archived',
    }


}


import {
  getStateContext,
  SagaContext as BaseSagaContext,
} from "@common/common/frontend/state/context"
import { getContext } from "redux-saga/effects"
import { firebase, functions, history } from "../../../services"
import { appStateStorage } from "./store"
import { messagingSliceContext } from "@features/messaging/state"
import { calendarSliceContext } from "@features/calendar/state"
import { performanceSliceContext } from "@features/performance/state"
import { notificationSliceContext } from "@features/notifications/state"

export const sagaStateContext = getStateContext({
  calendar: calendarSliceContext,
  messaging: messagingSliceContext,
  notifications: notificationSliceContext,
  performance: performanceSliceContext,
})

export interface SagaContext extends BaseSagaContext<SagaStateContext> {
  history: typeof history
  firebase: typeof firebase
}

export const sagaContext: SagaContext = {
  firebase,
  api: functions,
  state: sagaStateContext,
  history,
  store: appStateStorage,
}

export function* getSagaContext<T extends keyof SagaContext>(
  key: T
): Generator<any, SagaContext[T], any> {
  return yield getContext(key)
}

export type SagaStateContext = typeof sagaStateContext

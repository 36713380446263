import { takeLatest } from "typed-redux-saga"
import { calendarRoutines } from "../state"
import { watchUserCalendarsSaga } from "./watchCalendarsSaga"

export function* calendarsRootSaga() {
  yield* takeLatest(
    calendarRoutines.watchCalendars.trigger,
    watchUserCalendarsSaga
  )
}

import { setCssVar } from "@helpers/ui"
import { IonContent, IonHeader, IonToolbar } from "@ionic/react"
import AppInfoPanel from "@modules/app/components/AppInfoPanel"
import { Header } from "@molecules"
import { useRouting } from "@root/routing/useRouting"

const AppInfoContainer = () => {
  const { goTo } = useRouting()

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar
          className="flex items-end"
          style={{
            ...setCssVar("--min-height", 0),
          }}
        >
          <Header onClick={() => goTo("options")} showArrow center>
            <div className="font-semibold text-center text-md">Info</div>
          </Header>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <AppInfoPanel />
      </IonContent>
    </>
  )
}

export default AppInfoContainer

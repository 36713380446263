import {
  Firebase,
  FirestoreController,
  setServerTimestamp,
} from "../../../infrastructure/firebase/controller"
import { MessagingChannel } from "./channel.entity"
import { query, where, limit, orderBy, DocumentData } from "firebase/firestore"
import { Entity } from "../../../infrastructure/firebase/types"
import { ImMessage } from "../common/message.entity"

const CHANNEL_MESSAGES_SUBSCRIBE = 10

export class ChannelController extends FirestoreController<MessagingChannel> {
  readonly MessagesSubCollection = "messages"

  constructor(firebase: Firebase) {
    super(firebase, "ChannelRepo")
  }

  async appendMessage(channelId: string, message: ImMessage) {
    await this.createChildDocument({
      childId: message.id,
      data: message,
      parentId: channelId,
      subCollectionName: this.MessagesSubCollection,
    })
  }

  async updateChanelTimestamp(channelId: string) {
    await this.update({
      id: channelId,
      data: {
        lastMessageTime: setServerTimestamp(),
      },
    })
  }

  subscribeUserChannels(
    areaId: string,
    callback: (items: Entity<MessagingChannel>[]) => void
  ) {
    return this.subscribeQuery(
      (collection) =>
        query(
          collection,
          where("active", "==", true),
          where("areaId", "==", areaId)
        ),
      callback
    )
  }

  subscribeManagerChannels(
    callback: (items: Entity<MessagingChannel>[]) => void
  ) {
    return this.subscribeQuery(
      (collection) => query(collection, where("active", "==", true)),
      callback
    )
  }

  subscribeChannelNewMessages(
    channelId: string,
    callback: (items: DocumentData[]) => void
  ) {
    return this.subscribeSubCollectionQueryRaw(
      channelId,
      this.MessagesSubCollection,
      (collection) =>
        query(
          collection,
          orderBy("t", "desc"),
          limit(CHANNEL_MESSAGES_SUBSCRIBE)
        ),
      callback
    )
  }

  async fetchChannelMessagePage(
    channelId: string,
    pageSize: number,
    cursor: string
  ) {
    return await this.fetchSubCollectionPaged({
      parentId: channelId,
      cursor,
      subCollection: this.MessagesSubCollection,
      sortProperty: "t",
      sortOrder: "desc",
      pageSize,
    })
  }
}

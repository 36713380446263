import { takeLatest } from "typed-redux-saga"
import { notificationsRoutines } from "../state"
import { notificationsMarkReadSaga } from "./notificationsMarkRead"
import { watchUserNotifications } from "./watchUserNotifications"

export function* notificationsRootSaga() {
  yield* takeLatest(
    notificationsRoutines.watchNotifications.trigger,
    watchUserNotifications
  )
  yield* takeLatest(
    notificationsRoutines.notificationsMarkRead.trigger,
    notificationsMarkReadSaga
  )
}

import { useMemo } from "react"
import { DeviceContext } from "../context/device"
import { getPlatform, isMobileDevice } from "../utils/device"
import { useKeyboardSize } from "../hooks/useKeyboardSize"

export interface DeviceFeaturesProviderProps {
  children: React.ReactNode
}

const DeviceFeaturesProvider = ({ children }: DeviceFeaturesProviderProps) => {
  const isMobile = useMemo(() => isMobileDevice(), [])
  const platform = useMemo(() => getPlatform(), [])
  const keyboard = useKeyboardSize()

  return (
    <DeviceContext.Provider
      value={{
        isMobile,
        platform,
        keyboard,
      }}
    >
      {children}
    </DeviceContext.Provider>
  )
}

export default DeviceFeaturesProvider

import React from "react"
import { useSelector } from "react-redux"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode } from "swiper"
import { selectCourseCategoryCodeList } from "../../state/selectors"

import CourseCategoryBoxButton from "./CourseCategoryBoxButton"

interface Prop {
  showAll: () => void
}

const CourseCategoryBoxesSelector = ({ showAll }: Prop) => {
  const ids = useSelector(selectCourseCategoryCodeList)
  return (
    <div>
      <Swiper
        observer
        freeMode
        modules={[FreeMode]}
        spaceBetween={7}
        slidesPerView={"auto"}
      >
        {ids.map((id) => {
          return (
            <SwiperSlide key={id} className="!w-auto">
              <CourseCategoryBoxButton showAll={showAll} categoryCode={id} />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default React.memo(CourseCategoryBoxesSelector)

import { selectNewestCourseIdList } from "@features/courses/state/selectors/lists"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode } from "swiper"
import { useSelector } from "react-redux"
import { Banner } from "./Banner"
import { Box } from "./Box"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { BoxLg } from "./BoxLg"

type Props = {}

const NewestSection = (props: Props) => {
  const { t } = useTranslations()
  const courseIdList = useSelector(selectNewestCourseIdList)
  if (courseIdList == null || courseIdList.length === 0) {
    return <></>
  }

  return (
    <div>
      <div className="font-semibold leading-loose mb-[4px]">
        {t("courses:newest.title")}
      </div>
      <div className="flex flex-col gap-[24px]">
        {/* <Banner courseId={courseIdList[0]} /> */}
        {/* <BoxLg courseId={courseIdList[0]} /> */}
        {courseIdList.length > 0 && (
          <Swiper
            observer
            freeMode
            modules={[FreeMode]}
            spaceBetween={16}
            slidesPerView={"auto"}
          >
            {courseIdList.slice(0).map((courseId, i) => (
              <SwiperSlide key={i} className="!w-auto mt-6">
                <Box courseId={courseId} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  )
}

export { NewestSection }

import { IonTabBar, IonTabButton, IonTabs } from "@ionic/react"
import { TabIcon } from "@molecules"
import { IconType } from "@ui/atoms/Icon"
import classNames from "classnames"

export interface Tab {
  id: string
  selected: boolean
  icon: IconType
  iconSelected: IconType
  href: string
}

export interface TabsLayoutProps {
  children: any
  setTabsRef: React.Ref<any>
  fullScreen: boolean
  tabs: Tab[]
}

const TabsLayout = ({
  children,
  setTabsRef,
  fullScreen,
  tabs,
}: TabsLayoutProps) => {
  return (
    <IonTabs ref={setTabsRef}>
      {children}
      <IonTabBar
        className={classNames(
          "fixed bottom-0 left-0 w-full footer-toolbar h-[var(--footer-height)] transition-opacity duration-200",
          fullScreen ? "pointer-events-none opacity-0" : "opacity-100"
        )}
        // ref={setTabsBarRef}
        // hidden={fullScreen}
        slot="bottom"
      >
        {tabs.map((tab) => (
          <IonTabButton
            // className={classNames(selectedTab !== route && "before:hidden", "relative before:content-[''] before:w-[41px] before:h-[2px] before:bg-red before:absolute before:left-0 before:right-0 before:mx-auto before:top-0")}
            key={tab.id}
            tab={tab.id}
            href={tab.href}
          >
            <TabIcon
              selected={tab.selected}
              icon={tab.icon}
              iconSelected={tab.iconSelected}
            />
          </IonTabButton>
        ))}
      </IonTabBar>
    </IonTabs>
  )
}

export default TabsLayout

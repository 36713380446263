import { Icon } from "@atoms"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import React from "react"

interface Props {
  text: string
  showAll: () => void
}

const CourseShowAllLabel = ({ text, showAll }: Props) => {
  const { t } = useTranslations()
  return (
    <div className="flex items-center justify-between pb-[25px] ">
      <div className="font-semibold leading-loose text-md ">{text}</div>
      <div
        onClick={showAll}
        className="flex items-center gap-1 cursor-pointer text-red"
      >
        <div className="text-sm leading-normal">
          {t("courses:filters.showAll")}
        </div>
        <Icon iconName="arrowForward" className="w-[18px] h-[18px]" />
      </div>
    </div>
  )
}

export default CourseShowAllLabel

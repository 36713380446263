import classNames from "classnames"
import React from "react"

export type AvatarSize = "small" | "medium" | "xs"
export type AvatarVariant = "filled" | "outlined"

export interface PreviewAvatarProps {
  children?: any
  size: AvatarSize
  variant: AvatarVariant
  backgroundColor?: string
  borderColor?: string
  backgroundImageUrl?: string
}

const PreviewAvatar = ({
  children,
  size,
  variant,
  backgroundColor,
  borderColor,
  backgroundImageUrl,
}: PreviewAvatarProps) => {
  return (
    <div
      className={classNames(
        "rounded-full shrink-0 flex justify-center items-center",
        {
          "w-[32px] h-[32px]": size === "xs",
          "w-[46px] h-[46px]": size === "small",
          "w-[54px] h-[54px]": size === "medium",
          "bg-grey": variant === "filled" && !backgroundColor,
          "border-solid border-[1px]": variant === "outlined",
          "border-red": variant === "outlined" && !borderColor,
          "bg-cover bg-center": !!backgroundImageUrl,
        }
      )}
      style={{
        backgroundColor,
        borderColor,
        backgroundImage: backgroundImageUrl
          ? `url(${backgroundImageUrl})`
          : undefined,
      }}
    >
      {children}
    </div>
  )
}

PreviewAvatar.defaultProps = {
  size: "medium",
  variant: "filled",
}

export default PreviewAvatar

import { put, call, select } from "typed-redux-saga"
import { ChannelController } from "../../../../database/messaging/channel/channel.controller"
import { ChatController } from "../../../../database/messaging/chat/chat.controller"
import { sendChannelMessage } from "../../../../features/messaging/channel"
import { sendChatMessage } from "../../../../features/messaging/chat"
import { Firebase, getSagaContext } from "../../../state/context"
import { CommonState } from "../../../state/types"
import { GlobalOutbox, UserKeys } from "../state/types"

const getKeys = (state: CommonState) => state.messaging.keys

const getOutbox = (state: CommonState) => state.messaging.globalOutbox

const sendMessageToChannel = async (
  controller: ChannelController,
  uid: string,
  channelId: string,
  keys: UserKeys,
  outbox: GlobalOutbox
) => {
  const channelPrivateKey = keys.channels?.find(
    (x) => x.channelId === channelId
  )?.privateKey
  if (!channelPrivateKey) {
    throw new Error(`Cannot find channel key ${channelId}`)
  }

  await sendChannelMessage(controller, {
    channelId,
    message: outbox.draft.content.message,
    subject: outbox.draft.content.subject ?? "",
    messageId: outbox.draft.messageId,
    privateKey: channelPrivateKey,
    senderId: uid,
    attachments: outbox.draft.attachments.items,
  })
}

const sendMessageToChat = async (
  controller: ChatController,
  uid: string,
  receiverId: string,
  privateKey: string,
  outbox: GlobalOutbox
) => {
  const chat = await controller.getOrCreateChat(uid, receiverId)
  await sendChatMessage(controller, {
    senderId: uid,
    chatId: chat.id,
    message: outbox.draft.content.message,
    subject: outbox.draft.content.subject ?? "",
    messageId: outbox.draft.messageId,
    privateKey,
    attachments: outbox.draft.attachments.items,
  })
}

const sendMessages = async (
  outbox: GlobalOutbox,
  keys: UserKeys,
  firebase: Firebase
) => {
  const uid = firebase.getUid()
  if (!uid) {
    throw new Error("Uid is missing")
  }

  if (!keys.channels) {
    throw new Error("Missing channel keys")
  }

  if (!keys.userKey) {
    throw new Error("Missing user keys")
  }

  for (const to of outbox.recipients) {
    console.log("send to", to)
    firebase.collections.chat
    if (to.type === "chat") {
      await sendMessageToChat(
        firebase.collections.chat,
        uid,
        to.id,
        keys.userKey.personalPrivateKey,
        outbox
      )
    }
    if (to.type === "channel") {
      await sendMessageToChannel(
        firebase.collections.channels,
        uid,
        to.id,
        keys,
        outbox
      )
    }
  }
}

export function* sendBroadcastMessage() {
  const state = yield* getSagaContext("state")
  try {
    const keys = yield* select(getKeys)
    if (!keys || !keys.channels || !keys.userKey) {
      throw new Error(`Keys are missing`)
    }

    const firebase = yield* getSagaContext("firebase")
    const outbox = yield* select(getOutbox)

    yield* call(
      (outbox: GlobalOutbox, keys: UserKeys, firebase: Firebase) => {
        sendMessages(outbox, keys, firebase)
      },
      outbox,
      keys,
      firebase
    )

    yield* put(state.messaging.actions.outboxDraftSendCompleted())
  } catch (e) {
    yield* put(
      state.messaging.actions.outboxDraftSendError((e as Error).message)
    )
  }
}

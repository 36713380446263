import { call, fork, take, delay, race } from "typed-redux-saga"
import { performanceActions } from "."
import { performancesRootSaga as basePerformancesRootSaga } from "@common/common/frontend/features/performance/sagas"
import { getSagaContext } from "@modules/store/redux/sagaContext"
import { getDeviceId } from "@modules/device/queries"
import { AppSessionTrackingPayload } from "@common/common/api/functions"
import {
  buildAppVersionPayload,
  buildDeviceInfoPayload,
} from "@modules/device/converters"

const buildKeepalivePayload = async (
  fcmToken?: string
): Promise<AppSessionTrackingPayload> => {
  const deviceId = await getDeviceId()

  return {
    deviceId,
    deviceData: {
      fcmToken: fcmToken ?? null,
      info: await buildDeviceInfoPayload(),
      version: await buildAppVersionPayload(),
    },
  }
}

function* trackSessionSaga() {
  console.log("START SESSION TRACKING SAGA")
  const api = yield* getSagaContext("api")
  const payload = yield* call(buildKeepalivePayload)

  try {
    while (true) {
      console.log("tracking...")
      yield* call(api.appSessionKeepalive, payload)
      yield* delay(60000) // every minute
    }
  } catch (error) {
    console.error("ERROR SESSION TRACKING SAGA", error)
  } finally {
    console.log("STOP SESSION TRACKING SAGA")
  }
}
function* watchPerformanceSaga() {
  while (true) {
    yield* take(performanceActions.trackSession.TRIGGER)
    yield* race([
      call(trackSessionSaga),
      take(performanceActions.trackSession.FULFILL),
    ])
  }
}

export function* performanceRootSaga() {
  yield* fork(watchPerformanceSaga)
  yield* fork(basePerformancesRootSaga)
}

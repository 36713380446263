import { useRootSelector } from "@modules/store/redux"
import { AppUserEntity } from "@common/common/database/company/user/user.entity"
import { firebase } from "@services/index"

export const useChatCreate = () => {
  const { user } = useRootSelector((state) => state.auth)
  const createChat = async (targetUser: AppUserEntity) => {
    if (!user) {
      throw new Error("User is not authenticated")
    }
    const senderChat =
      await firebase.collections.chatPreview.getOrCreateChatPreview({
        createdBy: user.uid,
        receiverId: targetUser.id,
        receiverInfo: {
          profile: {
            firstName: targetUser.data.profile.firstName,
            lastName: targetUser.data.profile.lastName,
            ...(targetUser.data.profile.avatarUrl
              ? {
                  avatarUrl: targetUser.data.profile.avatarUrl,
                }
              : {}),
          },
          userType: targetUser.data.userType,
        },
        senderId: user.uid,
      })

    return {
      chatId: senderChat.id,
    }
  }

  return {
    createChat,
  }
}

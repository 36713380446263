import { useCallback, useEffect, useState } from "react"
import { Keyboard, KeyboardInfo } from "@capacitor/keyboard"
import { isWebPlatform } from "../utils/device"

export const useKeyboardSize = () => {
  const [keyboardSize, setKeyboardSize] = useState(0)
  const [keyboardDispose, setKeyboardDispose] = useState<() => void>()

  const onKeyboardDidShow = useCallback((event: KeyboardInfo) => {
    setKeyboardSize(event.keyboardHeight)
  }, [])

  const onKeyboardDidHide = useCallback(() => {
    setKeyboardSize(0)
  }, [])

  const registerKeyboardListeners = useCallback(async () => {
    console.log("registerKeyboardListeners -> called")
    const showHandler = await Keyboard.addListener(
      "keyboardDidShow",
      onKeyboardDidShow
    )
    const hideHandler = await Keyboard.addListener(
      "keyboardDidHide",
      onKeyboardDidHide
    )

    setKeyboardDispose(async () => {
      await showHandler.remove()
      await hideHandler.remove()
    })
    console.log("registerKeyboardListeners -> completed")
  }, [])

  const removeKeyboardListeners = useCallback(async () => {
    console.log("removeKeyboardListeners -> called")
    keyboardDispose?.()
    setKeyboardDispose(undefined)
    console.log("removeKeyboardListeners -> completed")
  }, [])

  useEffect(() => {
    console.log("useKeyboardSize -> called", isWebPlatform())
    if (isWebPlatform()) {
      return
    }

    registerKeyboardListeners()

    return () => {
      removeKeyboardListeners()
    }
  }, [])

  return {
    height: keyboardSize,
    opened: keyboardSize > 0,
  }
}

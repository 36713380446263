import { selectCourseDescription } from "@features/courses/state/selectors"
import classNames from "classnames"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import CourseChip from "./CourseChip"
import CourseTime from "./CourseTime"

interface Props {
  isVisible: boolean
}

const CourseDetailDescription = ({ isVisible }: Props) => {
  const info = useSelector(selectCourseDescription)
  useEffect(() => {
    console.log(info)
  }, [info])
  return (
    <div
      className={classNames(!isVisible && "hidden", "mb-[22px] p-container")}
    >
      <div className="mb-[12px] flex items-center gap-[12px]">
        <CourseChip info={info} />
        <CourseTime info={info} />
      </div>
      <div className="text-xl leading-[1.4] font-bold text-dark">
        {info.title}
      </div>
      <div className="text-light">di {info.brandName}</div>
      <div className="mt-[18px] text-base leading-[1.29] text-light">
        {info.description}
      </div>
    </div>
  )
}

export default React.memo(CourseDetailDescription)

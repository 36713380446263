import { IonIcon } from "@ionic/react"
import { ellipsisHorizontal, ellipsisVertical } from "ionicons/icons"

export interface MenuButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {}

const MenuButton = ({ ...props }: MenuButtonProps) => {
  return (
    <button {...props}>
      <IonIcon
        slot="icon-only"
        ios={ellipsisHorizontal}
        md={ellipsisVertical}
      ></IonIcon>
    </button>
  )
}

export default MenuButton

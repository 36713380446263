import { createSelector } from "@reduxjs/toolkit"
import { selectChannelMessages } from "@common/common/frontend/features/messaging/selectors/selectChannelMessages"
import { selectChannelPreviewById } from "../preview"
import { RootState } from "@modules/store/redux/rootReducer"
import { MessageItem } from "@features/messaging/types"
import { toChatAttachment } from "../common"

export const selectChannelMessagesById = (channelId: string) =>
  createSelector(
    (state: RootState) => state.auth,
    selectChannelPreviewById(channelId),
    (state: any) => selectChannelMessages(state, { channelId }),
    (auth, preview, messages) => {
      return {
        hasMoreMessages: messages?.hasMoreMessages ?? false,
        isLoading: messages?.loading ?? false,
        messageList: messages?.messages
          .filter((x) => !x.del)
          .map<MessageItem>((m) => {
            return {
              direction: m.si === auth.user?.uid ? "out" : "in",
              id: m.id,
              senderUid: m.si,
              message: m.message,
              attachments: m.a?.map(toChatAttachment),
              timestamp: m.t ?? new Date(),
              isLastReadMessage:
                m.id === preview?.entity.data.lastReadMessageId,
            }
          }),
      }
    }
  )

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AndroidPlayStoreVersionEntity } from "../models/AndroidPlayStoreVersionEntity"
import type { ApiKeyGenerateInput } from "../models/ApiKeyGenerateInput"
import type { ApiKeyUpdateInput } from "../models/ApiKeyUpdateInput"
import type { AppDeploymentBuildEntity } from "../models/AppDeploymentBuildEntity"
import type { AppDeploymentChannelEntity } from "../models/AppDeploymentChannelEntity"
import type { AppleStoreAppVersionEntity } from "../models/AppleStoreAppVersionEntity"
import type { AppointmentEvaluationData } from "../models/AppointmentEvaluationData"
import type { AppRootData } from "../models/AppRootData"
import type { AppSessionTrackingPayload } from "../models/AppSessionTrackingPayload"
import type { AppSettingsEntity } from "../models/AppSettingsEntity"
import type { Area } from "../models/Area"
import type { AreaEntity } from "../models/AreaEntity"
import type { Brand } from "../models/Brand"
import type { BrandEntity } from "../models/BrandEntity"
import type { BrandGroup } from "../models/BrandGroup"
import type { BrandGroupEntity } from "../models/BrandGroupEntity"
import type { CalendarAppointment } from "../models/CalendarAppointment"
import type { CalendarAppointmentCancellation } from "../models/CalendarAppointmentCancellation"
import type { CalendarDefinition } from "../models/CalendarDefinition"
import type { CatalogCategoryData } from "../models/CatalogCategoryData"
import type { CatalogCategoryEntity } from "../models/CatalogCategoryEntity"
import type { CategoryToggleInput } from "../models/CategoryToggleInput"
import type { ChannelMessageDeleteInput } from "../models/ChannelMessageDeleteInput"
import type { ChannelMessageMarkDeletedInput } from "../models/ChannelMessageMarkDeletedInput"
import type { ChannelMessageReceiptInput } from "../models/ChannelMessageReceiptInput"
import type { ChatMessageDeleteInput } from "../models/ChatMessageDeleteInput"
import type { ChatMessageMarkDeletedInput } from "../models/ChatMessageMarkDeletedInput"
import type { ChatMessageReceiptInput } from "../models/ChatMessageReceiptInput"
import type { CourseData } from "../models/CourseData"
import type { CourseEntity } from "../models/CourseEntity"
import type { CourseExportResponse } from "../models/CourseExportResponse"
import type { CourseItemReference } from "../models/CourseItemReference"
import type { CourseMediaUploadResult } from "../models/CourseMediaUploadResult"
import type { CourseRejectData } from "../models/CourseRejectData"
import type { CreateUserInput } from "../models/CreateUserInput"
import type { DashboardRootData } from "../models/DashboardRootData"
import type { DeleteCalendarAppointmentInput } from "../models/DeleteCalendarAppointmentInput"
import type { EntitiesDownloadResult } from "../models/EntitiesDownloadResult"
import type { GenerateFirebasePasswordResetLinkResult } from "../models/GenerateFirebasePasswordResetLinkResult"
import type { GetCoursePdfMetadataRequest } from "../models/GetCoursePdfMetadataRequest"
import type { GetCoursePdfMetadataResponse } from "../models/GetCoursePdfMetadataResponse"
import type { GetCoursePdfPageRequest } from "../models/GetCoursePdfPageRequest"
import type { GetCoursePdfPageResponse } from "../models/GetCoursePdfPageResponse"
import type { GetPdfMetadataRequest } from "../models/GetPdfMetadataRequest"
import type { GetPdfMetadataResponse } from "../models/GetPdfMetadataResponse"
import type { GetPdfPageUrlRequest } from "../models/GetPdfPageUrlRequest"
import type { GetPdfPageUrlResponse } from "../models/GetPdfPageUrlResponse"
import type { ManualNotificationToDeviceInput } from "../models/ManualNotificationToDeviceInput"
import type { ManualNotificationToTopicsInput } from "../models/ManualNotificationToTopicsInput"
import type { ManualNotificationToUsersInput } from "../models/ManualNotificationToUsersInput"
import type { NotificationsMarkReadInput } from "../models/NotificationsMarkReadInput"
import type { NotificationTopic } from "../models/NotificationTopic"
import type { NotificationUser } from "../models/NotificationUser"
import type { PerformanceItems } from "../models/PerformanceItems"
import type { ReportDownloadResult } from "../models/ReportDownloadResult"
import type { SearchUsersInput } from "../models/SearchUsersInput"
import type { SelfPasswordResetInput } from "../models/SelfPasswordResetInput"
import type { SendPasswordResetLinkInput } from "../models/SendPasswordResetLinkInput"
import type { SendUserActivationEmailInput } from "../models/SendUserActivationEmailInput"
import type { SetChannelAndroidAppVersionInput } from "../models/SetChannelAndroidAppVersionInput"
import type { SetChannelDeploymentBuildInput } from "../models/SetChannelDeploymentBuildInput"
import type { SetChannelIosAppVersionInput } from "../models/SetChannelIosAppVersionInput"
import type { Store } from "../models/Store"
import type { StoreEntity } from "../models/StoreEntity"
import type { StorePerformanceItems } from "../models/StorePerformanceItems"
import type { SubscribeFcmTopics } from "../models/SubscribeFcmTopics"
import type { ToggleAutomaticUpdatesInput } from "../models/ToggleAutomaticUpdatesInput"
import type { UpdateUserInput } from "../models/UpdateUserInput"
import type { User } from "../models/User"
import type { UserAvatarResult } from "../models/UserAvatarResult"
import type { UserDeviceEntry } from "../models/UserDeviceEntry"
import type { UserDeviceRegistrationData } from "../models/UserDeviceRegistrationData"
import type { UserDisableInput } from "../models/UserDisableInput"
import type { UserEnableInput } from "../models/UserEnableInput"
import type { UserProfileKeys } from "../models/UserProfileKeys"
import type { UserPwdResetInput } from "../models/UserPwdResetInput"
import type { UserPwdSetInput } from "../models/UserPwdSetInput"
import type { UserPwsSetTemporary } from "../models/UserPwsSetTemporary"
import type { UserReportItem } from "../models/UserReportItem"
import type { UserSheetInputData } from "../models/UserSheetInputData"
import type { UsersSheetDownloadResult } from "../models/UsersSheetDownloadResult"
import type { VideoMetadata } from "../models/VideoMetadata"

import type { CancelablePromise } from "../core/CancelablePromise"
import { OpenAPI } from "../core/OpenAPI"
import { request as __request } from "../core/request"

export class DefaultClient {
  /**
   * @returns any
   * @throws ApiError
   */
  public static appControllerIndex(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/",
    })
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static healthCheck(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/health",
    })
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static error(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/error",
    })
  }

  /**
   * @param language
   * @returns AppRootData
   * @throws ApiError
   */
  public static appRootContents(
    language: string
  ): CancelablePromise<AppRootData> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/appContents/appRootContents",
      query: {
        language: language,
      },
    })
  }

  /**
   * @returns DashboardRootData
   * @throws ApiError
   */
  public static dashboardRootContents(): CancelablePromise<DashboardRootData> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/appContents/dashboardRootContents",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static selfPasswordReset(
    requestBody: SelfPasswordResetInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/auth/selfPasswordReset",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @returns BrandEntity
   * @throws ApiError
   */
  public static brands(): CancelablePromise<Array<BrandEntity>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/brand",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static brandCreate(requestBody: Brand): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/brand/brandCreate",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static brandUpdate(requestBody: BrandEntity): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/brand/brandUpdate",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param formData
   * @returns any
   * @throws ApiError
   */
  public static brandsSheetUpload(formData: {
    file?: Blob
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/brand/brandsSheetUpload",
      formData: formData,
      mediaType: "multipart/form-data",
    })
  }

  /**
   * @returns EntitiesDownloadResult
   * @throws ApiError
   */
  public static brandsSheetDownload(): CancelablePromise<EntitiesDownloadResult> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/brand/brandsSheetDownload",
    })
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static brandDelete(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/brand/{id}",
      path: {
        id: id,
      },
    })
  }

  /**
   * @returns BrandGroupEntity
   * @throws ApiError
   */
  public static brandGroups(): CancelablePromise<Array<BrandGroupEntity>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/brand/brandGroups",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static brandGroupCreate(
    requestBody: BrandGroup
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/brand/brandGroupCreate",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static brandGroupUpdate(
    requestBody: BrandGroupEntity
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/brand/brandGroupUpdate",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static brandGroupDelete(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/brand/brandGroupDelete/{id}",
      path: {
        id: id,
      },
    })
  }

  /**
   * @param formData
   * @returns any
   * @throws ApiError
   */
  public static brandGroupsSheetUpload(formData: {
    file?: Blob
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/brand/brandGroupsSheetUpload",
      formData: formData,
      mediaType: "multipart/form-data",
    })
  }

  /**
   * @returns EntitiesDownloadResult
   * @throws ApiError
   */
  public static brandGroupsDownload(): CancelablePromise<EntitiesDownloadResult> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/brand/brandGroupsDownload",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static upsertCalendar(
    requestBody: CalendarDefinition
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/calendars/calendarDefinition",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param id
   * @param enabled
   * @returns any
   * @throws ApiError
   */
  public static toggleCalendar(
    id: string,
    enabled: boolean
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/calendars/toggleCalendar",
      path: {
        id: id,
        enabled: enabled,
      },
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static pushReservation(
    requestBody: CalendarAppointment
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/calendars/pushReservation",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static deleteReservation(
    requestBody: DeleteCalendarAppointmentInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/calendars/deleteReservation",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static cancelReservation(
    requestBody: CalendarAppointmentCancellation
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/calendars/cancelReservation",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @returns AreaEntity
   * @throws ApiError
   */
  public static areas(): CancelablePromise<Array<AreaEntity>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/areas/areas",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static areaCreate(requestBody: Area): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/areas/areaCreate",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static areaUpdate(requestBody: AreaEntity): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/areas/areaUpdate",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static areaDelete(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/areas/area/{id}",
      path: {
        id: id,
      },
    })
  }

  /**
   * @param formData
   * @returns any
   * @throws ApiError
   */
  public static areasSheetUpload(formData: {
    file?: Blob
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/areas/areasSheetUpload",
      formData: formData,
      mediaType: "multipart/form-data",
    })
  }

  /**
   * @returns EntitiesDownloadResult
   * @throws ApiError
   */
  public static areasSheetDownload(): CancelablePromise<EntitiesDownloadResult> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/areas/areasSheetDownload",
    })
  }

  /**
   * @returns StoreEntity
   * @throws ApiError
   */
  public static stores(): CancelablePromise<Array<StoreEntity>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/stores",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static storeCreate(requestBody: Store): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/stores/storeCreate",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static storeUpdate(requestBody: StoreEntity): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/stores/storeUpdate",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static storeDelete(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/stores/{id}",
      path: {
        id: id,
      },
    })
  }

  /**
   * @param formData
   * @returns any
   * @throws ApiError
   */
  public static storesSheetUpload(formData: {
    file?: Blob
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/stores/storesSheetUpload",
      formData: formData,
      mediaType: "multipart/form-data",
    })
  }

  /**
   * @returns EntitiesDownloadResult
   * @throws ApiError
   */
  public static storesSheetDownload(): CancelablePromise<EntitiesDownloadResult> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/stores/storesSheetDownload",
    })
  }

  /**
   * @returns CourseItemReference
   * @throws ApiError
   */
  public static coursesForEdit(): CancelablePromise<
    Array<CourseItemReference>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/courses/coursesForEdit",
    })
  }

  /**
   * @param id
   * @returns CourseEntity
   * @throws ApiError
   */
  public static courseForEdit(id: string): CancelablePromise<CourseEntity> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/courses/courseForEdit/{id}",
      path: {
        id: id,
      },
    })
  }

  /**
   * @param tot
   * @returns CourseItemReference
   * @throws ApiError
   */
  public static latestCoursesForEdit(
    tot: number
  ): CancelablePromise<Array<CourseItemReference>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/courses/latestCoursesForEdit",
      query: {
        tot: tot,
      },
    })
  }

  /**
   * @param requestBody
   * @returns CourseEntity
   * @throws ApiError
   */
  public static courseCreate(
    requestBody: CourseData
  ): CancelablePromise<CourseEntity> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/courses/courseCreate",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @returns string
   * @throws ApiError
   */
  public static courseCreateDraft(): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/courses/courseCreateDraft",
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns CourseEntity
   * @throws ApiError
   */
  public static courseUpdate(
    id: string,
    requestBody: CourseData
  ): CancelablePromise<CourseEntity> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/courses/courseUpdate/{id}",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param courseId
   * @param formData
   * @returns CourseMediaUploadResult
   * @throws ApiError
   */
  public static courseMediaUpload(
    courseId: string,
    formData: {
      file?: Blob
    }
  ): CancelablePromise<CourseMediaUploadResult> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/courses/courseMediaUpload",
      query: {
        courseId: courseId,
      },
      formData: formData,
      mediaType: "multipart/form-data",
    })
  }

  /**
   * @param id
   * @returns CourseEntity
   * @throws ApiError
   */
  public static coursePublish(id: string): CancelablePromise<CourseEntity> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/courses/coursePublish/{id}",
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns CourseEntity
   * @throws ApiError
   */
  public static courseReject(
    id: string,
    requestBody: CourseRejectData
  ): CancelablePromise<CourseEntity> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/courses/courseReject/{id}",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param id
   * @returns CourseEntity
   * @throws ApiError
   */
  public static courseSubmit(id: string): CancelablePromise<CourseEntity> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/courses/courseSubmit/{id}",
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @returns CourseEntity
   * @throws ApiError
   */
  public static courseRetire(id: string): CancelablePromise<CourseEntity> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/courses/courseRetire/{id}",
      path: {
        id: id,
      },
    })
  }

  /**
   * @param url
   * @returns VideoMetadata
   * @throws ApiError
   */
  public static generateVideoMetadata(
    url: string
  ): CancelablePromise<VideoMetadata> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/courses/generateVideoMetadata",
      query: {
        url: url,
      },
    })
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static courseArchive(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/courses/courseArchive/{id}",
      path: {
        id: id,
      },
    })
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static coursesBackup(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/courses/coursesBackup",
    })
  }

  /**
   * @param id
   * @returns CourseExportResponse
   * @throws ApiError
   */
  public static courseExport(
    id: string
  ): CancelablePromise<CourseExportResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/courses/courseExport/{id}",
      path: {
        id: id,
      },
    })
  }

  /**
   * @param formData
   * @returns any
   * @throws ApiError
   */
  public static courseImport(formData: {
    file?: Blob
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/courses/courseImport",
      formData: formData,
      mediaType: "multipart/form-data",
    })
  }

  /**
   * @param url
   * @param page
   * @returns any
   * @throws ApiError
   */
  public static getCoursePdfPage(
    url: string,
    page: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/courses/getCoursePdfPage",
      query: {
        url: url,
        page: page,
      },
    })
  }

  /**
   * @param requestBody
   * @returns GetPdfPageUrlResponse
   * @throws ApiError
   */
  public static getCoursePdfPageUrl(
    requestBody: GetPdfPageUrlRequest
  ): CancelablePromise<GetPdfPageUrlResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/courses/getCoursePdfPageUrl",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns GetPdfMetadataResponse
   * @throws ApiError
   */
  public static getPdfMetadata(
    requestBody: GetPdfMetadataRequest
  ): CancelablePromise<GetPdfMetadataResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/courses/getPdfMetadata",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns GetCoursePdfPageResponse
   * @throws ApiError
   */
  public static getCmsCoursePdfPageUrl(
    requestBody: GetCoursePdfPageRequest
  ): CancelablePromise<GetCoursePdfPageResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/courses/getCmsCoursePdfPageUrl",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns GetCoursePdfMetadataResponse
   * @throws ApiError
   */
  public static getCmsPdfMetadata(
    requestBody: GetCoursePdfMetadataRequest
  ): CancelablePromise<GetCoursePdfMetadataResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/courses/getCmsPdfMetadata",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @returns CatalogCategoryEntity
   * @throws ApiError
   */
  public static categories(): CancelablePromise<Array<CatalogCategoryEntity>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/categories",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static categoryCreate(
    requestBody: CatalogCategoryData
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/categories/categoryCreate",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static categoryToggle(
    requestBody: CategoryToggleInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/categories/categoryToggle",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static categoryUpdate(
    requestBody: CatalogCategoryEntity
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/categories/categoryUpdate",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static categoryDelete(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/categories/{id}",
      path: {
        id: id,
      },
    })
  }

  /**
   * @param formData
   * @returns any
   * @throws ApiError
   */
  public static categoriesSheetUpload(formData: {
    file?: Blob
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/categories/categoriesSheetUpload",
      formData: formData,
      mediaType: "multipart/form-data",
    })
  }

  /**
   * @returns EntitiesDownloadResult
   * @throws ApiError
   */
  public static categoriesSheetDownload(): CancelablePromise<EntitiesDownloadResult> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/categories/categoriesSheetDownload",
    })
  }

  /**
   * @returns AppDeploymentChannelEntity
   * @throws ApiError
   */
  public static appDeploymentChannels(): CancelablePromise<
    Array<AppDeploymentChannelEntity>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/appDeployments/channels",
    })
  }

  /**
   * @param appId
   * @param channelId
   * @param pageSize
   * @param cursor
   * @returns AndroidPlayStoreVersionEntity
   * @throws ApiError
   */
  public static androidAppVersions(
    appId: string,
    channelId: string,
    pageSize: number,
    cursor?: string
  ): CancelablePromise<Array<AndroidPlayStoreVersionEntity>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/appDeployments/androidVersions",
      query: {
        appId: appId,
        channelId: channelId,
        pageSize: pageSize,
        cursor: cursor,
      },
    })
  }

  /**
   * @param appId
   * @param channelId
   * @param pageSize
   * @param cursor
   * @returns AppleStoreAppVersionEntity
   * @throws ApiError
   */
  public static iosAppVersions(
    appId: string,
    channelId: string,
    pageSize: number,
    cursor?: string
  ): CancelablePromise<Array<AppleStoreAppVersionEntity>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/appDeployments/iosVersions",
      query: {
        appId: appId,
        channelId: channelId,
        pageSize: pageSize,
        cursor: cursor,
      },
    })
  }

  /**
   * @param id
   * @returns AppDeploymentChannelEntity
   * @throws ApiError
   */
  public static appDeploymentChannel(
    id: string
  ): CancelablePromise<AppDeploymentChannelEntity> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/appDeployments/channel",
      query: {
        id: id,
      },
    })
  }

  /**
   * @param appId
   * @param channelId
   * @param pageSize
   * @param cursor
   * @returns AppDeploymentBuildEntity
   * @throws ApiError
   */
  public static appDeploymentChannelBuilds(
    appId: string,
    channelId: string,
    pageSize: number,
    cursor?: string
  ): CancelablePromise<Array<AppDeploymentBuildEntity>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/appDeployments/builds",
      query: {
        appId: appId,
        channelId: channelId,
        pageSize: pageSize,
        cursor: cursor,
      },
    })
  }

  /**
   * @param appId
   * @param channelId
   * @param commitHash
   * @param previewChannelId
   * @param formData
   * @returns any
   * @throws ApiError
   */
  public static appChannelBuildPush(
    appId: string,
    channelId: string,
    commitHash: string,
    previewChannelId: string,
    formData: {
      file?: Blob
    }
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/appDeployments/build",
      query: {
        appId: appId,
        channelId: channelId,
        commitHash: commitHash,
        previewChannelId: previewChannelId,
      },
      formData: formData,
      mediaType: "multipart/form-data",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static setChannelDeploymentBuild(
    requestBody: SetChannelDeploymentBuildInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/appDeployments/setChannelBuild",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static setChannelIosAppVersion(
    requestBody: SetChannelIosAppVersionInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/appDeployments/setChannelIosAppVersion",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static createChannelIosAppVersion(
    requestBody: SetChannelIosAppVersionInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/appDeployments/createChannelIosAppVersion",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static createChannelAndroidAppVersion(
    requestBody: SetChannelAndroidAppVersionInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/appDeployments/createChannelAndroidAppVersion",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static setChannelAndroidAppVersion(
    requestBody: SetChannelAndroidAppVersionInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/appDeployments/setChannelAndroidAppVersion",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static toggleAutomaticUpdates(
    requestBody: ToggleAutomaticUpdatesInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/appDeployments/toggleAutomaticUpdates",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static toggleIosUpdates(
    requestBody: ToggleAutomaticUpdatesInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/appDeployments/toggleIosUpdates",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static toggleAndroidUpdates(
    requestBody: ToggleAutomaticUpdatesInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/appDeployments/toggleAndroidUpdates",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static deviceRegister(
    requestBody: UserDeviceRegistrationData
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/devices/register",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @returns UserDeviceEntry
   * @throws ApiError
   */
  public static devicesGet(): CancelablePromise<Array<UserDeviceEntry>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/devices",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static pushAppointmentEvaluation(
    requestBody: AppointmentEvaluationData
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/evaluations/pushAppointmentEvaluation",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiKeys(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/apiKeys",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static apiKeyGenerate(
    requestBody: ApiKeyGenerateInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/apiKeys",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiKeyGenerate1(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/apiKeys",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static apiKeyUpdate(
    requestBody: ApiKeyUpdateInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/apiKeys/apiKeyUpdate",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiKeyEnable(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/apiKeys/apiKeyEnable",
    })
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static apiKeyDisable(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/apiKeys/apiKeyDisable",
    })
  }

  /**
   * @param requestBody
   * @returns boolean
   * @throws ApiError
   */
  public static sendChannelReadNotification(
    requestBody: ChannelMessageReceiptInput
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/messaging/sendChannelReadNotification",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns boolean
   * @throws ApiError
   */
  public static sendChatReadNotification(
    requestBody: ChatMessageReceiptInput
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/messaging/sendChatReadNotification",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static deleteChatMessage(
    requestBody: ChatMessageDeleteInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/messaging/deleteChatMessage",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static markDeletedChatMessage(
    requestBody: ChatMessageMarkDeletedInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/messaging/markDeletedChatMessage",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static deleteChannelMessage(
    requestBody: ChannelMessageDeleteInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/messaging/deleteChannelMessage",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static markDeletedChannelMessage(
    requestBody: ChannelMessageMarkDeletedInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/messaging/markDeletedChannelMessage",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static sendManualNotificationToTopics(
    requestBody: ManualNotificationToTopicsInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/notifications/sendManualNotificationToTopics",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static sendManualNotificationToUsers(
    requestBody: ManualNotificationToUsersInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/notifications/sendManualNotificationToUsers",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static sendManualNotificationToDevice(
    requestBody: ManualNotificationToDeviceInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/notifications/sendManualNotificationToDevice",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @returns NotificationTopic
   * @throws ApiError
   */
  public static getNotificationTopics(): CancelablePromise<
    Array<NotificationTopic>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/notifications/getNotificationTopics",
    })
  }

  /**
   * @returns NotificationUser
   * @throws ApiError
   */
  public static getNotificationUsers(): CancelablePromise<
    Array<NotificationUser>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/notifications/getNotificationUsers",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static notificationsMarkRead(
    requestBody: NotificationsMarkReadInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/notifications/markRead",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns boolean
   * @throws ApiError
   */
  public static subscribeFcmTopics(
    requestBody: SubscribeFcmTopics
  ): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/notifications/subscribeFCMTopics",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static objectiveItemsPush(
    requestBody: PerformanceItems
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/performancesSync/objectiveItemsPush",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static objectiveStoreItemPush(
    requestBody: StorePerformanceItems
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/performancesSync/objectiveStoreItemPush",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static objectivesAggregationRefresh(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/performancesSync/objectivesAggregationRefresh",
    })
  }

  /**
   * @returns UserProfileKeys
   * @throws ApiError
   */
  public static personalKeysGet(): CancelablePromise<UserProfileKeys> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/profile/keys",
    })
  }

  /**
   * @param formData
   * @returns any
   * @throws ApiError
   */
  public static profileImageUpdate(formData: {
    file?: Blob
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/profile/profileImageUpdate",
      formData: formData,
      mediaType: "multipart/form-data",
    })
  }

  /**
   * @returns UserAvatarResult
   * @throws ApiError
   */
  public static getUserAvatarUrl(): CancelablePromise<UserAvatarResult> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/profile/getUserAvatarUrl",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static resetSelfPassword(
    requestBody: SelfPasswordResetInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/profile/resetSelfPassword",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static usersReportUpdate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/usersReportUpdate",
    })
  }

  /**
   * @returns UserReportItem
   * @throws ApiError
   */
  public static usersReportFetch(): CancelablePromise<Array<UserReportItem>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/reports/usersReport",
    })
  }

  /**
   * @returns ReportDownloadResult
   * @throws ApiError
   */
  public static reportSheetDownload(): CancelablePromise<ReportDownloadResult> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/reports/reportSheetDownload",
    })
  }

  /**
   * @returns AppSettingsEntity
   * @throws ApiError
   */
  public static getAppSettings(): CancelablePromise<Array<AppSettingsEntity>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/appDeployments/appSettings",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static appSettingsUpdate(
    requestBody: AppSettingsEntity
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/appDeployments/appSettingsUpdate",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static ensureAdmin(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/users/ensureAdmin",
    })
  }

  /**
   * @returns User
   * @throws ApiError
   */
  public static usersGet(): CancelablePromise<Array<User>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/users",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static userCreate(
    requestBody: CreateUserInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/users",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static userUpdate(
    requestBody: UpdateUserInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/users",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param userId
   * @returns User
   * @throws ApiError
   */
  public static userGet(userId: string): CancelablePromise<User> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/users/user",
      query: {
        userId: userId,
      },
    })
  }

  /**
   * @param requestBody
   * @returns User
   * @throws ApiError
   */
  public static usersSearch(
    requestBody: SearchUsersInput
  ): CancelablePromise<Array<User>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/users/usersSearch",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param tot
   * @returns User
   * @throws ApiError
   */
  public static usersGetLatest(tot: number): CancelablePromise<Array<User>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/users/usersGetLatest",
      path: {
        tot: tot,
      },
    })
  }

  /**
   * @param uid
   * @returns any
   * @throws ApiError
   */
  public static userDelete(uid: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/users/{uid}",
      path: {
        uid: uid,
      },
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static sendBackofficeAccountActivationMail(
    requestBody: SendUserActivationEmailInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/users/sendBackofficeAccountActivationMail",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static userUpload(
    requestBody: UserSheetInputData
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/users/userUpload",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static userEnable(
    requestBody: UserEnableInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/users/enable",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static userDisable(
    requestBody: UserDisableInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/users/disable",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static passwordReset(
    requestBody: UserPwdResetInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/users/passwordReset",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static passwordSetTemporary(
    requestBody: UserPwsSetTemporary
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/users/passwordSetTemporary",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static passwordSet(
    requestBody: UserPwdSetInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/users/passwordSet",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static sendBackofficePasswordResetEmail(
    requestBody: SendPasswordResetLinkInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/users/sendBackofficePasswordResetEmail",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static sendFirebasePasswordResetEmail(
    requestBody: SendPasswordResetLinkInput
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/users/sendFirebasePasswordResetEmail",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param requestBody
   * @returns GenerateFirebasePasswordResetLinkResult
   * @throws ApiError
   */
  public static generateFirebasePasswordResetLink(
    requestBody: SendPasswordResetLinkInput
  ): CancelablePromise<GenerateFirebasePasswordResetLinkResult> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/users/generateFirebasePasswordResetLink",
      body: requestBody,
      mediaType: "application/json",
    })
  }

  /**
   * @param formData
   * @returns any
   * @throws ApiError
   */
  public static usersSheetUpload(formData: {
    file?: Blob
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/users/usersSheetUpload",
      formData: formData,
      mediaType: "multipart/form-data",
    })
  }

  /**
   * @param formData
   * @returns UserSheetInputData
   * @throws ApiError
   */
  public static usersSheetParse(formData: {
    file?: Blob
  }): CancelablePromise<Array<UserSheetInputData>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/users/usersSheetParse",
      formData: formData,
      mediaType: "multipart/form-data",
    })
  }

  /**
   * @returns UsersSheetDownloadResult
   * @throws ApiError
   */
  public static usersSheetDownload(): CancelablePromise<UsersSheetDownloadResult> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/users/usersSheetDownload",
    })
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static usersSanify(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/users/usersSanify",
    })
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static runAggregateAppSessions(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/tasksAdmin/runAggregateAppSessions",
    })
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static runObjectivesAggregationRefresh(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/tasksAdmin/runObjectivesAggregationRefresh",
    })
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static runAppContentsUpdate(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/tasksAdmin/runAppContentsUpdate",
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static appSessionKeepalive(
    requestBody: AppSessionTrackingPayload
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/appTracking/appSessionKeepalive",
      body: requestBody,
      mediaType: "application/json",
    })
  }
}

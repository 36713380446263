import { useRootSelector } from "@modules/store/redux"
import { useCallback, useState } from "react"

const ADMIN_CLICKS_TO_ENABLE = 10

export const useAppAdminEnabler = () => {
  const [clicks, setClicks] = useState(0)
  const userData = useRootSelector((state) => state.userProfile?.user?.data)

  const click = useCallback(() => {
    if (clicks === ADMIN_CLICKS_TO_ENABLE) {
      console.log("Admin mode enabled")
    } else {
      setClicks(clicks + 1)
      console.log(
        `Admin mode enabled in ${ADMIN_CLICKS_TO_ENABLE - clicks} clicks`
      )
    }
  }, [clicks])

  return {
    isAdmin: clicks >= ADMIN_CLICKS_TO_ENABLE || userData?.userType === "admin",
    click,
  }
}

import { Button, Footer as FooterContainer } from "@atoms"

interface FooterCtaProps {
  onSave: () => void
  loading: boolean
  disabled: boolean
  children: React.ReactNode
}

const FooterCta = ({ onSave, loading, disabled, children }: FooterCtaProps) => {
  return (
    <FooterContainer>
      <div className="px-[32px] py-[14px]">
        <Button
          onClick={onSave}
          className="font-semibold text-white"
          loading={loading}
          disabled={disabled}
        >
          {children}
        </Button>
      </div>
    </FooterContainer>
  )
}

export default FooterCta

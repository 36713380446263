import { createSelector } from "@reduxjs/toolkit"
import { messagingSelector } from "./common"

export interface SelectDecodedChannelMessages {
  channelId?: string
}

export const selectChannelMessages = createSelector(
  messagingSelector,
  (_: any, input: SelectDecodedChannelMessages) => input,
  ({ channelMessages }, input) => {
    if (!input.channelId) {
      return undefined
    }

    const channel = channelMessages[input.channelId]
    if (!channel) {
      return undefined
    }

    return {
      messages: channel.messages.data?.filter((x) => !x.del) ?? [],
      hasMoreMessages: !!channel.messages.cursor,
      loading: channel.messages.loading,
    }
  }
)

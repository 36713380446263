import React from "react"
// Redux
// https://github.com/rt2zz/redux-persist
// import {PersistGate} from 'redux-persist/integration/react'
import { Provider } from "react-redux"

import store from "./redux"

type Props = {
  children: any
}

const StoreProvider = (props: Props) => {
  return <Provider store={store}>{props.children}</Provider>
}
export { StoreProvider }

import { categoriesLabelSelector } from "@modules/contents/state/selectors"
import { useRootSelector } from "@modules/store/redux"
import classNames from "classnames"
import { useDispatch } from "react-redux"
import { coursesActions } from "../../state"

interface Props {
  categoryCode: string | null
  showAll: () => void
}

const CourseCategoryBoxButton = ({ categoryCode, showAll }: Props) => {
  const dispatch = useDispatch()
  const { getCategoryName, getCategoryColor, getCategoryTexture } =
    useRootSelector(categoriesLabelSelector)
  return (
    <div
      className={classNames(
        "flex flex-col justify-end px-[15px] py-[16px] min-w-[174px] min-h-[116px] rounded-[8px] cursor-pointer"
      )}
      style={{
        backgroundColor: getCategoryColor(categoryCode),
      }}
      onClick={() => {
        dispatch(coursesActions.setCategoryCode(categoryCode))
        showAll()
      }}
    >
      <div
        className="text-left  text-white text-md leading-[1.31] font-bold"
        color="white"
      >
        {getCategoryName(categoryCode)}
        <div className="absolute right-0 top-[-10px] z-10 w-[85%] h-full pointer-events-none">
          <img src={getCategoryTexture(categoryCode)} className="w-full" />
        </div>
      </div>
    </div>
  )
}

export default CourseCategoryBoxButton

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  IonContent,
  IonFooter,
  IonHeader,
  IonMenu,
  IonPopover,
} from "@ionic/react"
import { createPortal } from "react-dom"
import { useDispatch, useSelector } from "react-redux"
import { brandsLabelSelector } from "@modules/contents/state/selectors"
import { Button, Icon } from "@atoms"
import { useRootSelector } from "@modules/store/redux"
import { equals } from "ramda"
import { coursesActions } from "@features/courses/state"
import classNames from "classnames"
import { CourseOrderType } from "@features/courses/types/courses"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import SafeTopAreaContainer from "@ui/utils/SafeTopAreaContainer"

type Menu = "CLOSED" | "FILTERS" | "ORDERS"

const useCoursesOrderBy = (): CourseOrderType[] => {
  const { t } = useTranslations()
  return [
    {
      id: "NEWEST_FIRST",
      label: t("courses:sort.newest"),
    },
    {
      id: "OLDEST_FIRST",
      label: t("courses:sort.oldest"),
    },
    {
      id: "COMPLETION_LAST",
      label: t("courses:sort.progress"),
    },
    {
      id: "COMPLETION_FIRST",
      label: t("courses:sort.completed"),
    },
    {
      id: "ALPHABETIC",
      label: t("courses:sort.az"),
    },
  ]
}

const CourseFilters = () => {
  const { t } = useTranslations()
  const coursesOrderBy = useCoursesOrderBy()
  const brands = useSelector(brandsLabelSelector)
  const dispatch = useDispatch()
  const filteredBrands = useRootSelector(
    (state) => state.courses.filteredBrands
  )
  const orderType = useRootSelector((state) => state.courses.orderType)
  const [selectedBrands, setSelectedBrands] = useState<Record<string, boolean>>(
    {}
  )
  const filtersLength = Object.keys(filteredBrands).length
  const toggleBrand = useCallback(
    (brand: string) => {
      const newObject = { ...selectedBrands }
      if (newObject[brand]) {
        delete newObject[brand]
      } else {
        newObject[brand] = true
      }
      setSelectedBrands(newObject)
    },
    [selectedBrands]
  )

  const areFiltersUpdated = useMemo(
    () => !equals(filteredBrands, selectedBrands),
    [filteredBrands, selectedBrands]
  )

  const [openMenu, setOpenMenu] = useState<Menu>("CLOSED")
  const menuRef = useRef<HTMLIonMenuElement>()
  const rootElemRef = React.useRef(document.querySelector("body"))
  const setMenuRef = useCallback((node: HTMLIonMenuElement) => {
    if (node != null) {
      menuRef.current = node
    }
  }, [])
  useEffect(() => {
    if (openMenu == "FILTERS") {
      menuRef.current?.open()
    }
    return () => {
      if (menuRef.current != null) {
        menuRef.current.close()
      }
    }
  }, [openMenu])
  useEffect(() => {
    setSelectedBrands(filteredBrands)
  }, [filteredBrands])

  // menuRef.current = document.querySelector("#courses-content") as any
  return (
    <>
      <div className="flex h-[40px] py-[11px] bg-grey justify-between items-center text-center border-y-[#BFC8CF] border-y">
        <div
          className="flex-1 cursor-pointer"
          onClick={() => setOpenMenu("ORDERS")}
          id="orders-button"
        >
          {t("courses:order.title")}
        </div>
        <div className="block h-[27px] bg-[#BFC8CF] w-[1px]" />
        <div
          className="flex items-center justify-center flex-1 gap-[18px] cursor-pointer"
          onClick={() => setOpenMenu("FILTERS")}
        >
          <div>{t("courses:filter.brand.title")}</div>
          {filtersLength > 0 && (
            <div className="rounded-full text-2xs w-[13px] h-[13px] bg-dark text-white flex justify-center items-center">
              {filtersLength}
            </div>
          )}
        </div>
      </div>
      <IonPopover
        style={{
          "--offset-y": "6px",
        }}
        isOpen={openMenu === "ORDERS"}
        onIonPopoverWillDismiss={() => setOpenMenu("CLOSED")}
        trigger="orders-button"
        reference="trigger"
      >
        <IonContent>
          <div className="p-[16px] bg-grey flex flex-col gap-[8px]">
            {coursesOrderBy.map((x) => (
              <div
                key={x.id}
                onClick={() => {
                  dispatch(coursesActions.setOrderTypeFilters(x.id))
                  setOpenMenu("CLOSED")
                }}
                className={classNames(
                  orderType === x.id ? "text-dark font-semibold" : "text-light",
                  "flex justify-between items-center h-[20px] cursor-pointer"
                )}
              >
                <div className="flex-1">{x.label}</div>
                <CheckIcon />
              </div>
            ))}
          </div>
        </IonContent>
      </IonPopover>
      {createPortal(
        <IonMenu
          side="end"
          content-id="courses-content"
          ref={setMenuRef}
          onIonWillClose={() => setOpenMenu("CLOSED")}
          swipeGesture={false}
        >
          <IonHeader className="ion-no-border">
            <SafeTopAreaContainer className="flex h-[65px] items-center justify-between px-[10px] mx-[20px] mt-[24px] text-dark border-b border-b-[#BFC8CF]">
              <div className="font-poppins font-semibold leading-[1.43] uppercase">
                {t("courses:filter.brand.all")}
              </div>
              <div
                className="text-xs leading-loose"
                onClick={() => setSelectedBrands({})}
              >
                {t("courses:filters.clear")}
              </div>
            </SafeTopAreaContainer>
          </IonHeader>
          <IonContent fullscreen>
            <div className="mx-[20px]">
              {brands.filteredBrandsLabels.map((b) => {
                const isBrandSelected = selectedBrands[b.code]
                return (
                  <div
                    onClick={() => toggleBrand(b.code)}
                    className={classNames(
                      isBrandSelected ? "text-dark" : "text-light",
                      "px-[10px] h-[48px] flex items-center justify-between border-b border-b-[#BFC8CF]"
                    )}
                    key={b.code}
                  >
                    <div
                      className={classNames(isBrandSelected && "font-semibold")}
                    >
                      {b.data.name}
                    </div>
                    <CheckIcon />
                  </div>
                )
              })}
            </div>
          </IonContent>
          <IonFooter className="ion-no-border">
            {
              <div className="pt-[23px] px-[30px] pb-[18px]">
                {areFiltersUpdated ? (
                  <Button
                    variant="primary"
                    onClick={() => {
                      dispatch(coursesActions.setBrandsFilters(selectedBrands))
                      setOpenMenu("CLOSED")
                    }}
                  >
                    {t("courses:filters.apply")}
                  </Button>
                ) : (
                  <Button
                    variant="light"
                    onClick={() => {
                      setOpenMenu("CLOSED")
                    }}
                  >
                    {t("courses:filters.cancel")}
                  </Button>
                )}
              </div>
            }
          </IonFooter>
        </IonMenu>,
        rootElemRef.current!
      )}
    </>
  )
}

const CheckIcon = () => {
  return <Icon iconName="check" className={classNames("h-[12px] w-[18px]")} />
}

export { CourseFilters }

import {
  Firebase,
  FirestoreController,
} from "../../../infrastructure/firebase/controller"
import { ChatPreview, ReceiverInfo } from "./chat-preview.entity"
import { query, where } from "firebase/firestore"
import { Entity } from "../../../infrastructure/firebase/types"
import { buildChatId } from "../chat/chat.id"

export interface ChatPreviewItem {
  hasChanges: boolean
  entity: Entity<ChatPreview>
}

export class ChatPreviewController extends FirestoreController<ChatPreview> {
  constructor(firebase: Firebase) {
    super(firebase, "ChatPreviewRepo")
  }

  async getOrCreateChatPreview({
    receiverId,
    receiverInfo,
    senderId,
    createdBy,
  }: {
    senderId: string
    receiverId: string
    receiverInfo: ReceiverInfo
    createdBy: string
  }) {
    if (!senderId) {
      throw new Error("Empty senderId")
    }

    if (!receiverId) {
      throw new Error("Empty receiverId")
    }

    return await this.getOrCreateDocument({
      id: buildChatId(senderId, receiverId),
      data: {
        active: true,
        hidden: false,
        createData: {
          source: "user",
          createdBy,
        },
        senderId,
        receiverId,
        receiverInfo,
        totUnreadMessages: 0,
      },
    })
  }

  subscribeUserChatPreviews(
    uid: string,
    callback: (items: ChatPreviewItem[]) => void
  ) {
    return this.subscribeQueryRaw(
      (collection) =>
        query(
          collection,
          where("senderId", "==", uid),
          where("active", "==", true)
        ),
      (snap) =>
        callback(
          snap.docs.map((x) => ({
            entity: x.data(),
            hasChanges: snap.docChanges().some((c) => x.id === c.doc.id),
          }))
        )
    )
  }
}

import { useIonViewDidEnter } from "@ionic/react"
import { useCallback, useEffect, useRef, useState } from "react"

export type ThemeColors =
  | "red"
  | "green"
  | "pink"
  | "gold"
  | "blue"
  | "dark-blue"
  | "violet"
  | "grey"
  | "white"
  | "black"
  | "light"

export const setThemeColorVar = (
  color?: ThemeColors,
  varName: string = "--theme-color"
) => {
  if (color == null) {
    return null
  }
  return {
    [varName]: getThemeColorVar(color),
  } as React.CSSProperties
}
export const getThemeColorVar = (color: ThemeColors) => {
  return `var(--ion-color-${color})`
}
export const setCssVar = (varName: string, value: string | number) => {
  return {
    [varName]: value,
  } as React.CSSProperties
}
const getNodeSize = (node: any) => {
  return {
    height: (node.getBoundingClientRect().height ?? 0) as number,
    width: (node.getBoundingClientRect().width ?? 0) as number,
  }
}

export const useSize = () => {
  const [observer, setObserver] = useState<IntersectionObserver>()
  const [size, setSize] = useState({
    height: 0,
    width: 0,
  })
  const ref = useRef<any>()

  const updateSize = () => {
    setSize(getNodeSize(ref.current))
  }
  const setRef = useCallback((node) => {
    if (node !== null) {
      ref.current = node
      window.addEventListener("resize", updateSize)
      const obs = new IntersectionObserver((mutationsList, o) => {
        for (const mutation of mutationsList) {
          if (mutation.isIntersecting) {
            updateSize()
            o.disconnect()
          }
        }
      })
      obs.observe(node)
      setObserver(obs)
    }
  }, [])

  useIonViewDidEnter(() => {
    if (ref.current != null) {
      setSize(getNodeSize(ref.current))
    }
  })

  useEffect(() => {
    return () => {
      if (ref.current != null) {
        window.removeEventListener("resize", updateSize)
      }
    }
  }, [])
  useEffect(() => {
    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [observer])

  return [size, setRef] as const
}

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<any>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
export const getFileSizeLabel = (sizeMB: number) => {
  if (sizeMB < 1) {
    return `${(sizeMB * 1024).toFixed(2)} KB`
  }
  return `${sizeMB.toFixed(2)} MB`
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }, [])

  return windowSize
}

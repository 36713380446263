import { PageTitle } from "@atoms"
import { IonHeader, IonToolbar } from "@ionic/react"
import { CourseTab } from "@features/courses/types/courses"
import { Header, Tabs } from "@molecules"
import classNames from "classnames"
import React from "react"

import CourseCategoriesSelector from "./CourseCategoriesSelector"
import { CourseFilters } from "./CourseFilters"
import { useTranslations } from "@modules/app/hooks/useTranslations"

interface Props {
  selectedTab: CourseTab
  tabs: CourseTab[]
  setSelectedTab: (tab: CourseTab) => void
  hideTabs?: boolean
}

const CoursesHeader = ({
  selectedTab,
  setSelectedTab,
  tabs,
  hideTabs,
}: Props) => {
  const { t } = useTranslations()
  return (
    <IonHeader className="ion-no-border">
      <IonToolbar className="">
        <Header>
          <PageTitle text={t("courses:header.title")} />
        </Header>
        {!hideTabs && (
          <Tabs
            activeTab={selectedTab}
            tabs={tabs}
            setSelectedTab={setSelectedTab}
          />
        )}
        <div>
          <CourseCategoriesSelector
            className={classNames(
              "h-[44px]",
              selectedTab.id !== "list" && "!hidden",
              hideTabs ? "mb-[28px]" : "my-[28px]"
            )}
          />
          {/* <CourseCategoriesSelector
            onlyCompleted
            className={classNames(
              "my-[28px] h-[44px]",
              selectedTab.id !== "completed" && "!hidden"
            )}
          /> */}
          <div className={classNames(selectedTab.id === "news" && "!hidden")}>
            <CourseFilters />
          </div>
        </div>
      </IonToolbar>
    </IonHeader>
  )
}

export default CoursesHeader

import classNames from "classnames"
import { Control, useController } from "react-hook-form"
import React, { ReactNode } from "react"

type InputType = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

interface Props {
  className?: string
  classes?: {
    input?: string
    root?: string
    inputWrapper?: string
    error?: string
    label?: string
  }
  // value?: string;
  // onChange?: (value: string) => void;
  name: string
  control: Control<any, object>
  label?: string
  placeholder?: string
  type: InputType["type"]
  children?: ReactNode
  onEnterKeyPressed?: () => void
}

const FormTextInput = (props: Props) => {
  const {
    classes,
    name,
    control,
    label,
    placeholder,
    type,
    onEnterKeyPressed,
    className,
  } = props

  const {
    field: { onChange, onBlur, name: inputName, value, ref },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
  })

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onEnterKeyPressed && e.keyCode === 13) {
      e.preventDefault()
      onEnterKeyPressed()
    }
  }

  return (
    <div className={classNames("relative", classes?.root, className)}>
      {label && (
        <div
          className={classNames(
            "font-bold text-sm leading-[16px] mb-[19px]",
            classes?.label
          )}
        >
          {label}
        </div>
      )}
      <div
        className={classNames("border-b border-grey", classes?.inputWrapper)}
      >
        {props.children}
        <input
          className={classNames(
            "text-base leading-[24px] p-0 w-full bg-white focus:outline-none ",
            classes?.input
          )}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onChange}
          autoComplete={"off"}
          name={inputName}
          placeholder={placeholder}
          ref={ref}
          type={type}
          onKeyDown={onKeyDown}
        />
      </div>
      <div
        className={classNames("mb-[25px] mt-[4px] text-red", classes?.error)}
      >
        {error?.message}
      </div>
    </div>
  )
}

export default FormTextInput

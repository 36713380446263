import {
  Firebase,
  FirestoreController,
} from "../../infrastructure/firebase/controller"
import { Entity } from "../../infrastructure/firebase/types"
import { buildAppChannelId } from "./app-deployment.converters"
import { AppDeploymentChannel, AppDeploymentRef } from "./app-deployment.entity"

export class AppDeploymentController extends FirestoreController<AppDeploymentChannel> {
  constructor(firebase: Firebase) {
    super(firebase, "AppDeploymentRepo")
  }

  subscribeAppDeploymentChannel(
    ref: AppDeploymentRef,
    callback: (item: Entity<AppDeploymentChannel>) => void
  ) {
    return this.subscribeDocument(buildAppChannelId(ref), (item) => {
      if (item) {
        console.log("subscribeAppDeploymentChannel -> data", item)
        callback(item)
      }
    })
  }

  getChannels() {
    return this.getAll()
  }
}

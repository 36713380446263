import { createSelector } from "@reduxjs/toolkit"
import { messagingSelector } from "./common"

export interface SelectChannelDraft {
  channelId?: string
}

export const selectChannelDraft = createSelector(
  messagingSelector,
  (_: any, input: SelectChannelDraft) => input,
  ({ channelMessages }, input) => {
    if (!input.channelId) {
      return undefined
    }

    const channel = channelMessages[input.channelId]
    if (!channel) {
      return undefined
    }
    return {
      draft: channel.draft,
    }
  }
)

import { Icon, PageTitle } from "@atoms"
import { IonHeader, IonToolbar } from "@ionic/react"
import classNames from "classnames"
import { Header } from "@molecules"
import { ReactNode } from "react"

export interface AppHeaderProps {
  onNotificationsClick: () => void
  hasUnreadNotifications: boolean
  title: string
  icon?: ReactNode
}

const AppHeader = ({
  onNotificationsClick,
  hasUnreadNotifications,
  title,
  icon,
}: AppHeaderProps) => {
  return (
    <IonHeader className="ion-no-border">
      <IonToolbar>
        <Header
          center
          className="!items-start w-full"
          rightNode={
            <Icon
              onClick={() => {
                onNotificationsClick()
              }}
              iconName={
                hasUnreadNotifications ? "notificationActive" : "notification"
              }
              className={classNames(
                "w-[24px] h-[24px]  relative",
                hasUnreadNotifications &&
                  "after:block after:absolute after:top-0 after:right-0 after:bg-red after:w-[7px] after:h-[7px] after:rounded-full"
              )}
            />
          }
        >
          <div className="flex items-center gap-3">
            {icon}
            <PageTitle text={title} />
          </div>
        </Header>
      </IonToolbar>
    </IonHeader>
  )
}

export { AppHeader }

import { Button } from "@atoms"
import { useForm } from "react-hook-form"
import { joiResolver } from "@hookform/resolvers/joi"
import { useDispatch } from "react-redux"
import { authActions } from "../state"
import joi from "joi"
import { useRootSelector } from "@modules/store/redux"
import { useEffect } from "react"
import { showToast } from "@animations"
import { useRouting } from "@root/routing/useRouting"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import FormTextInput from "@ui/forms/FormTextInput"

type Inputs = {
  password: string
  confirmPassword: string
  showPassword: boolean
}

const PasswordChangeForm = () => {
  const { t } = useTranslations()
  const schema = joi.object<Inputs>({
    showPassword: joi.boolean(),
    password: joi.string().messages({
      "string.empty": t("auth:pwdChange.emptyPwd"),
    }),
    confirmPassword: joi.string().when("showPassword", {
      is: false,
      then: joi.allow(""),
      otherwise: joi.valid(joi.ref("password")).messages({
        "string.empty": t("auth:pwdChange.emptyPwd"),
        "any.only": t("auth:pwdChange.differentPwd"),
      }),
    }),
  })

  const { control, handleSubmit, watch, setValue, formState } = useForm<Inputs>(
    {
      resolver: joiResolver(schema),
    }
  )

  const showPassword = watch("showPassword")
  // const confirmPassword = watch("confirmPassword")

  console.log("formState", formState.errors)

  useEffect(() => {
    if (showPassword) {
      console.log("confirmPassword reset")
      setValue("confirmPassword", "")
    }
  }, [showPassword])

  const { goTo } = useRouting()
  const dispatch = useDispatch()
  const onSubmit = (data: Inputs) => {
    dispatch(
      authActions.passwordChange({
        newPassword: data.password,
      })
    )
  }

  const { passwordChange } = useRootSelector((state) => state.auth)
  const submit = handleSubmit(onSubmit)

  useEffect(() => {
    if (passwordChange.success) {
      showToast({
        message: t("auth:pwdChange.messages.success"),
      })
      goTo("home")
    }
  }, [passwordChange.success])

  return (
    <div className="w-full rounded-[25px] border border-grey px-[20px] pb-[32px] pt-[23px] flex flex-col">
      <FormTextInput
        name="password"
        control={control}
        label={t("auth:pwdChange.inputs.newPwd.label")}
        placeholder={t("auth:pwdChange.inputs.newPwd.placeholder")}
        type={showPassword ? "text" : "password"}
        classes={{
          input: "order-1",
          inputWrapper: "flex",
        }}
        onEnterKeyPressed={submit}
      >
        {/* <Icon
          onClick={() => setValue("showPassword", !showPassword)}
          iconName={showPassword ? "eyeClosed" : "eyeOpen"}
          className="order-2 w-[24px] h-[22px]"
        /> */}
      </FormTextInput>
      <FormTextInput
        name="confirmPassword"
        control={control}
        label={t("auth:pwdChange.inputs.confirmPwd.label")}
        placeholder={t("auth:pwdChange.inputs.confirmPwd.placeholder")}
        type="password"
        classes={{
          root: showPassword ? "hidden" : undefined,
          input: "order-1",
          inputWrapper: "flex",
        }}
        onEnterKeyPressed={submit}
      >
        {/* <Icon
          onClick={() => setShowPassword(!showPassword)}
          iconName={showPassword ? "eyeClosed" : "eyeOpen"}
          className="order-2 w-[24px] h-[22px]"
        /> */}
      </FormTextInput>
      <Button
        color="red"
        className="text-white"
        onClick={submit}
        loading={passwordChange.loading}
      >
        {t("auth:pwdChange.cta")}
      </Button>
      {passwordChange.error === "invalidPassword" && (
        <p className="my-2">{t("auth:pwdChange.errors.invalidPassword")}</p>
      )}
      {passwordChange.error === "other" && (
        <p className="my-2">{t("auth:pwdChange.errors.other")}</p>
      )}
    </div>
  )
}

export default PasswordChangeForm

import { useRootSelector } from "@modules/store/redux"
import { FunctionsClient } from "@common/common/api"
import { useOperation } from "@punks/ui-core"
import { useEffect, useState } from "react"

export interface UsePdfDataInput {
  courseId: string
  assetId: string
  width: number
}

export const usePdfData = ({ assetId, courseId, width }: UsePdfDataInput) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [currentPageUrl, setCurrentPageUrl] = useState<string>()

  const [totPages, setTotPages] = useState<number>()
  const { courseAttachments, courseAttachmentsPages } = useRootSelector(
    (state) => state.courses
  )

  const { invoke: getPdfMetadata, state: getPdfMetadataState } = useOperation({
    operation: FunctionsClient.getCmsPdfMetadata,
  })

  const { invoke: getPageUrl, state: getPageUrlState } = useOperation({
    operation: FunctionsClient.getCmsCoursePdfPageUrl,
  })

  const loadPdfMetadata = async ({
    assetId,
    courseId,
  }: {
    assetId: string
    courseId: string
  }) => {
    const cachedMetadata = courseAttachments?.[courseId]?.[assetId]?.metadata
    if (cachedMetadata) {
      setTotPages(cachedMetadata.totPages)
      return
    }

    setTotPages(undefined)
    const metadata = await getPdfMetadata({
      assetId,
      courseId,
    })
    if (metadata) {
      setTotPages(metadata.totPages)
    }
  }

  const loadPageUrl = async ({
    assetId,
    courseId,
    pageIndex,
    width,
  }: {
    assetId: string
    courseId: string
    pageIndex: number
    width: number
  }) => {
    const cachedPageUrl =
      courseAttachmentsPages?.[courseId]?.[assetId]?.[pageIndex]?.sanityImageUrl
    if (cachedPageUrl) {
      setCurrentPageUrl(cachedPageUrl + `?w=${width}`)
      return
    }

    setCurrentPageUrl(undefined)
    const response = await getPageUrl({
      assetId,
      courseId,
      page: pageIndex,
      imageWidth: width,
    })
    if (response) {
      setCurrentPageUrl(response.url)
    }
  }

  const isFirstPage = () => currentPage === 0
  const isLastPage = () => {
    if (!totPages) {
      return false
    }

    return currentPage === totPages - 1
  }

  const handlePageNext = () => {
    if (isLastPage()) {
      return
    }

    if (totPages) {
      setCurrentPage(Math.min(currentPage + 1, totPages - 1))
    } else {
      setCurrentPage(currentPage + 1)
    }
  }

  const handlePagePrev = () =>
    currentPage <= 0 ? undefined : setCurrentPage(currentPage - 1)

  useEffect(() => {
    if (assetId && courseId) {
      console.log("loadPageUrl", assetId, courseId, currentPage)
      loadPageUrl({
        assetId,
        courseId,
        pageIndex: currentPage,
        width,
      })
    }
  }, [assetId, courseId, currentPage])

  useEffect(() => {
    if (assetId && courseId) {
      console.log("loadPdfMetadata", assetId, courseId)
      loadPdfMetadata({
        assetId,
        courseId,
      })
    }
  }, [assetId, courseId])

  const isLoadingPage = getPdfMetadataState.loading || getPageUrlState.loading

  return {
    currentPage,
    handlePageNext,
    handlePagePrev,
    isFirstPage: isFirstPage(),
    isLastPage: isLastPage(),
    isLoadingPage,
    totPages,
    pageUrl: currentPageUrl,
  }
}

import classNames from "classnames"
import { ReactNode, useCallback, useEffect, useState } from "react"
import { ChatAttachmentItem, MessageItem } from "@features/messaging/types"
import { Container, Text } from "./components"
import { ReadConfirmation } from "../ReadConfirmation"
import { useContentContext } from "@ui/atoms/Content"
import { Icon } from "@atoms"
import { isEmptyMessage } from "@modules/messaging/utils"
import Checkbox from "@ui/atoms/Checkbox"
import DynamicSizeBox from "@ui/structure/DynamicSizeBox"
import { useMessageDateFormatter } from "@features/messaging/hooks/useMessageDateFormatter"
import { buildMessageElementId } from "./utils"

interface MessageListItemProps {
  title?: ReactNode
  previousMessage?: MessageItem
  nextMessage?: MessageItem
  messageData: MessageItem
  lastReadMessageId?: string
  scrollTo: (y: number) => void
  newMessagesLabel: string
  isLastMessage?: boolean
  selectable?: boolean
  selected?: boolean
  onSelectToggle?: () => void
  showSelect?: boolean
  onLongPress?: () => void
}

const MessageListItem = ({
  title,
  messageData,
  nextMessage,
  lastReadMessageId,
  scrollTo,
  newMessagesLabel,
  isLastMessage,
  onSelectToggle,
  selectable,
  selected,
  showSelect,
  onLongPress,
}: MessageListItemProps) => {
  const { message, direction, timestamp, attachments, id, readStatus } =
    messageData
  const isMine = direction === "out"
  const [fullScreenImageId, setFullScreenImageId] = useState<string | null>()
  const { setFullScreen } = useContentContext()
  const { formatMessageDate } = useMessageDateFormatter()
  const [node, setNode] = useState<HTMLDivElement>()

  const scrollToNode = (node: HTMLDivElement) => {
    ;(window as any).CURRENTNODE = node
    scrollTo(node.getBoundingClientRect().y)
  }

  const setRef = useCallback((node: HTMLDivElement) => {
    setNode(node)
  }, [])

  useEffect(() => {
    if (isLastMessage && node) {
      scrollToNode(node)
    }
  }, [isLastMessage, node])

  const hasAttachments = (attachments?.length ?? 0) > 0

  const toggleFullscreen = (file: ChatAttachmentItem) => {
    if (fullScreenImageId) {
      setFullScreenImageId(null)
      setFullScreen(false)
    } else {
      setFullScreenImageId(file.id)
      setFullScreen(true)
    }
  }

  const downloadAttachment = (file: ChatAttachmentItem) => {
    // axios.client.get(file.url)
    window.open(file.url, "_blank")
  }

  const totAttachments = attachments?.length ?? 0
  const isSingleImageAttachment =
    totAttachments === 1 && attachments?.[0].type === "image"

  return (
    <>
      <div
        className="flex items-center"
        id={buildMessageElementId(messageData.id)}
      >
        <DynamicSizeBox fluidX width={showSelect ? 44 : 0}>
          {showSelect && (
            <Checkbox size="lg" checked={selected} onToggle={onSelectToggle} />
          )}
        </DynamicSizeBox>
        <Container
          isMine={isMine}
          hasAttachments={hasAttachments && !isSingleImageAttachment}
          hasSingleImage={isSingleImageAttachment}
          noMargin={isLastMessage}
          longPressEnabled={selectable}
          onLongPress={onLongPress}
          onClick={showSelect ? onSelectToggle : undefined}
        >
          {title && <div className="mb-2">{title}</div>}
          <div>
            {hasAttachments && (
              <div
                className={classNames("flex flex-wrap gap-[16px] mb-[16px]", {
                  "justify-end": isMine,
                })}
              >
                {attachments?.map((file) => (
                  <div
                    key={file.id}
                    className={classNames({
                      "col-span-2": file.type !== "image",
                      "aspect-[1.1692307692307693] w-[200px]":
                        file.type === "image",
                    })}
                  >
                    {file.type === "image" ? (
                      <div
                        className={classNames(
                          "w-full h-full",
                          file.id === fullScreenImageId &&
                            "fixed flex inset-0 w-full h-screen z-[99999999999] flex-col justify-center items-center bg-black"
                        )}
                      >
                        <img
                          onClick={
                            !showSelect
                              ? () => toggleFullscreen(file)
                              : undefined
                          }
                          className={classNames(
                            "rounded-[8px] max-w-full object-cover max-h-full w-full h-full cursor-pointer",
                            file.id === fullScreenImageId && "!h-auto"
                          )}
                          key={file.id}
                          src={file.url}
                        />
                      </div>
                    ) : (
                      <div
                        className={classNames(
                          "flex gap-[12px] items-center justify-center w-full cursor-pointer rounded-[8px] p-[12px]",
                          isMine
                            ? "text-white bg-dark"
                            : " bg-white text-dark border-dark border"
                        )}
                        onClick={
                          !showSelect
                            ? () => downloadAttachment(file)
                            : undefined
                        }
                      >
                        <div className="bg-grey flex items-center justify-center w-[54px] h-[54px] rounded-[8px] ">
                          <Icon
                            iconName="document"
                            className="text-dark w-[24px] h-[32px]"
                          />
                        </div>
                        <div className="flex-1 overflow-hidden break-all text-md line-clamp-2 text-ellipsis">
                          {file.fileName}
                        </div>
                        <div className="flex items-end text-right gap-[6px]">
                          {/* <div
                          className={classNames(
                            isMine ? "text-white" : "text-light ",
                            "text-sm leading-none "
                          )}
                        >
                          {getFileSizeLabel(file.sizeMB ?? 0)}
                        </div> */}
                          <Icon
                            iconName="download"
                            className={classNames(" w-[20px] h-[20px]")}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            {!isEmptyMessage(message) && (
              <Text isMine={isMine} message={message} />
            )}
          </div>
          <div
            className={classNames("flex items-center gap-4", {
              "justify-end": isMine,
            })}
          >
            <div className="text-base leading-normal text-light">
              {/* {capitalize(
                formatRelative(timestamp, new Date(), {
                  locale: it,
                })
              )} */}
              {formatMessageDate({
                messageDate: timestamp,
                nextMessageDate:
                  nextMessage && nextMessage.senderUid === messageData.senderUid
                    ? nextMessage?.timestamp
                    : undefined,
                now: Date.now(),
              })}
            </div>
            {readStatus && (
              <div>
                <ReadConfirmation readStatus={readStatus} />
              </div>
            )}
          </div>
        </Container>
      </div>
      {lastReadMessageId === id && (
        <div
          ref={setRef}
          className="flex items-center justify-center w-full py-4 text-md text-light"
        >
          {newMessagesLabel}
        </div>
      )}
    </>
  )
}

export { MessageListItem }

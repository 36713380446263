import { createRoutine } from "redux-saga-routines"
import {
  createMessagingSlice,
  createMessagingSliceContext,
  messagingRoutines,
} from "@common/common/frontend/features/messaging/state/state"
import { SendChatMessageInput } from "@common/common/features/messaging/chat"

export const sendChatMessage = createRoutine("MESSAGING|SEND_CHAT_MESSAGE", {
  TRIGGER: (payload: SendChatMessageInput) => payload,
})

const routines = { sendChatMessage }

interface ExtraMessagingState {
  isSendingMessage: boolean
}

export const messagingSlice = createMessagingSlice<ExtraMessagingState>({
  extendState: {
    isSendingMessage: false,
  },
  extendExtraReducers: (builder) => {
    builder
      .addCase(sendChatMessage.REQUEST, (state) => {
        state.isSendingMessage = true
      })
      .addCase(sendChatMessage.FULFILL, (state) => {
        state.isSendingMessage = false
      })
  },
})

const {
  name: messagingName,
  actions: sliceActions,
  reducer: messagingReducer,
} = messagingSlice

const messagingActions = {
  ...sliceActions,
  ...messagingRoutines,
  ...routines,
}
const messagingSliceContext = createMessagingSliceContext(
  messagingSlice,
  routines
)
export {
  messagingName,
  messagingReducer,
  messagingActions,
  messagingSliceContext,
}

import PasswordChangeContainer from "@containers/authentication/PasswordChange"
import PageRoot from "@root/pages/PageRoot"

export const PasswordChangePage = () => {
  return (
    <PageRoot fullscreen>
      <PasswordChangeContainer />
    </PageRoot>
  )
}

import { selectAuthStatus } from "@modules/auth/state/selectors"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { coursesActions } from "./state"
import { useRootSelector } from "@modules/store/redux"

type Props = {
  children: React.ReactNode
}

const CourseProvider = (props: Props) => {
  const { isAuthenticated } = useSelector(selectAuthStatus)
  const dispatch = useDispatch()
  const { impersonation } = useRootSelector((state) => state.auth)

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(coursesActions.watchCourses())
      dispatch(coursesActions.watchCoursesAttachments())
      dispatch(coursesActions.watchCoursesAttachmentPages())
    }
    return () => {
      if (isAuthenticated) {
        dispatch(coursesActions.watchCourses.fulfill())
        dispatch(coursesActions.watchCoursesAttachments.fulfill())
        dispatch(coursesActions.watchCoursesAttachmentPages.fulfill())
      }
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(coursesActions.getCoursesProgress())
    }
  }, [isAuthenticated, impersonation?.uid])

  return <>{props.children}</>
}

export { CourseProvider }

import { Icon } from "@atoms"
import classNames from "classnames"
import React, { useLayoutEffect } from "react"
import { useSelector } from "react-redux"
import { useSaveCourseProgress } from "../../../hooks"
import {
  selectAttachmentById,
  selectAttachmentProgressById,
} from "../../../state/selectors/attachment"
import CompletedButton from "../CompletedButton"
import {
  selectCourseId,
  selectCurrentViewIndex,
} from "@features/courses/state/selectors/lists"
import ScreenModal from "@ui/structure/ScreenModal"
import { useSize } from "@helpers/ui"
import { PDFViewer } from "@organisms"

interface Props {
  fileId: string
}

const CourseDetailFileListItem = ({ fileId }: Props) => {
  const [{ width }, setRef] = useSize()
  const file = useSelector(selectAttachmentById(fileId))
  const fileProgress = useSelector(selectAttachmentProgressById(fileId))
  const viewIndex = useSelector(selectCurrentViewIndex)
  const courseId = useSelector(selectCourseId)

  const [pdfModalOpen, setPdfModalOpen] = React.useState(false)

  const openPdfModal = () => {
    setPdfModalOpen(true)
  }
  const closePdfModal = () => {
    setPdfModalOpen(false)
  }

  // const [openPdfModal, closePdfModal] = useIonModal(PDFPlayer, {
  //   onClose: () => closePdfModal(),
  //   url: file.document?.url,
  // })

  useLayoutEffect(() => {
    return () => {
      closePdfModal()
    }
  }, [])

  const { saveCourseProgress } = useSaveCourseProgress()
  return (
    <>
      <div
        onClick={() => {
          if (fileProgress == null) {
            saveCourseProgress({
              progress: {
                attachments: {
                  [fileId]: {
                    completed: true,
                    completedTime: new Date().getTime(),
                  },
                },
              },
            })
          }
          if (/pdf/i.test(file.document?.fileExtension ?? "")) {
            openPdfModal()
            return
          }
          //TODO
          //DOWNLOAD FILE
        }}
        className={classNames(
          "p-[12px] flex gap-[16px] border border-grey rounded-[8px]"
        )}
      >
        <div className="w-[54px] h-[54px] rounded-[8px] bg-grey flex items-center justify-center">
          <Icon iconName="document" className="text-[32px]" />
        </div>
        <div className="flex-1 font-bold leading-normal break-all  text-md">
          <div>{`${file.title}`}</div>
        </div>
        <div className="">
          <div className="flex items-end gap-[6px] text-sm leading-normal">
            <div>
              {`${Math.round((file.document?.fileMB ?? 0) * 100) / 100} MB`}
            </div>
            {viewIndex === 0 && fileProgress == null ? (
              <Icon
                iconName="download"
                className="text-dark w-[20px] h-[20px]"
              />
            ) : (
              <CompletedButton
                classes={{
                  container: "!w-[20px] !h-[20px] !border-0 bg-red !text-white",
                }}
              />
            )}
          </div>
        </div>
      </div>
      <ScreenModal open={pdfModalOpen} onClose={() => closePdfModal()}>
        {file.id && courseId && (
          <div ref={setRef} className="text-primary">
            <PDFViewer assetId={file.id} courseId={courseId} />
          </div>
        )}
      </ScreenModal>
    </>
  )
}

export default React.memo(CourseDetailFileListItem)

import { AnimationBuilder } from "@ionic/react"

import { rightToLeftSlide } from "./rightToLeftSlide"
import { none } from "./none"
import { RouteAnimation } from "@root/routing/types"

export const animations: Record<RouteAnimation, AnimationBuilder | undefined> =
  {
    rightToLeftSlide: rightToLeftSlide,
    none: none,
    default: undefined,
  }

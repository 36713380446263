import { createSelector } from "@reduxjs/toolkit"
import { MessageDraft } from "../state/types"
import { messagingSelector } from "./common"

export interface SelectChatDraft {
  chatId?: string
}

export interface SelectChatResult {
  draft: MessageDraft
}

export const selectChatDraft = createSelector(
  messagingSelector,
  (_: any, input: SelectChatDraft) => input,
  ({ chatMessages }, input): SelectChatResult | undefined => {
    if (!input?.chatId) {
      return undefined
    }

    const chat = chatMessages?.[input.chatId]
    if (!chat) {
      return undefined
    }
    return {
      draft: chat.draft,
    }
  }
)

import { isWebPlatform } from "@modules/app/utils/device"
import { useEffect } from "react"
import { PrivacyScreen } from "@capacitor-community/privacy-screen"

const enablePrivacyScreen = async () => {
  try {
    console.log("enablePrivacyScreen -> enabling")
    await PrivacyScreen.enable()
    console.log("enablePrivacyScreen -> enabled")
  } catch (e) {
    console.error("enablePrivacyScreen -> error", e)
  }
}

export const PrivacyScreenActivator = () => {
  useEffect(() => {
    if (isWebPlatform()) {
      return
    }

    enablePrivacyScreen()
  }, [])

  return <></>
}

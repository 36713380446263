import { Button } from "@atoms"
import { useAppInfo, useAppReset } from "@modules/deployments/hooks"
import { useRootSelector } from "@modules/store/redux"
import Select from "@ui/atoms/Select"
import { useOperation } from "@punks/ui-core"
import { FrameLayout } from "@ui/layouts"
import { format } from "date-fns"
import { firebase } from "@services/index"
import { getDeploymentChannelId } from "@modules/deployments/features"
import { useDispatch } from "react-redux"
import { deploymentsSlice } from "@modules/deployments/state/store"
import { useAppAdminEnabler } from "@modules/deployments/hooks/useAppAdminEnabler"

const AppInfoPanel = () => {
  const dispatch = useDispatch()
  const { channel } = useRootSelector((state) => state.deployments)
  const appInfo = useAppInfo()
  const appReset = useAppReset()
  const adminMode = useAppAdminEnabler()

  const { state } = useOperation({
    operation: async () => {
      const channels =
        await firebase.collections.appDeploymentController.getChannels()
      const currentChannel = await getDeploymentChannelId()
      return {
        channels,
        currentChannel,
      }
    },
    autoTrigger: true,
  })

  const handleLiveUpdate = async () => {
    const channel = state.data?.channels.find(
      (x) => x.data.channelId === state.data?.currentChannel
    )
    if (!channel) {
      console.error("Channel not found", state.data)
      return
    }
    dispatch(deploymentsSlice.actions.liveUpdateTrigger(channel))
  }

  return (
    <>
      <FrameLayout
        icon="shield"
        title={"App info"}
        onHeadClick={() => adminMode.click()}
      >
        <div>
          <p>
            <b>Id:</b> {appInfo?.bundleVersion?.id}
          </p>
          {appInfo?.bundleVersion?.version && (
            <p>
              <b>Version:</b> {appInfo?.bundleVersion?.version}
            </p>
          )}
          {appInfo?.buildHash && (
            <p>
              <b>Hash:</b> {appInfo?.buildHash?.substring(0, 16) + "..."}
            </p>
          )}
          <p>
            <b>Status:</b> {appInfo?.bundleVersion?.status}
          </p>
          {appInfo?.bundleVersion?.checksum && (
            <p>
              <b>Checksum:</b> {appInfo?.bundleVersion?.checksum}
            </p>
          )}
          <p>
            <b>Downloaded:</b>{" "}
            {appInfo?.bundleVersion?.downloaded
              ? format(
                  new Date(appInfo?.bundleVersion?.downloaded),
                  "dd/mm/yyyy HH:mm"
                )
              : ""}
          </p>
          {adminMode.isAdmin && state.data && (
            <p>
              <b>Channel select:</b>
              <Select
                value={channel?.data.channelId}
                onChange={(value) =>
                  dispatch(deploymentsSlice.actions.switchChannelTrigger(value))
                }
                options={
                  state.data.channels?.map((channel) => ({
                    label: channel.data.channelId,
                    value: channel.data.channelId,
                  })) ?? []
                }
                placeholder="Channel"
              />
            </p>
          )}
        </div>

        <div className="flex items-center gap-2 my-8">
          <Button
            variant="primary"
            onClick={() => appReset.invoke()}
            loading={appReset.loading}
            className="px-4"
          >
            Reset
          </Button>

          <Button
            variant="primary"
            onClick={() => handleLiveUpdate()}
            className="px-4"
          >
            Aggiorna
          </Button>
        </div>
      </FrameLayout>
    </>
  )
}

export default AppInfoPanel

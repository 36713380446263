import LoginContainer from "@containers/authentication/Login"
import PageRoot from "@root/pages/PageRoot"

export const LoginPage = () => {
  return (
    <PageRoot fullscreen>
      <LoginContainer />
    </PageRoot>
  )
}

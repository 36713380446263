import { WeekDays } from "@modules/app/hooks/useWeekDays"
import {
  formatTimeHHMM,
  isThisMonth,
  isThisYear,
  isToday,
  isYesterday,
} from "./dates"

const getDayName = (d: number, weekDays: WeekDays) => {
  return weekDays[d]
}

const getDayShortName = (d: number, weekDays: WeekDays) =>
  getDayName(d, weekDays)?.substring(0, 3)

export const formatDateLabel = (
  d: Date,
  labels: {
    yesterday: string
    weekDays: WeekDays
  }
) => {
  const now = new Date()
  if (isToday(d, now)) {
    return formatTimeHHMM(d)
  }
  if (isYesterday(d, now)) {
    return `${labels.yesterday} ${formatTimeHHMM(d)}`
  }

  if (isThisMonth(d, now)) {
    return `${getDayShortName(d.getDay(), labels.weekDays)} ${d
      .getDate()
      .toString()
      .padStart(2, "0")} ${formatTimeHHMM(d)}`
  }

  if (isThisYear(d, now)) {
    return `${d.getDate().toString().padStart(2, "0")}/${(d.getMonth() + 1)
      .toString()
      .padStart(2, "0")} ${formatTimeHHMM(d)}`
  }

  return `${d.getDate().toString().padStart(2, "0")}/${(d.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${d.getFullYear()} ${formatTimeHHMM(d)}`
}

export const formatDayLabel = (
  d: Date,
  labels: {
    yesterday: string
    weekDays: WeekDays
  }
) => {
  const now = new Date()
  if (isToday(d, now)) {
    return undefined
  }
  if (isYesterday(d, now)) {
    return labels.yesterday
  }

  if (isThisMonth(d, now)) {
    return `${getDayShortName(d.getDay(), labels.weekDays)} ${d
      .getDate()
      .toString()
      .padStart(2, "0")}`
  }

  if (isThisYear(d, now)) {
    return `${d.getDate().toString().padStart(2, "0")}/${(d.getMonth() + 1)
      .toString()
      .padStart(2, "0")}`
  }

  return `${d.getDate().toString().padStart(2, "0")}/${(d.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${d.getFullYear()}`
}

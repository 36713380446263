import { put, call, select } from "typed-redux-saga"
import { getSagaContext } from "../../../state/context"
import { userProfileRoutines } from "../state/state"

export function* selfResetPassword({
  payload,
}: ReturnType<typeof userProfileRoutines.updateUserPassword.trigger>) {
  const api = yield* getSagaContext("api")
  const firebase = yield* getSagaContext("firebase")
  try {
    yield* call(api.resetSelfPassword, {
      password: payload.newPassword,
    })
  } catch (error) {
    console.error("Update password error", error)
    yield* put(userProfileRoutines.updateUserPassword.failure({}))
  } finally {
    yield* put(userProfileRoutines.updateUserPassword.fulfill())
    const email = select()
    yield* call(firebase.login, {
      email: payload.email,
      password: payload.newPassword,
    })
  }
}

import { FunctionsClient } from "@common/common/api"
import { useOperation } from "@punks/ui-core"

export type ChannelMessagesMarkDeletedInput = {
  channelId: string
  messageIds: string[]
}

export const useChannelMessagesDelete = () => {
  return useOperation({
    operation: async (input: ChannelMessagesMarkDeletedInput) => {
      for (const messageId of input.messageIds) {
        await FunctionsClient.markDeletedChannelMessage({
          channelId: input.channelId,
          messageId: messageId,
        })
      }
    },
  })
}

import { Button, Icon, TextInput } from "@atoms"
import { useForm } from "react-hook-form"
import { joiResolver } from "@hookform/resolvers/joi"
import { useDispatch } from "react-redux"
import { authActions } from "../state"
import joi from "joi"
import { buildUserEmail } from "../utils/user"
import { useRootSelector } from "@modules/store/redux"
import { useState } from "react"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import FormTextInput from "@ui/forms/FormTextInput"

type Inputs = {
  user: string
  password: string
}

const LoginBox = () => {
  const { t } = useTranslations()
  const schema = joi.object<Inputs>({
    user: joi.string().messages({
      "string.empty": t("auth:login.input.user.empty"),
    }),
    password: joi.string().messages({
      "string.empty": t("auth:login.input.pwd.empty"),
    }),
  })
  const { control, handleSubmit } = useForm<Inputs>({
    resolver: joiResolver(schema),
  })
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const dispatch = useDispatch()
  const onSubmit = (data: Inputs) => {
    dispatch(
      authActions.login({
        email: buildUserEmail(data.user).toLowerCase(),
        password: data.password,
      })
    )
  }

  const { isLogging, error } = useRootSelector((state) => state.auth)
  const login = handleSubmit(onSubmit)

  return (
    <div className="w-full rounded-[25px] border border-grey px-[20px] pb-[32px] pt-[23px] flex flex-col">
      <FormTextInput
        name="user"
        control={control}
        label={t("auth:login.input.user.label")}
        placeholder={t("auth:login.input.user.placeholder")}
        type="text"
        onEnterKeyPressed={login}
      />
      <FormTextInput
        name="password"
        control={control}
        label={t("auth:login.input.pwd.label")}
        placeholder={t("auth:login.input.pwd.placeholder")}
        type={isPasswordVisible ? "text" : "password"}
        classes={{
          input: "order-1",
          inputWrapper: "flex",
        }}
        onEnterKeyPressed={login}
      >
        <Icon
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          iconName={isPasswordVisible ? "eyeClosed" : "eyeOpen"}
          className="order-2 w-[24px] h-[22px]"
        />
      </FormTextInput>
      <Button
        color="red"
        className="text-white"
        onClick={login}
        loading={isLogging}
      >
        {t("auth:login.cta")}
      </Button>
      {error === "invalidUser" && (
        <p className="my-2">{t("auth:login.errors.invalidUser")}</p>
      )}
      {error === "invalidPassword" && (
        <p className="my-2">{t("auth:login.errors.invalidPassword")}</p>
      )}
      {error === "tooManyRequests" && (
        <p className="my-2">{t("auth:login.errors.tooManyRequests")}</p>
      )}
      {error === "other" && (
        <p className="my-2">{t("auth:login.errors.other")}</p>
      )}
    </div>
  )
}

export default LoginBox

import { put, call, fork } from "typed-redux-saga"
import { PayloadAction } from "@reduxjs/toolkit"
import { AttachFileItem, DraftAttachFilesInput } from "../state/types"
import { DraftRef } from "../types"
import {
  attachmentUpload,
  AttachmentUploadInput,
} from "../actions/attachmentUpload"
import { getSagaContext } from "../../../state/context"

const toAttachmentUploadInput = (
  uid: string,
  item: AttachFileItem
): AttachmentUploadInput => ({
  attachmentId: item.id,
  file: item.file,
  uploadDate: new Date(),
  metadata: {
    senderId: uid,
    channelIds: [],
    receiverIds: [],
  },
})

export function* draftAttachFileSaga(ref: DraftRef, item: AttachFileItem) {
  const state = yield* getSagaContext("state")
  try {
    const firebase = yield* getSagaContext("firebase")
    const uid = yield* call(firebase.getUid)
    if (!uid) {
      throw new Error("Missing uid")
    }
    const asset = yield* call(
      attachmentUpload,
      firebase.storage,
      toAttachmentUploadInput(uid, item)
    )
    yield* put(
      state.messaging.actions.draftAttachmentUploaded({
        ref,
        attachmentId: item.id,
        asset,
      })
    )
  } catch (e) {
    console.error(`ATTACHMENT UPLOAD ERROR -> ${item.id} ${item.file.name}`, e)
    yield* put(
      state.messaging.actions.draftAttachmentUploadError({
        ref,
        attachmentId: item.id,
      })
    )
  }
}

export function* draftAttachFilesSaga(
  action: PayloadAction<DraftAttachFilesInput>
) {
  for (const item of action.payload.items) {
    yield* fork(draftAttachFileSaga, action.payload.ref, item)
  }
}

import { useState, useRef, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSaveCourseProgress } from "."
import { coursesActions } from "../state"
import {
  selectChapter,
  selectChapterId,
  selectChapterProgress,
  selectPlayChapterId,
} from "../state/selectors/chapter"

type VideoEvent = React.SyntheticEvent<HTMLVideoElement, Event>

export const useChapterProgress = () => {
  const FREQUENCY = 10
  const dispatch = useDispatch()
  const [remoteProgress, setRemoteProgress] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isVideoLoaded, setIsVideoLoaded] = useState(false)
  // const videoRef = useRef<HTMLVideoElement>(null)
  const { saveCourseProgress, viewIndex, courseId } = useSaveCourseProgress()
  const chapterId = useSelector(selectChapterId)
  // const playChapterId = useSelector(selectPlayChapterId)
  const chapter = useSelector(selectChapter)
  const chapterProgress = useSelector(selectChapterProgress)
  const currentProgress = chapterProgress?.progress ?? 0
  const isCompleted = chapterProgress?.completed ?? false

  // const play = useCallback(() => {
  //   videoRef.current?.play()
  // }, [])

  // useEffect(() => {
  //   //when video change, trigger video load and setCurrent progress (and set remote quantized progress)
  //   if (chapterId && isVideoLoaded) {
  //     videoRef.current!.load()
  //     setRemoteProgress(currentProgress - (currentProgress % FREQUENCY))
  //     videoRef.current!.currentTime = currentProgress
  //     if (playChapterId === chapterId) {
  //       videoRef.current!.play()
  //     }
  //   }
  // }, [chapterId, isVideoLoaded, playChapterId])

  // useEffect(() => {
  //   const onStart = async () => {
  //     try {
  //       // await (videoRef.current as HTMLVideoElement).requestFullscreen();
  //       videoRef.current?.play()
  //     } catch (error) {}
  //   }
  //   if (chapterId && isPlaying) {
  //     if (chapterProgress == null) {
  //       saveCourseProgress({
  //         progress: {
  //           chapters: {
  //             [chapterId]: {
  //               progress: 0,
  //               startedTime: new Date().getTime(),
  //             },
  //           },
  //         },
  //       })
  //     }
  //     onStart()
  //   }
  // }, [chapterId, isPlaying, courseId])

  // const setVideoRef = useCallback((node: HTMLVideoElement) => {
  //   if (node) {
  //     ;(videoRef.current as any) = node
  //     setIsVideoLoaded(true)
  //   }
  // }, [])

  const onLoaded = useCallback(() => {
    setIsVideoLoaded(true)
    setIsPlaying(false)
  }, [])

  const onTimeUpdate = (e: VideoEvent) => {
    if (chapterId == null) {
      return
    }
    if (
      e == null ||
      isCompleted ||
      e.currentTarget.readyState !== 4 ||
      e.currentTarget.currentTime <= currentProgress
    ) {
      return
    }
    const data: Parameters<typeof saveCourseProgress>[0] = {
      progress: {
        chapters: {
          [chapterId]: {
            progress: e.currentTarget.currentTime,
          },
        },
      },
    }

    if (e.currentTarget.currentTime - remoteProgress >= FREQUENCY) {
      setRemoteProgress(e.currentTarget.currentTime)
      saveCourseProgress(data)
    } else {
      dispatch(
        coursesActions.saveCourseProgressOnlyLocally({
          courseId,
          viewIndex,
          ...data,
        })
      )
    }
  }

  // const onSeeking = (e: VideoEvent) => {
  //   if (e == null || isCompleted) {
  //     return
  //   }
  //   if (e.currentTarget.currentTime > currentProgress) {
  //     e.currentTarget.currentTime = currentProgress
  //   }
  // }

  const onEnded = useCallback(
    (e: VideoEvent) =>
      chapterId != null &&
      !isCompleted &&
      saveCourseProgress({
        progress: {
          chapters: {
            [chapterId]: {
              progress: 0,
              completed: true,
              completedTime: new Date().getTime(),
            },
          },
        },
      }),
    [isCompleted, chapterId]
  )

  const onPause = useCallback(
    (e: VideoEvent) =>
      e != null && e.currentTarget.readyState === 4 && setIsPlaying(false),
    []
  )

  const onPlay = useCallback(() => setIsPlaying(true), [])

  return {
    // play,
    isPlaying,
    onTimeUpdate,
    // onSeeking,
    // setVideoRef,
    onLoaded,
    onPause,
    onPlay,
    onEnded,
    isVideoLoaded,
    video: chapter?.video,
  }
}

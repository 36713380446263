import { createSelector } from "@reduxjs/toolkit"
import { selectChatMessages } from "@common/common/frontend/features/messaging/selectors/selectChatMessages"
import { selectChatPreviewById } from "../preview"
import { RootState } from "@modules/store/redux/rootReducer"
import { MessageItem } from "@features/messaging/types"
import { toChatAttachment } from "../common"
import { ImDecodedChatMessage } from "@common/common/frontend/features/messaging/state/types"
import { ChatPreviewEntity } from "@common/common/database/messaging/chat-preview/chat-preview.entity"
import { ReadStatus } from "@features/messaging/components/common/ReadConfirmation"

interface GetReadStatusInput {
  message: ImDecodedChatMessage
  messageIndex: number
  lastReadIndex: number
  lastReceivedIndex: number
}

const getReadStatus = (input: GetReadStatusInput): ReadStatus | undefined => {
  if (input.message.d === "in") {
    return undefined
  }

  if (input.messageIndex <= input.lastReadIndex) {
    return "READ"
  }

  if (input.messageIndex <= input.lastReceivedIndex) {
    return "UNREAD"
  }

  if (input.message.t) {
    return "SENT"
  }

  return "NOT_SENT"
}

const getLastReadMessageIndex = (
  chatPreview: ChatPreviewEntity,
  messages: ImDecodedChatMessage[]
) =>
  messages.findIndex((x) => x.id === chatPreview.data.targetLastReadMessageId)

const getLastReceivedMessageIndex = (
  chatPreview: ChatPreviewEntity,
  messages: ImDecodedChatMessage[]
) =>
  messages.findIndex(
    (x) => x.id === chatPreview.data.targetLastReceivedMessageId
  )

export const selectChatMessagesById = (chatId: string) =>
  createSelector(
    (state: RootState) => state.auth,
    selectChatPreviewById(chatId),
    (state: any) => selectChatMessages(state, { chatId }),
    (auth, preview, messages) => {
      const lastReadIndex =
        preview?.entity && messages?.messages
          ? getLastReadMessageIndex(preview.entity, messages.messages)
          : -1
      const lastReceivedIndex =
        preview?.entity && messages?.messages
          ? getLastReceivedMessageIndex(preview.entity, messages.messages)
          : -1

      // messages?.messages.findIndex(
      //   (x) => x.id === preview?.entity.data.targetLastReadMessageId
      // ) ?? -1
      return {
        hasMoreMessages: messages?.hasMoreMessages ?? false,
        isLoading: messages?.loading ?? false,
        preview,
        messageList: messages?.messages
          .filter((x) => !x.del)
          .map<MessageItem>((m, index) => {
            const direction = m.si === auth.user?.uid ? "out" : "in"
            return {
              direction,
              id: m.id,
              senderUid: m.si,
              message: m.message,
              timestamp: m.t ?? new Date(),
              attachments: m.a?.map(toChatAttachment),
              readStatus: getReadStatus({
                message: m,
                messageIndex: index,
                lastReadIndex,
                lastReceivedIndex,
              }),
            }
          }),
      }
    }
  )

import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Autoplay } from "swiper"
import Banner from "../../../../modules/contents/components/SectionsBanners/Banner"
import { privateRoutes } from "@root/routing/routes"
import { useRoutingContext } from "@modules/routing/context"
import { useTranslations } from "@modules/app/hooks/useTranslations"

type Props = {
  existsNotifications: boolean
  existsCourses: boolean
}

const NewsBanners = ({ existsNotifications, existsCourses }: Props) => {
  const { t } = useTranslations()
  const { selectTab } = useRoutingContext()

  return (
    <div className="w-full mb-[26px]">
      <Swiper
        observer
        pagination
        autoplay
        spaceBetween={12}
        modules={[Pagination, Autoplay]}
        className="swiper-news-banner"
      >
        {existsNotifications && (
          <SwiperSlide>
            <div className="w-full text-left">
              <Banner
                onClick={() =>
                  selectTab(
                    privateRoutes.chat.getRouteWithParams({
                      communicationTab: "previews",
                    })
                  )
                }
                text={<pre>{t("news:communications.title")}</pre>}
                iconName={"bannerComunicazioni"}
              />
            </div>
          </SwiperSlide>
        )}
        {existsCourses && (
          <SwiperSlide>
            <div className="w-full text-left">
              <Banner
                onClick={() =>
                  selectTab(privateRoutes.courses.getRouteWithParams())
                }
                text={<pre>{t("news:courses.title")}</pre>}
                iconName={"bannerFormazione"}
              />
            </div>
          </SwiperSlide>
        )}
      </Swiper>
      {/* <Banner onClick={() => selectTab(privateRoutes.performances.getRouteWithParams())} text="Performance" iconName={"bannerPerformance"}/> */}
    </div>
  )
}

export { NewsBanners }

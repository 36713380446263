import classNames from "classnames"
import { DivProps } from "@ui/types"

interface Props extends DivProps {
  text: string
}
const PageTitle = ({ text, className, ...rest }: Props) => {
  return (
    <div
      className={classNames(
        "font-quattrocento text-2xl leading-[1.33] text-dark font-semibold",
        className
      )}
      {...rest}
    >
      {text}
    </div>
  )
}

export default PageTitle

import { buffers, eventChannel } from "redux-saga"
import { put, take, cancelled, takeLatest, call } from "typed-redux-saga"
import { getSagaContext } from "../../../state/context"
import { MessagingChannelEntity } from "../../../../database/messaging/channel/channel.entity"
import { normalizeMessagingChannelEntity } from "../../../../database/messaging/channel/channel.converter"

export function* watchManagerChannelsSaga() {
  const state = yield* getSagaContext("state")
  const firebase = yield* getSagaContext("firebase")
  const channel = eventChannel<MessagingChannelEntity[]>((emitter) => {
    const unsubscribe = firebase.collections.channels.subscribeManagerChannels(
      (data) => {
        emitter(data.map(normalizeMessagingChannelEntity))
      }
    )
    return () => unsubscribe()
  }, buffers.sliding(1))
  try {
    yield* takeLatest(
      state.messaging.actions.watchChannels.fulfill,
      function* () {
        channel.close()
      }
    )
    while (true) {
      const data = yield* take(channel)
      yield* put(state.messaging.actions.watchChannels.success(data))
    }
  } catch (error) {
    yield* put(state.messaging.actions.watchChannels.failure(error))
  } finally {
    if (yield* cancelled()) {
      channel.close()
    }
  }
}

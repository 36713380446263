import { RootState } from "@modules/store/redux/rootReducer"
import { createSelector as cs } from "@reduxjs/toolkit"
import { selectCourseProgressMap, selectCourses } from "./selectors/lists"

export const selectCourseId = ({ courses }: RootState) =>
  courses.selectedCourseId

export const selectCourse = cs(
  selectCourseId,
  selectCourses,
  (id, courses) => courses[id ?? ""]
)

export const selectCourseProgress = cs(
  selectCourseId,
  selectCourseProgressMap,
  (id, progress) => progress[id ?? ""]
)

export const selectViewIndex = cs(selectCourseProgress, (p) =>
  p?.status === "completed" ? p.viewIndex + 1 : p?.viewIndex ?? 0
)

export const selectExistsProgress = ({ courses }: RootState) =>
  courses.coursesProgress[courses.selectedCourseId ?? ""] != null

export const selectIsCourseCompleted = ({ courses }: RootState) =>
  courses.coursesProgress?.[courses.selectedCourseId ?? ""]?.status ===
    "completed" ?? false

export const selectCourseHasAttachments = ({ courses }: RootState) =>
  (courses.courses?.[courses.selectedCourseId ?? ""]?.data.attachments.items
    .length ?? 0) > 0

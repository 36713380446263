import {
  Firebase,
  FirestoreController,
} from "../../../infrastructure/firebase/controller"
import { Entity } from "../../../infrastructure/firebase/types"
import { CalendarReservationCancellation } from "./calendar-reservation-cancellation.entity"
import { orderBy, query, where } from "firebase/firestore"

export class CalendarReservationCancellationController extends FirestoreController<CalendarReservationCancellation> {
  constructor(firebase: Firebase) {
    super(firebase, "CalendarReservationCancellationRepo")
  }

  subscribeCalendarReservationCancellations(
    calendarIds: string[],
    callback: (items: Entity<CalendarReservationCancellation>[]) => void
  ) {
    if (calendarIds.length <= 10) {
      return this.subscribeQuery(
        (collection) =>
          query(
            collection,
            ...[where("calendarId", "in", calendarIds)],
            orderBy("date", "desc"),
            orderBy("startHour"),
            orderBy("startMinute")
          ),
        callback
      )
    }

    return this.subscribeCollection((items) =>
      callback(
        items
          .filter((x) => calendarIds.includes(x.data.calendarId))
          .sort((a, b) => {
            if (a.data.date < b.data.date) {
              return 1
            }
            if (a.data.date > b.data.date) {
              return -1
            }
            if (a.data.startHour < b.data.startHour) {
              return 1
            }
            if (a.data.startHour > b.data.startHour) {
              return -1
            }
            if (a.data.startMinute < b.data.startMinute) {
              return 1
            }
            if (a.data.startMinute > b.data.startMinute) {
              return -1
            }
            return 0
          })
      )
    )
  }
}

import {
  Firebase,
  FirestoreController,
} from "../../../infrastructure/firebase/controller"
import { Entity } from "../../../infrastructure/firebase/types"
import { CoursePdfPage } from "./course-attachment-page.entity"

export class CourseAttachmentPagesController extends FirestoreController<CoursePdfPage> {
  constructor(firebase: Firebase) {
    super(firebase, "CourseAttachmentPagesRepo")
  }

  subscribeAll(callback: (items: Entity<CoursePdfPage>[]) => void) {
    return this.subscribeCollection(callback)
  }
}

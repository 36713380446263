import { takeLatest } from "typed-redux-saga"
import { getSagaContext } from "../../../state/context"
import { performanceRoutines } from "../state"
import { watchAppUsageData } from "./watchAppUsageData"
import { watchStoreSalesSaga } from "./watchStoreSalesSaga"
import { watchStoreSalesProgressSaga } from "./watchStoreSalesProgressSaga"

export function* performancesRootSaga() {
  const s = yield* getSagaContext("state")

  yield* takeLatest(s.performance.actions.selectStore, watchStoreSalesSaga)
  yield* takeLatest(
    s.performance.actions.selectStore,
    watchStoreSalesProgressSaga
  )
  yield* takeLatest(
    performanceRoutines.watchAppUsage.trigger,
    watchAppUsageData
  )
}

import { useTranslations } from "@modules/app/hooks/useTranslations"
import { useRootSelector } from "@modules/store/redux"
import { ObjectiveItem } from "@package/common/database/performance/objective-item/objective-item.entity"
import { selectStoreSalesItemsGroupedByType } from "../selectors"
import { ObjectiveBoxes } from "./ObjectiveBoxes"
import { roundDecimals } from "@utils/numbers"

const toObjectiveBoxData = (item: ObjectiveItem) => ({
  title: item.brandName,
  typology: item.description,
  // currentPercentage: item.progressPercent,
  currentPercentage:
    item.budgetTotal > 0
      ? roundDecimals((item.progressTotal / item.budgetTotal) * 100, 2)
      : 0,
  currentValue: item.progressTotal,
  maxValue: item.budgetTotal,
  dueDate: new Date(item.endDate),
})

export const ObjectiveBoxesGrid = () => {
  const items = useRootSelector(selectStoreSalesItemsGroupedByType)
  const { t } = useTranslations()

  return (
    <div>
      {items.map((x) => (
        <ObjectiveBoxes
          title={`${t("performance:objectives.prefix")} - ${x.title}`}
          key={x.id}
          className="my-4"
          items={x.items.map(toObjectiveBoxData)}
        />
      ))}
    </div>
  )
}

import { call, put } from "typed-redux-saga"
import { getSagaContext } from "@modules/store/redux/sagaContext"
import { authActions } from "../state"
import { fetchUserData } from "./utils/fetchUser"

export function* loginSaga({
  payload,
}: ReturnType<typeof authActions.login.trigger>) {
  const firebase = yield* getSagaContext("firebase")
  const routine = authActions.login
  try {
    yield* call(firebase.login, payload)
    const user = yield* call(fetchUserData, firebase)

    yield* put(routine.success(user))
  } catch (error) {
    const errorCode = (error as any)?.code
    console.log("authError", errorCode)
    switch (errorCode) {
      case "auth/user-not-found":
        yield* put(routine.failure("invalidUser"))
        break
      case "auth/wrong-password":
        yield* put(routine.failure("invalidPassword"))
        break
      case "auth/too-many-requests":
        yield* put(routine.failure("tooManyRequests"))
        break
      default:
        yield* put(routine.failure("other"))
    }
  }
}

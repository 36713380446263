import { IonHeader, IonToolbar } from "@ionic/react"
import { useRouting } from "@root/routing/useRouting"
import { Header } from "@molecules"
import React, { ReactNode } from "react"

interface Props {
  children: ReactNode
  hideArrow?: boolean
}

const MessagingDetailHeader = ({ children, hideArrow }: Props) => {
  const { goBack } = useRouting()
  return (
    <IonHeader className="ion-no-border border-b-[1px] border-solid border-grey">
      <IonToolbar>
        <Header showArrow={!hideArrow} onClick={() => goBack()}>
          {children}
        </Header>
      </IonToolbar>
    </IonHeader>
  )
}

export default MessagingDetailHeader

import {
  createUserProfileSlice,
  userProfileRoutines,
} from "@common/common/frontend/features/profile/state/state"

interface ExtraProfileState {}

const slice = createUserProfileSlice<ExtraProfileState>({})

const {
  name: profileName,
  actions: sliceActions,
  reducer: profileReducer,
} = slice

const profileActions = {
  ...sliceActions,
  ...userProfileRoutines,
}

export { profileName, profileReducer, profileActions }

import { normalizeChannelMessage } from "../channel/channel.converter"
import { ChannelPreviewEntity } from "./channel-preview.entity"

export const normalizeChannelPreviewEntity = (
  item: ChannelPreviewEntity
): ChannelPreviewEntity => ({
  ...item,
  data: {
    ...item.data,
    lastMessageTime: item.data.lastMessageTime?.toMillis(),
    lastMessage: item.data.lastMessage
      ? normalizeChannelMessage(item.data.lastMessage)
      : undefined,
  },
})

import {
  createCalendarsSlice,
  calendarRoutines,
  createCalendarSliceContext,
} from "@common/common/frontend/features/calendar/state"

interface ExtraCalendarState {}

export const calendarSlice = createCalendarsSlice<ExtraCalendarState>({})

const {
  name: calendarName,
  actions: sliceActions,
  reducer: calendarReducer,
} = calendarSlice

const calendarActions = {
  ...sliceActions,
  ...calendarRoutines,
}
const calendarSliceContext = createCalendarSliceContext(calendarSlice)

export { calendarName, calendarReducer, calendarActions, calendarSliceContext }

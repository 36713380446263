import { truncateDecimals } from "@utils/numbers"
import classNames from "classnames"

type BaseProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "class" | "className" | "color" | "children"
>

interface Props extends BaseProps {
  value: number | { current: number; total: number }
  classes?: {
    circle?: string
    progress?: string
    container?: string
    text?: string
  }
}

//variables
const circleStrokeWidth = 6
const progressStrokeWidth = 6

//calculations (do not touch)
const centerX = 50
const centerY = 50
const radius =
  50 -
  (progressStrokeWidth > circleStrokeWidth
    ? progressStrokeWidth
    : progressStrokeWidth) /
    2
const circumference = radius * 2 * Math.PI
const viewbox = `0 0 ${centerX * 2} ${centerY * 2}`

const ProgressCircle = ({ value, classes, ...rest }: Props) => {
  const progress =
    typeof value === "number"
      ? value
      : value.total > 0
      ? truncateDecimals(value.current / value.total, 2)
      : 0

  if (isNaN(progress)) {
    return null
  }

  return (
    <div
      className={classNames(
        "inline-flex items-center justify-center overflow-hidden relative min-w-[2rem] min-h-[2rem]",
        classes?.container
      )}
      {...rest}
    >
      <svg
        className="inset-0 absolute rotate-[-130deg] origin-center"
        viewBox={viewbox}
      >
        <circle
          className={classNames("text-grey stroke-grey", classes?.circle)}
          strokeWidth={circleStrokeWidth}
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx={centerX}
          cy={centerY}
        />
        <circle
          className={classNames(
            "transition-[stroke-dashoffset] duration-1000 ",
            classes?.progress
          )}
          strokeWidth={progressStrokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - progress * circumference}
          strokeLinecap="square"
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx={centerX}
          cy={centerY}
        />
      </svg>
      <div className={classNames(classes?.text)}>
        {typeof value === "number"
          ? `${Math.round(value * 100)}%`
          : `${value.current}/${value.total}`}
      </div>
    </div>
  )
}

export { ProgressCircle }

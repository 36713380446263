export const truncateDecimals = (value: number, decimalPlaces: number) => {
  const str = value.toFixed(decimalPlaces)
  return parseFloat(str)
}

export const roundDecimals = (value: number, decimalPlaces: number) => {
  const factor = 10 ** decimalPlaces
  const roundedValue = Math.floor(value * factor + 0.5) / factor
  return parseFloat(roundedValue.toFixed(decimalPlaces))
}

export const calculateAvg = (values: number[]) => {
  if (values.length === 0) return 0
  const sum = values.reduce((acc, val) => acc + val, 0)
  return sum / values.length
}

import { takeLatest, takeEvery, fork } from "typed-redux-saga"
import { watchChatPreviewsPreviewsSaga } from "./watchChatPreviewsPreviewsSaga"
import { watchChannelsPreviewsSaga } from "./watchChannelsPreviewsSaga"
import { watchManagerChannelsSaga } from "./watchManagerChannelsSaga"
import { watchManagerUsersSaga } from "./watchManagerUsersSaga"
import { watchChannelMessages } from "./watchChannelMessages"
import { fetchChannelMessagesPage } from "./fetchChannelMessagePage"
import { watchChatMessages } from "./watchChatMessages"
import { fetchChatMessagesPage } from "./fetchChatMessagePage"
import { sendBroadcastMessage } from "./sendBroadcastMessage"
import { sendChannelMessageSaga } from "./sendChannelMessage"
import { sendChatMessageSaga } from "./sendChatMessage"
import { draftAttachFilesSaga } from "./draftAttachFilesSaga"
import { sendChatReadNotification } from "./sendChatReadNotification"
import { sendChannelReadNotification } from "./sendChannelReadNotification"
import { getSagaContext } from "../../../state/context"
import { watchUserChannelKey } from "./watchUserChannelKeys"
import { watchUserKey } from "./watchUserKey"
import { PayloadAction } from "@reduxjs/toolkit"
import { WatchUserKeysInput } from "../state/types"

function* watchKeys(payload: PayloadAction<WatchUserKeysInput>) {
  yield* fork(watchUserChannelKey, payload)
  yield* fork(watchUserKey, payload)
}

export function* messagingRootSaga() {
  const state = yield* getSagaContext("state")
  yield* takeLatest(state.messaging.actions.watchUserKeys.trigger, watchKeys)

  yield* takeLatest(
    state.messaging.actions.watchUsers.trigger,
    watchManagerUsersSaga
  )
  yield* takeLatest(
    state.messaging.actions.watchChannels.trigger,
    watchManagerChannelsSaga
  )
  // yield* takeLatest(
  //   state.messaging.actions.watchUsers.trigger,
  //   watchManagerUsersSaga
  // )
  yield* takeLatest(
    state.messaging.actions.watchChannelPreviews.trigger,
    watchChannelsPreviewsSaga
  )
  yield* takeLatest(
    state.messaging.actions.watchChatPreviews.trigger,
    watchChatPreviewsPreviewsSaga
  )
  yield* takeEvery(
    state.messaging.actions.channelMessagesSubscribe,
    watchChannelMessages
  )
  yield* takeLatest(
    state.messaging.actions.channelMessagesLoadMore,
    fetchChannelMessagesPage
  )
  yield* takeLatest(
    state.messaging.actions.sendChannelReadNotification,
    sendChannelReadNotification
  )

  yield* takeEvery(
    state.messaging.actions.chatMessagesSubscribe,
    watchChatMessages
  )
  yield* takeLatest(
    state.messaging.actions.chatMessagesLoadMore,
    fetchChatMessagesPage
  )
  yield* takeLatest(
    state.messaging.actions.outboxDraftSendTrigger,
    sendBroadcastMessage
  )
  yield* takeLatest(
    state.messaging.actions.channelDraftSendTrigger,
    sendChannelMessageSaga
  )
  yield* takeLatest(
    state.messaging.actions.chatSendReadNotification,
    sendChatReadNotification
  )
  yield* takeLatest(
    state.messaging.actions.chatDraftSendTrigger,
    sendChatMessageSaga
  )
  yield* takeEvery(
    state.messaging.actions.draftUploadFiles,
    draftAttachFilesSaga
  )
}

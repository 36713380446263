import { useRouting } from "@root/routing/useRouting"
import { selectCourseById } from "@features/courses/state/selectors"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import PlayButton from "../../detail/PlayButton"
import { getCourseThumbUrl } from "../../utils"
import CourseChip from "../CourseChip"
import CourseTime from "../CourseTime"

type Props = {
  courseId: string
}

const Box = ({ courseId }: Props) => {
  const selector = useMemo(() => selectCourseById(courseId), [courseId])
  const course = useSelector(selector)
  const { goTo } = useRouting()
  const thumb = course ? getCourseThumbUrl(course) : undefined

  return (
    <div
      className="cursor-pointer w-[290px]"
      onClick={() => goTo("courseDetail", { params: { courseId } })}
    >
      <div
        className="w-[290px] h-[156px] rounded-[8px] bg-grey bg-cover bg-center pt-[6px] px-[10px] mb-[18px]"
        style={{ backgroundImage: `url(${thumb})` }}
      >
        <CourseChip className="flex" info={course.data.info} />
      </div>
      <div className="flex gap-[16px] min-h-[60px]">
        <div className="w-[40px] h-[40px]">
          <PlayButton />
        </div>
        <div className="flex flex-col justify-between text-left max-w-[calc(100%-56px)]">
          <div className="truncate">{course.data.info.title}</div>
          <div className="text-sm text-light leading-none">
            di {course.data.info.brandName}
          </div>
          <div className="mt-2">
            <CourseTime info={course.data.info} />
          </div>
        </div>
      </div>
    </div>
  )
}

export { Box }

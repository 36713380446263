import { takeLatest } from "typed-redux-saga"
import { deploymentsSlice } from "../store"
import { updatesCheckSaga } from "./updatesCheckSaga"
import { liveUpdateExecuteSaga } from "./liveUpdateExecuteSaga"
import { switchChannelSaga } from "./switchChannelSaga"

export function* deploymentsRootSaga() {
  yield* takeLatest(
    deploymentsSlice.actions.updatesCheckTrigger,
    updatesCheckSaga
  )

  yield* takeLatest(
    deploymentsSlice.actions.liveUpdateTrigger,
    liveUpdateExecuteSaga
  )

  yield* takeLatest(
    deploymentsSlice.actions.switchChannelTrigger,
    switchChannelSaga
  )
}

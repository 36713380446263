import { ChannelController } from "@common/common/database/messaging/channel/channel.controller"
import { ChannelPreviewController } from "@common/common/database/messaging/channel-preview/channel-preview.controller"
import { ChatController } from "@common/common/database/messaging/chat/chat.controller"
import { ChatPreviewController } from "@common/common/database/messaging/chat-preview/chat-preview.controller"
import { UsersController } from "@common/common/database/company/user/users.controller"
import { Firestore } from "firebase/firestore"
import { CalendarDefinitionController } from "../../database/calendars/definition/calendar-definition.controller"
import { CalendarReservationController } from "../../database/calendars/reservation/calendar-reservation.controller"
import { CalendarReservationCancellationController } from "../../database/calendars/reservationCancellation/calendar-reservation-cancellation.controller"
import { ObjectivesAggregationController } from "../../database/performance/objectives-aggregation/objectives-aggregation.controller"
import { AppUsageAggregationController } from "../../database/tracking/app-usage-aggregation/app-usage-aggregation.controller"
import { NotificationEventsController } from "../../database/notifications/notification-event.controller"
import { AppDeploymentController } from "../../database/deployments/app-deployment.controller"
import { UserPublicKeysController } from "../../database/security/userKeys/publicKeys.controller"
import { UserPrivateKeysController } from "../../database/security/userKeys/privateKeys.controller"
import { UserChannelKeysController } from "../../database/security/userKeys/userChannelKeys.controller"
import { AppContentsController } from "../../database/contents/app/app-contents.controller"
import { UserResourcesController } from "../../database/company/userResources/user-resources.controller"
import { CourseAttachmentsController } from "../../database/contents/course-attachment/course-attachment.controller"
import { CourseAttachmentPagesController } from "../../database/contents/course-attachment-page/course-attachment-page.controller"
import { ObjectivesStoreController } from "../../database/performance/objective-store/objective-store.controller"

export class FirestoreCollections {
  public readonly channels: ChannelController
  public readonly channelPreview: ChannelPreviewController
  public readonly users: UsersController
  public readonly userResources: UserResourcesController
  public readonly chat: ChatController
  public readonly chatPreview: ChatPreviewController
  public readonly calendarDefinitions: CalendarDefinitionController
  public readonly calendarReservations: CalendarReservationController
  public readonly calendarCancellations: CalendarReservationCancellationController
  public readonly objectivesAggregationController: ObjectivesAggregationController
  public readonly objectivesStoreController: ObjectivesStoreController
  public readonly appUsageAggregationController: AppUsageAggregationController
  public readonly notificationEventsController: NotificationEventsController
  public readonly appDeploymentController: AppDeploymentController
  public readonly userPublicKeys: UserPublicKeysController
  public readonly userPrivateKeys: UserPrivateKeysController
  public readonly userChannelKeys: UserChannelKeysController
  public readonly appContents: AppContentsController
  public readonly courseAttachmentPages: CourseAttachmentPagesController
  public readonly courseAttachments: CourseAttachmentsController

  constructor(firestore: Firestore) {
    const firebase = {
      firestore,
    }
    this.channels = new ChannelController(firebase)
    this.channelPreview = new ChannelPreviewController(firebase)
    this.users = new UsersController(firebase)
    this.userResources = new UserResourcesController(firebase)
    this.chat = new ChatController(firebase)
    this.chatPreview = new ChatPreviewController(firebase)
    this.calendarDefinitions = new CalendarDefinitionController(firebase)
    this.calendarReservations = new CalendarReservationController(firebase)
    this.calendarCancellations = new CalendarReservationCancellationController(
      firebase
    )
    this.objectivesAggregationController = new ObjectivesAggregationController(
      firebase
    )
    this.objectivesStoreController = new ObjectivesStoreController(firebase)
    this.appUsageAggregationController = new AppUsageAggregationController(
      firebase
    )
    this.notificationEventsController = new NotificationEventsController(
      firebase
    )
    this.appDeploymentController = new AppDeploymentController(firebase)
    this.userPublicKeys = new UserPublicKeysController(firebase)
    this.userPrivateKeys = new UserPrivateKeysController(firebase)
    this.userChannelKeys = new UserChannelKeysController(firebase)
    this.appContents = new AppContentsController(firebase)
    this.courseAttachmentPages = new CourseAttachmentPagesController(firebase)
    this.courseAttachments = new CourseAttachmentsController(firebase)
  }
}

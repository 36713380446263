import { useMemo } from "react"
import { useSelector } from "react-redux"
import { selectChannelPreviewById } from "../selectors/preview"

export const useChannelData = (channelId: string) => {
  const selector = useMemo(
    () => selectChannelPreviewById(channelId),
    [channelId]
  )
  const channelData = useSelector(
    selector,
    (before, after) =>
      before?.entity.id === after?.entity.id &&
      before?.privateKey === after?.privateKey &&
      before?.active === after?.active
  )
  return channelData
}

import Axios from "./axios"
import Firebase from "./firebase"
import { FunctionsClient } from "@common/common/api"
import { createBrowserHistory } from "history"

const firebase = new Firebase()
const axios = new Axios(firebase.getToken)
const functions = FunctionsClient
const history = createBrowserHistory()

export { firebase, axios, functions, history }

import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit"
import { NoInfer } from "@reduxjs/toolkit/dist/tsHelpers"
import { ContactsState } from "./types"
import { completed, faulted, idle, loading } from "../../../../utils/state"
import { ContactInfo } from "../types"

const initialState: ContactsState = {
  contacts: {},
  search: {
    query: "",
    results: idle(),
  },
}

type CreateContactsSlice<T extends {} | undefined> = {
  extendState?: T
  extendExtraReducers?: (
    builder: ActionReducerMapBuilder<NoInfer<ContactsState & T>>
  ) => void
}

const createContactsSlice = <T extends {}>(prop: CreateContactsSlice<T>) =>
  createSlice({
    name: "contacts",
    initialState: {
      ...initialState,
      ...prop.extendState,
    } as ContactsState & T,
    reducers: {
      // contacts search
      contactsSearchQueryChanged: (state, action: PayloadAction<string>) => {
        state.search.query = action.payload
      },
      contactsSearchCompleted: (
        state,
        action: PayloadAction<ContactInfo[]>
      ) => {
        state.search.results = completed(action.payload)
      },
      contactsSearchError: (state, action: PayloadAction<any>) => {
        state.search.results = {
          ...faulted(action.payload),
          data: state.search.results.data,
        }
      },
      contactsSearchClear: (state) => {
        state.search = {
          query: "",
          results: idle(),
        }
      },
      // contact fetch
      contactFetchTrigger: (state, action: PayloadAction<string>) => {
        state.contacts[action.payload] = loading()
      },
      contactFetchCompleted: (state, action: PayloadAction<ContactInfo>) => {
        state.contacts[action.payload.uid] = completed(action.payload)
      },
      contactFetchError: (state, action: PayloadAction<any>) => {
        state.contacts[action.payload.uid] = {
          ...faulted(action.payload),
          data: state.contacts[action.payload.uid]?.data,
        }
      },
    },
    extraReducers: (builder) => {
      prop?.extendExtraReducers?.(builder as any)
    },
  })

export const contactsSlice = createContactsSlice({})

import { authName, AuthState } from "."
import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "@modules/store/redux/rootReducer"

export const authStateSelector = (state: RootState): AuthState =>
  state && state[authName]

const selectAuthStatus = createSelector(authStateSelector, (auth) => {
  return {
    isAuthenticated: auth.isAuthenticated,
    isAuthLoading: auth.isLoading,
    isAuthWatching: auth.isWatching,
    user: auth.user,
    impersonation: auth.impersonation,
  }
})

export { selectAuthStatus }

import { selectChannelPreviewIdList } from "@features/messaging/selectors/preview"
import { useSelector } from "react-redux"
import ChannelListItem from "../ChannelPreviewListItem"
const ChannelPreviewList = () => {
  const list = useSelector(selectChannelPreviewIdList)
  // const isLoading = useRootSelector(state => state.messaging.channelPreview.loading)
  // if (true) {
  //   return (
  //     <div className="flex items-center justify-center w-full h-full">
  //       <InlineLoader/>
  //     </div>
  //   )
  // }

  return (
    <div className="flex flex-col gap-[18px] mb-[20px]">
      {list.map((id) => (
        <ChannelListItem key={id} channelId={id} />
      ))}
    </div>
  )
}

export default ChannelPreviewList

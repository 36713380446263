import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { selectCalendarData } from "../../selectors"
import { isSameDay, format, startOfWeek, addDays, parse } from "date-fns"
import { es, it } from "date-fns/locale"
import styles from "./style.module.css"
import classNames from "classnames"
import { Swiper, SwiperSlide } from "swiper/react"
import { splitEvery } from "ramda"
import { DailyEventParams } from "@pages/private/Communication/DailyEvent"
import { useRouting } from "@root/routing/useRouting"
import { useRouteContext } from "@modules/routing/context"
type Props = {
  className?: string
  weekOffset: number
  setWeekOffset: (number: number) => void
}

type WeekDay = {
  formattedDate: string
  formattedDay: string
  dayNumber: number
  selectedDay: boolean
  hasEvents: boolean
}

const weeksSpan = 2

const HorizontalCalendar = ({
  className,
  weekOffset,
  setWeekOffset,
}: Props) => {
  const [isFirstMoved, setIsFirstMoved] = useState(false)
  const { goTo } = useRouting()
  const {
    match: {
      params: { date: rawDate },
    },
  } = useRouteContext<DailyEventParams>()
  const date = useMemo(
    () => parse(rawDate, "yyyy-MM-dd", new Date()),
    [rawDate]
  )
  const events = useSelector(selectCalendarData)

  const changeDateOrAddDays = useCallback(
    (value: number | string) => {
      let newDate: string
      if (typeof value === "number") {
        newDate = format(addDays(date, value), "yyyy-MM-dd")
      } else {
        newDate = value
      }
      goTo("calendarDailyEvent", {
        params: { date: newDate },
        action: "replace",
        direction: "none",
        animation: "none",
      })
    },
    [date]
  )

  const dates = useMemo(() => {
    const weeksDays: WeekDay[] = []
    const startWeek = startOfWeek(date, { weekStartsOn: 1 })
    for (let index = -7 * weeksSpan; index < 7 + 7 * weeksSpan; index++) {
      const day = addDays(startWeek, index + weekOffset)
      const selectedDay = isSameDay(day, date)
      const hasEvents = events.calendarInfo.some((x) => isSameDay(x.date, day))
      weeksDays.push({
        formattedDate: format(day, "yyyy-MM-dd"),
        formattedDay: format(day, "E", { locale: it }),
        dayNumber: Number.parseInt(format(day, "d")),
        selectedDay: selectedDay,
        hasEvents,
      })
    }
    return splitEvery(7, weeksDays)
  }, [date, weekOffset])
  useEffect(() => {
    setWeekOffset(0)
  }, [date])

  // const addSlides =

  console.log(dates[0][0])
  return (
    <Swiper
      spaceBetween={8}
      slidesPerView={1}
      preventInteractionOnTransition={true}
      initialSlide={weeksSpan}
      centeredSlides
      onSlideNextTransitionEnd={(e) => {
        if (isFirstMoved) {
          console.log("NEXT")
          setWeekOffset(weekOffset + 7)
          setIsFirstMoved(false)
          e.slideTo(2, 0)
        }
      }}
      onSlidePrevTransitionEnd={(e) => {
        if (isFirstMoved) {
          console.log("PREV")
          setWeekOffset(weekOffset - 7)
          setIsFirstMoved(false)
          e.slideTo(2, 0)
        }
      }}
      onSliderFirstMove={(e) => {
        setIsFirstMoved(true)
      }}
    >
      {dates.map((week) => {
        return (
          <SwiperSlide key={week[0].formattedDate}>
            <div
              className={classNames(
                className,
                "grid justify-between h-[98px]  font-medium w-full grid-flow-col"
              )}
            >
              {/* <div className="flex w-full"> */}
              {week.map((date) => {
                return (
                  <div
                    key={date.formattedDate}
                    onClick={() =>
                      !date.selectedDay &&
                      changeDateOrAddDays(date.formattedDate)
                    }
                    className={classNames(
                      "flex flex-col justify-center items-center gap-[13px] flex-1 relative cursor-pointer",
                      date.selectedDay &&
                        "after:bg-grey after:block after:absolute after:w-[45px] after:h-[full] after:top-0 after:bottom-0 after:-z-20 after:rounded-[22.5px]"
                    )}
                  >
                    <div className={classNames(date.selectedDay && "text-red")}>
                      {date.formattedDay}
                    </div>
                    <div
                      className={classNames(
                        "relative flex justify-center items-center",
                        date.hasEvents &&
                          "after:block after:-z-10 after:rounded-full after:bg-green/[0.45] after:absolute  after:h-[36px] after:w-[36px]"
                      )}
                    >
                      {date.dayNumber}
                    </div>
                  </div>
                )
              })}
              {/* </div> */}
            </div>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export { HorizontalCalendar }

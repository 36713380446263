import classNames from "classnames"
import { IonImg } from "@ionic/react"
import styles from "./Image.module.css"
type BaseProps = Omit<
  React.ComponentProps<typeof IonImg>,
  "class" | "className" | "string"
>

interface Props extends BaseProps {
  src: string
  classes?: {
    img?: string
  }
  shadowParts?: {
    image?: string
  }
}

const Image = ({ src, shadowParts, classes, ...rest }: Props) => {
  return (
    <IonImg
      className={classNames(styles.imagePart, shadowParts?.image, classes?.img)}
      src={src}
      {...rest}
    />
  )
}

export default Image

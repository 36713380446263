import { Icon } from "@atoms"
import { useChannelData } from "@features/messaging/hooks/useChannelData"
import { messagingActions } from "@features/messaging/state"
import React from "react"
import { useDispatch } from "react-redux"
import { getChannelLogo } from "@helpers/channels"
import { PreviewAvatar } from "@molecules"
import { UseMessagingModeData } from "../../common/MessageListItem/hooks/useMessagingMode"
import { UseMessagesSelectData } from "../../common/MessageListItem/hooks/useMessagesSelect"
import { classNames } from "@punks/ui-core"

interface Props {
  channelId: string
  messagingMode: UseMessagingModeData
  selectedMessages: UseMessagesSelectData
  onDeleteConfirmed: () => void
  deleteInProgress?: boolean
}

const ChannelHeader = ({
  channelId,
  messagingMode,
  selectedMessages,
  onDeleteConfirmed,
  deleteInProgress,
}: Props) => {
  const channelData = useChannelData(channelId)
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (channelData?.entity.id) {
      dispatch(
        messagingActions.channelMessagesSubscribe({
          channel: channelData.entity,
          channelPrivateKey: channelData.privateKey,
        })
      )
    }
    return () => {
      if (channelData?.entity.id) {
        dispatch(messagingActions.channelMessagesFulfill(channelData?.entity))
      }
    }
  }, [channelData?.entity.id])

  if (!channelData) {
    return <></>
  }

  return (
    <div
      className={classNames("text-black flex w-full items-center", {
        "justify-between": messagingMode.mode === "select",
      })}
    >
      {messagingMode.mode === "select" && (
        <>
          <div
            className="cursor-pointer"
            onClick={() => {
              messagingMode.setDefaultMode()
              selectedMessages.clear()
            }}
          >
            <Icon iconName={"closeX"} className="w-[28px] h-[28px]" />
          </div>
          {selectedMessages.selected.length > 0 && (
            <button
              className="cursor-pointer mr-4"
              disabled={deleteInProgress}
              onClick={onDeleteConfirmed}
            >
              <Icon iconName={"trash"} className="w-[28px] h-[28px] text-red" />
            </button>
          )}
        </>
      )}
      {messagingMode.mode === "default" && (
        <>
          <PreviewAvatar size="small" variant="outlined">
            <Icon
              iconName={getChannelLogo(channelData.entity.data.scope)}
              className="w-[24px] h-[24px]"
            />
          </PreviewAvatar>
          <div className="ml-6 text-lg font-medium">
            {channelData?.entity.data.displayName}
          </div>
        </>
      )}
    </div>
  )
}

export default ChannelHeader

import { InlineLoader, useContentContext } from "@atoms"
import { buildMessageElementId } from "@features/messaging/components/common/MessageListItem/utils"
import { useEffect, useState } from "react"

export interface MessagingPanelProps {
  isLoading: boolean
  hasMoreMessages: boolean
  children?: React.ReactNode
  totMessages?: number
  noMessagesPlaceholder: React.ReactNode
  lastMessageId?: string
  firstMessageId?: string
  onLoadMore?: () => void
}

export const MessagingPanel = ({
  hasMoreMessages,
  isLoading,
  totMessages,
  noMessagesPlaceholder,
  firstMessageId,
  lastMessageId,
  children,
  onLoadMore,
}: MessagingPanelProps) => {
  const {
    scrollToBottom,
    checkIsBottom,
    isScrollTop,
    contentElement,
    scrollToPosition,
    scrollToPositionInstant,
  } = useContentContext()
  const [oldFocusedMessageId, setOldFocusedMessageId] = useState<string>()

  useEffect(() => {
    if (oldFocusedMessageId) {
      setTimeout(() => {
        const element = document.getElementById(
          buildMessageElementId(oldFocusedMessageId)
        )
        if (element && contentElement) {
          // calcola l'altezza dell'elemento e del pannello
          const elementHeight = element.offsetHeight
          const contentHeight = contentElement.offsetHeight

          // calcola la posizione dell'elemento all'interno del pannello
          const elementTop = element.offsetTop
          const contentTop = contentElement.offsetTop

          // calcola la posizione finale di scorrimento
          const toolbarHeight = 85
          const scrollPosition =
            elementTop +
            elementHeight -
            contentHeight +
            contentTop -
            toolbarHeight

          scrollToPositionInstant(scrollPosition)

          // // esegui lo scrolling
          // contentElement.scrollTo({
          //   top: scrollPosition,
          //   left: 0,
          //   behavior: "smooth",
          // })
        }
      }, 500)
    }
    setOldFocusedMessageId(firstMessageId)
  }, [firstMessageId])

  useEffect(() => {
    const isBottom = checkIsBottom()
    if (!isBottom) {
      scrollToBottom()
    }
  }, [lastMessageId])

  useEffect(() => {
    if (isScrollTop && !isLoading && hasMoreMessages) {
      onLoadMore?.()
    }
  }, [isScrollTop])

  return (
    <div className="flex flex-col gap-primary p-primary">
      {isLoading && hasMoreMessages && (
        <div className="flex items-center justify-center w-full mb-4">
          <InlineLoader />
        </div>
      )}
      {!isLoading && totMessages === 0 ? (
        <div className="flex items-center justify-center w-full h-full">
          {noMessagesPlaceholder}
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  )
}

import { takeLatest } from "typed-redux-saga"
import { appActions } from ".."
import { checkIntroCarouselSaga } from "./checkIntroCarousel"
import { appLoadSaga } from "./load"

export function* appRootSaga() {
  yield* takeLatest(appActions.load.TRIGGER, appLoadSaga)
  yield* takeLatest(
    appActions.checkIntroCarousel.TRIGGER,
    checkIntroCarouselSaga
  )
}

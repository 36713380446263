import { ChannelDetailParams } from "@pages/private/Communication/ChannelDetail"
import { useRouteContext } from "@modules/routing/context"
import {
  ChannelHeader,
  ChannelMessageList,
} from "@features/messaging/components/Channel"
import {
  MessagingDetailHeader,
  Body,
  Footer,
} from "@features/messaging/components/common"
import { useMessagingMode } from "@features/messaging/components/common/MessageListItem/hooks/useMessagingMode"
import { useMessagesSelect } from "@features/messaging/components/common/MessageListItem/hooks/useMessagesSelect"
import { useEffect } from "react"
import { useChannelMessagesDelete } from "@features/messaging/hooks/useChannelMessagesDelete"
import LoaderBar from "@ui/molecules/LoaderBar"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { useChannelData } from "@features/messaging/hooks/useChannelData"
import { ChannelToolbar } from "@features/messaging/components/Channel/ChannelToolbar"
import { useChannelMode } from "@features/messaging/components/common/MessageListItem/hooks/useChannelMode"

const ChannelDetailContainer = () => {
  const { t } = useTranslations()
  const {
    match: {
      params: { channelId },
    },
  } = useRouteContext<ChannelDetailParams>()
  const channelData = useChannelData(channelId)
  const channelMode = useChannelMode(channelData?.entity)

  const messagingMode = useMessagingMode()
  const selectedMessages = useMessagesSelect()

  useEffect(() => {
    selectedMessages.clear()
  }, [channelId])

  const messagesDelete = useChannelMessagesDelete()

  const handleDelete = async () => {
    if (!channelData) {
      throw new Error(`Channel data is not loaded for channel ${channelId}`)
    }
    await messagesDelete.invoke({
      channelId: channelData.entity.data.channelId,
      messageIds: selectedMessages.selected.map((m) => m.id),
    })
    messagingMode.setDefaultMode()
  }

  return (
    <>
      <MessagingDetailHeader hideArrow={messagingMode.mode === "select"}>
        <ChannelHeader
          channelId={channelId}
          messagingMode={messagingMode}
          selectedMessages={selectedMessages}
          onDeleteConfirmed={handleDelete}
          deleteInProgress={messagesDelete.state.loading}
        />
      </MessagingDetailHeader>
      <Body
        backgroundPattern={{
          imageUrl: "/assets/images/chat-bg-pattern-2.jpeg",
          size: "320px 320px",
          opacity: 0.25,
        }}
      >
        <ChannelMessageList
          channelId={channelId}
          messagingMode={messagingMode}
          selectedMessages={selectedMessages}
        />
      </Body>
      {channelMode === "readWrite" && (
        <Footer noRounding>
          <ChannelToolbar channelId={channelId} />
        </Footer>
      )}
      <LoaderBar show={messagesDelete.state.loading}>
        {t("messaging:channel.messages.deleting")}
      </LoaderBar>
    </>
  )
}

export { ChannelDetailContainer }

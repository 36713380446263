import { Notification, PreviewAvatar, TextPreview } from "@molecules"
import { format, isSameDay, isSameYear } from "date-fns"
import { ReactNode } from "react"
import { ReadConfirmation, ReadStatus } from "../ReadConfirmation"

interface Props {
  onClick: () => void
  title: string
  lastMessage?: string
  lastMessageTime?: number
  noMessagesPlaceholder: string
  notifications?: number
  readStatus?: ReadStatus
  circleNode?: ReactNode
  avatarImgUrl?: string
  avatarColor?: string
}

const formatMessageTime = (time: number) => {
  if (!isSameYear(time, Date.now())) {
    return format(time, "dd/MM/yyyy")
  }

  if (!isSameDay(time, Date.now())) {
    return format(time, "dd/MM - HH:mm")
  }

  return format(time, "HH:mm")
}

const PreviewListItem = ({
  onClick,
  title,
  lastMessage,
  lastMessageTime,
  noMessagesPlaceholder,
  notifications,
  readStatus,
  circleNode,
  avatarColor,
  avatarImgUrl,
}: Props) => {
  const isEmpty = !lastMessage && !lastMessageTime
  return (
    <div
      onClick={onClick}
      className="flex px-primary gap-[14px] cursor-pointer"
    >
      <PreviewAvatar
        size="medium"
        variant="filled"
        backgroundImageUrl={avatarImgUrl}
        backgroundColor={avatarColor}
      >
        {circleNode}
      </PreviewAvatar>
      <div className="flex-1 leading-[1.5] overflow-hidden">
        <div className="grid gap-x-[14px] grid-cols-[1fr_95px] grid-flow-col grid-rows-1 items-center">
          <div className="flex items-center gap-[6px]">
            <div>{title}</div>
            {notifications != null && notifications > 0 && (
              <Notification count={notifications} />
            )}
          </div>
          <div className="text-right whitespace-nowrap">
            {lastMessageTime && formatMessageTime(lastMessageTime as number)}
          </div>
        </div>
        <div className="grid gap-x-[14px] grid-cols-[1fr_32px] grid-flow-col grid-rows-1 items-center">
          <TextPreview
            htmlContent={!isEmpty ? lastMessage ?? "" : noMessagesPlaceholder}
          />

          <div>
            <ReadConfirmation readStatus={readStatus} />
          </div>
        </div>
      </div>
    </div>
  )
}

export { PreviewListItem }

import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "@modules/store/redux/rootReducer"
import { rootSelector } from "@modules/store/redux"
import { AppState } from "./types"
import { liveUpdateEnabled } from "@modules/deployments/features"

export const appStateSelector = (state: RootState): AppState =>
  state && state.app

export const selectIsFullscreen = createSelector(appStateSelector, (app) => {
  return app.isFullscreen
})

export const selectIsAppInitialized = createSelector(
  rootSelector,
  ({ app, deployments, auth }) => {
    const isInitialized =
      app.isInitialized && deployments.initialCheckCompleted && auth.initialized

    console.log(
      `APP initialized -> ${app.isInitialized} - ${deployments.initialCheckCompleted} - ${auth.initialized}`
    )

    return isInitialized
  }
)

export const selectIsAppLoading = createSelector(
  rootSelector,
  ({ app, auth, contents }) => {
    const isLoading = app.isloading || auth.isLoading || contents.isLoading
    // console.log(
    //   `APP isLoading -> ${app.isloading} - ${auth.isLoading} - ${contents.isLoading}`
    // )
    return isLoading
  }
)

export const selectAppLanguage = createSelector(
  rootSelector,
  ({ app }) => app.localeId
)

import { ReceiverInfo } from "@common/common/database/messaging/chat-preview/chat-preview.entity"
import { useUserTypeLabel } from "./useUserTypeLabel"

export const useUserPreviewLabel = () => {
  const { getUserTypeLabel } = useUserTypeLabel()

  const getUserLabel = (receiver: ReceiverInfo) => {
    const title = getUserTypeLabel(receiver.userType)
    switch (receiver.userType) {
      case "areaManager":
        return `${title}${
          receiver.context?.areaName ? ` - ${receiver.context.areaName}` : ""
        }`
      case "storeManager":
      case "user":
        return `${title}${
          receiver.context?.storeName ? ` - ${receiver.context.storeName}` : ""
        }`
      default:
        return title
    }
  }

  return {
    getUserLabel,
  }
}

import {
  Firebase,
  FirestoreController,
} from "../../../infrastructure/firebase/controller"
import { query, where, documentId } from "firebase/firestore"
import { Entity } from "../../../infrastructure/firebase/types"
import { AppUser } from "./user.entity"

export class UsersController extends FirestoreController<AppUser> {
  constructor(firebase: Firebase) {
    super(firebase, "UsersRepo")
  }

  getUser(uid: string) {
    return this.get(uid)
  }

  async getUserProfile(uid: string) {
    const user = await this.get(uid)
    return user?.data.profile
  }

  subscribeUserProfile(
    uid: string,
    callback: (items: Entity<AppUser> | undefined) => void
  ) {
    return this.subscribeDocument(uid, (item) => {
      callback(item)
    })
  }

  subscribeAreaManagers(
    callback: (users: Entity<AppUser>[]) => void,
    areaId: string
  ) {
    return this.subscribeQuery(
      (collection) =>
        query(
          collection,
          ...[
            where("disabled", "==", false),
            where("userType", "==", "areaManager"),
            where("context.areaId", "==", areaId),
          ]
        ),
      callback
    )
  }

  subscribeStoreUsers(
    callback: (users: Entity<AppUser>[]) => void,
    areaId: string
  ) {
    return this.subscribeQuery(
      (collection) =>
        query(
          collection,
          ...[
            where("disabled", "==", false),
            where("userType", "==", "user"),
            where("context.areaId", "==", areaId),
          ]
        ),
      callback
    )
  }

  subscribeAllActiveInternalUsers(
    callback: (users: Entity<AppUser>[]) => void,
    excludedUserIds?: string[]
  ) {
    return this.subscribeQuery(
      (collection) =>
        query(
          collection,
          ...[
            where("disabled", "==", false),
            where("scope", "==", "internal"),
            ...(excludedUserIds && excludedUserIds?.length > 0
              ? [where(documentId(), "not-in", excludedUserIds)]
              : []),
          ]
        ),
      callback
    )
  }
}

import { IonItem, IonList, IonSelect, IonSelectOption } from "@ionic/react"

export type SelectOption = {
  value: string
  label: string
}

export interface SelectProps {
  defaultValue?: string
  value?: string
  onChange: (value: string) => void
  options: SelectOption[]
  label?: string
  placeholder?: string
}

const Select = ({
  onChange,
  options,
  defaultValue,
  value,
  label,
  placeholder,
}: SelectProps) => {
  return (
    <IonList>
      <IonItem>
        <IonSelect
          aria-label={label}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          onIonChange={(x) => onChange(x.target.value)}
        >
          {options.map((option) => (
            <IonSelectOption value={option.value} key={option.value}>
              {option.label}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    </IonList>
  )
}

export default Select

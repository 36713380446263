import { Storage } from "@capacitor/storage"

const currentBuildHashKey = "currentBuildHash"
const currentBuildVersionKey = "currentBuildVersion"

export const getCurrentBuildHash = async () => {
  const { value } = await Storage.get({ key: currentBuildHashKey })
  return value
}

export const setCurrentBuildHash = async (value: string) => {
  await Storage.set({ key: currentBuildHashKey, value })
}

export const clearCurrentBuildHash = async () => {
  await Storage.remove({ key: currentBuildHashKey })
}

export const getCurrentBuildVersion = async () => {
  const { value } = await Storage.get({ key: currentBuildVersionKey })
  return value
}

export const setCurrentBuildVersion = async (value: string) => {
  await Storage.set({ key: currentBuildVersionKey, value })
}

export const clearCurrentBuildVersion = async () => {
  await Storage.remove({ key: currentBuildVersionKey })
}

import { useIonToast } from "@ionic/react"
import { CourseDetailParams } from "@pages/private"
import { useRouteContext } from "@modules/routing/context"
import { useRouting } from "@root/routing/useRouting"
import {
  selectTestById,
  selectAreTestsEnabled,
  selectIsTestCompleteById,
} from "@features/courses/state/selectors/test"
import classNames from "classnames"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { coursesActions } from "../../../state"
import CompletedButton from "../CompletedButton"
import LockedButton from "../LockedButton"
import { T } from "ramda"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { Icon } from "@atoms"
import { selectCurrentViewIndex } from "@features/courses/state/selectors/lists"

interface Props {
  testId: string
}

const CourseDetailTestListItem = ({ testId }: Props) => {
  const test = useSelector(selectTestById(testId))
  const {
    match: {
      params: { courseId },
    },
  } = useRouteContext<CourseDetailParams>()
  const { goTo } = useRouting()
  const dispatch = useDispatch()
  const areTestEnabled = useSelector(selectAreTestsEnabled)
  const isTestCompleted = useSelector(selectIsTestCompleteById(testId))
  const viewIndex = useSelector(selectCurrentViewIndex)

  const [present] = useIonToast()
  const { t } = useTranslations()

  return (
    <div
      onClick={() => {
        if (areTestEnabled) {
          dispatch(coursesActions.setTestId(testId))
          goTo("testDetail", { params: { courseId, testId } })
        } else {
          present({
            message: t("courses:test.locked.message"),
            duration: 3000,
          })
        }
      }}
      className={classNames(
        "p-[12px] flex gap-[16px] border border-grey rounded-[8px]"
      )}
    >
      <div className="w-[54px] h-[54px] rounded-[8px] bg-grey flex items-center justify-center">
        <Icon iconName="shield" className="text-[32px]" />
      </div>
      <div className="flex-1 ">
        <div className="font-bold leading-normal break-all text-md">
          {`${test.title}`}
        </div>
        <div className="text-sm leading-normal break-all text-light">
          {`${t("courses:test.questions.title")}: ${test.questions.length}`}
        </div>
      </div>
      <div className="">
        <div className="flex items-end gap-[6px]  text-sm leading-normal">
          {viewIndex === 0 && !areTestEnabled ? (
            <LockedButton
              classes={{
                container: "!w-[30px] !h-[30px] !border-0",
              }}
            />
          ) : (
            <>
              {(isTestCompleted || viewIndex > 0) && (
                <CompletedButton
                  classes={{
                    container:
                      "!w-[20px] !h-[20px] !border-0 bg-red text-white",
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(CourseDetailTestListItem)

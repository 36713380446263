import { getSagaContext } from "@modules/store/redux/sagaContext"
import { call, put } from "typed-redux-saga"
import { deploymentsSlice } from "../store"
import {
  isAndroidDevice,
  isIosDevice,
  isWebPlatform,
} from "@modules/app/utils/device"
import { getDeploymentChannelId } from "@modules/deployments/features"
import { getCurrentBuildHash } from "@modules/deployments/services/buildVersion"
import { isVersionGreater } from "@modules/deployments/utils/versionComparer"
import { getAppInfo } from "@modules/device/info"

export function* updatesCheckSaga() {
  try {
    console.log(`APP UPDATE -> check started`)

    const firebase = yield* getSagaContext("firebase")

    // channels
    const channels = yield* call(() =>
      firebase.collections.appDeploymentController.getChannels()
    )
    yield* put(deploymentsSlice.actions.setChannels(channels))

    console.log(`APP UPDATE -> channels loaded`, channels)
    const selectedChannelId = yield* call(getDeploymentChannelId)
    let channel = channels.find((c) => c.data.channelId === selectedChannelId)
    if (!channel) {
      channel = channels[0]
      console.error(
        `APP UPDATE -> no channel found for ${selectedChannelId} -> Using default channel`,
        channel
      )
    }

    if (!channel) {
      throw new Error(`No matching channel found for ${selectedChannelId}`)
    }

    yield* put(deploymentsSlice.actions.setChannel(channel))
    console.log(`APP UPDATE -> using channel ${channel.id}`, channel)

    // check if live updates are enabled
    if (isWebPlatform()) {
      yield* put(deploymentsSlice.actions.setLiveUpdateEnabled(false))
      deploymentsSlice.actions.setUpdateStatus("updates-disabled")
      console.log("APP UPDATE -> live update disabled: web platform")
      return
    }

    if (!channel.data.autoUpdateEnabled) {
      yield* put(deploymentsSlice.actions.setLiveUpdateEnabled(false))
      deploymentsSlice.actions.setUpdateStatus("updates-disabled")
      console.log("APP UPDATE -> live update disabled: switch disabled")
      return
    }

    // check current app version
    const buildHash = yield* call(getCurrentBuildHash)
    if (buildHash) {
      yield* put(deploymentsSlice.actions.setCurrentBuildHash(buildHash))
      console.log(`APP UPDATE -> current build hash ${buildHash}`)
    }

    // check if android update is required
    const appInfo = yield* call(getAppInfo)
    if (isAndroidDevice()) {
      yield* put(
        deploymentsSlice.actions.setCurrentAndroidAppVersion(appInfo.version)
      )

      if (
        channel.data.androidUpdateEnabled &&
        channel.data.androidVersion?.data.version &&
        isVersionGreater(
          channel.data.androidVersion.data.version,
          appInfo.version
        )
      ) {
        console.log(
          `APP UPDATE -> android app update required from ${appInfo.version} to ${channel.data.androidVersion.data.version}`
        )
        yield* put(
          deploymentsSlice.actions.setUpdateStatus("play-store-update-required")
        )
        return
      } else {
        console.log(
          `APP UPDATE -> android app version satisfied ${appInfo.version} - min required ${channel.data.androidVersion?.data.version}`
        )
      }
    }

    if (isIosDevice()) {
      yield* put(
        deploymentsSlice.actions.setCurrentIosAppVersion(appInfo.version)
      )

      if (
        channel.data.iosUpdateEnabled &&
        channel.data.iosVersion?.data.version &&
        isVersionGreater(channel.data.iosVersion.data.version, appInfo.version)
      ) {
        console.log(
          `APP UPDATE -> iOS app update required from ${appInfo.version} to ${channel.data.iosVersion.data.version}`
        )
        yield* put(
          deploymentsSlice.actions.setUpdateStatus(
            "apple-store-update-required"
          )
        )
        return
      } else {
        console.log(
          `APP UPDATE -> ios app version satisfied from ${appInfo.version}`
        )
      }
    }

    // check if live update is required
    if (channel.data.current?.data.commitHash !== buildHash) {
      yield* put(
        deploymentsSlice.actions.setUpdateStatus("live-update-required")
      )
      yield* put(deploymentsSlice.actions.liveUpdateTrigger(channel))
      return
    } else {
      console.log(
        `APP UPDATE -> live update version satisfied from ${buildHash}`
      )
    }

    yield* put(deploymentsSlice.actions.setUpdateStatus("no-update-required"))
    console.log(`APP UPDATE -> no updates required`)
  } catch (e) {
    console.error("APP UPDATE -> error", e)
    yield* put(deploymentsSlice.actions.setUpdateStatus("faulted"))
  } finally {
    yield* put(deploymentsSlice.actions.updatesCheckCompleted())
    console.log(`APP UPDATE -> check completed`)
  }
}

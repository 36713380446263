import { RootState } from "@modules/store/redux/rootReducer"
import { CourseTestQuestion } from "@common/common/api/functions"
import { createSelector as cs } from "@reduxjs/toolkit"
import { last } from "ramda"
import { selectTestProgressAttemptId } from "../selectors"
import { selectTest } from "./test"

export const selectTestQuestionId = cs(
  selectTestProgressAttemptId,
  ({ courses }: RootState) => courses,
  (testAttempId, courses) => {
    if (courses.selectedTestQuestionId != null) {
      return courses.selectedTestQuestionId
    }
    const progressQuestionId = last(
      Object.keys(
        courses.coursesProgress?.[courses.selectedCourseId!]?.progress.tests?.[
          courses.selectedTestId!
        ]?.attempts[testAttempId!]?.answers ?? {}
      )
        .map((questionId) => {
          const index =
            courses.courses[courses.selectedCourseId!]?.data?.tests
              ?.find((test) => test.id === courses?.selectedTestId!)
              ?.questions?.findIndex(
                (question) => question.id === questionId
              ) ?? 0
          return {
            questionId,
            index,
          }
        })
        .sort((a, b) => a.index - b.index)
    )?.questionId
    return (
      progressQuestionId ??
      courses.courses?.[courses.selectedCourseId!]?.data?.tests?.find(
        (x) => x.id === courses?.selectedTestId!
      )?.questions?.[0]?.id
    )
  }
)

export const selectTestQuestion = cs(
  selectTest,
  selectTestQuestionId,
  (test, questionId) => test.questions?.find((x) => x.id == questionId)
)

export const selectTestQuestionById = (testQuestionId: string) =>
  cs(
    selectTest,
    (test) => test.questions?.find((x) => testQuestionId === x.id)!
  )

export const selectNextTestQuestionById = (testQuestionId: string) =>
  cs(selectTest, (test) => {
    const currentIndex = test.questions?.findIndex(
      (x) => x.id === testQuestionId
    )
    return (
      (test.questions?.[currentIndex + 1] as CourseTestQuestion) || undefined
    )
  })

export const selectPreviousTestQuestionById = (testQuestionId: string) =>
  cs(selectTest, (test) => {
    const currentIndex = test.questions?.findIndex(
      (x) => x.id === testQuestionId
    )
    return (
      (test.questions?.[currentIndex + -1] as CourseTestQuestion) || undefined
    )
  })

export const selectTestQuestionProgressById =
  (attemptId: string, questionId: string) =>
  ({ courses }: RootState) =>
    courses.coursesProgress[courses.selectedCourseId!]?.progress?.tests?.[
      courses.selectedTestId!
    ]?.attempts[attemptId]?.answers?.[questionId]

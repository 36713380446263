import { Icon } from "@atoms"
import classNames from "classnames"
import { IconNames } from "@ui/atoms/Icon"

type Props = {
  showArrow?: boolean
  text: string
  classes?: {
    text?: string
  }
  onClick?: () => void
  iconName: IconNames
}

const ListItem = ({ showArrow, onClick, iconName, text, classes }: Props) => {
  return (
    <div
      onClick={onClick}
      className={`flex items-center gap-[15px] h-[74px] border-b border-b-light ${
        onClick ? "cursor-pointer" : ""
      }`}
    >
      <Icon className="w-[24px] h-[24px]" iconName={iconName} />
      <div className={classNames("flex-1 ", classes?.text)}>{text}</div>
      {showArrow && (
        <Icon iconName="arrowForward" className="text-red w-[18px] h-[18px]" />
      )}
    </div>
  )
}

export default ListItem

import IosUpdateContainer from "@containers/update/IosUpdateContainer"
import PageRoot from "@root/pages/PageRoot"

const AppleStoreUpdate = () => {
  return (
    <PageRoot fullscreen>
      <IosUpdateContainer />
    </PageRoot>
  )
}

export default AppleStoreUpdate

import { NotificationEntry } from "@common/common/frontend/features/notifications/state/types"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { notificationsActions } from "../state"

export const useNotificationsReadMark = (
  unreadNotifications: NotificationEntry[] | undefined,
  options: {
    delayMs?: number
  }
) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (unreadNotifications?.length) {
      setTimeout(
        () =>
          dispatch(
            notificationsActions.notificationsMarkRead({
              notificationIds: unreadNotifications?.map(
                (x) => x.id
              ) as string[],
            })
          ),
        options.delayMs ?? 0
      )
    }
  }, [unreadNotifications])
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SearchUsersInput = {
    limit: number | null;
    orderByField: SearchUsersInput.orderByField;
    orderByDirection: SearchUsersInput.orderByDirection;
};

export namespace SearchUsersInput {

    export enum orderByField {
        CREATION = 'creation',
        NAME = 'name',
    }

    export enum orderByDirection {
        ASC = 'asc',
        DESC = 'desc',
    }


}


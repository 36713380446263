import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import { NoInfer } from "@reduxjs/toolkit/dist/tsHelpers"
import { createRoutine } from "redux-saga-routines"
import { AppUsageAggregation } from "../../../../database/tracking/app-usage-aggregation/app-usage-aggregation.entity"
import { completed, empty, failure, loading } from "../../../../utils/state"
import { getSliceContextCreator } from "../../../state/context"
import {
  PerformancesState,
  StoreRef,
  StoreWatchRef,
  WatchAppUsageInput,
  WatchStoreSalesProgressResult,
  WatchStoreSalesResult,
} from "./types"

const watchStoreSales = createRoutine("PERFORMANCES|WATCH_STORE_SALES", {
  trigger: (payload: StoreWatchRef) => payload,
  success: (payload: WatchStoreSalesResult) => payload,
})

const watchStoreSalesProgress = createRoutine(
  "PERFORMANCES|WATCH_STORE_SALES_PROGRESS",
  {
    trigger: (payload: StoreWatchRef) => payload,
    success: (payload: WatchStoreSalesProgressResult) => payload,
  }
)

const watchAppUsage = createRoutine("PERFORMANCES|WATCH_APP_USAGE", {
  trigger: (payload: WatchAppUsageInput) => payload,
  success: (payload: AppUsageAggregation[]) => payload,
})

const initialState: PerformancesState = {
  storeSales: empty(),
  storesSalesProgress: empty(),
  appUsage: empty(),
}

type PerformancesSlice<T extends {} | undefined> = {
  extendState?: T
  extendExtraReducers?: (
    builder: ActionReducerMapBuilder<NoInfer<PerformancesState & T>>
  ) => void
}

const createPerformancesState = <T extends {}>(prop: PerformancesSlice<T>) => {
  return createSlice({
    name: "performances",
    initialState: {
      ...initialState,
      ...prop.extendState,
    } as PerformancesState & T,
    reducers: {
      selectStore(state, action: PayloadAction<StoreRef>) {},
    },
    extraReducers: (builder) => {
      // store objectives
      builder
        .addCase(
          watchStoreSales.TRIGGER,
          (state, action: ReturnType<typeof watchStoreSales.trigger>) => {
            state.storeSales = loading()
            state.selectedStore = action.payload
          }
        )
        .addCase(watchStoreSales.FULFILL, (state) => {})
        .addCase(
          watchStoreSales.SUCCESS,
          (state, action: ReturnType<typeof watchStoreSales.success>) => {
            state.storeSales = completed(action.payload.items)
            state.selectedStore = action.payload.store
          }
        )
        .addCase(
          watchStoreSales.FAILURE,
          (state, action: ReturnType<typeof watchStoreSales.failure>) => {
            state.storeSales = failure(action.payload)
          }
        )

      // store performance
      builder
        .addCase(
          watchStoreSalesProgress.TRIGGER,
          (
            state,
            action: ReturnType<typeof watchStoreSalesProgress.trigger>
          ) => {
            state.storesSalesProgress = loading()
            state.selectedStore = action.payload
          }
        )
        .addCase(watchStoreSalesProgress.FULFILL, (state) => {})
        .addCase(
          watchStoreSalesProgress.SUCCESS,
          (
            state,
            action: ReturnType<typeof watchStoreSalesProgress.success>
          ) => {
            state.storesSalesProgress = completed(action.payload.items)
            state.selectedStore = action.payload.store
          }
        )
        .addCase(
          watchStoreSalesProgress.FAILURE,
          (
            state,
            action: ReturnType<typeof watchStoreSalesProgress.failure>
          ) => {
            state.storesSalesProgress = failure(action.payload)
          }
        )

      // app usage
      builder
        .addCase(watchAppUsage.TRIGGER, (state) => {
          state.appUsage = loading()
        })
        .addCase(watchAppUsage.FULFILL, (state) => {})
        .addCase(
          watchAppUsage.SUCCESS,
          (state, action: ReturnType<typeof watchAppUsage.success>) => {
            state.appUsage = completed(action.payload)
          }
        )
        .addCase(
          watchAppUsage.FAILURE,
          (state, action: ReturnType<typeof watchAppUsage.failure>) => {
            state.appUsage = failure(action.payload)
          }
        )
      prop?.extendExtraReducers?.(builder as any)
    },
  })
}

const performanceRoutines = {
  watchStoreSales,
  watchStoreSalesProgress,
  watchAppUsage,
}

export type PerformanceSlice = ReturnType<typeof createPerformancesState>
export type PerformanceRoutines = typeof performanceRoutines

export type PerformanceSliceContext = PerformanceSlice & {
  actions: PerformanceRoutines
}

export const createPerformanceSliceContext =
  getSliceContextCreator(performanceRoutines)

export { createPerformancesState, performanceRoutines }

import React from "react"
import { useSelector } from "react-redux"
import { selectCalendarData } from "../../selectors"
import ReactCalendar from "react-calendar"
import { isSameDay, format } from "date-fns"
import { it } from "date-fns/locale"
import styles from "./style.module.css"
import classNames from "classnames"
import { Icon } from "@atoms"
import { useRouting } from "@root/routing/useRouting"
type Props = {}

const Calendar = (props: Props) => {
  const { calendarInfo } = useSelector(selectCalendarData)
  const { goTo } = useRouting()

  return (
    <ReactCalendar
      maxDetail="month"
      minDetail="month"
      onClickDay={(e) =>
        goTo("calendarDailyEvent", {
          params: { date: format(e, "yyyy-MM-dd") },
        })
      }
      formatMonthYear={(locale, date) => {
        return format(date, "MMMM, yyyy", { locale: it })
      }}
      nextLabel={
        <div>
          <Icon iconName="rightCaret" className="w-[8px] h-[14px] text-light" />
        </div>
      }
      prevLabel={
        <div>
          <Icon iconName="leftCaret" className="w-[8px] h-[14px] text-light" />
        </div>
      }
      locale="it-IT"
      // maxDate={maxDate}
      // minDate={minDate}
      className={styles.calendar}
      tileClassName={(tile) => {
        const eventDay = calendarInfo?.find((x) => isSameDay(x.date, tile.date))
        return classNames(eventDay != null && styles.eventTile)
      }}
    />
  )
}

export { Calendar }

import { Icon } from "@atoms"
import { IconType } from "@ui/atoms/Icon"
import classNames from "classnames"

interface Props {
  selected: boolean
  iconSelected: IconType
  icon: IconType
}

const TabIcon = ({ selected, iconSelected, icon }: Props) => {
  return (
    <div>
      <Icon
        className={classNames(selected && "hidden", "w-[30px] h-[30px]")}
        iconName={icon}
      />
      <Icon
        className={classNames(!selected && "hidden", "w-[30px] h-[30px]")}
        iconName={iconSelected}
      />
    </div>
  )
}

export default TabIcon

import {
  AppUser,
  AppUserProfile,
} from "../../../../database/company/user/user.entity"
import { Entity } from "../../../../infrastructure/firebase/types"
import { ContactInfo } from "../types"

export const toContactInfo = (user: Entity<AppUser>): ContactInfo => ({
  fistName: user.data.profile.firstName,
  lastName: user.data.profile.lastName,
  avatarUrl: user.data.profile.avatarUrl,
  userType: user.data.userType,
  disabled: user.data.disabled,
  uid: user.id,
  timestamp: Date.now(),
})

import { useCommonSelector, useRootSelector } from "@modules/store/redux"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { messagingActions } from "../state"
import { useCallback, useEffect } from "react"
import { filesToAttachments } from "@common/common/frontend/features/messaging/converters/draft"
import { selectChannelDraft } from "@common/common/frontend/features/messaging/selectors/selectChannelDraft"
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera"
import { base64ToFile } from "@helpers"
import { MessageDraft } from "@common/common/frontend/features/messaging/state/types"
import { useChannelData } from "./useChannelData"

type Message = {
  text: string
  files: FileList | null
}

const isValidMessage = (data: Message, draft?: MessageDraft) => {
  if (
    (data.text?.trim() ?? "") == "" &&
    draft?.attachments.items.length === 0
  ) {
    return false
  }

  if (draft?.attachments.uploadInProgress) {
    return false
  }

  return true
}

export const useChannelToolbar = (channelId: string) => {
  const channelData = useChannelData(channelId)
  const draft = useCommonSelector((state) =>
    selectChannelDraft(state, {
      channelId: channelData?.entity?.data?.channelId,
    })
  )

  const dispatch = useDispatch()
  const uid = useRootSelector((state) => state.auth.user?.uid)
  const { register, handleSubmit, reset, watch } = useForm<Message>()
  const inputFiles = watch("files")

  useEffect(() => {
    if (inputFiles && inputFiles.length > 0) {
      if (!channelData) {
        console.error("channelData is undefined", channelId)
        return
      }
      dispatch(
        messagingActions.draftUploadFiles({
          ref: {
            id: channelData.entity.data.channelId,
            scope: "channel",
          },
          items: filesToAttachments(inputFiles),
        })
      )
      reset({ files: null })
    }

    return () => {
      // second
    }
  }, [inputFiles])

  const removeFile = useCallback(
    (fileId: string) => {
      if (!channelData) {
        console.error("channelData is undefined", channelId)
        return
      }
      dispatch(
        messagingActions.draftDetachFile({
          ref: {
            scope: "channel",
            id: channelData.entity.data.channelId,
          },
          attachmentId: fileId,
        })
      )
    },
    [channelData?.entity.data.channelId]
  )
  const onSubmit = async (data: Message) => {
    if (!channelData || !uid || !isValidMessage(data, draft?.draft)) {
      return
    }

    try {
      // dispatch(messagingActions.sendChatMessage({
      //   chatId: chatData.entity.id,
      //   message: data.text,
      //   messageId: newDocId(),
      //   privateKey: chatData.privateKey,
      //   senderId: uid,
      //   attachments: chatDraft.draft.attachments.items
      // }))
      console.log("send message")
      dispatch(
        messagingActions.channelDraftUpdateContent({
          ref: channelData.entity.data.channelId,
          content: {
            message: data.text,
          },
        })
      )
      dispatch(
        messagingActions.channelDraftSendTrigger({
          channelId: channelData.entity.data.channelId,
        })
      )
      reset({
        text: "",
      })

      return true
    } catch (e) {
      console.error("Error sending channel message", e)
    } finally {
    }
  }
  const takePicture = async () => {
    if (!channelData) {
      console.error("channelData is undefined", channelId)
      return
    }

    const image = await Camera.getPhoto({
      quality: 70,
      allowEditing: false,
      saveToGallery: true,
      promptLabelCancel: "Annulla",
      source: CameraSource.Camera,
      resultType: CameraResultType.Base64,
    })
    const file = base64ToFile(
      image.base64String!,
      `image/${image.format}`,
      new Date().getTime() + "." + image.format
    )

    dispatch(
      messagingActions.draftUploadFiles({
        ref: {
          id: channelData.entity.data.channelId,
          scope: "channel",
        },
        items: filesToAttachments([file] as unknown as FileList),
      })
    )
  }

  return {
    register,
    onSendMessage: handleSubmit(onSubmit),
    draft,
    removeFile,
    takePicture,
  }
}

import { IonModal } from "@ionic/react"
import { useEffect, useRef, useState } from "react"
import styles from "./Modal.module.css"
import { classNames } from "@punks/ui-core"

export interface ModalProps {
  open?: boolean
  onClose?: () => void
  trigger?: string
  children: React.ReactNode
  partial?: boolean
  classes?: {
    root?: string
    wrapper?: string
  }
}

const Modal = ({
  trigger,
  children,
  open,
  onClose,
  partial,
  classes,
}: ModalProps) => {
  const modal = useRef<HTMLIonModalElement>(null)
  const [currentOpen, setCurrentOpen] = useState(false)

  const present = () => modal.current?.present()
  const dismiss = () => modal.current?.dismiss()

  useEffect(() => {
    if (open && !currentOpen) {
      setCurrentOpen(true)
      present()
    }
    if (!open && currentOpen) {
      setCurrentOpen(false)
      ;(modal.current as any).canDismiss = true
      dismiss()
    }
  }, [open])

  return (
    <IonModal
      ref={modal}
      trigger={trigger}
      className={classNames(
        {
          [styles.fitContent]: partial,
        },
        classes?.root
      )}
      canDismiss={!open}
    >
      <div className={classNames(styles.wrapper, classes?.wrapper)}>
        {children}
      </div>
    </IonModal>
  )
}

export default Modal

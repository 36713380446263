import { Icon } from "@atoms"
import classNames from "classnames"
import React from "react"

interface Props {
  onClick?: () => void
  classes?: {
    container?: string
    icon?: string
  }
}
const LockedButton = ({ onClick, classes }: Props) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-center w-full h-full rounded-full bg-grey",
        classes?.container
      )}
      onClick={onClick}
    >
      <Icon iconName="locked" className="!w-[40%] !h-[40%] text-dark" />
    </div>
  )
}

export default LockedButton

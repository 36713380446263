import { takeLatest } from "typed-redux-saga"
import { userProfileRoutines } from "../state/state"
import { selfResetPassword } from "./selfResetPassword"
import { updateUserProfileImage } from "./updateUserProfileImage"
import { watchUserProfileSaga } from "./watchUserProfileSaga"
import { watchUserResourcesSaga } from "./watchUserResourcesSaga"

export function* userProfileRootSaga() {
  yield* takeLatest(
    userProfileRoutines.watchUserProfile.trigger,
    watchUserProfileSaga
  )
  yield* takeLatest(
    userProfileRoutines.watchUserResources.trigger,
    watchUserResourcesSaga
  )
  yield* takeLatest(
    userProfileRoutines.updateUserProfileImage.trigger,
    updateUserProfileImage
  )
  yield* takeLatest(
    userProfileRoutines.updateUserPassword.trigger,
    selfResetPassword
  )
}

import { PreviewListItem } from "../../common"
import { getAvatarUserColor } from "@helpers/profiles"
import { useRouting } from "@root/routing/useRouting"
import { AppUserEntity } from "@package/common/database/company/user/user.entity"
import { useChatCreate } from "@features/messaging/hooks/useChatCreate"
import { useUserPreviewLabel } from "@modules/app/hooks/useUserPreviewLabel"

interface Props {
  user: AppUserEntity
}

const ChatNewPreviewListItem = ({ user }: Props) => {
  const { goTo } = useRouting()
  const { getUserLabel } = useUserPreviewLabel()
  const { createChat } = useChatCreate()

  const handleCreateChant = async () => {
    const { chatId } = await createChat(user)
    goTo("chatDetail", { params: { chatId } })
  }

  return (
    <PreviewListItem
      onClick={handleCreateChant}
      title={[user.data.profile.firstName, user.data.profile.lastName].join(
        " "
      )}
      noMessagesPlaceholder={getUserLabel({
        userType: user.data.userType,
        profile: user.data.profile,
        context: user.data.context,
      })}
      avatarImgUrl={user.data.profile.avatarUrl}
      avatarColor={getAvatarUserColor(user.data.profile?.firstName)}
    />
  )
}

export { ChatNewPreviewListItem }

import { takeLatest, fork } from "typed-redux-saga"
import { authActions } from "../state"
import { loginSaga } from "./loginSaga"
import { logoutSaga } from "./logoutSaga"
import { passwordChangeSaga } from "./passwordChangeSaga"
import { watchRefreshToken } from "./refreshFirebaseToken"
import { watchFirebaseAuthStateSaga } from "./watchFirebaseAuthStateSaga"
import {
  startImpersonationSaga,
  stopImpersonationSaga,
} from "./impersonateSaga"

export function* authRootSaga() {
  yield* takeLatest(authActions.login.TRIGGER, loginSaga)
  yield* takeLatest(authActions.logout.TRIGGER, logoutSaga)
  yield* takeLatest(authActions.passwordChange.TRIGGER, passwordChangeSaga)
  yield* takeLatest(authActions.watch.TRIGGER, watchFirebaseAuthStateSaga)
  yield* takeLatest(authActions.setUserImpersonationUid, startImpersonationSaga)
  yield* takeLatest(
    authActions.clearUserImpersonationUid,
    stopImpersonationSaga
  )
  yield* fork(watchRefreshToken)
}

import { useTranslations } from "./useTranslations"

export type WeekDays = string[]
export interface WeekMap {
  [key: number]: string
}

const buildWeekMap = (days: string[]): WeekMap => ({
  0: days[0],
  1: days[1],
  2: days[2],
  3: days[3],
  4: days[4],
  5: days[5],
  6: days[6],
})

export const useShortWeekDays = () => {
  const { t } = useTranslations()
  const weekDays: WeekDays = [
    t("common:days.sunday.initial"),
    t("common:days.monday.initial"),
    t("common:days.tuesday.initial"),
    t("common:days.wednesday.initial"),
    t("common:days.thursday.initial"),
    t("common:days.friday.initial"),
    t("common:days.saturday.initial"),
  ]

  return {
    weekDays,
    weekMap: buildWeekMap(weekDays),
  }
}

export const useFullWeekDays = () => {
  const { t } = useTranslations()
  const weekDays: WeekDays = [
    t("common:days.sunday.full"),
    t("common:days.monday.full"),
    t("common:days.tuesday.full"),
    t("common:days.wednesday.full"),
    t("common:days.thursday.full"),
    t("common:days.friday.full"),
    t("common:days.saturday.full"),
  ]

  return {
    weekDays,
    weekMap: buildWeekMap(weekDays),
  }
}

export const useWeekDays = () => {
  const short = useShortWeekDays()
  const full = useFullWeekDays()

  return {
    short,
    full,
  }
}

import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { selectChannelPreviewById } from "@features/messaging/selectors/preview"
import { PreviewListItem } from "../../common"
import { Icon } from "@atoms"
import { getChannelLogo } from "@helpers/channels"
import { useRouting } from "@root/routing/useRouting"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { ChannelPreviewEntity } from "@package/common/database/messaging/channel-preview/channel-preview.entity"

interface Props {
  channelId: string
}

const getMessagePreviewText = (chat: ChannelPreviewEntity) => {
  // if (chat?.data?.lastMessage?.m) {
  //   return chat?.data?.lastMessage.m
  // }

  if (chat?.data?.lastMessage?.a) {
    return "Media"
  }

  return ""
}

const ChannelPreviewListItem = ({ channelId }: Props) => {
  const { t } = useTranslations()
  const channelSelector = useMemo(
    () => selectChannelPreviewById(channelId),
    [channelId]
  )
  const channel = useSelector(channelSelector)
  const { goTo } = useRouting()

  if (channel == null) {
    return <></>
  }

  return (
    <PreviewListItem
      circleNode={
        <Icon
          iconName={getChannelLogo(channel.entity.data.scope)}
          className="w-[28px] h-[28px]"
        />
      }
      onClick={() => goTo("channelDetail", { params: { channelId } })}
      title={channel.entity?.data?.displayName}
      lastMessage={
        channel.lastMessage?.trim()
          ? channel.lastMessage
          : getMessagePreviewText(channel.entity)
      }
      notifications={channel.entity.data.totUnreadMessages}
      noMessagesPlaceholder={t("messaging:channel.empty.placeholder")}
      lastMessageTime={channel.lastMessageTime}
    />
  )
}

export default ChannelPreviewListItem

import { newUuid } from "../../../../utils/uid"
import { AttachFileItem } from "../state/types"

export const filesToAttachments = (files: FileList): AttachFileItem[] => {
  const items: AttachFileItem[] = []
  for (const file of files) {
    items.push({
      file,
      id: newUuid(),
    })
  }
  return items
}

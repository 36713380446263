import { InlineLoader } from "@atoms"
import { classNames } from "@punks/ui-core"

export interface LoaderBarProps {
  children: React.ReactNode
  position?: "top" | "bottom"
  absolute?: boolean
  show?: boolean
}

const LoaderBar = ({ children, position, absolute, show }: LoaderBarProps) => {
  if (show === false) {
    return <></>
  }

  return (
    <div
      className={classNames("left-0 right-0 inline-flex justify-center", {
        "bottom-[100px]": position === "bottom",
        "top-[100px]": position === "top",
        fixed: !absolute,
        absolute,
      })}
    >
      <div className="bg-white p-[12px] gap-[8px] flex items-center rounded-lg opacity-90">
        <div className="text-sm text-dark">{children}</div>
        <InlineLoader />
      </div>
    </div>
  )
}

LoaderBar.defaultProps = {
  position: "bottom",
}

export default LoaderBar

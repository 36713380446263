/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CourseImage } from './CourseImage';
import type { CourseTestQuestionAnswer } from './CourseTestQuestionAnswer';

export type CourseTestQuestion = {
    id: string;
    text: string;
    image?: CourseImage;
    questionType: CourseTestQuestion.questionType;
    answers: Array<CourseTestQuestionAnswer>;
};

export namespace CourseTestQuestion {

    export enum questionType {
        SINGLE_CHOICE = 'singleChoice',
        MULTIPLE_CHOICES = 'multipleChoices',
    }


}


import { ImChannelMessage, MessagingChannelEntity } from "./channel.entity"

export const normalizeMessagingChannelEntity = (
  item: MessagingChannelEntity
): MessagingChannelEntity => ({
  ...item,
  data: {
    ...item.data,
    lastMessageTime: item.data.lastMessageTime?.toMillis(),
  },
})

export const normalizeChannelMessage = (message: ImChannelMessage) => ({
  ...message,
  t: message.t?.toMillis(),
})

import HomeContainer from "@containers/home"
import PageRoot from "@root/pages/PageRoot"

export const HomePage = () => {
  return (
    <PageRoot>
      <HomeContainer />
    </PageRoot>
  )
}

import { AppUserEntity } from "@common/common/database/company/user/user.entity"
import { ChannelPreviewEntity } from "@common/common/database/messaging/channel-preview/channel-preview.entity"
import {
  ImChannelMessage,
  MessagingChannelEntity,
} from "@common/common/database/messaging/channel/channel.entity"
import { ChatPreviewEntity } from "@common/common/database/messaging/chat-preview/chat-preview.entity"
import { completed, empty, failure, loading } from "@common/common/utils/state"
import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit"
import { omit } from "lodash"
import { createRoutine } from "redux-saga-routines"
import {
  ChannelKey,
  ChannelMessageAppendInput,
  ChannelMessageDeleteInput,
  ChannelMessageLoadMoreInput,
  ChannelMessagesDeleteInput,
  ChannelMessageSendErrorInput,
  ChannelMessageSendTriggerInput,
  ChannelMessagesFetchError,
  ChannelMessagesSubscribeInput,
  ChannelSendReadNotificationInput,
  ChatMessageAppendInput,
  ChatMessageDeleteInput,
  ChatMessageLoadMoreInput,
  ChatMessageSendErrorInput,
  ChatMessageSendTriggerInput,
  ChatMessagesFetchError,
  ChatMessagesSubscribeInput,
  ChatSendReadNotificationInput,
  DraftAttachFilesInput,
  DraftAttachmentRemove,
  DraftAttachmentUploadedInput,
  DraftAttachmentUploadErrorInput,
  ImDecodedChannelMessage,
  ImDecodedChatMessage,
  MessageContent,
  MessageContentUpdateInput,
  MessageRecipient,
  MessagingState,
  UserKey,
  WatchUserKeysInput,
} from "./types"
import { UserProfileKeys } from "../../../../api/functions"
import { mergeMessages } from "./converters/collection"
import {
  messageContentUpdate,
  messageSendError,
  messageSendTrigger,
  messageSent,
  newDraft,
  toMessageAttachment,
} from "./converters/drafts"
import {
  hasUploadsInProgress,
  selectAttachment,
  selectDraft,
} from "../selectors/selectDraft"
import { NoInfer } from "@reduxjs/toolkit/dist/tsHelpers"
import { getSliceContextCreator } from "../../../state/context"

const watchChannels = createRoutine("MESSAGING|WATCH_CHANNELS", {
  success: (payload: MessagingChannelEntity[]) => payload,
})

const watchChannelPreviews = createRoutine("MESSAGING|WATCH_CHANNEL_PREVIEWS", {
  success: (payload: ChannelPreviewEntity[]) => payload,
})

const watchUsers = createRoutine("MESSAGING|WATCH_USERS", {
  success: (payload: AppUserEntity[]) => payload,
})

const watchUserKeys = createRoutine("MESSAGING|WATCH_USER_KEYS", {
  trigger: (payload: WatchUserKeysInput) => payload,
})

const watchChatPreviews = createRoutine("MESSAGING|WATCH_CHAT_PREVIEWS", {
  success: (payload: ChatPreviewEntity[]) => payload,
})

interface ChannelFetchData {
  channel: ChannelPreviewEntity
  messages: ImChannelMessage[]
}

const watchChannelMessages = createRoutine("MESSAGING|WATCH_CHANNEL_MESSAGES", {
  trigger: (payload: ChannelPreviewEntity) => payload,
  success: (payload: ChannelFetchData) => payload,
})

const initialState: MessagingState = {
  globalOutbox: {
    draft: newDraft(),
    recipients: [],
  },
  channels: empty(),
  channelPreviews: empty(),
  channelMessages: {},
  users: empty(),
  chatPreviews: empty(),
  chatMessages: {},
  keys: {},
}

type CreateMessagingSlice<T extends {} | undefined> = {
  extendState?: T
  extendExtraReducers?: (
    builder: ActionReducerMapBuilder<NoInfer<MessagingState & T>>
  ) => void
}

const createMessagingSlice = <T extends {}>(prop: CreateMessagingSlice<T>) =>
  createSlice({
    name: "messaging",
    initialState: {
      ...initialState,
      ...prop.extendState,
    } as MessagingState & T,
    reducers: {
      setUserKey(state, action: PayloadAction<UserKey>) {
        state.keys.userKey = action.payload
      },
      setChannelKeys(state, action: PayloadAction<ChannelKey[]>) {
        state.keys.channels = action.payload
      },
      // global outbox draft
      outboxDraftUpdateRecipients(
        state,
        action: PayloadAction<MessageRecipient[]>
      ) {
        state.globalOutbox.recipients = action.payload
      },
      outboxDraftUpdateContent(state, action: PayloadAction<MessageContent>) {
        state.globalOutbox.draft = messageContentUpdate(
          state.globalOutbox.draft,
          action.payload
        )
      },
      outboxDraftSendTrigger(state) {
        state.globalOutbox.draft = messageSendTrigger(state.globalOutbox.draft)
      },
      outboxDraftSendCompleted(state) {
        state.globalOutbox = {
          draft: messageSent(),
          recipients: [],
        }
      },
      outboxDraftSendError(state, action: PayloadAction<any>) {
        state.globalOutbox.draft = messageSendError(
          state.globalOutbox.draft,
          action.payload
        )
      },
      // channel drafts
      channelDraftUpdateContent(
        state,
        action: PayloadAction<MessageContentUpdateInput>
      ) {
        state.channelMessages[action.payload.ref].draft = messageContentUpdate(
          state.channelMessages[action.payload.ref].draft,
          action.payload.content
        )
      },
      channelDraftSendTrigger(
        state,
        action: PayloadAction<ChannelMessageSendTriggerInput>
      ) {
        state.channelMessages[action.payload.channelId].draft =
          messageSendTrigger(
            state.channelMessages[action.payload.channelId].draft
          )
      },
      channelDraftSendCompleted(
        state,
        action: PayloadAction<ChannelMessageSendTriggerInput>
      ) {
        state.channelMessages[action.payload.channelId].draft = messageSent()
      },
      channelDraftSendError(
        state,
        action: PayloadAction<ChannelMessageSendErrorInput>
      ) {
        state.channelMessages[action.payload.channelId].draft =
          messageSendError(
            state.channelMessages[action.payload.channelId].draft,
            action.payload.error
          )
      },
      // channel messages
      channelMessagesSubscribe(
        state,
        action: PayloadAction<ChannelMessagesSubscribeInput>
      ) {
        state.channelMessages[action.payload.channel.data.channelId] = {
          channel: action.payload.channel,
          messages: {
            loading: true,
            data: [],
          },
          draft: newDraft(),
        }
      },
      channelMessagesLoadMore(
        state,
        action: PayloadAction<ChannelMessageLoadMoreInput>
      ) {
        if (!state.channelMessages[action.payload.channelId]) {
          console.warn(
            `channelMessagesLoadMore -> no channel found ${action.payload.channelId}`
          )
          return
        }
        state.channelMessages[action.payload.channelId].messages.loading = true
        state.channelMessages[action.payload.channelId].messages.error =
          undefined
        state.channelMessages[action.payload.channelId].messages.success =
          undefined
      },
      channelMessagesSetFaulted(
        state,
        action: PayloadAction<ChannelMessagesFetchError>
      ) {
        if (!state.channelMessages[action.payload.channelId]) {
          console.warn(
            `channelMessagesSetFaulted -> no channel found ${action.payload.channelId}`
          )
          return
        }
        state.channelMessages[action.payload.channelId].messages.loading = false
        state.channelMessages[action.payload.channelId].messages.error =
          action.payload.error
        state.channelMessages[action.payload.channelId].messages.success = false
      },
      channelMessagesAppend(
        state,
        action: PayloadAction<ChannelMessageAppendInput>
      ) {
        if (!state.channelMessages[action.payload.channelId]) {
          console.warn(
            `channelMessagesAppend -> no channel found ${action.payload.channelId}`
          )
          return
        }

        const channelMessages =
          state.channelMessages[action.payload.channelId].messages
        channelMessages.error = undefined
        channelMessages.success = true
        channelMessages.cursor = action.payload.cursor
        channelMessages.isLastPage = !action.payload.cursor
        channelMessages.data = mergeMessages<ImDecodedChannelMessage>(
          state.channelMessages[action.payload.channelId].messages.data ??
            ([] as ImDecodedChannelMessage[]),
          action.payload.messages
        )
        channelMessages.loading = false
        // if (
        //   !action.payload.isNewMessage ||
        //   action.payload.messages.length === 0
        // ) {
        //   channelMessages.loading = false
        // }
      },
      sendChannelReadNotification(
        state,
        action: PayloadAction<ChannelSendReadNotificationInput>
      ) {},
      channelMessagesFulfill(
        state,
        action: PayloadAction<ChannelPreviewEntity>
      ) {
        state.channelMessages = omit(state.channelMessages, [action.payload.id])
      },
      channelMessageDeleteTrigger(
        state,
        action: PayloadAction<ChannelMessageDeleteInput>
      ) {},
      channelMessagesDeleteTrigger(
        state,
        action: PayloadAction<ChannelMessagesDeleteInput>
      ) {},
      // chat drafts
      chatDraftUpdateContent(
        state,
        action: PayloadAction<MessageContentUpdateInput>
      ) {
        state.chatMessages[action.payload.ref].draft = messageContentUpdate(
          state.chatMessages[action.payload.ref].draft,
          action.payload.content
        )
      },
      chatDraftSendTrigger(
        state,
        action: PayloadAction<ChatMessageSendTriggerInput>
      ) {
        state.chatMessages[action.payload.chatId].draft = messageSendTrigger(
          state.chatMessages[action.payload.chatId].draft
        )
      },
      chatDraftSendCompleted(
        state,
        action: PayloadAction<ChatMessageSendTriggerInput>
      ) {
        state.chatMessages[action.payload.chatId].draft = messageSent()
      },
      chatDraftSendError(
        state,
        action: PayloadAction<ChatMessageSendErrorInput>
      ) {
        state.chatMessages[action.payload.chatId].draft = messageSendError(
          state.chatMessages[action.payload.chatId].draft,
          action.payload.error
        )
      },
      // chat messages
      chatMessagesSubscribe(
        state,
        action: PayloadAction<ChatMessagesSubscribeInput>
      ) {
        state.chatMessages[action.payload.chat.id] = {
          chat: action.payload.chat,
          messages: {
            loading: true,
            data: [],
          },
          draft: newDraft(),
        }
      },
      chatMessagesLoadMore(
        state,
        action: PayloadAction<ChatMessageLoadMoreInput>
      ) {
        state.chatMessages[action.payload.chatId].messages.loading = true
        state.chatMessages[action.payload.chatId].messages.error = undefined
        state.chatMessages[action.payload.chatId].messages.success = undefined
      },
      chatMessagesSetFaulted(
        state,
        action: PayloadAction<ChatMessagesFetchError>
      ) {
        state.chatMessages[action.payload.chatId].messages.loading = false
        state.chatMessages[action.payload.chatId].messages.error =
          action.payload.error
        state.chatMessages[action.payload.chatId].messages.success = false
      },
      chatMessagesAppend(state, action: PayloadAction<ChatMessageAppendInput>) {
        const chatMessages = state.chatMessages[action.payload.chatId].messages
        chatMessages.error = undefined
        chatMessages.success = true
        chatMessages.cursor = action.payload.cursor
        chatMessages.isLastPage = !action.payload.cursor
        chatMessages.data = mergeMessages<ImDecodedChatMessage>(
          state.chatMessages[action.payload.chatId].messages.data ??
            ([] as ImDecodedChatMessage[]),
          action.payload.messages
        )
        chatMessages.loading = false
        // if (
        //   !action.payload.isNewMessage ||
        //   action.payload.messages.length === 0
        // ) {
        //   chatMessages.loading = false
        // }
      },
      chatSendReadNotification(
        state,
        action: PayloadAction<ChatSendReadNotificationInput>
      ) {},
      chatMessagesFulfill(state, action: PayloadAction<ChatPreviewEntity>) {
        state.chatMessages = omit(state.chatMessages, [action.payload.id])
      },
      chatMessageDeleteTrigger(
        state,
        action: PayloadAction<ChatMessageDeleteInput>
      ) {},
      // attachments
      draftUploadFiles(state, action: PayloadAction<DraftAttachFilesInput>) {
        const draft = selectDraft(state, action.payload.ref)
        draft.attachments = {
          uploadInProgress: true,
          items: [
            ...draft.attachments.items,
            ...action.payload.items.map((x) => toMessageAttachment(x)),
          ],
        }
      },
      draftAttachmentUploaded(
        state,
        action: PayloadAction<DraftAttachmentUploadedInput>
      ) {
        const item = selectAttachment(
          state,
          action.payload.ref,
          action.payload.attachmentId
        )
        item.asset = action.payload.asset
        item.uploaded = true
        item.error = false
        item.uploading = false
        const draft = selectDraft(state, action.payload.ref)
        draft.attachments.uploadInProgress = hasUploadsInProgress(draft)
      },
      draftAttachmentUploadError(
        state,
        action: PayloadAction<DraftAttachmentUploadErrorInput>
      ) {
        const item = selectAttachment(
          state,
          action.payload.ref,
          action.payload.attachmentId
        )
        item.uploaded = false
        item.error = true
        item.uploading = false
        const draft = selectDraft(state, action.payload.ref)
        draft.attachments.uploadInProgress = hasUploadsInProgress(draft)
      },
      draftDetachFile(state, action: PayloadAction<DraftAttachmentRemove>) {
        const draft = selectDraft(state, action.payload.ref)
        draft.attachments.items = draft.attachments.items.filter(
          (x) => x.id !== action.payload.attachmentId
        )
        draft.attachments.uploadInProgress = hasUploadsInProgress(draft)
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(watchChannels.TRIGGER, (state) => {
          state.channels = loading()
        })
        .addCase(watchChannels.FULFILL, (state) => {})
        .addCase(
          watchChannels.SUCCESS,
          (state, action: ReturnType<typeof watchChannels.success>) => {
            state.channels = completed(action.payload)
          }
        )
        .addCase(
          watchChannels.FAILURE,
          (state, action: ReturnType<typeof watchChannels.failure>) => {
            state.channels = failure(action.payload)
          }
        )

      builder
        .addCase(watchChannelPreviews.TRIGGER, (state) => {
          state.channelPreviews = loading()
        })
        .addCase(watchChannelPreviews.FULFILL, (state) => {})
        .addCase(
          watchChannelPreviews.SUCCESS,
          (state, action: ReturnType<typeof watchChannelPreviews.success>) => {
            state.channelPreviews = completed(action.payload)
          }
        )
        .addCase(
          watchChannelPreviews.FAILURE,
          (state, action: ReturnType<typeof watchChannelPreviews.failure>) => {
            state.channelPreviews = failure(action.payload)
          }
        )

      builder
        .addCase(watchUsers.TRIGGER, (state) => {
          state.users = loading()
        })
        .addCase(watchUsers.FULFILL, (state) => {})
        .addCase(
          watchUsers.SUCCESS,
          (state, action: ReturnType<typeof watchUsers.success>) => {
            state.users = completed(action.payload)
          }
        )
        .addCase(
          watchUsers.FAILURE,
          (state, action: ReturnType<typeof watchUsers.failure>) => {
            state.users = failure(action.payload)
          }
        )

      builder
        .addCase(watchChatPreviews.TRIGGER, (state) => {
          state.chatPreviews = loading()
        })
        .addCase(watchChatPreviews.FULFILL, (state) => {})
        .addCase(
          watchChatPreviews.SUCCESS,
          (state, action: ReturnType<typeof watchChatPreviews.success>) => {
            state.chatPreviews = completed(action.payload)
          }
        )
        .addCase(
          watchChatPreviews.FAILURE,
          (state, action: ReturnType<typeof watchChatPreviews.failure>) => {
            state.chatPreviews = failure(action.payload)
          }
        )
      prop?.extendExtraReducers?.(builder as any)
    },
  })

const messagingRoutines = {
  watchUsers,
  watchChannels,
  watchChannelPreviews,
  watchChatPreviews,
  watchChannelMessages,
  watchUserKeys,
}

export type MessagingSlice = ReturnType<typeof createMessagingSlice>
export type MessagingRoutines = typeof messagingRoutines

export type MessagingSliceContext = MessagingSlice & {
  actions: MessagingRoutines
}

export const createMessagingSliceContext =
  getSliceContextCreator(messagingRoutines)

export { createMessagingSlice, messagingRoutines }

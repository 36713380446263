import { useUserRole } from "@modules/auth/hooks/useUserRole"
import { AppUserEntity } from "@common/common/database/company/user/user.entity"
import { selectMessagingPreviews } from "@common/common/frontend/features/messaging/selectors/selectMessagingPreviews"
import { selectMessagingUsers } from "@common/common/frontend/features/messaging/selectors/selectUsers"
import { MessagingPreview } from "@common/common/frontend/features/messaging/types"
import { useSelector } from "react-redux"

const isPreviewMatchingQuery = (preview: MessagingPreview, query: string) => {
  if (!query?.trim()) {
    return true
  }
  const name = preview.displayName.toLowerCase()
  const queryLower = query.toLowerCase().trim()
  return name.includes(queryLower)
}

const isUserMatchingQuery = (user: AppUserEntity, query: string) => {
  if (!query?.trim()) {
    return true
  }

  const name = [user.data.profile.firstName, user.data.profile.lastName]
    .join(" ")
    .toLowerCase()
  const queryLower = query.toLowerCase().trim()
  return name.includes(queryLower)
}

export const useFilterableContacts = (query: string) => {
  const previews = useSelector(selectMessagingPreviews)
  const users = useSelector(selectMessagingUsers)

  const { isManager } = useUserRole()
  const nonPreviewUsers = isManager
    ? users.data?.filter(
        (x) => !previews.items.find((y) => y.recipientId === x.data.uid)
      )
    : []

  return {
    previews,
    filteredPreviews: previews.items.filter((x) =>
      isPreviewMatchingQuery(x, query)
    ),
    users: nonPreviewUsers,
    filteredUsers: nonPreviewUsers?.filter((x) =>
      isUserMatchingQuery(x, query)
    ),
  }
}

import { useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react"
import { useDispatch } from "react-redux"
import { appActions } from "../state"

export const useDefaultScreen = () => {
  const dispatch = useDispatch()
  useIonViewWillEnter(() => {
    dispatch(appActions.setFullscreen(false))
    console.log("full screen -> default")
  })
}

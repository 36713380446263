import { authStateSelector } from "@modules/auth/state/selectors"
import { useRootSelector } from "@modules/store/redux"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { performanceActions } from "./state"

type Props = {
  children: React.ReactNode
}

const PerformanceProvider = (props: Props) => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector(authStateSelector)
  const { userResources } = useRootSelector((state) => state.userProfile)
  const userData = useRootSelector((state) => state.userProfile.user.data)
  const { selectedStore } = useRootSelector((state) => state.performances)
  const stores = userResources.data?.stores ?? []

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(performanceActions.trackSession.trigger())
    }
    return () => {
      if (isAuthenticated) {
        dispatch(performanceActions.trackSession.fulfill())
      }
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (userData) {
      dispatch(
        performanceActions.watchAppUsage.trigger({
          uid: userData.uid,
          daysToWatch: 7,
        })
      )
    }
    return () => {
      if (userData) {
        dispatch(performanceActions.watchAppUsage.fulfill())
      }
    }
  }, [userData])

  useEffect(() => {
    if (!selectedStore && stores.length > 0) {
      dispatch(
        performanceActions.selectStore({
          storeCode: stores[0].code,
          storeName: stores[0].name,
        })
      )
    }
  }, [stores])

  return <>{props.children}</>
}

export { PerformanceProvider }

import { Icon } from "@atoms"
import { setCssVar } from "@helpers/ui"
import classNames from "classnames"
import React, { ReactNode } from "react"
import { DivProps } from "@ui/types"

interface Props extends DivProps {
  showArrow?: boolean
  center?: boolean
  rightNode?: ReactNode
  paddings?: {
    top?: number
    bottom?: number
  }
}

const Header = ({
  children,
  className,
  showArrow,
  center,
  onClick,
  rightNode,
  paddings,
  ...rest
}: Props) => {
  return (
    <div
      style={{
        ...setCssVar("--header-padding-top", `${paddings?.top ?? 28}px`),
        ...setCssVar("--header-padding-bottom", `${paddings?.bottom ?? 22}px`),
      }}
      {...rest}
      className={classNames(
        "leading-[1.33] text-3xl text-dark flex items-center relative",
        !showArrow && "pl-container",
        center && rightNode == null && "after:pr-[69px] after:block",
        className
      )}
    >
      {showArrow && (
        <div
          className="w-[69px] flex justify-center pt-[var(--header-padding-top)] pb-[var(--header-padding-bottom)]"
          onClick={onClick}
        >
          <Icon iconName="arrowBack" className="h-[24px] w-[24px]" />
        </div>
      )}
      <div
        className={classNames(
          "pt-[var(--header-padding-top)] pb-[var(--header-padding-bottom)] flex-1",
          center && "flex-1"
        )}
      >
        {children}
      </div>
      {rightNode && (
        <div className="w-[69px] flex justify-center pt-[var(--header-padding-top)] pb-[var(--header-padding-bottom)]">
          {rightNode}
        </div>
      )}
    </div>
  )
}

export default Header

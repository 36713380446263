import { createAnimation, AnimationBuilder } from "@ionic/react"
import { TransitionOptions } from "./types"

export const none: AnimationBuilder = (
  baseEl: HTMLElement,
  opts: TransitionOptions
) => {
  return createAnimation().addElement(opts.enteringEl).beforeStyles({
    opacity: 1,
  })
}

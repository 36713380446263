import { MessagingToolbarAttachments } from "./MessagingToolbarAttachments"
import MessagingToolbarInput from "./MessagingToolbarInput"

export interface MessagingToolbarProps {
  children: any
}

export const MessagingToolbar = ({ children }: MessagingToolbarProps) => {
  return (
    <div className="text-base leading-normal text-light my-[9px]">
      {children}
    </div>
  )
}

MessagingToolbar.Attachments = MessagingToolbarAttachments
MessagingToolbar.Input = MessagingToolbarInput

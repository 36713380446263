import { getSagaContext } from "@modules/store/redux/sagaContext"
import { buffers, eventChannel } from "redux-saga"
import { CoursesProgress } from "@services/firebase/coursesProgress"
import {
  call,
  put,
  take,
  cancelled,
  takeLatest,
  takeEvery,
} from "typed-redux-saga"
import { coursesActions } from "."
import {
  CourseAttachments,
  CourseAttachmentPages,
  Courses,
} from "../types/courses"
import { filterCourses } from "./filters"
import { toCourseAttachments, toCourseAttachmentsPages } from "./converters"

function* watchFirebaseCoursesAttachmentsSaga() {
  const firebase = yield* getSagaContext("firebase")
  const channel = eventChannel<CourseAttachments>((emitter) => {
    const unsubscribe = firebase.collections.courseAttachments.subscribeAll(
      (items) => {
        const convertedItems = toCourseAttachments(items)
        console.log(
          "watchFirebaseCoursesAttachmentsSaga -> Mapped items",
          convertedItems
        )
        emitter(convertedItems)
      }
    )
    return () => unsubscribe()
  }, buffers.sliding(1))
  try {
    yield* takeLatest(coursesActions.watchCourses.FULFILL, function* () {
      channel.close()
    })
    while (true) {
      const items = yield* take(channel)
      yield* put(coursesActions.watchCoursesAttachments.success(items))
    }
  } catch (error) {
    console.error("watchFirebaseCoursesAttachmentsSaga -> error", error)
    yield* put(coursesActions.watchCoursesAttachments.failure())
  } finally {
    if (yield* cancelled()) {
      channel.close()
    }
  }
}

function* watchFirebaseCoursesAttachmentPagesSaga() {
  const firebase = yield* getSagaContext("firebase")
  const channel = eventChannel<CourseAttachmentPages>((emitter) => {
    const unsubscribe = firebase.collections.courseAttachmentPages.subscribeAll(
      (items) => {
        const convertedItems = toCourseAttachmentsPages(items)
        console.log(
          "watchFirebaseCoursesAttachmentPagesSaga -> Mapped items",
          convertedItems
        )
        emitter(convertedItems)
      }
    )
    return () => unsubscribe()
  }, buffers.sliding(1))
  try {
    yield* takeLatest(
      coursesActions.watchCoursesAttachmentPages.FULFILL,
      function* () {
        channel.close()
      }
    )
    while (true) {
      const items = yield* take(channel)
      yield* put(coursesActions.watchCoursesAttachmentPages.success(items))
    }
  } catch (error) {
    console.error("watchFirebaseCoursesAttachmentPagesSaga -> error", error)
    yield* put(coursesActions.watchCoursesAttachmentPages.failure())
  } finally {
    if (yield* cancelled()) {
      channel.close()
    }
  }
}

function* watchFirebaseCoursesSaga() {
  const firebase = yield* getSagaContext("firebase")
  const firebaseCoursesChannel = eventChannel<Courses>((emitter) => {
    const unsubscribe = firebase.watchCourses((courses) => {
      const filteredCourses = filterCourses(courses)
      console.log(
        "watchFirebaseCoursesSaga -> Filtered courses",
        filteredCourses
      )
      emitter(filteredCourses)
    })
    return () => unsubscribe()
  }, buffers.sliding(1))
  try {
    yield* takeLatest(coursesActions.watchCourses.FULFILL, function* () {
      firebaseCoursesChannel.close()
    })
    while (true) {
      const courses = yield* take(firebaseCoursesChannel)
      yield* put(coursesActions.watchCourses.success(courses))
    }
  } catch (error) {
    yield* put(coursesActions.watchCourses.failure())
  } finally {
    if (yield* cancelled()) {
      firebaseCoursesChannel.close()
    }
  }
}

function* watchFirebaseCoursesProgressSaga() {
  const firebase = yield* getSagaContext("firebase")
  const firebaseCoursesProgressChannel = eventChannel<CoursesProgress>(
    (emitter) => {
      const unsubscribe = firebase.watchCoursesProgress((coursesProgress) => {
        emitter(coursesProgress)
      })
      return () => unsubscribe()
    },
    buffers.sliding(1)
  )
  try {
    yield* takeLatest(
      coursesActions.watchCoursesProgress.FULFILL,
      function* () {
        firebaseCoursesProgressChannel.close()
      }
    )
    while (true) {
      const courses = yield* take(firebaseCoursesProgressChannel)
      yield* put(coursesActions.watchCoursesProgress.success(courses))
    }
  } catch (error) {
    yield* put(coursesActions.watchCoursesProgress.failure())
  } finally {
    if (yield* cancelled()) {
      firebaseCoursesProgressChannel.close()
    }
  }
}

function* getFirebaseCoursesProgressSaga() {
  const firebase = yield* getSagaContext("firebase")
  const routine = coursesActions.getCoursesProgress
  try {
    const progress = yield* call(firebase.getCoursesProgress)
    yield* put(routine.success(progress))
  } catch (error) {
    yield* put(routine.failure())
  } finally {
    yield* put(routine.fulfill())
  }
}

function* saveCourseProgressSaga({
  payload,
}: ReturnType<typeof coursesActions.saveCourseProgress.trigger>) {
  const firebase = yield* getSagaContext("firebase")
  const routine = coursesActions.saveCourseProgress
  try {
    yield* call(firebase.saveCourseProgress, payload)
    yield* put(routine.success())
  } catch (error) {
    console.log(error)
    yield* put(routine.failure())
  }
}

function* initCourseProgressSaga({
  payload,
}: ReturnType<typeof coursesActions.initCourseProgress.trigger>) {
  const firebase = yield* getSagaContext("firebase")
  const routine = coursesActions.initCourseProgress
  try {
    yield* call(firebase.initCourseProgress, payload)
    yield* put(routine.success())
  } catch (error) {
    console.log(error)
    yield* put(routine.failure())
  }
}

export function* coursesRootSaga() {
  yield* takeLatest(
    coursesActions.watchCourses.TRIGGER,
    watchFirebaseCoursesSaga
  )
  yield* takeLatest(
    coursesActions.watchCoursesAttachments.TRIGGER,
    watchFirebaseCoursesAttachmentsSaga
  )
  yield* takeLatest(
    coursesActions.watchCoursesAttachmentPages.TRIGGER,
    watchFirebaseCoursesAttachmentPagesSaga
  )
  yield* takeLatest(
    coursesActions.watchCoursesProgress.TRIGGER,
    watchFirebaseCoursesProgressSaga
  )
  yield* takeLatest(
    coursesActions.getCoursesProgress.TRIGGER,
    getFirebaseCoursesProgressSaga
  )
  yield* takeEvery(
    coursesActions.saveCourseProgress.TRIGGER,
    saveCourseProgressSaga
  )
  yield* takeEvery(
    coursesActions.initCourseProgress.TRIGGER,
    initCourseProgressSaga
  )
  // yield* takeLatest(authActions.logout.TRIGGER, logoutSaga);
  // yield* takeLatest(authActions.watch.TRIGGER, watchFirebaseAuthStateSaga);
}

import React from "react"

export interface TextPreviewProps {
  htmlContent: string
}

const TextPreview = ({ htmlContent }: TextPreviewProps) => {
  return (
    <div
      className="overflow-hidden text-light text-ellipsis whitespace-nowrap"
      dangerouslySetInnerHTML={{
        __html: htmlContent,
      }}
    />
  )
}

export default TextPreview

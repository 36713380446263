import UserImpersonateContainer from "@containers/authentication/Impersonate"
import PageRoot from "@root/pages/PageRoot"

export const UserImpersonatePage = () => {
  return (
    <PageRoot fullscreen>
      <UserImpersonateContainer />
    </PageRoot>
  )
}

import { SectionTitle } from "@atoms"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { ProgressBox } from "@molecules"
import { useSelector } from "react-redux"
import { selectStoreSalesProgressPercentage } from "../selectors"
import { roundDecimals } from "@utils/numbers"

const DailySalesPerformances = () => {
  const storeProgress = useSelector(selectStoreSalesProgressPercentage)
  const { t } = useTranslations()
  return (
    <div>
      <SectionTitle text={t("performance:sales.daily.title")} />
      <ProgressBox
        classes={{
          container: "bg-grey !w-full !h-auto !py-[25px]",
          ringContainer: "!w-[154px] !h-[154px]",
          progress: "!stroke-dark",
          circle: "!stroke-light",
          text: "!text-dark text-[40px]",
        }}
        value={roundDecimals(Math.round(Math.min(storeProgress, 100)) / 100, 2)}
      />
    </div>
  )
}

export { DailySalesPerformances }

import { buffers, eventChannel } from "redux-saga"
import { put, take, cancelled, takeLatest } from "typed-redux-saga"
import { AppUserEntity } from "../../../../database/company/user/user.entity"
import { getSagaContext } from "../../../state/context"

export function* watchManagerUsersSaga() {
  console.log("watchManagerUsersSaga -> called")
  const state = yield* getSagaContext("state")
  const firebase = yield* getSagaContext("firebase")
  const channel = eventChannel<AppUserEntity[]>((emitter) => {
    const unsubscribe =
      firebase.collections.users.subscribeAllActiveInternalUsers(
        (data) => {
          emitter(data)
        },
        [firebase.getUid() as string]
      )
    return () => unsubscribe()
  }, buffers.sliding(1))
  try {
    yield* takeLatest(state.messaging.actions.watchUsers.fulfill, function* () {
      channel.close()
    })
    while (true) {
      const data = yield* take(channel)
      yield* put(state.messaging.actions.watchUsers.success(data))
    }
  } catch (error) {
    yield* put(state.messaging.actions.watchUsers.failure(error))
  } finally {
    if (yield* cancelled()) {
      channel.close()
    }
  }
}

import { setCssVar } from "@helpers/ui"
import { useIonToast } from "@ionic/react"
import { coursesActions } from "@features/courses/state"
import {
  selectChapterByIndex,
  selectChapterId,
  selectChapterProgressById,
} from "@features/courses/state/selectors/chapter"
import classNames from "classnames"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { formatMediaDuration } from "@utils/time"
import CompletedButton from "../CompletedButton"
import LockedButton from "../LockedButton"
import PlayButton from "../PlayButton"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { selectCurrentViewIndex } from "@features/courses/state/selectors/lists"

interface Props {
  chapterId: string
  chapterLength: number
  index: number
}

const CourseDetailVideoListItem = ({
  chapterId,
  chapterLength,
  index,
}: Props) => {
  const dispatch = useDispatch()
  const { t } = useTranslations()

  const chapter = useSelector(selectChapterByIndex(index))!
  const prevChapter = useSelector(selectChapterByIndex(index - 1))

  const chapterIdSelected = useSelector(selectChapterId)
  const viewIndex = useSelector(selectCurrentViewIndex)
  const progressChapter = useSelector(selectChapterProgressById(chapterId))
  const previousChapter = useSelector(
    selectChapterProgressById(prevChapter?.id || "")
  )
  const [present] = useIonToast()

  const isLocked =
    viewIndex === 0 && index !== 0 && (!previousChapter?.completed ?? false)
  const isCompleted =
    viewIndex > 0 || (!isLocked && (progressChapter?.completed ?? false))

  const isRunning = !isLocked && !isCompleted
  const videoDuration =
    (chapter.video?.duration?.hours ?? 0) * 60 * 60 +
      (chapter.video?.duration?.minutes ?? 0) * 60 +
      (chapter.video?.duration?.seconds ?? 0) ?? 100

  const progress =
    isRunning &&
    Math.round(((progressChapter?.progress ?? 0) / videoDuration) * 100)
  useEffect(() => {
    if (isRunning || (isCompleted && chapterLength == index + 1)) {
      dispatch(coursesActions.setChapterId(chapterId))
    }
  }, [isRunning])

  return (
    <div
      onClick={() => {
        if (isRunning || isCompleted) {
          dispatch(coursesActions.setChapterIdAndPlay(chapterId))
        }
        if (isLocked) {
          present({
            message: t("courses:video.blocked"),
            duration: 3000,
          })
        }
      }}
      {...(isRunning && {
        style: setCssVar("--course-progress-bar-width", `${progress}%`),
      })}
      className={classNames(
        "flex p-courses-container relative gap-[16px] items-center",
        chapterIdSelected == chapter.id && "bg-grey",
        isRunning &&
          "after:content-[''] after:h-[2px] after:bg-red after:absolute after:bottom-[0px] after:left-0 after:transition-transform after:w-[var(--course-progress-bar-width)]"
      )}
    >
      <div className="w-[40px] h-[40px]">
        {isCompleted && <CompletedButton classes={{ icon: "!text-red" }} />}
        {isLocked && <LockedButton />}
        {isRunning && <PlayButton />}
      </div>
      <div className="flex-1 break-all">
        <div className="font-bold text-dark text-md">{`${index + 1}. ${
          chapter.title
        }`}</div>
        <div className="text-sm text-light">
          {chapter.video?.duration
            ? formatMediaDuration(chapter.video?.duration)
            : ""}
        </div>
      </div>
    </div>
  )
}

export default React.memo(CourseDetailVideoListItem)

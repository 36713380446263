import { put, select, call } from "typed-redux-saga"
import { PayloadAction } from "@reduxjs/toolkit"
import { last } from "ramda"
import { getSagaContext } from "../../../state/context"
import { CommonState } from "../../../state/types"
import { ImChatMessage } from "../../../../database/messaging/chat/chat.entity"
import { ChatMessageLoadMoreInput } from "../state/types"
import { decodeAndNormalizeChatMsg } from "../state/converters/encryption"

export const getCursor = (state: CommonState, chatId: string) =>
  state.messaging.chatMessages[chatId]?.messages.cursor

export const getUserPrivateKey = (state: CommonState) =>
  state.messaging.keys?.userKey?.personalPrivateKey

export function* fetchChatMessagesPage(
  action: PayloadAction<ChatMessageLoadMoreInput>
) {
  console.log("fetchChatMessagesPage -> start", action.payload)

  const state = yield* getSagaContext("state")
  const { chatId, pageSize } = action.payload
  const firebase = yield* getSagaContext("firebase")
  try {
    const cursor = yield* select(getCursor, chatId)
    if (!cursor) {
      console.warn(
        `fetchChatMessagesPage | Fetch request for chat without cursor ${chatId}`
      )
      return
    }

    const privateKey = yield* select(getUserPrivateKey)
    if (!privateKey) {
      throw new Error(`Cannot fetch user private key`)
    }

    const messages = yield* call(
      (chatId: string, cursor: string) =>
        firebase.collections.chat.fetchChatMessagePageDesc(
          chatId,
          pageSize,
          cursor
        ),
      chatId,
      cursor
    )

    console.log(
      `fetchChatMessagesPage -> fetched ${messages.docs.length}`,
      messages
    )

    yield* put(
      state.messaging.actions.chatMessagesAppend({
        chatId,
        messages: messages.docs.map((x) =>
          decodeAndNormalizeChatMsg(privateKey, x.data() as ImChatMessage)
        ),
        cursor:
          messages.docs.length === pageSize
            ? last(messages.docs)?.id
            : undefined,
      })
    )
    console.log("fetchChatMessagesPage -> completed", action.payload)
  } catch (e) {
    console.error(`fetchChatMessagesPage -> error`, e)
    yield* put(
      state.messaging.actions.chatMessagesSetFaulted({
        chatId,
        error: (e as any).message,
      })
    )
  }
}

export interface OperationalState<TError> {
  loading: boolean
  success?: boolean
  error?: TError
}

export interface LoadableState<TData, TError> extends OperationalState<TError> {
  data?: TData
}

export const empty = <TData, TError>(): LoadableState<TData, TError> => ({
  loading: false,
  success: undefined,
  error: undefined,
})

export const loading = <TData, TError>(): LoadableState<TData, TError> => ({
  loading: true,
  success: undefined,
  error: undefined,
})

export const completed = <TData, TError>(
  data: TData
): LoadableState<TData, TError> => ({
  loading: false,
  success: true,
  error: undefined,
  data,
})

export const failure = <TData, TError>(
  error?: TError
): LoadableState<TData, TError> => ({
  loading: false,
  success: false,
  error,
})

export const idle = <TError>(): OperationalState<TError> => ({
  loading: false,
  success: undefined,
  error: undefined,
})

export const running = <TError>(): OperationalState<TError> => ({
  loading: true,
  success: undefined,
  error: undefined,
})

export const success = <TError>(): OperationalState<TError> => ({
  loading: false,
  success: true,
  error: undefined,
})

export const faulted = <TError>(error?: TError): OperationalState<TError> => ({
  loading: false,
  success: false,
  error,
})

import { Image, Icon } from "@atoms"
import { setCssVar } from "@helpers/ui"
import { IonContent, IonHeader, IonToolbar } from "@ionic/react"
import { useRouting } from "@root/routing/useRouting"
import { authActions } from "@modules/auth/state"
import { useRootSelector } from "@modules/store/redux"
import { ListItem } from "@molecules"
import { useDispatch } from "react-redux"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { DEFAULT_PROFILE_IMAGE } from "../consts"
import { useUserTypeLabel } from "@modules/app/hooks/useUserTypeLabel"

const ProfileSettingsContainer = () => {
  const { t } = useTranslations()
  const dispatch = useDispatch()
  const { goTo } = useRouting()
  const userData = useRootSelector((state) => state.userProfile?.user?.data)
  const { getUserTypeLabel } = useUserTypeLabel()
  let location = ""
  switch (userData?.userType) {
    case "user":
      location = userData.context.storeName ?? ""
      break
    case "areaManager":
      location = userData.context.areaName ?? ""
      break

    default:
      break
  }
  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar
          className="flex items-end"
          style={{
            ...setCssVar("--min-height", 0),
          }}
        >
          <div className="flex gap-[22px] px-[32px] items-center mt-[56px]">
            <Image
              src={
                userData?.profile?.avatarUrl
                  ? userData?.profile?.avatarUrl
                  : DEFAULT_PROFILE_IMAGE
              }
              classes={{
                img: "rounded-[100px_812px_32.8125px_32.8125px] w-[65px] h-[65px] overflow-hidden object-cover",
              }}
            />
            <div className="">
              <div className="text-lg leading-[0.9] font-semibold">{`${userData?.profile.firstName} ${userData?.profile.lastName}`}</div>
              {userData?.userType && userData.userType !== "user" && (
                <div className="text-light">
                  {getUserTypeLabel(userData.userType)}
                </div>
              )}
              {location && (
                <div className="flex gap-[7px] items-center leading-[1.74] mt-1">
                  <Icon iconName="location" className="w-[20px] h-[20px]" />
                  <div>{location}</div>
                </div>
              )}
            </div>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="flex flex-col px-[32px] pt-[24px] mb-[var(--footer-height)]">
          <ListItem
            onClick={() => goTo("profile")}
            iconName="profile"
            text={t("profile:subsection.settings.title")}
            showArrow
          />
          {/* <Section iconName="camera" text={"Notifiche"} showArrow/> */}
          <ListItem
            onClick={() => goTo("appInfo")}
            iconName="shield"
            text={t("profile:subsection.appInfo.title")}
            showArrow
          />
          {userData?.userType === "admin" && (
            <ListItem
              onClick={() => goTo("userImpersonate")}
              iconName="support"
              text={t("profile:subsection.userImpersonate.title")}
              showArrow
            />
          )}
          <ListItem
            onClick={() => {
              dispatch(authActions.logout())
              goTo("login")
            }}
            classes={{ text: "text-light" }}
            iconName="logout"
            text={t("profile:subsection.logout.title")}
            showArrow
          />
        </div>
      </IonContent>
    </>
  )
}

export default ProfileSettingsContainer

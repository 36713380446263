import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { contentsActions } from "./state"
import { useRootSelector } from "@modules/store/redux"

type Props = {
  children: React.ReactNode
}

export const DataProvider = (props: Props) => {
  const { isInitialized } = useRootSelector((state) => state.app)

  const dispatch = useDispatch()
  useEffect(() => {
    if (isInitialized) {
      dispatch(contentsActions.load())
    }
  }, [isInitialized])

  return <>{props.children}</>
}

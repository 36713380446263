import { buffers, eventChannel } from "redux-saga"
import { put, take, cancelled, takeLatest } from "typed-redux-saga"
import { ChatPreviewItem } from "../../../../database/messaging/chat-preview/chat-preview.controller"
import { normalizeChatPreviewItem } from "../../../../database/messaging/chat-preview/chat-preview.converter"
import { getSagaContext } from "../../../state/context"

const shouldSendReceivedNotification = (chatPreview: ChatPreviewItem) => {
  return (
    chatPreview.entity.data.lastMessage &&
    chatPreview.entity.data.lastSyncReceivedMessageId !==
      chatPreview.entity.data.lastMessage.id
  )
}

const isInboundMessage = (chatPreview: ChatPreviewItem) =>
  chatPreview.entity.data.lastMessage?.d === "in"

const filterChatsWithNewMessages = (items: ChatPreviewItem[]) =>
  items.filter(
    (x) =>
      x.hasChanges && isInboundMessage(x) && shouldSendReceivedNotification(x)
  )

export function* watchChatPreviewsPreviewsSaga() {
  const state = yield* getSagaContext("state")
  const firebase = yield* getSagaContext("firebase")
  const channel = eventChannel<ChatPreviewItem[]>((emitter) => {
    const unsubscribe =
      firebase.collections.chatPreview.subscribeUserChatPreviews(
        firebase.getUid() as string,
        (data) => {
          emitter(data.map(normalizeChatPreviewItem))
        }
      )
    return () => unsubscribe()
  }, buffers.sliding(1))
  try {
    yield* takeLatest(
      state.messaging.actions.watchChatPreviews.fulfill,
      function* () {
        channel.close()
      }
    )
    while (true) {
      const data = yield* take(channel)
      yield* put(
        state.messaging.actions.watchChatPreviews.success(
          data.map((x) => x.entity)
        )
      )

      const chatWithNewIncomingMessages = filterChatsWithNewMessages(data)
      for (const chat of chatWithNewIncomingMessages) {
        if (!chat.entity.data.lastMessage?.id) {
          continue
        }
        console.log("RECEIVED MESSAGE NOTIFICATION", chat)
        yield* put(
          state.messaging.actions.chatSendReadNotification({
            chatId: chat.entity.id,
            type: "received",
            lastMessageId: chat.entity.data.lastMessage.id,
          })
        )
      }
    }
  } catch (error) {
    yield* put(state.messaging.actions.watchChatPreviews.failure(error))
  } finally {
    if (yield* cancelled()) {
      channel.close()
    }
  }
}

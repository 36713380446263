import { CurrentUser } from "@modules/auth/types"
import Firebase from "@services/firebase"

export const fetchUserData = (firebase: Firebase): CurrentUser => {
  const user = firebase.getUser()
  const claims = firebase.getClaims()

  return {
    displayName: user?.displayName ?? "",
    email: user?.email ?? "",
    phoneNumber: user?.phoneNumber ?? "",
    photoURL: user?.photoURL ?? "",
    claims,
    uid: user?.uid ?? "",
  }
}

import { MessageItem } from "@features/messaging/types"
import { MessageListItem } from "../../common"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { useUserRole } from "@modules/auth/hooks/useUserRole"
import { useContentContext } from "@atoms"
import { MessagingMode } from "../../common/MessageListItem/types"

export interface ChatMessageListItemProps {
  previousMessage?: MessageItem
  nextMessage?: MessageItem
  message: MessageItem
  lastReadMessageId?: string
  isLastMessage: boolean
  onSelectToggle?: () => void
  selected?: boolean
  mode: MessagingMode
  onLongPress?: () => void
}

const ChatMessageListItem = ({
  message,
  previousMessage,
  nextMessage,
  lastReadMessageId,
  isLastMessage,
  onSelectToggle,
  selected,
  mode,
  onLongPress,
}: ChatMessageListItemProps) => {
  const { t } = useTranslations()
  const { scrollToPosition } = useContentContext()
  const { isManager, isAdmin } = useUserRole()

  const isMine = message.direction === "out"
  const canDelete = (isManager && isMine) || isAdmin

  return (
    <MessageListItem
      newMessagesLabel={t("messaging:chat.new.message")}
      messageData={message}
      previousMessage={previousMessage}
      nextMessage={nextMessage}
      lastReadMessageId={lastReadMessageId}
      scrollTo={scrollToPosition}
      isLastMessage={isLastMessage}
      selectable={canDelete}
      onSelectToggle={onSelectToggle}
      selected={selected}
      showSelect={canDelete && mode === "select"}
      onLongPress={onLongPress}
    />
  )
}

export default ChatMessageListItem

import { limit, orderBy, query, where } from "firebase/firestore"
import {
  Firebase,
  FirestoreController,
} from "../../../infrastructure/firebase/controller"
import { Entity } from "../../../infrastructure/firebase/types"
import { AppUsageAggregation } from "./app-usage-aggregation.entity"

export class AppUsageAggregationController extends FirestoreController<AppUsageAggregation> {
  constructor(firebase: Firebase) {
    super(firebase, "AppUsageAggregationRepo")
  }

  subscribeAppDailyUsage(
    uid: string,
    daysToWatch: number,
    callback: (items: Entity<AppUsageAggregation>[]) => void
  ) {
    return this.subscribeQuery(
      (collection) =>
        query(
          collection,
          where("uid", "==", uid),
          orderBy("date", "desc"),
          limit(daysToWatch)
        ),
      (data) => callback(data)
    )
  }
}

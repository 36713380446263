import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import auth from "./locales/it/auth.json"
import calendars from "./locales/it/calendars.json"
import courses from "./locales/it/courses.json"
import common from "./locales/it/common.json"
import messaging from "./locales/it/messaging.json"
import news from "./locales/it/news.json"
import notifications from "./locales/it/notifications.json"
import performance from "./locales/it/performance.json"
import profile from "./locales/it/profile.json"
import pdf from "./locales/it/pdf.json"

export const resources = {
  it: {
    auth,
    calendars,
    common,
    courses,
    messaging,
    news,
    notifications,
    performance,
    profile,
    pdf,
  },
} as const

export const i18nInitialize = () =>
  i18n.use(initReactI18next).init({
    fallbackLng: "it",
    ns: Object.keys(resources.it),
    resources,
  })

import { IonHeader, IonToolbar } from "@ionic/react"
import { DailyEventParams } from "@pages/private/Communication/DailyEvent"
import { useRouteContext } from "@modules/routing/context"
import { useRouting } from "@root/routing/useRouting"
import { Header } from "@molecules"
import { addDays, format, parse, startOfWeek } from "date-fns"
import { it } from "date-fns/locale"
import { useMemo, useState } from "react"
import { Content } from "@ui/atoms"
import { DailyEvents, HorizontalCalendar } from "@features/calendar/components"
import { useTranslations } from "@modules/app/hooks/useTranslations"

type Props = {}

const DailyEventsContainer = (props: Props) => {
  const { t } = useTranslations()
  const { goBack } = useRouting()
  const {
    match: {
      params: { date, eventId },
    },
  } = useRouteContext<DailyEventParams>()
  const [weekOffset, setWeekOffset] = useState(0)
  const parsedDate = useMemo(
    () => parse(date, "yyyy-MM-dd", new Date()),
    [date]
  )
  const formattedHeaderDate = useMemo(
    // format(addDays(startWeekDate, weekOffset), "MMMM, yyyy", { locale: it })
    () =>
      format(
        addDays(startOfWeek(parsedDate, { weekStartsOn: 1 }), weekOffset),
        "MMMM, yyyy",
        { locale: it }
      ),
    [parsedDate, weekOffset]
  )

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <Header center showArrow onClick={() => goBack()}>
            <div className="font-semibold leading-normal text-center text-md">
              {t("calendars:events.title")}
            </div>
          </Header>
          <div className="px-[16px] mb-[16px]">
            <div className="mb-2 font-semibold capitalize ">
              {formattedHeaderDate}
            </div>
            <HorizontalCalendar
              setWeekOffset={setWeekOffset}
              weekOffset={weekOffset}
              // setSelectedDate={setSelectedDate}
              // date={parsedDate}
            />
          </div>
          <div className="footer-toolbar pt-[13px] px-[16px]">
            <div className="font-semibold leading-normal pb-[16px] ">
              {t("calendars:events.all")}
            </div>
          </div>
        </IonToolbar>
      </IonHeader>
      <Content>
        <DailyEvents date={parsedDate} eventId={eventId} />
      </Content>
    </>
  )
}

export default DailyEventsContainer

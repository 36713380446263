import { useTranslations } from "@modules/app/hooks/useTranslations"
import { SectionBannerBox } from "@molecules"
import { IconNames } from "@ui/atoms/Icon"
import classNames from "classnames"
import React from "react"

type Props = {
  onClick: () => void
  text: React.ReactNode
  iconName: Extract<
    IconNames,
    | "bannerComunicazioni"
    | "bannerPerformance"
    | "bannerFormazione"
    | "bannerNuoviCorsi"
  >
}

const Banner = ({ onClick, text, iconName }: Props) => {
  const { t } = useTranslations()
  return (
    <div className="relative flex flex-col gap-6">
      <SectionBannerBox
        className={classNames(
          iconName === "bannerComunicazioni" && "bg-green",
          iconName === "bannerFormazione" && "bg-dark",
          iconName === "bannerPerformance" && "bg-red-dark",
          iconName === "bannerNuoviCorsi" && "bg-dark"
        )}
        onClick={onClick}
        text={text}
        iconName={iconName}
        buttonText={t("common:navigation.sectionsBanner.cta")}
      />
    </div>
  )
}

export default Banner

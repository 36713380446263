import { useTranslations } from "@modules/app/hooks/useTranslations"
import classNames from "classnames"
import React from "react"
import { useSelector } from "react-redux"
import { selectTestIdList } from "../../../state/selectors/lists"
import CourseDetailTestListItem from "./CourseDetailTestListItem"

interface Props {
  isVisible: boolean
}

const CourseDetailTestList = ({ isVisible }: Props) => {
  const { tPluralized } = useTranslations()
  const tests = useSelector(selectTestIdList)
  return (
    <div className={classNames(!isVisible && "hidden", "p-courses-container")}>
      <div className="border-b border-b-grey  text-base leading-[1.5] mb-[16px]">
        <div>{`${tests.length} ${tPluralized(
          {
            singular: "courses:tests.tot.single",
            plural: "courses:tests.tot.plural",
          },
          tests.length
        )}`}</div>
      </div>
      <div className="flex flex-col gap-[8px]">
        {tests.map((id, index) => {
          return <CourseDetailTestListItem key={id ?? index} testId={id} />
        })}
      </div>
    </div>
  )
}

export default React.memo(CourseDetailTestList)

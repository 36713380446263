import {
  Firebase,
  FirestoreController,
} from "../../../infrastructure/firebase/controller"
import { UserChannelKey, UserChannelKeys } from "./userChannelKeys.entity"

export class UserChannelKeysController extends FirestoreController<UserChannelKeys> {
  ChannelKeysSubCollection = "userChannelKeys"

  constructor(firebase: Firebase) {
    super(firebase, "UserChannelKeysRepo")
  }

  subscribeUserChannelKeys(
    uid: string,
    callback: (docs: UserChannelKey[]) => void
  ) {
    return this.subscribeSubCollection<UserChannelKey>(
      uid,
      this.ChannelKeysSubCollection,
      callback
    )
  }
}

import { IStore } from "../../../../abstractions/storage"
import { ContactInfo } from "../types"

const buildProfileKey = (id: string) => `profile:${id}`

export const getProfileFromStore = (
  store: IStore,
  uid: string
): Promise<ContactInfo | undefined> => {
  return store.get(buildProfileKey(uid))
}

export const saveProfileToStore = async (
  store: IStore,
  profile: ContactInfo
) => {
  await store.save(buildProfileKey(profile.uid), profile)
}

import Firebase from "."
import {
  collection,
  getDocs,
  Firestore,
  setDoc,
  doc,
  onSnapshot,
  QueryDocumentSnapshot,
  serverTimestamp,
  Timestamp,
  query,
  where,
  getDoc,
} from "firebase/firestore"
import { CoursesProgressEntity } from "@common/common/types/firebase/coursesProgress"
import { mapObjIndexed } from "ramda"
import { DeepPartial } from "@helpers/types"

export type CoursesProgress = Record<
  string,
  CoursesProgressEntity<number> | undefined
>

const converter = {
  toFirestore: (data: CoursesProgressEntity<number>): any => {
    let to = convertTimeToServerTimestamp(data) as CoursesProgressEntity<number>
    return to
  },
  fromFirestore: (
    snap: QueryDocumentSnapshot<CoursesProgressEntity<Timestamp>>
  ): CoursesProgressEntity<number> => {
    let data = snap.data()
    const from = convertServerTimeToNumber(data)
    return from as CoursesProgressEntity<number>
  },
}

function coursesRef(db: Firestore) {
  return collection(db, `CoursesProgressRepo`).withConverter(converter)
}

const buildCourseProgressId = ({
  uid,
  courseId,
  viewIndex,
}: {
  uid: string
  courseId: string
  viewIndex: number
}) => `${uid}-${courseId}-${viewIndex}`

export function watchCoursesProgress(
  this: Firebase,
  callback: (courses: CoursesProgress) => void
) {
  const unsubscribe = onSnapshot(coursesRef(this.firestore), (snap) => {
    // const data = snap.docs.map((x) => ({ ...x.data(), id: x.id }));
    const data = snap.docs.reduce<any>((o, v) => {
      const data = v.data()
      const { ...toSave } = data
      o[data.courseId] = { ...toSave }
      return o
    }, {})
    callback(data)
  })
  return () => unsubscribe()
}

export async function saveCourseProgress(
  this: Firebase,
  coursesProgress: DeepPartial<CoursesProgressEntity<number>>
) {
  const { courseId, id, startedTime, userId, viewIndex, ...rest } =
    coursesProgress

  console.log("COURSE UPDATE -> saveCourseProgress", coursesProgress)
  const uid = userId ?? this.getImpersonatedUid() ?? this.getUser()?.uid
  if (!uid) {
    console.error("COURSE UPDATE -> saveCourseProgress -> no uid", {
      coursesProgress,
    })
    return
  }

  if (!coursesProgress.courseId) {
    console.error("COURSE UPDATE -> saveCourseProgress -> no courseId", {
      coursesProgress,
    })
    return
  }

  if (coursesProgress.viewIndex === undefined) {
    console.error("COURSE UPDATE -> saveCourseProgress -> no viewIndex")
    return
  }

  const courseProgressId = buildCourseProgressId({
    uid,
    courseId: coursesProgress.courseId,
    viewIndex: coursesProgress.viewIndex,
  })

  const current = await getDoc(
    doc(coursesRef(this.firestore), courseProgressId)
  )
  if (!current.exists()) {
    console.error("COURSE UPDATE -> saveCourseProgress -> not exists", {
      courseProgressId,
    })
    return
  }

  if (current.data()?.status === "completed") {
    console.error("COURSE UPDATE -> saveCourseProgress -> already completed", {
      courseProgressId,
    })
    return
  }

  await setDoc(doc(coursesRef(this.firestore), courseProgressId), rest, {
    merge: true,
  })
}

export async function initCourseProgress(
  this: Firebase,
  { courseId, viewIndex }: { courseId: string; viewIndex: number }
) {
  const uid = this.getImpersonatedUid() ?? this.getUser()?.uid!
  const courseProgressId = buildCourseProgressId({ uid, courseId, viewIndex })

  console.log("COURSE UPDATE -> initCourseProgress", courseId, viewIndex)
  const current = await getDoc(
    doc(coursesRef(this.firestore), courseProgressId)
  )
  if (current.exists()) {
    console.error("COURSE UPDATE -> initCourseProgress -> already exists", {
      courseProgressId,
    })
    return
  }

  const data: CoursesProgressEntity<number> = {
    courseId,
    id: courseProgressId,
    progress: {},
    startedTime: 1,
    status: "running",
    userId: uid,
    viewIndex,
  }
  await setDoc(doc(coursesRef(this.firestore), courseProgressId), data)
}

export async function getCoursesProgress(this: Firebase) {
  const snapshot = await getDocs(
    query(
      coursesRef(this.firestore),
      where("userId", "==", this.getImpersonatedUid() ?? this.getUser()?.uid)
    )
  )
  return snapshot.docs.reduce<CoursesProgress>((o, doc) => {
    const data = doc.data()
    const { ...toSave } = data
    o[data.courseId] = { ...toSave }
    return o
  }, {})
}

const deepRemap = (fn: any) => {
  const deepRemapInner = (obj: any): any => {
    return mapObjIndexed((val: any, key: string) => {
      const res = fn(key, val)
      if (typeof val === "object" && !res.trans) {
        return deepRemapInner(val)
      }
      return res.val
    }, obj)
  }
  return deepRemapInner
}

const convertTimeToServerTimestamp = deepRemap((k: any, v: any) =>
  k === "startedTime" || k === "completedTime"
    ? { val: serverTimestamp(), trans: true }
    : { val: v, trans: false }
)

const convertServerTimeToNumber = deepRemap((k: any, v: any) =>
  k === "startedTime" || k === "completedTime"
    ? { val: v.toMillis(), trans: true }
    : { val: v, trans: false }
)

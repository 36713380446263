import { authStateSelector } from "@modules/auth/state/selectors"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { notificationsActions } from "./state"
import { selectNotificationTopics } from "./selectors"
import { useRootSelector } from "@modules/store/redux"

type Props = {
  children: React.ReactNode
}

const NotificationsProvider = (props: Props) => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector(authStateSelector)
  const { isSubscribedFCM } = useRootSelector((state) => state.notifications)

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(notificationsActions.watchNotifications.trigger())
    }

    return () => {
      if (isAuthenticated) {
        dispatch(notificationsActions.watchNotifications.fulfill())
      }
    }
  }, [isAuthenticated])

  React.useEffect(() => {
    if (isAuthenticated && !isSubscribedFCM) {
      dispatch(notificationsActions.subscribeFCM.trigger())
    }

    if (!isAuthenticated && isSubscribedFCM) {
      dispatch(notificationsActions.subscribeFCM.fulfill())
    }

    // return () => {
    //   if (isAuthenticated && topics.length > 0) {
    //     dispatch(notificationsActions.subscribeFCM.fulfill())
    //   }
    // }
  }, [isSubscribedFCM, isAuthenticated])

  return <>{props.children}</>
}

export { NotificationsProvider }

import { Calendar } from "../Calendar"
import CalendarStoreSelector from "../CalendarStoreSelector"
import { UpcomingEvents } from "../UpcomingEvents"

const CalendarControl = () => {
  return (
    <div className="flex flex-col gap-[50px]">
      <CalendarStoreSelector />
      <Calendar />
      <UpcomingEvents />
    </div>
  )
}

export default CalendarControl

import classNames from "classnames"
import React from "react"
import { DivProps } from "@ui/types"
import Spinner from "../Spinner"

export type ButtonVariant = "primary" | "light"

interface Props extends DivProps {
  loading?: boolean
  disabled?: boolean
  variant?: ButtonVariant
  fullWidth?: boolean
}

const Button = (props: Props) => {
  const {
    children,
    className,
    onClick,
    loading,
    disabled,
    variant,
    fullWidth,
    ...rest
  } = props

  const handleClick = (event: React.MouseEvent) => {
    if (loading || disabled) {
      return
    }

    props.onClick?.(event as any)
  }

  return (
    <div
      {...rest}
      onClick={(e) => handleClick(e)}
      className={classNames(
        "capitalize bg-red rounded-[11px] flex justify-center items-center h-[46px]",
        {
          "cursor-pointer": !loading && !disabled,
          "w-full": fullWidth,
          "!bg-grey text-light": variant === "light",
          "!bg-red text-white": variant === "primary",
          "px-2": !fullWidth,
        },
        props.className
      )}
    >
      {loading ? (
        <>
          <Spinner />
        </>
      ) : (
        <>{children}</>
      )}
    </div>
  )
}

export default Button

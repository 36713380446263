import { InlineLoader } from "@atoms"
import { IonContent } from "@ionic/react"
import IntroCarousel from "@modules/contents/components/IntroCarousel"
import { introCarouselSelector } from "@modules/contents/state/selectors"
import { useRootSelector } from "@modules/store/redux"
import { useSelector } from "react-redux"

const IntroCarouselContainer = () => {
  const isLoading = useRootSelector((state) => state.contents.isLoading)
  const carouselData = useSelector(introCarouselSelector)

  return (
    <IonContent fullscreen>
      {isLoading ? (
        <InlineLoader />
      ) : (
        <IntroCarousel carouselData={carouselData} />
      )}
    </IonContent>
  )
}

export default IntroCarouselContainer

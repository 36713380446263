import { useTranslations } from "@modules/app/hooks/useTranslations"
import { ChannelPreviewList } from "../Channel"
import { ChatPreviewList } from "../Chat"

const MessagingUserControl = () => {
  const { t } = useTranslations()
  return (
    <div className="">
      <div className="text-base font-[500] mb-[20px]">
        {t("messaging:control.channels.title")}
      </div>
      <ChannelPreviewList />
      <div className="text-base font-[500] mb-[20px]">
        {t("messaging:control.contacts.title")}
      </div>
      <ChatPreviewList />
    </div>
  )
}

export default MessagingUserControl

export const compareVersions = (a: string, b: string): number => {
  const pa = a.split(".")
  const pb = b.split(".")
  for (let i = 0; i < 3; i++) {
    const na = Number(pa[i])
    const nb = Number(pb[i])
    if (!isNaN(na) && isNaN(nb)) return 1
    if (isNaN(na) && !isNaN(nb)) return -1
    if (na > nb) return 1
    if (nb > na) return -1
  }
  return a > b ? 1 : b > a ? -1 : 0
}

export const isVersionGreater = (a: string, b: string): boolean => {
  return compareVersions(a, b) === 1
}

import { IonContent, IonHeader, IonToolbar } from "@ionic/react"
import { APPLE_STORE_APP_URL } from "@modules/deployments/consts"
import { Header } from "@molecules"
import { setCssVar } from "@punks/ui-core"
import { FrameLayout } from "@ui/layouts"
import UpdateBanner from "@ui/organisms/UpdateBanner"

const IosUpdateContainer = () => {
  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar
          className="flex items-end"
          style={{
            ...setCssVar("--min-height", 0),
          }}
        >
          <Header>
            <div className="font-semibold text-center text-md"></div>
          </Header>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <FrameLayout icon="download" title="Aggiorna APP">
          <UpdateBanner
            link={APPLE_STORE_APP_URL}
            cta="Aggiorna"
            description="Per continuare devi aggiornare l'app tramite Apple Store"
          />
        </FrameLayout>
      </IonContent>
    </>
  )
}

export default IosUpdateContainer

import { createDeepEqualsSelector } from "@helpers/selectors"
import { RootState } from "@modules/store/redux/rootReducer"
import {
  CourseChapter,
  CourseEntity,
  CourseTestQuestion,
} from "@common/common/api/functions"
import {
  CoursesProgressEntity,
  CoursesProgressTest,
} from "@common/common/types/firebase/coursesProgress"
import { createSelector } from "@reduxjs/toolkit"
import { CoursesProgress } from "@services/firebase/coursesProgress"
import { compareDesc, parseISO } from "date-fns"
import { pipe, pluck, uniq, values, last, keys } from "ramda"
import { CoursesState } from "."
import { Courses } from "../types/courses"

// TODO: CHORE -> need better organization

//PRIVATE
const chapters = ({ courses }: RootState) =>
  courses.courses[courses.selectedCourseId!].data.contents.chapters
const attachments = ({ courses }: RootState) =>
  courses.courses[courses.selectedCourseId!].data.attachments.items
const tests = ({ courses }: RootState) =>
  courses.courses[courses.selectedCourseId!].data.tests
const progress = ({ courses }: RootState) => courses.coursesProgress

export const selectedCategoryCode = ({ courses }: RootState) =>
  courses.selectedCategoryCode
export const selectedCompletedCategoryCode = ({ courses }: RootState) =>
  courses.selectedCompletedCategoryCode
export const selectedTestId = ({ courses }: RootState) => courses.selectedTestId

export const selectedViewIndex = ({ courses }: RootState) => {
  const courseProgress = courses.coursesProgress[courses.selectedCourseId!]
  if (courseProgress == null) {
    return 0
  }
  return courseProgress.status === "completed"
    ? courseProgress.viewIndex + 1
    : courseProgress.viewIndex
}
export const selectedChapterId = ({ courses }: RootState) =>
  courses.selectedChapterId ??
  courses.courses[courses.selectedCourseId!].data.contents.chapters[0].id
export const selectCourseDescription = ({ courses }: RootState) =>
  courses.courses[courses.selectedCourseId!].data.info

export const selectCourseList = createDeepEqualsSelector(
  ({ courses }: RootState) => courses.courses,
  (courses) =>
    values(courses)
      .sort((a, b) => compareDesc(parseISO(a.updatedAt), parseISO(b.updatedAt)))
      .map((x) => {
        return {
          id: x.id,
          categoryCode: x.data.info.categoryCode ?? "",
        }
      })
)
export const selectChapterIdList = createDeepEqualsSelector(
  chapters,
  pipe(pluck("id"))
)
export const selectAttachmentIdList = createDeepEqualsSelector(
  attachments,
  pipe(pluck("id"))
)
export const selectTestIdList = createDeepEqualsSelector(
  tests,
  pipe(pluck("id"))
)
export const selectTestQuestions = createDeepEqualsSelector(
  selectedTestId,
  tests,
  (testId, tests) => tests.find((x) => testId === x.id)!.questions
)
export const selectCompletedCourseIdList = createDeepEqualsSelector(
  ({ courses }: RootState) => courses.coursesProgress,
  (p) =>
    values(p)
      .filter((x) => x?.status === "completed" || (x?.viewIndex ?? 0) > 0)
      .map((x) => x?.courseId ?? "")
)
export const selectRunningCourseIdList = createDeepEqualsSelector(
  ({ courses }: RootState) => courses.coursesProgress,
  (p) =>
    values(p)
      .filter((x) => x?.status === "running" && (x?.viewIndex ?? 0) === 0)
      .map((x) => x?.courseId ?? "")
)
export const selectCourseCategoryCodeList = createDeepEqualsSelector(
  selectCourseList,
  pipe(pluck("categoryCode"), uniq)
)
export const selectCompletedCategoryCodeList = createDeepEqualsSelector(
  ({ courses }: RootState) => courses.courses,
  ({ courses }: RootState) => courses.coursesProgress,
  (courses, progress) => {
    const vals = values(courses)
      .filter(
        ({ id }) =>
          progress[id]?.status === "completed" ||
          (progress[id]?.viewIndex ?? 0) > 0
      )
      .map((item) => item.data.info.categoryCode ?? "")
    const uni = uniq(vals)
    return uni
  }
)
export const selectCourseById =
  (id: string) =>
  ({ courses }: RootState) =>
    courses.courses[id!]
export const selectAttachmentById = (id: string) =>
  createSelector(attachments, (data) => data.find((x) => id === x.id)!)
export const selectChapterByIndex =
  (index: number) =>
  ({ courses }: RootState): CourseChapter | undefined =>
    courses.courses[courses.selectedCourseId!].data.contents.chapters[index]
export const selectChapterById = (id: string) =>
  createSelector(chapters, (data) => data.find((x) => id === x.id)!)
const selectedTest = createSelector(
  tests,
  selectedTestId,
  (data, id) => data.find((x) => id === x.id)!
)
export const selectTestById = (id: string) =>
  createSelector(tests, (data) => data.find((x) => id === x.id)!)
export const selectTestQuestionById = (id: string) =>
  createSelector(
    selectedTest,
    (test) => test.questions.find((x) => id === x.id)!
  )
export const selectNextTestQuestionById = (currentId: string) =>
  createSelector(selectedTest, (test) => {
    const currentIndex = test.questions.findIndex((x) => x.id === currentId)
    return (test.questions[currentIndex + 1] as CourseTestQuestion) || undefined
  })
export const selectPreviousTestQuestionById = (currentId: string) =>
  createSelector(selectedTest, (test) => {
    const currentIndex = test.questions.findIndex((x) => x.id === currentId)
    return (
      (test.questions[currentIndex + -1] as CourseTestQuestion) || undefined
    )
  })
// export const selectTestQuestionByIndex = (index: number) => createSelector(selectedTest, (test)=> test.questions[index])
// export const selectTestQuestionIndexById = (id: string) => createSelector(selectedTest, (test)=> datal.findIndex(x=>id===x.id)!)

export const selectProgressExists = ({ courses }: RootState) =>
  courses.coursesProgress[courses.selectedCourseId!] != null

export const selectChapterProgressById =
  (id: string) =>
  ({ courses }: RootState) =>
    courses.coursesProgress[courses.selectedCourseId!]?.progress.chapters?.[id]

export const selectAttachmentProgressById =
  (id: string) =>
  ({ courses }: RootState) =>
    courses.coursesProgress[courses.selectedCourseId!]?.progress.attachments?.[
      id
    ]

export const selectTestProgressAttemptId = createSelector(
  ({ courses }: RootState) => {
    const attempts =
      courses.coursesProgress[courses.selectedCourseId!]?.progress.tests?.[
        courses.selectedTestId!
      ]?.attempts ?? {}
    const attempt = last(
      keys(attempts)
        .map((x) => {
          return {
            passed: attempts[x].passed,
            index: Number.parseInt(x),
          }
        })
        .sort((a, b) => a.index - b.index)
    )
    if (attempt == null) {
      return "0"
    }
    if (attempt.passed) {
      return attempt.index.toString()
    }
    if (attempt.passed != null && !attempt.passed) {
      return (attempt.index + 1).toString()
    }
    return attempt.index.toString()
  },
  (id) => id
)

export const selectTestProgressById =
  (attemptId: string) =>
  ({ courses }: RootState) =>
    courses.coursesProgress[courses.selectedCourseId!]?.progress.tests?.[
      courses.selectedTestId!
    ]?.attempts[attemptId]

export const selectTestQuestionProgressById =
  (attemptId: string, questionId: string) =>
  ({ courses }: RootState) =>
    courses.coursesProgress[courses.selectedCourseId!]?.progress.tests?.[
      courses.selectedTestId!
    ]?.attempts[attemptId]?.answers?.[questionId]

export const selectCourseCompletionPercentageMap = createDeepEqualsSelector(
  progress,
  ({ courses }: RootState) => courses.courses,
  (progress, courses) => {
    return Object.entries(progress).reduce((o, [k, v]) => {
      if (v?.viewIndex != null && v.viewIndex > 0) {
        o[k] = 1
        return o
      }
      if (v == null) {
        o[k] = 0
        return o
      }
      const numCompletedFiles =
        Object.values(v?.progress.attachments ?? {}).filter((x) => x.completed)
          .length ?? 0
      const numCompletedVideos =
        Object.values(v?.progress.chapters ?? {}).filter((x) => x.completed)
          .length ?? 0
      const numCompletedTests =
        Object.values(v?.progress.tests ?? {}).filter(
          (x) =>
            Object.values(x.attempts).find((x) => x.passed === true) != null
        ).length ?? 0
      const numFiles = courses[k]?.data.attachments.items.length ?? 0
      const numVideos = courses[k]?.data.contents.chapters.length ?? 0
      const numTests = courses[k]?.data.tests.length ?? 0

      const totContents = numFiles + numVideos + numTests
      if (totContents === 0) {
        o[k] = 0
      } else {
        const value =
          (numCompletedFiles + numCompletedVideos + numCompletedTests) /
          (numFiles + numVideos + numTests)

        o[k] = isFinite(value) && !isNaN(value) ? value : 0
      }
      return o
    }, {} as Record<string, number>)
  }
)

export const selectAreTestsEnabled = ({ courses }: RootState) => {
  const course = courses.courses[courses.selectedCourseId!]
  const courseProgress = courses.coursesProgress[courses.selectedCourseId!]

  const numFiles = course.data.attachments.items.length
  const numVideos = course.data.contents.chapters.length
  const numCompletedFiles =
    Object.values(courseProgress?.progress.attachments ?? {}).filter(
      (x) => x.completed
    ).length ?? 0
  const numCompletedVideos =
    Object.values(courseProgress?.progress.chapters ?? {}).filter(
      (x) => x.completed
    ).length ?? 0
  return numFiles === numCompletedFiles && numVideos === numCompletedVideos
}

export const selectCourseCompletionStatus = createSelector(
  selectCourseList,
  selectCompletedCourseIdList,
  (coursesIds, completedCoursesIds) => {
    const currentCoursesCompletedId = completedCoursesIds.filter((x) =>
      coursesIds.some((c) => c.id === x)
    )

    console.log("currentCoursesCompletedId", currentCoursesCompletedId)
    return {
      completedCourses: currentCoursesCompletedId.length,
      courses: coursesIds.length,
    }
  }
)

const getCoursesProgress = (coursesProgress: CoursesProgress) => {
  return Object.keys(coursesProgress)
    .map(
      (courseId) => coursesProgress[courseId] as CoursesProgressEntity<number>
    )
    .filter((x) => x)
}

const getCourseTests = (course: CoursesProgressEntity<number>) => {
  return Object.keys(course.progress.tests ?? {})
    .map((testId) => ({
      progress: course.progress.tests?.[testId] as CoursesProgressTest<number>,
      testId,
      courseId: course.courseId,
      courseStatus: course.status,
      viewIndex: course.viewIndex,
    }))
    .filter((x) => x)
}

const getAllCourseTestsProgress = (coursesProgress: CoursesProgress) => {
  return getCoursesProgress(coursesProgress).flatMap(getCourseTests)
}

const isTestCompleted = (test: CoursesProgressTest<number>) => {
  return Object.keys(test.attempts).some(
    (attemptId) => test.attempts[attemptId].passed
  )
}

const getAllCourses = (courses: Courses) => {
  return Object.keys(courses).map(
    (courseId) => courses[courseId] as CourseEntity
  )
}

const getAllCoursesTests = (courses: Courses) => {
  return getAllCourses(courses).flatMap((course) =>
    course.data.tests.map((x) => ({
      test: x,
      course,
    }))
  )
}

export const selectCoursesTestStatus = createSelector(
  ({ courses }: RootState) => courses,
  selectCompletedCourseIdList,
  ({ coursesProgress, courses }: CoursesState, completedCoursesIds) => {
    const testsProgress = getAllCourseTestsProgress(coursesProgress)
    const tests = getAllCoursesTests(courses)

    const getTestProgress = (testId: string) =>
      testsProgress.find((x) => x.testId === testId)

    const completedTestItems = tests.filter((test) => {
      if (completedCoursesIds.includes(test.course.id)) {
        return true
      }

      const progress = getTestProgress(test.test.id)
      if (!progress) {
        return false
      }

      const isPassed = isTestCompleted(progress.progress)
      return isPassed
    })

    return {
      completedTests: completedTestItems.length,
      tests: tests.length,
    }
  }
)

export const selectedTestQuestionId = createSelector(
  selectTestProgressAttemptId,
  ({ courses }: RootState) => courses,
  (testAttempId, courses) => {
    if (courses.selectedTestQuestionId != null) {
      return courses.selectedTestQuestionId
    }
    const progressQuestionId = last(
      Object.keys(
        courses.coursesProgress?.[courses.selectedCourseId!]?.progress.tests?.[
          courses.selectedTestId!
        ]?.attempts[testAttempId!]?.answers ?? {}
      )
        .map((questionId) => {
          const index =
            courses.courses[courses.selectedCourseId!].data.tests
              .find((test) => test.id === courses.selectedTestId!)
              ?.questions.findIndex((question) => question.id === questionId) ??
            0
          return {
            questionId,
            index,
          }
        })
        .sort((a, b) => a.index - b.index)
    )?.questionId
    return (
      progressQuestionId ??
      courses.courses[courses.selectedCourseId!].data.tests.find(
        (x) => x.id === courses.selectedTestId!
      )?.questions[0].id
    )
  }
)
export const selectIsTestComplete = (testId: string) =>
  createSelector(
    ({ courses }: RootState) =>
      Object.values(
        courses.coursesProgress[courses.selectedCourseId!]?.progress.tests?.[
          testId
        ]?.attempts ?? {}
      ).find((x) => x.passed),
    (result) => result != null
  )
export const isCourseCompleted = ({ courses }: RootState) =>
  courses.coursesProgress?.[courses.selectedCourseId!]?.status === "completed"

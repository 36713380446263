import { ChannelController } from "../../database/messaging/channel/channel.controller"
import { MessageAttachment } from "../../frontend/features/messaging/state/types"
import { setServerTimestamp } from "../../infrastructure/firebase/controller"
import { encryptMessage } from "./common"
import { toImAttachment } from "./converters"

export interface SendChannelMessageInput {
  messageId: string
  message: string
  subject?: string
  channelId: string
  privateKey: string
  senderId: string
  attachments: MessageAttachment[]
}

export const sendChannelMessage = async (
  controller: ChannelController,
  input: SendChannelMessageInput
) => {
  const messageData = encryptMessage({
    message: input.message,
    subject: input.subject,
    privateKey: input.privateKey,
  })

  await controller.appendMessage(input.channelId, {
    t: setServerTimestamp(),
    si: input.senderId,
    m: messageData.message,
    s: messageData.subject as string,
    otc: messageData.oneTimeCode,
    e: "sym1",
    id: input.messageId,
    r: false,
    a: input.attachments.filter((x) => x.asset).map((x) => toImAttachment(x)),
    del: false,
  })
  await controller.updateChanelTimestamp(input.channelId)
}

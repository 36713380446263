import { fork, takeLatest } from "typed-redux-saga"
import { notificationsActions } from "."
import { notificationsRootSaga as baseNotificationsRootSaga } from "@common/common/frontend/features/notifications/sagas"
import { subscribeNotificationsSaga } from "../sagas/subscribeNotificationsSaga"

export function* notificationsRootSaga() {
  yield* fork(baseNotificationsRootSaga)
  yield* takeLatest(
    notificationsActions.subscribeFCM.TRIGGER,
    subscribeNotificationsSaga
  )
}

import { RootState } from "@modules/store/redux/rootReducer"
import { createSelector as cs } from "@reduxjs/toolkit"
import { selectAttachments } from "./lists"

export const selectAttachmentById = (attachmentId: string) =>
  cs(selectAttachments, (data) => data.find((x) => attachmentId === x.id)!)

export const selectAttachmentProgressById =
  (id: string) =>
  ({ courses }: RootState) =>
    courses.coursesProgress[courses.selectedCourseId!]?.progress.attachments?.[
      id
    ]

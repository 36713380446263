import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { authActions } from "./state"
import { useRootSelector } from "@modules/store/redux"

type Props = {
  children: React.ReactNode
}

const AuthProvider = (props: Props) => {
  const dispatch = useDispatch()
  const { isInitialized } = useRootSelector((state) => state.app)

  useEffect(() => {
    if (isInitialized) {
      dispatch(authActions.watch())
    }
  }, [isInitialized])

  return <>{props.children}</>
}

export { AuthProvider }

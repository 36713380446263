import { useLongPress } from "@ui/hooks/useLongPress"
import classNames from "classnames"
import React from "react"

type Props = {
  isMine: boolean
  hasSingleImage: boolean
  hasAttachments: boolean
  children: React.ReactNode
  noMargin?: boolean
  onLongPress?: () => void
  longPressEnabled?: boolean
  onClick?: () => void
}

const Container = ({
  isMine,
  hasSingleImage,
  hasAttachments,
  noMargin,
  children,
  onLongPress,
  longPressEnabled,
  onClick,
}: Props) => {
  const { ref } = useLongPress({
    callback: () => onLongPress?.(),
    disabled: !longPressEnabled,
  })
  return (
    <div
      ref={ref}
      className={classNames("w-full flex ", isMine && "justify-end")}
      onClick={onClick}
    >
      <div
        className={classNames("max-w-[92%]", {
          "mb-[27px]": !noMargin,
          "w-[92%]": hasAttachments,
          "w-[200px]": hasSingleImage,
        })}
      >
        {children}
      </div>
    </div>
  )
}

export { Container }

import { IonCheckbox } from "@ionic/react"
import { setCssVar } from "@punks/ui-core"

export interface CheckboxProps {
  checked?: boolean
  disabled?: boolean
  onToggle?: (checked: boolean) => void
  size?: "sm" | "md" | "lg"
}

const Checkbox = ({ checked, disabled, size, onToggle }: CheckboxProps) => {
  return (
    <IonCheckbox
      style={{
        ...(size === "lg" ? setCssVar("--size", "24px") : {}),
      }}
      checked={checked}
      disabled={disabled}
      onIonChange={(e) => onToggle?.(e.detail.checked)}
    />
  )
}

export default Checkbox

import { useCallback, useRef } from "react"
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom"

export interface ZoomableImageProps {
  src: string
  className?: string
}

const ZoomableImage = ({ src, className }: ZoomableImageProps) => {
  const imgRef = useRef<HTMLImageElement>(null)
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef

    if (img) {
      const value = make3dTransformValue({ x, y, scale })

      img.style.setProperty("transform", value)
    }
  }, [])

  return (
    <QuickPinchZoom onUpdate={onUpdate}>
      <img ref={imgRef} className={className} src={src} />
    </QuickPinchZoom>
  )
}

export default ZoomableImage

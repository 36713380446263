import { buffers, eventChannel } from "redux-saga"
import { put, take, cancelled, takeLatest, fork } from "typed-redux-saga"
import { DocumentData } from "firebase/firestore"
import { PayloadAction } from "@reduxjs/toolkit"
import { getSagaContext } from "../../../state/context"
import { ChannelMessagesSubscribeInput } from "../state/types"
import { ChannelPreviewEntity } from "../../../../database/messaging/channel-preview/channel-preview.entity"
import { decodeAndNormalizeChannelMsg } from "../state/converters/encryption"
import { fetchChannelMessagesProfiles } from "./fetchChannelMessagesProfiles"

// const DEFAULT_CHANNEL_MESSAGES_FETCH = 10

export function* watchChannelMessages(
  action: PayloadAction<ChannelMessagesSubscribeInput>
) {
  console.log("CHANNEL MESSAGES -> start", action.payload)
  const state = yield* getSagaContext("state")
  const { channel: messagingChannel, channelPrivateKey } = action.payload
  const firebase = yield* getSagaContext("firebase")
  const channel = eventChannel<DocumentData[]>((emitter) => {
    const unsubscribe =
      firebase.collections.channels.subscribeChannelNewMessages(
        messagingChannel.data.channelId,
        (data) => {
          emitter(data)
        }
      )
    return () => unsubscribe()
  }, buffers.sliding(1))
  try {
    yield* takeLatest(
      state.messaging.actions.channelMessagesFulfill,
      function* (action: PayloadAction<ChannelPreviewEntity>) {
        if (action.payload.data.channelId === messagingChannel.data.channelId) {
          channel.close()
        }
      }
    )
    let firstResult = true
    while (true) {
      const data = yield* take(channel)
      const decodedMessages = data.map((x) =>
        decodeAndNormalizeChannelMsg(channelPrivateKey, x.data())
      )

      yield* fork(fetchChannelMessagesProfiles, decodedMessages)

      yield* put(
        state.messaging.actions.channelMessagesAppend({
          channelId: messagingChannel.data.channelId,
          messages: decodedMessages,
          cursor: data[0]?.id,
          isNewMessage: true,
        })
      )

      yield* put(
        state.messaging.actions.sendChannelReadNotification({
          channelId: messagingChannel.data.channelId,
          type: "read",
          lastMessageId: data[0]?.id,
        })
      )

      // if (firstResult && data.length > 0) {
      //   firstResult = false
      //   yield* put(
      //     state.messaging.actions.channelMessagesLoadMore({
      //       channelId: messagingChannel.data.channelId,
      //       pageSize: DEFAULT_CHANNEL_MESSAGES_FETCH,
      //     })
      //   )
      // }
    }
  } catch (error) {
    yield* put(
      state.messaging.actions.channelMessagesSetFaulted({
        channelId: messagingChannel.data.channelId,
        error,
      })
    )
  } finally {
    if (yield* cancelled()) {
      channel.close()
    }
    console.log("CHANNEL MESSAGES -> closes", action.payload)
  }
}

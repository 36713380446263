import { useUserTypeLabel } from "@modules/app/hooks/useUserTypeLabel"
import { ContactInfo } from "@package/common/frontend/features/contacts/types"
import { capitalize } from "lodash"

export interface ContactLabelProps {
  contact: ContactInfo
}

const ContactLabel = ({ contact }: ContactLabelProps) => {
  const { getUserTypeLabel } = useUserTypeLabel()
  return (
    <div className="text-sm font-bold">
      {capitalize(contact.fistName)} {capitalize(contact.lastName)}
      <span className="text-greyDark pl-1">
        {"("}
        {getUserTypeLabel(contact.userType)}
        {")"}
      </span>
    </div>
  )
}

export default ContactLabel

import { Icon } from "@atoms"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { CourseInfo } from "@package/common/api/functions"

type BaseProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "class" | "className" | "children" | "style" | "color"
>
interface Props extends BaseProps {
  info: CourseInfo
}

const CourseTime = ({ info }: Props) => {
  const { duration } = info ?? {}
  const { t } = useTranslations()
  return (
    <div className="text-sm font-normal text-light flex items-center">
      <Icon iconName="clock" className="w-[16px] h-[16px] mr-1" />
      {`${duration?.hours ?? 0}${t("courses:duration.hours")} ${
        duration?.minutes ?? 0
      }${t("courses:duration.minutes")}`}
    </div>
  )
}

export default CourseTime

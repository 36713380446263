import { useRootSelector } from "@modules/store/redux"
import { useRouting } from "@root/routing/useRouting"
import { useEffect } from "react"

const DeploymentsRedirect = () => {
  const { updateState } = useRootSelector((state) => state.deployments)
  const { goTo, routeInfo } = useRouting()

  useEffect(() => {
    if (updateState === "apple-store-update-required") {
      goTo("iosAppUpdate")
    }
    if (updateState === "play-store-update-required") {
      goTo("androidAppUpdate")
    }
  }, [updateState, routeInfo?.pathname])

  return <></>
}

export default DeploymentsRedirect

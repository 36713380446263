import { StoreSalesData } from "@atoms"
import { WeekDays, WeekMap } from "@modules/app/hooks/useWeekDays"
import { RootState } from "@modules/store/redux/rootReducer"
import { ObjectiveItem } from "@common/common/database/performance/objective-item/objective-item.entity"
import {
  StoreSellingPerformances,
  StoreSellingProgress,
} from "@common/common/frontend/features/performance/state/types"
import { createSelector } from "@reduxjs/toolkit"
import { parse, getDay, differenceInDays } from "date-fns"
import { groupBy, orderBy } from "lodash"
import { PerformanceData } from "@ui/atoms/PerformanceBars"

const DAYS_RETENTION_FOR_DELETE = 3

const isValidItem = (item: ObjectiveItem) => {
  return (
    differenceInDays(new Date(), new Date(item.updateTime)) <=
    DAYS_RETENTION_FOR_DELETE
  )
}

const appUsageSelector = (state: RootState) => state?.performances.appUsage
const storeSalesSelector = (state: RootState) => state?.performances.storeSales
const storeSalesProgressSelector = (state: RootState) =>
  state?.performances.storesSalesProgress

export const selectWeekAppUsage = ({
  weekDays,
  weekMap,
}: {
  weekDays: WeekDays
  weekMap: WeekMap
}) =>
  createSelector(appUsageSelector, (appUsage): PerformanceData[] => {
    const dummyDate = new Date()
    let lastDayNumber: number | null = null
    const data =
      appUsage?.data?.slice(0, 7)?.reduce((o, data) => {
        const parsedDate = parse(data.date, "yyyy-MM-dd", dummyDate)
        const day = getDay(parsedDate)
        if (lastDayNumber == null) {
          lastDayNumber = day
        }
        o[day] = data.totMinutes ?? 0
        return o
      }, {} as Record<number, number>) ?? {}

    return weekDays.map((x, i) => {
      const currentDay: keyof typeof weekMap = (((lastDayNumber ?? 1) + 1 + i) %
        7) as any
      return {
        date: weekMap[currentDay],
        minutes: data[currentDay] ?? 0,
        // minutes: randomIntFromInterval(0,480),
      }
    })
  })

export const selectStoreProgress = ({
  weekDays,
  weekMap,
}: {
  weekDays: WeekDays
  weekMap: WeekMap
}) =>
  createSelector(storeSalesSelector, (sales): StoreSalesData[] => {
    const dummyDate = new Date()
    let lastDayNumber: number | null = null
    const data =
      sales?.data?.slice(0, 7).reduce((o, data) => {
        const parsedDate = parse(data.date, "yyyy-MM-dd", dummyDate)
        const day = getDay(parsedDate)
        if (lastDayNumber == null) {
          lastDayNumber = day
        }
        o[day] = data.aggregated.progressPercent ?? 0
        return o
      }, {} as Record<number, number>) ?? {}

    return weekDays.map((x, i) => {
      const currentDay: keyof typeof weekMap = (((lastDayNumber ?? 1) + 1 + i) %
        7) as any
      return {
        date: weekMap[currentDay],
        percent: Math.round(data[currentDay] ?? 0),
        isToday: i === 6,
        // percent: randomIntFromInterval(5,100),
      }
    })
  })

const getTodaySales = (sales: StoreSellingPerformances) =>
  orderBy(sales?.data ?? [], (x) => x.date, "desc")[0]

export const selectStoreSalesItems = createSelector(
  storeSalesSelector,
  (sales): ObjectiveItem[] => {
    return getTodaySales(sales)?.allItems ?? []
  }
)

export interface ObjectiveItemGroup {
  id: string
  title: string
  items: ObjectiveItem[]
}

const groupItems = (items: ObjectiveItem[]): ObjectiveItemGroup[] => {
  return Object.entries(groupBy(items, (x) => x.description)).map(
    ([key, x]) => ({
      id: x[0].code,
      title: x[0].description,
      items: x,
    })
  )
}

export const selectStoreSalesItemsGroupedByType = createSelector(
  storeSalesSelector,
  (sales): ObjectiveItemGroup[] => {
    return groupItems(
      getTodaySales(sales)?.allItems.filter((x) => isValidItem(x)) ?? []
    )
  }
)

export const selectStoreSalesPercentage = createSelector(
  storeSalesSelector,
  (sales): number => {
    return getTodaySales(sales)?.aggregated?.progressPercent ?? 0
  }
)

const getTodaySalesProgress = (sales: StoreSellingProgress) =>
  orderBy(sales?.data ?? [], (x) => x.date, "desc")[0]

export const selectStoreSalesProgressPercentage = createSelector(
  storeSalesProgressSelector,
  (sales): number => {
    console.log("sales", getTodaySalesProgress(sales))
    return getTodaySalesProgress(sales)?.progressPercent ?? 0
  }
)

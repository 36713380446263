import { FirestoreCollections } from "../database/collections"
import { getContext } from "redux-saga/effects"
import { DefaultClient } from "../../api/functions"
import { FirebaseStorage } from "firebase/storage"
// import { CommonSlices } from "./types"
import { IStore } from "../../abstractions/storage"
// import { CommonSagaStateContext } from "./generator"
import { CommonSlices } from "./types"
import { Slice } from "@reduxjs/toolkit"

export type LoginInfo = {
  email: string
  password: string
}

export type SagaContext<T extends unknown> = {
  api: typeof DefaultClient
  firebase: Firebase
  state: T
  store: IStore
}

export interface Firebase {
  login: (data: LoginInfo) => void
  getUid: () => string | undefined
  collections: FirestoreCollections
  storage: FirebaseStorage
}

export function* getSagaContext<T extends keyof SagaContext<CommonSlices>>(
  key: T
): Generator<any, SagaContext<CommonSlices>[T], any> {
  return yield getContext(key)
}

export const getSliceContextCreator =
  <Routines extends {}>(routines?: Routines) =>
  <T extends Slice, ExtraRoutines extends {}>(
    slice: T,
    extraRoutines?: ExtraRoutines
  ): Omit<T, "actions"> & {
    actions: T["actions"] & Routines & ExtraRoutines
  } => {
    return {
      ...slice,
      actions: {
        ...(slice.actions as any),
        ...(routines as any),
        ...(extraRoutines as any),
      },
    }
  }
export const getStateContext = <T extends { [P in keyof CommonSlices]: T[P] }>(
  input: T
) => input

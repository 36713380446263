import { select, put } from "typed-redux-saga"
import { ImDecodedChannelMessage } from "../state/types"
import { contactsSlice } from "../../contacts/state"
import { ContactInfo } from "../../contacts/types"
import { ContactsState } from "../../contacts/state/types"
import { uniq } from "lodash"

const CONTACT_DATA_EXPIRATION_MINUTES = 5

const shouldRefetchContactData = (contact: ContactInfo) => {
  const now = new Date()
  const lastFetched = new Date(contact.timestamp)
  const diff = now.getTime() - lastFetched.getTime()
  const minutes = Math.floor(diff / 1000 / 60)

  return minutes > CONTACT_DATA_EXPIRATION_MINUTES
}

export function* fetchChannelMessagesProfiles(
  payload: ImDecodedChannelMessage[]
) {
  console.log(`fetchChannelMessagesProfiles -> payload`, payload)
  const distinctUids = uniq(payload.map((x) => x.si).filter((x) => x))
  for (const uid of distinctUids) {
    console.log(`fetchChannelMessagesProfiles -> processing uid ${uid}`)

    const contact = yield* select(
      (state) => (state.contacts as ContactsState["contacts"])[uid]
    )

    if (contact?.loading) {
      console.log(
        `fetchChannelMessagesProfiles -> profile already loading ${uid}}`
      )
      return
    }

    if (
      contact?.data &&
      !contact?.success &&
      !shouldRefetchContactData(contact.data)
    ) {
      console.log(
        `fetchChannelMessagesProfiles -> profile already loaded ${uid}}`
      )
      return
    }

    yield* put(contactsSlice.actions.contactFetchTrigger(uid))
    console.log(
      `fetchChannelMessagesProfiles -> profile load triggered ${uid}}`
    )
  }
}

import { appName, appReducer } from "@modules/app/state"
import { authName, authReducer } from "@modules/auth/state"
import { coursesName, coursesReducer } from "@features/courses/state"
import { messagingReducer, messagingName } from "@features/messaging/state"
import { calendarReducer, calendarName } from "@features/calendar/state"
import { profileReducer, profileName } from "@features/profile/state"
import { contentsName, contentsReducer } from "@modules/contents/state"
import {
  performanceName,
  performanceReducer,
} from "@features/performance/state"
import {
  notificationsName,
  notificationsReducer,
} from "@features/notifications/state"

import { combineReducers } from "@reduxjs/toolkit"
import { deploymentsSlice } from "@modules/deployments/state/store"
import { contactsSlice } from "@common/common/frontend/features/contacts/state"

export const rootReducer = combineReducers({
  [authName]: authReducer,
  [coursesName]: coursesReducer,
  [appName]: appReducer,
  [contentsName]: contentsReducer,
  [messagingName]: messagingReducer,
  [calendarName]: calendarReducer,
  [profileName]: profileReducer,
  [performanceName]: performanceReducer,
  [notificationsName]: notificationsReducer,
  [deploymentsSlice.name]: deploymentsSlice.reducer,
  [contactsSlice.name]: contactsSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

import classNames from "classnames"
import React from "react"

interface Props {
  count?: number
  className?: string
}
const Notification = ({ count, className }: Props) => {
  return (
    <div
      className={classNames(
        "rounded-full bg-red flex items-center justify-center w-[17px] h-[17px] text-white text-2xs font-[500] ",
        className
      )}
    >
      <div>{count}</div>
    </div>
  )
}

export default Notification

import { Icon } from "@atoms"
import classNames from "classnames"
import React from "react"

interface Props {
  onClick?: () => void
  preventDefault?: boolean
  classes?: {
    container?: string
    icon?: string
  }
}
const PlayButton = ({ onClick, classes, preventDefault }: Props) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-center  w-full h-full rounded-full bg-red",
        classes?.container
      )}
      onClick={(e) => {
        onClick?.()
        if (preventDefault) {
          e.preventDefault()
        }
      }}
    >
      <Icon iconName="play" className="!w-[40%] !h-[40%] text-white" />
    </div>
  )
}

export default PlayButton

export const extractDate = (str: string) => str?.split("T")?.[0]

export const toItaDate = (dateStr: string) => {
  const p = dateStr.split("-")
  return `${p[2]}/${p[1]}/${p[0]}`
}

export const formatTimeHHMM = (d: Date) =>
  `${d.getHours().toString().padStart(2, "0")}:${d
    .getMinutes()
    .toString()
    .padStart(2, "0")}`

export const isToday = (d: Date, refDate: Date) => {
  return (
    d.getFullYear() === refDate.getFullYear() &&
    d.getMonth() === refDate.getMonth() &&
    d.getDate() === refDate.getDate()
  )
}

export const isYesterday = (d: Date, refDate: Date) => {
  return (
    d.getFullYear() === refDate.getFullYear() &&
    d.getMonth() === refDate.getMonth() &&
    d.getDate() === refDate.getDate() - 1
  )
}

export const isThisMonth = (d: Date, refDate: Date) => {
  return (
    d.getFullYear() === refDate.getFullYear() &&
    d.getMonth() === refDate.getMonth()
  )
}

export const isThisYear = (d: Date, refDate: Date) => {
  return d.getFullYear() === refDate.getFullYear()
}

export const isoToItaDate = (date: string) => {
  if (!date) {
    return
  }
  const p = date.split("-")
  return [p[2], p[1], p[0]].join("/")
}

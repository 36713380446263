import { Button, TextInput } from "@atoms"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { authActions } from "@modules/auth/state"
import { useRootSelector } from "@modules/store/redux"
import { FrameLayout } from "@ui/layouts"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { useDebouncedCallback } from "use-debounce"

const INPUT_DELAY = 200

const UserImpersonatePanel = () => {
  const { t } = useTranslations()
  const [uid, setUid] = useState("")
  const dispatch = useDispatch()

  const debouncedSave = useDebouncedCallback((value: string) => {
    dispatch(authActions.setUserImpersonationUid(value))
  }, INPUT_DELAY)

  const { impersonation } = useRootSelector((state) => state.auth)

  const handleClear = () => {
    setUid("")
    dispatch(authActions.clearUserImpersonationUid())
  }

  return (
    <FrameLayout
      icon="support"
      title={t("auth:userImpersonate.title")}
      className="pb-16"
    >
      <TextInput
        name="uid"
        type="text"
        value={uid}
        onChange={(value) => {
          setUid(value)
          debouncedSave(value)
        }}
        placeholder={t("auth:userImpersonate.placeholder")}
      />

      {impersonation?.uid && (
        <div className="mt-8">
          <Button onClick={handleClear} variant="primary">
            {t("auth:userImpersonate.clear.label")}
          </Button>
        </div>
      )}
    </FrameLayout>
  )
}

export default UserImpersonatePanel

import React, { ReactNode } from "react"
import { Footer as FooterContainer } from "@atoms"

interface Props {
  children: ReactNode
  noRounding?: boolean
}

const Footer = ({ children, noRounding }: Props) => {
  return (
    <FooterContainer noRounding={noRounding}>
      <div className="px-[24px] py-[14px]">{children}</div>
    </FooterContainer>
  )
}

export default Footer

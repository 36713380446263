import { PayloadAction } from "@reduxjs/toolkit"
import { put, call, select } from "typed-redux-saga"
import { sendChatMessage } from "../../../../features/messaging/chat"
import { Firebase, getSagaContext } from "../../../state/context"
import { CommonState } from "../../../state/types"
import { ChatMessageSendTriggerInput, MessageDraft } from "../state/types"

const getPrivateKey = (state: CommonState) =>
  state.messaging.keys?.userKey?.personalPrivateKey

const getChatDraft = (state: CommonState, chatId: string) =>
  state.messaging.chatMessages[chatId].draft

export function* sendChatMessageSaga(
  action: PayloadAction<ChatMessageSendTriggerInput>
) {
  const state = yield* getSagaContext("state")
  const {
    payload: { chatId },
  } = action
  try {
    const privateKey = yield* select(getPrivateKey)
    if (!privateKey) {
      throw new Error(`Cannot get user key for channel ${privateKey}`)
    }

    const firebase = yield* getSagaContext("firebase")
    const draft = yield* select(getChatDraft, chatId)

    yield* call(
      (
        firebase: Firebase,
        draft: MessageDraft,
        chatId: string,
        privateKey: string
      ) => {
        sendChatMessage(firebase.collections.chat, {
          chatId,
          message: draft.content.message,
          subject: draft.content.subject,
          messageId: draft.messageId,
          privateKey,
          senderId: firebase.getUid() as string,
          attachments: draft.attachments.items,
        })
      },
      firebase,
      draft,
      chatId,
      privateKey
    )

    yield* put(
      state.messaging.actions.chatDraftSendCompleted({
        chatId,
      })
    )
  } catch (e) {
    yield* put(
      state.messaging.actions.chatDraftSendError({
        chatId,
        error: (e as Error).message,
      })
    )
  }
}

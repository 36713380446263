import { UserType } from "@common/common/types/user"
import { useTranslations } from "./useTranslations"

export const useUserTypeLabel = () => {
  const { t } = useTranslations()
  const getUserTypeLabel = (userType?: UserType) => {
    switch (userType) {
      case "admin":
        return t("common:userType.admin")
      case "brand":
        return t("common:userType.brand")
      case "manager":
        return t("common:userType.manager")
      case "user":
        return t("common:userType.user")
      case "storeManager":
        return t("common:userType.storeManager")
      case "areaManager":
        return t("common:userType.areaManager")
      case "chief":
        return t("common:userType.chief")
      default:
        return t("common:userType.default")
    }
  }

  return {
    getUserTypeLabel,
  }
}

import { call, fork, take, put, delay, race } from "typed-redux-saga"
import { eventChannel } from "redux-saga"
import { isWebPlatform } from "@modules/app/utils/device"
import { getSagaContext } from "@modules/store/redux/sagaContext"
import { deviceRegister } from "@modules/device/register"
import { notificationsProvider } from "@modules/notifications"
import { notificationsActions } from "../state"

const PERMISSION_CHECK_DELAY = 5000

function* watchFcmTokenSaga() {
  console.log("watchFcmTokenSaga -> called")
  // const api = yield* getSagaContext("api")
  const onTokenChannel = eventChannel<string>((emitter) => {
    const listener = notificationsProvider.subscribeToken((token) => {
      emitter(token)
    })
    return async () => await notificationsProvider.unregister()
  })
  while (true) {
    const token = yield* take(onTokenChannel)

    console.log(`watchFcmTokenSaga -> token: ${token}`)
    yield* call(deviceRegister, {
      fcmToken: token,
    })

    yield* put(notificationsActions.setFcmToken(token))

    // console.log(`Subscribing to topics -> token: ${token}`, topics)
    // yield* call(api.subscribeFcmTopics, {
    //   fcmToken: token,
    //   topics,
    // })
  }
}

function* watchNotificationsSaga() {
  console.log("watchNotificationsSaga -> Triggered")

  const history = yield* getSagaContext("history")
  const onMessagingChannel = eventChannel<string>((emitter) => {
    const listener = notificationsProvider.subscribeNotifications(
      async (x: any) => {
        //this get invoked on click push notification (useful for navigation on chat detail)

        console.log("watchNotificationsSaga -> Push notification received", x)
        if (x?.navigate != null) {
          emitter(x.navigate)
          return
        }
      }
    )
    return async () => {
      console.log("watchNotificationsSaga -> Removing listener")
      await (await listener).remove()
      console.log("watchNotificationsSaga -> Removed listener")
    }
  })

  while (true) {
    const route = yield* take(onMessagingChannel)
    yield* call(history.push, route)
  }
}

function* handleNotificationPermissionsGranted() {
  try {
    console.log(
      "handleNotificationPermissionsGranted -> Push notifications permission -> granted"
    )
    yield* fork(watchFcmTokenSaga)
    // yield* fork(watchNotificationsSaga)
    yield* call(notificationsProvider.register)
    yield* put(notificationsActions.subscribeFCM.success())
    console.log("handleNotificationPermissionsGranted -> FCM subscribed")
  } catch (error: any) {
    console.error(
      "handleNotificationPermissionsGranted -> Error subscribing to FCM",
      error
    )
    yield* put(notificationsActions.subscribeFCM.failure())
  }
}

function* ensureNotificationsPermission() {
  console.log("ensureNotificationsPermission -> called")
  while (true) {
    const receivePermission = yield* call(
      notificationsProvider.checkReceivePermissions
    )
    if (receivePermission === "granted") {
      yield put(notificationsActions.setPushNotificationPermission("granted"))
      console.log(
        "ensureNotificationsPermission -> Push notifications permission -> granted"
      )
      yield* fork(handleNotificationPermissionsGranted)
      return
    }

    console.log(
      "ensureNotificationsPermission -> Push notifications permission -> not granted"
    )
    yield* delay(PERMISSION_CHECK_DELAY)
  }
}

export function* subscribeNotificationsSaga() {
  try {
    if (isWebPlatform()) {
      console.log(
        "subscribeNotificationsSaga -> Web platform, skipping FCM registration"
      )
      return
    }

    console.log("subscribeNotificationsSaga -> called")
    yield* race([
      call(ensureNotificationsPermission),
      take(notificationsActions.subscribeFCM.FULFILL),
    ])

    // console.log(`subscribeFCMSaga -> started`)
    // const receivePermission = yield* call(
    //   notificationsProvider.checkReceivePermissions
    // )

    // if (receivePermission !== "granted") {
    //   console.log(
    //     "subscribeFCMSaga -> Push notifications permission not granted -> requesting"
    //   )
    //   const requestPermissions = yield* call(
    //     notificationsProvider.requestPermissions
    //   )
    //   if (requestPermissions === "denied") {
    //     yield* call(Toast.show, {
    //       text: "Push Notification permission denied",
    //     })
    //     console.log(
    //       "subscribeFCMSaga -> Push notifications permission not granted -> denied"
    //     )
    //     return
    //   }
    // }
  } catch (error: any) {
    console.error(
      "subscribeNotificationsSaga -> Error subscribing to notifications",
      error
    )
    yield* put(notificationsActions.subscribeFCM.failure())
  } finally {
    // notificationsProvider.removeAllListeners()
  }
}

import { createRoutine } from "redux-saga-routines"
import {
  createPerformancesState,
  performanceRoutines,
  createPerformanceSliceContext,
} from "@common/common/frontend/features/performance/state"

export interface ExtraPerformanceState {
  isTrackingSession: boolean
}

const initialState: ExtraPerformanceState = {
  isTrackingSession: false,
}

const trackSession = createRoutine("PERFORMANCE|TRACK_SESSIONS")

const extraRoutines = {
  trackSession,
}

export const performanceSlice = createPerformancesState<ExtraPerformanceState>({
  extendState: initialState,
  extendExtraReducers: (builder) => {
    builder
      .addCase(trackSession.TRIGGER, (state) => {
        state.isTrackingSession = true
      })
      .addCase(trackSession.FULFILL, (state) => {
        state.isTrackingSession = false
      })
  },
})

const {
  name: performanceName,
  actions: sliceActions,
  reducer: performanceReducer,
} = performanceSlice

const performanceActions = {
  ...sliceActions,
  ...extraRoutines,
  ...performanceRoutines,
}
const performanceSliceContext = createPerformanceSliceContext(
  performanceSlice,
  extraRoutines
)

export {
  performanceName,
  performanceReducer,
  performanceActions,
  performanceSliceContext,
}

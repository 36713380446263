import Spinner from "@ui/atoms/Spinner"
import classNames from "classnames"
import styles from "./PDFViewer.module.css"
import ZoomableImage from "@ui/structure/ZoomableImage"
import { usePdfData } from "./usePdfData"

type PDFViewerClasses = {
  root?: string
  document?: string
}

type Props = {
  courseId: string
  assetId: string
  className?: string
  classes?: PDFViewerClasses
  width?: number
}

const DEFAULT_WIDTH = 1200

const PDFViewer = ({ assetId, courseId, className, classes, width }: Props) => {
  const {
    currentPage,
    handlePageNext,
    handlePagePrev,
    isFirstPage,
    isLastPage,
    isLoadingPage,
    pageUrl,
    totPages,
  } = usePdfData({
    assetId,
    courseId,
    width: width ?? DEFAULT_WIDTH,
  })

  return (
    <div className={classNames(styles.root, className, classes?.root)}>
      <div
        className={classNames(
          isLoadingPage && "flex items-center justify-center h-full"
        )}
      >
        <div className="max-w-[800px]">
          {isLoadingPage && (
            <div className="my-4 flex items-center justify-center">
              <Spinner color="primary" />
            </div>
          )}

          {pageUrl && <ZoomableImage className="w-full" src={pageUrl} />}
          {(totPages ?? 0) > 1 ? (
            <>
              <div className="flex gap-6 items-center justify-center my-4">
                <button
                  onClick={() => handlePagePrev()}
                  disabled={isFirstPage}
                  className={classNames({ "opacity-50": isFirstPage })}
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 1024 1024"
                    height={32}
                    width={32}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 0 0 0 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>
                  </svg>
                </button>
                <button
                  onClick={() => handlePageNext()}
                  disabled={isLastPage}
                  className={classNames({ "opacity-50": isLastPage })}
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 1024 1024"
                    height={32}
                    width={32}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 0 0 0-48.4z"></path>
                  </svg>
                </button>
              </div>
              <div className="text-center my-4">
                Pagina {currentPage + 1} / {totPages}
              </div>
            </>
          ) : undefined}
        </div>
      </div>
    </div>
  )
}

export { PDFViewer }

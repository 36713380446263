import { Icon } from "@atoms"
import classNames from "classnames"
import React from "react"

export type ReadStatus = "NOT_SENT" | "SENT" | "UNREAD" | "READ"
type Props = {
  readStatus?: ReadStatus
}

const ReadConfirmation = ({ readStatus }: Props) => {
  if (!readStatus) {
    return <></>
  }

  return (
    <>
      {readStatus && (
        <div
          className={classNames(
            "flex justify-end text-light",
            readStatus === "READ" && "!text-blue"
          )}
        >
          {readStatus === "SENT" && (
            <Icon iconName="check2" className="w-[22px] h-[14px] -ml-[13px]" />
          )}
          {(readStatus === "READ" || readStatus === "UNREAD") && (
            <>
              <Icon
                iconName="check2"
                className="w-[22px] h-[14px] -ml-[13px]"
              />
              <Icon
                iconName="check2"
                className="w-[22px] h-[14px] -ml-[13px]"
              />
            </>
          )}
        </div>
      )}
    </>
  )
}

export { ReadConfirmation }

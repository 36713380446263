import { IonContent } from "@ionic/react"
import classNames from "classnames"
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

interface Props {
  children: ReactNode
  className?: string
  fullscreen?: boolean
  style?: React.CSSProperties
}

interface ContentContextInterface {
  scrollToElementById: (elementId: string) => void
  scrollToBottom: () => void
  scrollToTop: () => void
  scrollToPosition: (_: number) => void
  scrollToPositionInstant: (__: number) => void
  setFullScreen: (isFullScreen: boolean) => void
  checkIsBottom: () => boolean
  isScrollTop: boolean
  isScrollBottom: boolean
  contentElement: HTMLIonContentElement | null
  scrollElement: HTMLElement | null
}

export const ContentContext = React.createContext<ContentContextInterface>({
  scrollToElementById: (_: string) => {},
  scrollToBottom: () => {},
  scrollToTop: () => {},
  scrollToPosition: (_: number) => {},
  scrollToPositionInstant: (_: number) => {},
  setFullScreen: (_: boolean) => {},
  checkIsBottom: () => false,
  isScrollTop: false,
  isScrollBottom: false,
  contentElement: null,
  scrollElement: null,
})

export const useContentContext = () => React.useContext(ContentContext)

const Content = ({ children, className, fullscreen, style }: Props) => {
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [isScrollTop, setIsScrollTop] = useState(false)
  const [isScrollBottom, setIsScrollBottom] = useState(false)
  const contentRef = useRef<HTMLIonContentElement | null>(null)
  const scrollRef = useRef<HTMLElement | null>(null)

  const checkIsTop = () => {
    const detail = (contentRef.current as any)?.detail
    if (!detail) {
      return false
    }

    return detail.currentY === 0
  }

  const checkIsBottom = () => {
    const detail = (contentRef.current as any)?.detail
    if (!detail) {
      return false
    }

    const scrollHeight = scrollRef.current?.scrollHeight ?? 0
    const clientHeight = scrollRef.current?.clientHeight ?? 0
    const currentY = (contentRef.current as any)?.detail.currentY
    return scrollHeight - clientHeight - currentY === 0
  }

  const handleScroll = (event: any) => {
    setTimeout(() => {
      setIsScrollBottom(checkIsBottom())
      setIsScrollTop(checkIsTop())
    }, 100)
  }

  const setContentRef = async (node: HTMLIonContentElement) => {
    if (node != null && contentRef.current == null) {
      contentRef.current = node
      scrollRef.current = await node.getScrollElement()
      scrollRef.current.addEventListener("scroll", handleScroll)
    }
  }

  const scrollToBottom = useCallback(() => {
    setTimeout(() => contentRef.current?.scrollToBottom(500), 50)
  }, [])

  const scrollToTop = useCallback(() => {
    setTimeout(() => contentRef.current?.scrollToTop(500), 50)
  }, [])

  const scrollToPosition = useCallback((top: number) => {
    setTimeout(() => {
      contentRef.current?.scrollToPoint(
        0,
        // top - (contentRef.current?.offsetTop ?? 0),
        top,
        500
      )
    }, 50)
  }, [])

  const scrollToPositionInstant = useCallback((y: number) => {
    setTimeout(() => {
      contentRef.current?.scrollToPoint(0, y, 5)
    }, 50)
  }, [])

  const scrollToElementById = useCallback((elementId: string) => {
    const element = document.getElementById(elementId)
    if (!element) {
      return
    }
    setTimeout(() => {
      const elementRect = element.getBoundingClientRect()
      //const containerRect = element.getBoundingClientRect();
      const scrollY = elementRect.top // - containerRect.top
      contentRef.current?.scrollToPoint(0, scrollY, 500)
    }, 50)
  }, [])

  useEffect(() => {
    return () => {
      scrollRef.current?.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <ContentContext.Provider
      value={{
        scrollToBottom,
        scrollToPosition,
        scrollToPositionInstant,
        scrollToTop,
        scrollToElementById,
        setFullScreen: setIsFullscreen,
        checkIsBottom,
        isScrollTop,
        isScrollBottom,
        contentElement: contentRef.current,
        scrollElement: scrollRef.current,
      }}
    >
      <IonContent
        className={classNames(isFullscreen && "z-50", className)}
        ref={setContentRef}
        scrollEvents={true}
        fullscreen={fullscreen}
        style={style}
        id="scrollContent"
      >
        {children}
      </IonContent>
    </ContentContext.Provider>
  )
}
export { Content }

import classNames from "classnames"
import React, { useMemo } from "react"

type Props = {
  currentSlideNumber: number
  slidesCount: number
  className?: string
  type: "BARS" | "CIRCLES"
}

const SlidePosition = ({
  currentSlideNumber,
  slidesCount,
  className,
  type,
}: Props) => {
  const slides = useMemo(() => {
    return [...Array(slidesCount).keys()]
  }, [slidesCount])
  if (type === "BARS") {
    return (
      <div className="flex gap-[10px]">
        {slides.map((x) => {
          return (
            <div
              key={x}
              className={classNames(
                "h-[5px] rounded-[5px]",
                currentSlideNumber === x
                  ? "w-[28px] bg-red "
                  : "w-[9px] bg-light"
              )}
            />
          )
        })}
      </div>
    )
  }
  if (type === "CIRCLES") {
    return (
      <div
        style={{}}
        className="relative flex items-center justify-center w-full"
      >
        {slides.map((x) => {
          return (
            <div
              key={x}
              className={classNames(
                "flex max-w-[54px] items-center after:w-[10px] after:h-[10px] after:rounded-full after:block",
                currentSlideNumber >= x
                  ? "before:bg-red after:bg-red "
                  : "before:bg-light after:bg-light",
                x > 0 &&
                  "flex-1 before:max-w-[44px] before:h-[1px] before:block before:flex-1"
              )}
            />
          )
        })}
      </div>
    )
  }
  return null
}

export { SlidePosition }

import { isPlatform } from "@ionic/react"
import { persistObject, readObject } from "@services/storage"

const STORAGE_CHANNEL_ID_KEY = "channelId"

const envLiveUpdateEnabled = () =>
  process.env.REACT_APP_ENABLE_LIVE_UPDATE === "true"

export const liveUpdateEnabled = () =>
  (isPlatform("android") || isPlatform("ios")) && envLiveUpdateEnabled()

export const deploymentAppId = () => process.env.REACT_APP_APP_ID

export const getDeploymentChannelId = async (): Promise<string> => {
  const customChannelId = await readObject<string>(STORAGE_CHANNEL_ID_KEY)
  if (customChannelId) {
    console.log(`SELECTED CUSTOM CHANNEL ID -> ${customChannelId}`)
    return customChannelId
  }
  return process.env.REACT_APP_CHANNEL_ID as string
}

export const saveCustomChannelId = async (channelId: string) => {
  await persistObject(STORAGE_CHANNEL_ID_KEY, channelId)
  console.log(`SAVED CUSTOM CHANNEL ID -> ${channelId}`)
}

import { Storage } from "@ionic/storage"

const store = new Storage()

export const initializeStorage = async () => {
  await store.create()
}

export const persistObject = async <T>(key: string, value: T) => {
  await store.set(key, JSON.stringify(value))
}

export const readObject = async <T>(key: string): Promise<T | undefined> => {
  const serializedValue = await store.get(key)
  return serializedValue ? (JSON.parse(serializedValue) as T) : undefined
}

export const removeObject = (key: string) => store.remove(key)

export const clearStorage = () => store.clear()

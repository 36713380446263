import { format } from "date-fns"
import { it } from "date-fns/locale"
import React from "react"

type Props = {
  startDate: Date
}

const EventDayText = ({ startDate }: Props) => {
  return (
    <div className="mb-[24px]">
      <div className="flex gap-[30px] items-center after:bg-grey after:flex-1 after:h-[1px] after:block">
        {format(startDate, "d MMMM", { locale: it })}
      </div>
    </div>
  )
}

export default EventDayText

import { PayloadAction } from "@reduxjs/toolkit"
import { put, call, select } from "typed-redux-saga"
import { sendChannelMessage } from "../../../../features/messaging/channel"
import { Firebase, getSagaContext } from "../../../state/context"
import { CommonState } from "../../../state/types"
import { ChannelMessageSendTriggerInput, MessageDraft } from "../state/types"

const getChannelKey = (state: CommonState, channelId: string) =>
  state.messaging?.keys?.channels?.find((x) => x.channelId === channelId)
    ?.privateKey

const getChannelDraft = (state: CommonState, channelId: string) =>
  state.messaging.channelMessages[channelId].draft

export function* sendChannelMessageSaga(
  action: PayloadAction<ChannelMessageSendTriggerInput>
) {
  const state = yield* getSagaContext("state")
  const {
    payload: { channelId },
  } = action
  try {
    const channelKey = yield* select(getChannelKey, channelId)
    if (!channelKey) {
      throw new Error(`Channel ${channelId} key not found`)
    }

    const firebase = yield* getSagaContext("firebase")
    const draft = yield* select(getChannelDraft, channelId)

    yield* call(
      (
        firebase: Firebase,
        draft: MessageDraft,
        channelId: string,
        channelKey: string
      ) => {
        sendChannelMessage(firebase.collections.channels, {
          channelId,
          message: draft.content.message,
          subject: draft.content.subject,
          messageId: draft.messageId,
          privateKey: channelKey,
          senderId: firebase.getUid() as string,
          attachments: draft.attachments.items,
        })
      },
      firebase,
      draft,
      channelId,
      channelKey
    )

    yield* put(
      state.messaging.actions.channelDraftSendCompleted({
        channelId,
      })
    )
  } catch (e) {
    yield* put(
      state.messaging.actions.channelDraftSendError({
        channelId,
        error: (e as Error).message,
      })
    )
  }
}

import { RootState } from "@modules/store/redux/rootReducer"
import { CourseChapter } from "@common/common/api/functions"
import { createSelector as cs } from "@reduxjs/toolkit"
import { selectCourseProgress } from "../selectorsNew"
import { selectChapters } from "./lists"

export const selectChapterId = ({ courses }: RootState) =>
  courses.selectedChapterId

export const selectPlayChapterId = ({ courses }: RootState) =>
  courses.playChapterId

export const selectChapter = cs(selectChapterId, selectChapters, (id, data) =>
  data.find((x) => id === x.id)
)
export const selectChapterProgress = cs(
  selectChapterId,
  selectCourseProgress,
  (chapterId, progress) => progress?.progress.chapters?.[chapterId ?? ""]
)

export const selectChapterByIndex =
  (index: number) =>
  ({ courses }: RootState): CourseChapter | undefined =>
    courses.courses[courses.selectedCourseId!].data.contents.chapters[index]

export const selectChapterById = (chapterId: string) =>
  cs(selectChapters, (data) => data.find((x) => chapterId === x.id)!)

export const selectChapterProgressById = (chapterId: string) =>
  cs(
    selectCourseProgress,
    (progress) => progress?.progress.chapters?.[chapterId ?? ""]
  )

import { classNames } from "@punks/ui-core"

export interface DynamicSizeBoxProps {
  children: any
  fluidX?: boolean
  fluidY?: boolean
  width?: string | number
  height?: string | number
  className?: string
}

const DynamicSizeBox = ({
  children,
  fluidX,
  fluidY,
  width,
  height,
  className,
}: DynamicSizeBoxProps) => {
  return (
    <div
      className={classNames(
        {
          "transition-[width]": fluidX,
          "transition-[height]": fluidY,
        },
        className
      )}
      style={{
        width,
        height,
      }}
    >
      {children}
    </div>
  )
}

export default DynamicSizeBox

import classNames from "classnames"
import React, { ReactNode } from "react"

type InputType = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

interface Props {
  className?: string
  classes?: {
    input?: string
    root?: string
    inputWrapper?: string
    error?: string
    label?: string
  }
  value?: string
  onChange?: (value: string) => void
  name: string
  label?: string
  placeholder?: string
  type: InputType["type"]
  children?: ReactNode
  onEnterKeyPressed?: () => void
  errorMessage?: string
}

const TextInput = (props: Props) => {
  const {
    classes,
    name,
    label,
    placeholder,
    type,
    onEnterKeyPressed,
    className,
    errorMessage,
    onChange,
    value,
  } = props

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onEnterKeyPressed && e.keyCode === 13) {
      e.preventDefault()
      onEnterKeyPressed()
    }
  }

  return (
    <div className={classNames("relative", classes?.root, className)}>
      {label && (
        <div
          className={classNames(
            "font-bold text-sm leading-[16px] mb-[19px]",
            classes?.label
          )}
        >
          {label}
        </div>
      )}
      <div
        className={classNames("border-b border-grey", classes?.inputWrapper)}
      >
        {props.children}
        <input
          className={classNames(
            "text-base leading-[24px] p-0 w-full bg-white focus:outline-none ",
            classes?.input
          )}
          onChange={(e) => onChange?.(e.target.value)}
          autoComplete={"off"}
          name={name}
          placeholder={placeholder}
          type={type}
          onKeyDown={onKeyDown}
          value={value}
        />
      </div>
      <div
        className={classNames("mb-[25px] mt-[4px] text-red", classes?.error)}
      >
        {errorMessage}
      </div>
    </div>
  )
}

export default TextInput

import { TestParams } from "@pages/private/Courses/Test"
import { useRouteContext } from "@modules/routing/context"
import { useRouting } from "@root/routing/useRouting"
import { without } from "ramda"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSaveCourseProgress } from "."
import { coursesActions } from "../state"
import {
  selectTestAttemptIndex,
  selectTestProgress,
  selectTestProgressAttemptId,
  selectTestViewIndex,
} from "../state/selectors/test"

import {
  selectTestQuestionId,
  selectTestQuestionById,
  selectNextTestQuestionById,
  selectTestQuestionProgressById,
} from "../state/selectors/testQuestion"
import { useRootSelector } from "@modules/store/redux"

export const useTestProgress = () => {
  const {
    match: {
      params: { testId },
    },
  } = useRouteContext<TestParams>()

  const { goTo, goBack } = useRouting()
  const { saveCourseProgress } = useSaveCourseProgress()
  const dispatch = useDispatch()
  const [currentAnswers, setCurrentAnswers] = useState<string[]>([])
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<undefined | boolean>()

  const currentQuestionId = useSelector(selectTestQuestionId)
  const question = useSelector(selectTestQuestionById(currentQuestionId ?? ""))
  const nextQuestion = useSelector(
    selectNextTestQuestionById(currentQuestionId ?? "")
  )
  const attemptId = useSelector(selectTestProgressAttemptId)
  const viewIndex = useSelector(selectTestViewIndex)
  const textAttemptIndex = useSelector(selectTestAttemptIndex)
  const progress = useSelector(selectTestProgress)
  const questionProgress = useSelector(
    selectTestQuestionProgressById(attemptId, currentQuestionId ?? "")
  )
  const showQuestionError = useRootSelector(
    (state) => state.courses.showQuestionError
  )

  const isDisabled = currentAnswers.length === 0
  const isRetryAttempt = viewIndex > 0 || textAttemptIndex > 0

  const getCurrentQuestionResult = () => {
    return currentAnswers.length === 0
      ? { passed: false, score: 0 }
      : currentAnswers.reduce(
          (res, answerId) => {
            const ans = question?.answers.find((x) => x.id === answerId)!
            res.passed &&= ans.correct
            res.score += ans.correct ? ans.points ?? 0 : 0
            return res
          },
          { passed: true, score: 0 }
        )
  }

  const isCurrentQuestionCorrect = () => {
    return getCurrentQuestionResult().passed
  }

  const saveCurrentQuestionResult = () => {
    const data = getCurrentQuestionResult()
    saveCourseProgress({
      progress: {
        tests: {
          [testId]: {
            attempts: {
              [attemptId]: {
                answers: {
                  [question.id]: {
                    answerIds: currentAnswers,
                    correct: data.passed,
                    points: data.passed ? data.score : 0,
                  },
                },
              },
            },
          },
        },
      },
    })
  }

  const getFinalScore = () => {
    return Object.entries(progress?.answers!).length === 0
      ? { passed: false, score: 0 }
      : Object.entries(progress?.answers!).reduce(
          (o, [questionId, question]) => {
            o.passed &&= question.correct
            o.score += question.correct ? question.points : 0
            return o
          },
          { passed: true, score: 0 }
        )
  }

  const selectAnswer = (checked: boolean, id: string, isMulti: boolean) => {
    dispatch(coursesActions.setShowTestQuestionError(false))
    if (!isMulti) {
      setCurrentAnswers([id])
      return
    }
    if (checked) {
      setCurrentAnswers([...currentAnswers, id])
    } else {
      setCurrentAnswers(without([id], currentAnswers))
    }
  }

  const retry = () => {
    goBack()
    dispatch(coursesActions.setTestQuestionId(null))
  }

  const next = () => {
    if (isRetryAttempt && !isCurrentQuestionCorrect()) {
      dispatch(coursesActions.setShowTestQuestionError(true))
      return
    }

    dispatch(coursesActions.setShowTestQuestionError(false))
    saveCurrentQuestionResult()
    if (nextQuestion == null) {
      setIsCompleted(true)
    } else {
      setCurrentAnswers([])
      dispatch(coursesActions.setTestQuestionId(nextQuestion.id))
    }
  }

  useEffect(() => {
    if (isCompleted) {
      const data = getFinalScore()
      if (data.passed) {
        setIsSuccess(true)
        saveCourseProgress({
          completedTime: new Date().getTime(),
          status: "completed",
          progress: {
            tests: {
              [testId]: {
                attempts: {
                  [attemptId]: {
                    completedTime: new Date().getTime(),
                    passed: true,
                    score: attemptId != "0" ? 0 : data.score,
                  },
                },
              },
            },
          },
        })
      } else {
        setIsSuccess(false)
        saveCourseProgress({
          progress: {
            tests: {
              [testId]: {
                attempts: {
                  [attemptId]: {
                    completedTime: new Date().getTime(),
                    passed: false,
                    score: 0,
                  },
                },
              },
            },
          },
        })
      }
    }
  }, [isCompleted])

  useEffect(() => {
    if (currentQuestionId == null) {
      goTo("courses")
    }
  }, [])

  useEffect(() => {
    if (progress == null) {
      saveCourseProgress({
        progress: {
          tests: {
            [testId]: {
              attempts: {
                [attemptId]: {
                  startedTime: new Date().getTime(),
                },
              },
            },
          },
        },
      })
    }
  }, [testId])

  useEffect(() => {
    if (questionProgress) {
      setCurrentAnswers(Object.values(questionProgress.answerIds ?? []))
    }
  }, [currentQuestionId])

  return {
    next,
    question,
    currentAnswers,
    selectAnswer,
    isDisabled,
    isSuccess,
    retry,
    showQuestionError,
  }
}

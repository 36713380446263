import { buffers, eventChannel } from "redux-saga"
import { put, take, cancelled, takeLatest } from "typed-redux-saga"
import { normalizeChannelPreviewEntity } from "../../../../database/messaging/channel-preview/channel-preview.converter"
import { ChannelPreviewEntity } from "../../../../database/messaging/channel-preview/channel-preview.entity"
import { getSagaContext } from "../../../state/context"

export function* watchChannelsPreviewsSaga() {
  const state = yield* getSagaContext("state")
  const firebase = yield* getSagaContext("firebase")
  const uid = firebase.getUid()
  if (!uid) {
    throw new Error("Missing uid")
  }
  const channel = eventChannel<ChannelPreviewEntity[]>((emitter) => {
    const unsubscribe =
      firebase.collections.channelPreview.subscribeUserChannelPreviews(
        uid,
        (data) => {
          emitter(data.map(normalizeChannelPreviewEntity))
        }
      )
    return () => unsubscribe()
  }, buffers.sliding(1))
  try {
    yield* takeLatest(
      state.messaging.actions.watchChannelPreviews.fulfill,
      function* () {
        channel.close()
      }
    )
    while (true) {
      const data = yield* take(channel)
      yield* put(state.messaging.actions.watchChannelPreviews.success(data))
    }
  } catch (error) {
    yield* put(state.messaging.actions.watchChannelPreviews.failure(error))
  } finally {
    if (yield* cancelled()) {
      channel.close()
    }
  }
}

import { PageTitle } from "@atoms"
import { setCssVar } from "@helpers/ui"
import { IonContent, IonHeader, IonToolbar } from "@ionic/react"
import { CommunicationParams } from "@pages/private"
import { useRouteContext } from "@modules/routing/context"
import { useRouting } from "@root/routing/useRouting"
import { Header, Tabs } from "@molecules"
import classNames from "classnames"
import CalendarControl from "@features/calendar/components/CalendarControl"
import MessagingUserControl from "@features/messaging/components/MessagingUserControl"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { useUserRole } from "@modules/auth/hooks/useUserRole"
import MessagingManagerControl from "@features/messaging/components/MessagingManagerControl"

const CommunicationContainer = () => {
  const {
    match: {
      params: { communicationTab },
    },
  } = useRouteContext<CommunicationParams>()
  const { t } = useTranslations()
  const { isManager, isTraining, isAreaManager } = useUserRole()

  const tabs = [
    {
      id: "messages",
      label: t("common:tabs.messages.title"),
    },
    {
      id: "calendar",
      label: t("common:tabs.calendar.title"),
    },
  ]
  const selectedTab = communicationTab === "calendar" ? tabs[1] : tabs[0]

  const { goTo } = useRouting()

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar
          className="flex items-end"
          style={{
            ...setCssVar("--min-height", 0),
          }}
        >
          <Header>
            <PageTitle text="Comunicazioni" />
          </Header>
          <Tabs
            activeTab={selectedTab}
            tabs={tabs}
            setSelectedTab={(tab) => {
              goTo("chat", {
                params: {
                  communicationTab:
                    tab.id === "calendar" ? "calendar" : "previews",
                },
                action: "replace",
                direction: "none",
                animation: "none",
              })
            }}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div
          className={classNames(
            "px-container py-[25px] mb-[var(--footer-height)]",
            selectedTab.id !== "messages" && "!hidden"
          )}
        >
          {isManager || isTraining || isAreaManager ? (
            <MessagingManagerControl />
          ) : (
            <MessagingUserControl />
          )}
        </div>
        <div
          className={classNames(
            "px-container py-[25px] mb-[var(--footer-height)]",
            selectedTab.id !== "calendar" && "!hidden"
          )}
        >
          <CalendarControl />
        </div>
      </IonContent>
    </>
  )
}

export default CommunicationContainer

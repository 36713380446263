import {
  Firebase,
  FirestoreController,
} from "../../../infrastructure/firebase/controller"
import {
  AppUserResources,
  AppUserResourcesEntity,
} from "./user-resources.entity"

export class UserResourcesController extends FirestoreController<AppUserResources> {
  constructor(firebase: Firebase) {
    super(firebase, "UserResourcesRepo")
  }

  subscribeUserResources(
    uid: string,
    callback: (items: AppUserResourcesEntity | undefined) => void
  ) {
    return this.subscribeDocument(uid, (item) => {
      callback(item)
    })
  }
}

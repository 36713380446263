import { takeEvery, takeLatest } from "typed-redux-saga"
import { contactsSlice } from "../state"
import { contactFetchSaga } from "./contactFetchSaga"
import { contactsSearchSaga } from "./contactsSearchSaga"

export function* contactsRootSaga() {
  yield* takeEvery(contactsSlice.actions.contactFetchTrigger, contactFetchSaga)
  yield* takeLatest(
    contactsSlice.actions.contactsSearchQueryChanged,
    contactsSearchSaga
  )
}

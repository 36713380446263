import { IonContent, IonToolbar, IonModal as IonicModal } from "@ionic/react"
import { classNames } from "@punks/ui-core"
import { CloseIcon } from "@ui/icons"
import SafeTopAreaContainer from "@ui/utils/SafeTopAreaContainer"

const IonModal = IonicModal as any

export interface ScreenModalProps {
  open?: boolean
  onClose?: () => void
  children: React.ReactNode
}

const ScreenModal = ({ onClose, open, children }: ScreenModalProps) => {
  return (
    <IonModal
      isOpen={open}
      onDidDismiss={() => onClose?.()}
      style={{ "--height": "100vh" }}
    >
      <IonToolbar>
        <SafeTopAreaContainer className={classNames("text-right text-black")}>
          <button onClick={() => onClose?.()} className="p-2">
            <CloseIcon size="large" />
          </button>
        </SafeTopAreaContainer>
      </IonToolbar>
      <IonContent>{children}</IonContent>
    </IonModal>
  )
}

export default ScreenModal

import { ChatDetailContainer } from "@containers/messaging/ChatDetail"
import PageRoot from "@root/pages/PageRoot"

export interface ChatDetailParams {
  chatId: string
}

const ChatDetailPage = () => {
  return (
    <PageRoot fullscreen>
      <ChatDetailContainer />
    </PageRoot>
  )
}

export { ChatDetailPage }

import { call, put, take, delay, race } from "typed-redux-saga"
import { getSagaContext } from "@modules/store/redux/sagaContext"
import { authActions } from "../state"

const FIREBASE_TOKEN_REFRESH_DELAY = 18e4 // 1 minute

function* refreshFirebaseToken() {
  const firebase = yield* getSagaContext("firebase")
  let lastToken: string | undefined
  while (true) {
    const token = yield* call(firebase.refreshFirebaseToken)
    if (token != lastToken) {
      lastToken = token
      const user = yield* call(firebase.getUser)
      const claims = yield* call(firebase.getClaims)
      yield* put(
        authActions.login.success({
          displayName: user?.displayName ?? "",
          email: user?.email ?? "",
          phoneNumber: user?.phoneNumber ?? "",
          photoURL: user?.photoURL ?? "",
          claims,
          uid: user?.uid ?? "",
        })
      )
    }
    yield* delay(FIREBASE_TOKEN_REFRESH_DELAY)
  }
}

export function* watchRefreshToken() {
  while (true) {
    yield* take(authActions.watch.TRIGGER)
    yield* race([call(refreshFirebaseToken), take(authActions.watch.FULFILL)])
  }
}

import {
  Firebase,
  FirestoreController,
} from "../../../infrastructure/firebase/controller"
import { UserPublicKey } from "./publicKey.entity"

export class UserPublicKeysController extends FirestoreController<UserPublicKey> {
  constructor(firebase: Firebase) {
    super(firebase, "UserPublicKeysRepo")
  }

  subscribeUserKey(uid: string, callback: (userKey: UserPublicKey) => void) {
    return this.subscribeDocument(uid, (item) => {
      if (item) {
        callback(item.data)
      }
    })
  }
}

import { SectionTitle } from "@atoms"
import { selectLastChannelCommunications } from "@features/notifications/selectors"
import { useRootSelector } from "@modules/store/redux"
import React from "react"
import { useSelector } from "react-redux"
import { selectNewestCourseIdList } from "@features/courses/state/selectors/lists"
import { NewsBanners } from "./NewsBanners"
import { NewestChannelCommunications } from "./NewestChannelCommunications"
import { useTranslations } from "@modules/app/hooks/useTranslations"

const NewsWidget = () => {
  const channelNotifications = useRootSelector(selectLastChannelCommunications)
  const courseIdList = useSelector(selectNewestCourseIdList)
  const existsNotifications =
    channelNotifications && channelNotifications.length > 0
  const existsCourses = courseIdList && courseIdList.length > 0
  const existsAnyNews = existsNotifications || existsCourses
  const { t } = useTranslations()

  return (
    <>
      {existsAnyNews && (
        <div>
          <SectionTitle text={t("news:section.title")} />
          <div>
            {existsAnyNews && (
              <NewsBanners
                existsCourses={existsCourses}
                existsNotifications={existsNotifications}
              />
            )}
            {existsNotifications && (
              <NewestChannelCommunications
                channelNotifications={channelNotifications}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export { NewsWidget }

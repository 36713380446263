import { createRoutine } from "redux-saga-routines"
import {
  createNotificationsSliceContext,
  createNotificationsState,
  notificationsRoutines,
} from "@common/common/frontend/features/notifications/state"
export interface ExtraNotificationsState {
  isSubscribedFCM: boolean
}
const initialState: ExtraNotificationsState = {
  isSubscribedFCM: false,
}
export const subscribeFCM = createRoutine("NOTIFICATIONS/SUBSCRIBE_FCM", {
  // TRIGGER: (payload: string[]) => payload,
})

const extraRoutines = {
  subscribeFCM,
}

export const notificationsSlice =
  createNotificationsState<ExtraNotificationsState>({
    extendState: initialState,
    extendExtraReducers: (builder) => {
      builder
        .addCase(subscribeFCM.TRIGGER, (state) => {
          state.isSubscribedFCM = true
        })
        .addCase(subscribeFCM.FULFILL, (state) => {
          state.isSubscribedFCM = false
        })
    },
  })
const {
  name: notificationsName,
  actions: sliceActions,
  reducer: notificationsReducer,
} = notificationsSlice

const notificationsActions = {
  ...sliceActions,
  ...extraRoutines,
  ...notificationsRoutines,
}
const notificationSliceContext = createNotificationsSliceContext(
  notificationsSlice,
  extraRoutines
)

export {
  notificationsName,
  notificationsReducer,
  notificationsActions,
  notificationSliceContext,
}

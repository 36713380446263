import { useTranslations } from "@modules/app/hooks/useTranslations"
import { brandsLabelSelector } from "@modules/contents/state/selectors"
import classNames from "classnames"
import { useSelector } from "react-redux"

type Props = {
  brandCode?: string
  brandName?: string
  className?: string
}

const CourseBrandName = ({ brandCode, brandName, className }: Props) => {
  const { t } = useTranslations()
  const { getBrandName } = useSelector(brandsLabelSelector)
  return (
    <div
      className={classNames("text-sm leading-normal text-light", className)}
    >{`${t("courses:brand.of")} ${brandName ?? getBrandName(brandCode)}`}</div>
  )
}

export default CourseBrandName

import { getSagaContext } from "@modules/store/redux/sagaContext"
import { sendChatMessage } from "@common/common/features/messaging/chat"
import { messagingRootSaga as baseMessagingRootSaga } from "@common/common/frontend/features/messaging/sagas"
import { call, put, fork, takeLatest, takeEvery } from "typed-redux-saga"
import { messagingActions } from "."
import { PayloadAction } from "@reduxjs/toolkit"
import {
  ChannelMessageDeleteInput,
  ChannelMessagesDeleteInput,
} from "@common/common/frontend/features/messaging/state/types"

function* deleteChannelMessageSaga(
  action: PayloadAction<ChannelMessageDeleteInput>
) {
  const api = yield* getSagaContext("api")
  yield* call(api.markDeletedChannelMessage, {
    channelId: action.payload.channelId,
    messageId: action.payload.messageId,
  })
}

function* deleteChannelMessagesSaga(
  action: PayloadAction<ChannelMessagesDeleteInput>
) {
  const api = yield* getSagaContext("api")
  for (const messageId of action.payload.messageIds) {
    yield* call(api.markDeletedChannelMessage, {
      channelId: action.payload.channelId,
      messageId,
    })
  }
}

function* sendChatMessageSaga({
  payload,
}: ReturnType<typeof messagingActions.sendChatMessage.trigger>) {
  const firebase = yield* getSagaContext("firebase")
  try {
    yield* put(messagingActions.sendChatMessage.request())
    yield* call(sendChatMessage, firebase.collections.chat, payload)
    yield* put(messagingActions.sendChatMessage.success())
  } catch {
    yield* put(messagingActions.sendChatMessage.failure())
  } finally {
    yield* put(messagingActions.sendChatMessage.fulfill())
  }
}

export function* messagingRootSaga() {
  yield* fork(baseMessagingRootSaga)

  yield* takeLatest(
    messagingActions.sendChatMessage.TRIGGER,
    sendChatMessageSaga
  )

  yield* takeEvery(
    messagingActions.channelMessageDeleteTrigger,
    deleteChannelMessageSaga
  )

  yield* takeEvery(
    messagingActions.channelMessagesDeleteTrigger,
    deleteChannelMessagesSaga
  )
}

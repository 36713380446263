import {
  Firebase,
  FirestoreController,
} from "../../../infrastructure/firebase/controller"
import { UserPrivateKey } from "./privateKey.entity"

export class UserPrivateKeysController extends FirestoreController<UserPrivateKey> {
  constructor(firebase: Firebase) {
    super(firebase, "UserPrivateKeysRepo")
  }

  subscribeUserKey(uid: string, callback: (userKey: UserPrivateKey) => void) {
    return this.subscribeDocument(uid, (item) => {
      if (item) {
        callback(item.data)
      }
    })
  }
}

import { AppProvider } from "./providers"
import { Routing } from "./routing"

export const AppRoot = () => {
  return (
    <AppProvider>
      <Routing />
    </AppProvider>
  )
}

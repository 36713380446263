import { PayloadAction } from "@reduxjs/toolkit"
import { buffers, eventChannel } from "redux-saga"
import { put, call, cancelled, take, takeLatest } from "typed-redux-saga"
import { getSagaContext } from "../../../state/context"
import { ChannelKey, WatchUserKeysInput } from "../state/types"

const CHANNEL_KEYS_KEY = "_messaging:user_channel_keys"

export function* watchUserChannelKey(
  payload: PayloadAction<WatchUserKeysInput>
) {
  const state = yield* getSagaContext("state")
  const store = yield* getSagaContext("store")
  const firebase = yield* getSagaContext("firebase")
  const cachedKeys = yield* call(
    (key: string) => store.get<ChannelKey[]>(key),
    CHANNEL_KEYS_KEY
  )
  if (cachedKeys) {
    yield* put(state.messaging.actions.setChannelKeys(cachedKeys))
  }

  const channel = eventChannel<ChannelKey[]>((emitter) => {
    const unsubscribe =
      firebase.collections.userChannelKeys.subscribeUserChannelKeys(
        payload.payload.uid,
        (data) => emitter(data)
      )
    return () => unsubscribe()
  }, buffers.sliding(1))

  try {
    yield* takeLatest(
      state.messaging.actions.watchChannelPreviews.fulfill,
      function* () {
        channel.close()
      }
    )

    while (true) {
      const data = yield* take(channel)
      yield* put(state.messaging.actions.setChannelKeys(data))
    }
  } finally {
    if (yield* cancelled()) {
      channel.close()
    }
  }
}

import { FirebaseStorage, ref, uploadBytes } from "firebase/storage"

export interface FileUploadInput {
  file: File
  path: string
  contentType: string
  customData?: {
    [key: string]: string
  }
}

export const uploadFile = async (
  storage: FirebaseStorage,
  input: FileUploadInput
) => {
  const folder = ref(storage, input.path)
  return await uploadBytes(folder, input.file, {
    contentType: input.contentType,
    customMetadata: input.customData,
  })
}

import { useRootSelector } from "@modules/store/redux"
import { UserType } from "@common/common/types/user"

const CHIEF_ROLES: UserType[] = ["chief"]
const TRAINING_ROLES: UserType[] = ["admin", "training"]
const MANAGER_ROLES: UserType[] = ["admin", "chief", "manager"]
const ADMIN_ROLES: UserType[] = ["admin"]
const AREA_MANAGER_ROLES: UserType[] = ["admin", "areaManager"]
const STORE_MANAGER_ROLES: UserType[] = ["admin", "storeManager"]

const isInRole = (role: string | undefined, roles: string[]) =>
  roles.some((x) => x === role)

export const useUserRole = () => {
  const { user } = useRootSelector((state) => state.userProfile)
  return {
    isManager: isInRole(user.data?.userType, MANAGER_ROLES),
    isAdmin: isInRole(user.data?.userType, ADMIN_ROLES),
    isTraining: isInRole(user.data?.userType, TRAINING_ROLES),
    isAreaManager: isInRole(user.data?.userType, AREA_MANAGER_ROLES),
    isStoreManager: isInRole(user.data?.userType, STORE_MANAGER_ROLES),
    isChief: isInRole(user.data?.userType, CHIEF_ROLES),
  }
}

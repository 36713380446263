import { useIonRouter } from "@ionic/react"
import { useCallback } from "react"

import { animations } from "../../modules/routing/animations"
import { allRoutes } from "./routes"
import { Go } from "./types"

export const useRouting = () => {
  const { goBack, canGoBack, push, routeInfo } = useIonRouter()
  const goTo = useCallback<Go>((route, options) => {
    const routeWithParams = allRoutes[route].getRouteWithParams(options?.params)
    const direction = options?.direction ?? "forward"
    const action = options?.action ?? (direction === "forward" ? "push" : "pop")
    switch (route) {
      default:
        push(
          routeWithParams,
          direction,
          action,
          options?.options,
          animations[options?.animation ?? "default"]
        )
        break
    }
    return undefined
  }, [])

  const back = useCallback(() => {
    if (routeInfo.pushedByRoute) {
      push(routeInfo.pushedByRoute, "back", "pop", {}, routeInfo.routeAnimation)
    } else {
      push("/main", "root", "replace")
    }
  }, [])

  return { goBack: back, goTo, routeInfo }
}

import CommunicationContainer from "@containers/messaging/Communication"
import PageRoot from "@root/pages/PageRoot"

export interface CommunicationParams {
  communicationTab: "previews" | "calendar"
}

const CommunicationPage = () => {
  return (
    <PageRoot>
      <CommunicationContainer />
    </PageRoot>
  )
}

export { CommunicationPage }

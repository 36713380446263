import { ReactNode } from "react"
import { Content } from "@atoms"
import classNames from "classnames"

export interface BodyBackgroundPattern {
  imageUrl: string
  size: string
  opacity?: number
}

interface Props {
  children: ReactNode
  backgroundPattern?: BodyBackgroundPattern
}

const Body = ({ children, backgroundPattern }: Props) => {
  return (
    <Content fullscreen>
      <div className={"p-container relative"}>
        {backgroundPattern && (
          <div
            className={classNames("fixed inset-0 z-[-1]", {
              "bg-repeat": !!backgroundPattern?.imageUrl,
            })}
            style={{
              backgroundImage: `url(${backgroundPattern.imageUrl})`,
              backgroundSize: backgroundPattern.size,
              opacity: backgroundPattern.opacity,
            }}
          />
        )}
        <div>{children}</div>
      </div>
    </Content>
  )
}

export default Body

import React from "react"
import { createContext, useContext } from "react"
import { RouteComponentProps } from "react-router"
export const RouteContext = createContext<any>({})

export const withRouteContext = (Component: any) => {
  return (props: any) => {
    return (
      <RouteContext.Provider
        value={{
          ...props,
        }}
      >
        <Component />
      </RouteContext.Provider>
    )
  }
}

export function useRouteContext<T extends { [K in keyof T]?: string }>() {
  return useContext<RouteComponentProps<T>>(RouteContext)
}

interface RouteParams<T> {
  rawRoute: string
  data?: T
  defaultParam?: T
}

export function getRouteWithParams<T>({
  data,
  rawRoute,
  defaultParam,
}: RouteParams<T>) {
  let parsedRoute = rawRoute
  const dataToReplace = data ?? defaultParam
  if (dataToReplace != null) {
    parsedRoute = Object.entries(dataToReplace).reduce((str, [key, val]) => {
      return str.replace(`:${key}`, val as string)
    }, rawRoute)
  }
  const matches = parsedRoute.match(/^\/:tab\((.+?)\)(.*?$)/)
  if (matches) {
    parsedRoute = `/${matches[1]}${matches[2]}`
  }

  return parsedRoute
}

export type RoutingContextType = {
  selectTab: (tab: string) => void
}

export const RoutingContext = React.createContext<RoutingContextType>({
  selectTab: (_tab) => {},
})

export const useRoutingContext = () => React.useContext(RoutingContext)

import { useCommonSelector, useRootSelector } from "@modules/store/redux"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { messagingActions } from "../state"
import { useChatData } from "./useChatData"
import { useCallback, useEffect } from "react"
import { filesToAttachments } from "@common/common/frontend/features/messaging/converters/draft"
import { selectChatDraft } from "@common/common/frontend/features/messaging/selectors/selectChatDraft"
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera"
import { base64ToFile } from "@helpers"
import { MessageDraft } from "@common/common/frontend/features/messaging/state/types"

type Message = {
  text: string
  files: FileList | null
}

const isValidMessage = (data: Message, draft?: MessageDraft) => {
  if (
    (data.text?.trim() ?? "") == "" &&
    draft?.attachments.items.length === 0
  ) {
    return false
  }

  if (draft?.attachments.uploadInProgress) {
    return false
  }

  return true
}

export const useChatToolbar = (chatId: string) => {
  const chatData = useChatData(chatId)
  const draft = useCommonSelector((state) =>
    selectChatDraft(state, {
      chatId,
    })
  )

  const dispatch = useDispatch()
  const uid = useRootSelector((state) => state.auth.user?.uid)
  const { register, handleSubmit, reset, watch } = useForm<Message>()
  const inputFiles = watch("files")
  useEffect(() => {
    if (inputFiles && inputFiles.length > 0) {
      dispatch(
        messagingActions.draftUploadFiles({
          ref: {
            id: chatId,
            scope: "chat",
          },
          items: filesToAttachments(inputFiles),
        })
      )
      reset({ files: null })
    }

    return () => {
      // second
    }
  }, [inputFiles])
  const removeFile = useCallback(
    (fileId: string) => {
      dispatch(
        messagingActions.draftDetachFile({
          ref: {
            scope: "chat",
            id: chatId,
          },
          attachmentId: fileId,
        })
      )
    },
    [chatId]
  )
  const onSubmit = async (data: Message) => {
    if (!chatData || !uid || !isValidMessage(data, draft?.draft)) {
      return
    }

    try {
      // dispatch(messagingActions.sendChatMessage({
      //   chatId: chatData.entity.id,
      //   message: data.text,
      //   messageId: newDocId(),
      //   privateKey: chatData.privateKey,
      //   senderId: uid,
      //   attachments: chatDraft.draft.attachments.items
      // }))
      console.log("send message")
      dispatch(
        messagingActions.chatDraftUpdateContent({
          ref: chatData.entity.id,
          content: {
            message: data.text,
          },
        })
      )
      dispatch(
        messagingActions.chatDraftSendTrigger({
          chatId: chatData.entity.id,
        })
      )
      reset({
        text: "",
      })

      return true
    } catch (e) {
      console.error("Error sending message", e)
    } finally {
    }
  }
  const takePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 70,
      allowEditing: false,
      saveToGallery: true,
      promptLabelCancel: "Annulla",
      source: CameraSource.Camera,
      resultType: CameraResultType.Base64,
    })
    const file = base64ToFile(
      image.base64String!,
      `image/${image.format}`,
      new Date().getTime() + "." + image.format
    )

    dispatch(
      messagingActions.draftUploadFiles({
        ref: {
          id: chatId,
          scope: "chat",
        },
        items: filesToAttachments([file] as unknown as FileList),
      })
    )
  }

  return {
    register,
    onSendMessage: handleSubmit(onSubmit),
    draft,
    removeFile,
    takePicture,
  }
}

import { useUserRole } from "@modules/auth/hooks/useUserRole"
import { ChannelPreviewEntity } from "@common/common/database/messaging/channel-preview/channel-preview.entity"

export type ChannelMode = "readOnly" | "readWrite"

export const useChannelMode = (channel?: ChannelPreviewEntity): ChannelMode => {
  const { isTraining, isManager, isAreaManager } = useUserRole()

  if (isTraining || isManager) {
    return "readWrite"
  }

  if (isAreaManager) {
    return channel?.data.scope === "area" || channel?.data.scope === "store"
      ? "readWrite"
      : "readOnly"
  }

  return "readOnly"
}

import { Icon } from "@atoms"
import { setCssVar } from "@helpers/ui"
import { IonContent, IonHeader, IonToolbar } from "@ionic/react"
import { useRouting } from "@root/routing/useRouting"
import { useRootSelector } from "@modules/store/redux"
import { Header } from "@molecules"
import classNames from "classnames"
import NotificationList from "../../../features/notifications/components/NotificationList"
import {
  selectExistsUnreadNotifications,
  selectNotifications,
} from "../../../features/notifications/selectors"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { useNotificationsReadMark } from "@features/notifications/hooks/useNotificationsRead"

const NOTIFICATIONS_READ_DELAY_MS = 3000

const NotificationsListContainer = () => {
  const { t } = useTranslations()

  const existsUnreadNotifications = useRootSelector(
    selectExistsUnreadNotifications
  )
  const { goBack } = useRouting()
  const { oldNotifications, recentNotifications, unreadNotifications } =
    useRootSelector(selectNotifications)

  useNotificationsReadMark(unreadNotifications, {
    delayMs: NOTIFICATIONS_READ_DELAY_MS,
  })

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar
          className="flex items-end"
          style={{
            ...setCssVar("--min-height", 0),
          }}
        >
          <Header
            showArrow
            center
            onClick={() => goBack()}
            rightNode={
              <Icon
                iconName={
                  existsUnreadNotifications
                    ? "notificationActive"
                    : "notification"
                }
                className={classNames(
                  "w-[24px] h-[24px] relative",
                  existsUnreadNotifications &&
                    "after:block after:absolute after:top-0 after:right-0 after:bg-red after:w-[7px] after:h-[7px] after:rounded-full"
                )}
              />
            }
          >
            <div className="font-semibold text-center text-md ">
              {t("notifications:section.title")}
            </div>
          </Header>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="py-container flex flex-col gap-[9px]">
          {recentNotifications.length > 0 && (
            <NotificationList
              notifications={recentNotifications}
              title={t("notifications:tab.recent.title")}
            />
          )}
          {oldNotifications.length > 0 && (
            <NotificationList
              notifications={oldNotifications}
              title={t("notifications:tab.old.title")}
            />
          )}
        </div>
      </IonContent>
    </>
  )
}

export default NotificationsListContainer

import { Button, Icon } from "@atoms"
import classNames from "classnames"
import { IconNames } from "@ui/atoms/Icon"
import { DivProps } from "@ui/types"

interface Props extends DivProps {
  text: React.ReactNode
  classes?: {
    container?: string
    button?: string
    text?: string
  }
  iconName: IconNames
  buttonText: React.ReactNode
}

const SectionBannerBox = ({
  className,
  text,
  classes,
  iconName,
  buttonText,
  ...rest
}: Props) => {
  return (
    <div
      className={classNames(
        "min-h-[116px] w-full pt-[40px] pl-[17px] pb-[17px] rounded-[8px] text-white  relative overflow-hidden cursor-pointer flex flex-col justify-between",
        classes?.container,
        className
      )}
      {...rest}
    >
      <div
        className={classNames(
          "font-bold text-xl leading-[1.4] mb-[13px]",
          classes?.text
        )}
      >
        {text}
      </div>
      <Button className="!bg-[transparent] h-auto w-full flex !justify-start">
        <div className="text-sm leading-[1.5]">{buttonText}</div>
        <Icon iconName="arrowForward" className="!w-[26px] !h-[20px]"></Icon>
      </Button>
      <Icon
        className="absolute inset-0 z-10 w-full h-full pointer-events-none"
        iconName={iconName}
      />
    </div>
  )
}

export default SectionBannerBox

import { createContext, useContext } from "react"
import { DevicePlatform } from "../types/device"
import { getPlatform, isMobileDevice } from "../utils/device"

export interface MobileKeyboard {
  opened: boolean
  height: number
}

export interface DeviceContextType {
  isMobile: boolean
  platform: DevicePlatform
  keyboard?: MobileKeyboard
}

export const DeviceContext = createContext<DeviceContextType>({
  isMobile: isMobileDevice(),
  platform: getPlatform(),
})

export const useDeviceFeatures = () => useContext(DeviceContext)

import {
  Firebase,
  FirestoreController,
} from "../../../infrastructure/firebase/controller"
import { Entity } from "../../../infrastructure/firebase/types"
import { CalendarDefinition } from "./calendar-definition.entity"

export class CalendarDefinitionController extends FirestoreController<CalendarDefinition> {
  constructor(firebase: Firebase) {
    super(firebase, "CalendarDefinitionRepo")
  }

  subscribeStoreCalendars(
    storeIds: string[],
    callback: (items: Entity<CalendarDefinition>[]) => void
  ) {
    return this.subscribeAllCalendars((items) => {
      return callback(
        items
          .filter((x) => x.data.store?.id && storeIds.includes(x.data.store.id))
          .sort((a, b) => a.data.name.localeCompare(b.data.name))
      )
    })
  }

  subscribeAreaCalendars(
    areaIds: string[],
    callback: (items: Entity<CalendarDefinition>[]) => void
  ) {
    return this.subscribeAllCalendars((items) => {
      return callback(
        items
          .filter((x) => x.data.area?.id && areaIds.includes(x.data.area?.id))
          .sort((a, b) => a.data.name.localeCompare(b.data.name))
      )
    })
  }

  subscribeAllCalendars(
    callback: (items: Entity<CalendarDefinition>[]) => void
  ) {
    return this.subscribeCollection((items) =>
      callback(items.sort((a, b) => a.data.name.localeCompare(b.data.name)))
    )
  }
}

import DailyEventsContainer from "@containers/calendars/DailyEvents"
import PageRoot from "@root/pages/PageRoot"

export interface DailyEventParams {
  date: string
  eventId: string
}

const DailyEventPage = () => {
  return (
    <PageRoot fullscreen>
      <DailyEventsContainer />
    </PageRoot>
  )
}

export { DailyEventPage }

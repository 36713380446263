import { IonPage } from "@ionic/react"
import DefaultScreen from "@modules/app/components/DefaultScreen"
import FullScreen from "@modules/app/components/FullScreen"
import DeploymentsRedirect from "@modules/deployments/components/DeploymentsRedirect"

export interface PageRootProps {
  children: React.ReactNode
  fullscreen?: boolean
}

const PageRoot = ({ children, fullscreen }: PageRootProps) => {
  return (
    <IonPage>
      {children}
      <DeploymentsRedirect />
      {fullscreen ? <FullScreen /> : <DefaultScreen />}
    </IonPage>
  )
}

export default PageRoot

import { MessageItem } from "@features/messaging/types"
import { MessageListItem } from "../../common"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { useUserRole } from "@modules/auth/hooks/useUserRole"
import { useContentContext } from "@atoms"
import { MessagingMode } from "../../common/MessageListItem/types"
import { useContactInfo } from "@modules/contacts/hooks/useContactInfo"
import ContactLabel from "../../common/ContactLabel"

export interface ChannelMessageItemProps {
  previousMessage?: MessageItem
  nextMessage?: MessageItem
  message: MessageItem
  isLastMessage: boolean
  onSelectToggle?: () => void
  selected?: boolean
  mode: MessagingMode
  onLongPress?: () => void
}

const ChannelMessageItem = ({
  message,
  previousMessage,
  nextMessage,
  isLastMessage,
  onSelectToggle,
  selected,
  mode,
  onLongPress,
}: ChannelMessageItemProps) => {
  const { t } = useTranslations()
  const { scrollToPosition } = useContentContext()

  const { isManager, isAdmin } = useUserRole()
  const isMine = message.direction === "out"
  const canDelete = (isManager && isMine) || isAdmin

  const { contact } = useContactInfo(message.senderUid)
  const showTitle = !isMine && message.senderUid !== previousMessage?.senderUid

  console.log("ChannelMessageItem " + showTitle, {
    message,
    previousMessage,
  })

  return (
    <MessageListItem
      title={
        showTitle && contact?.data ? (
          <ContactLabel contact={contact.data} />
        ) : undefined
      }
      messageData={message}
      previousMessage={previousMessage}
      nextMessage={nextMessage}
      key={message.id}
      lastReadMessageId={""}
      scrollTo={scrollToPosition}
      newMessagesLabel={t("messaging:channel.new.message")}
      isLastMessage={isLastMessage}
      selectable={canDelete}
      onSelectToggle={onSelectToggle}
      selected={selected}
      showSelect={canDelete && mode === "select"}
      onLongPress={onLongPress}
    />
  )
}

export default ChannelMessageItem

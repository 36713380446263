import { randomBytes, secretbox } from "tweetnacl"
import { decodeUtf8, encodeUtf8 } from "../../utils/encoding"

export interface EncryptMessageInput {
  message: string
  privateKey: Uint8Array
  oneTimeCode?: Uint8Array
}

export interface EncryptMessageResult {
  encryptedMessage: Uint8Array
  oneTimeCode: Uint8Array
}

export const encryptMessage = (input: EncryptMessageInput) => {
  const { message, privateKey } = input
  const oneTimeCode = input?.oneTimeCode ?? randomBytes(24)
  const encryptedMessage = secretbox(
    decodeUtf8(message),
    oneTimeCode,
    privateKey
  )
  return {
    encryptedMessage,
    oneTimeCode,
  }
}

export interface DecryptMessageInput {
  encryptedMessage: Uint8Array
  privateKey: Uint8Array
  oneTimeCode: Uint8Array
}

export interface DecryptMessageResult {
  message: string
}

export const decryptMessage = (input: DecryptMessageInput) => {
  const { encryptedMessage, oneTimeCode, privateKey } = input

  const decodedMessage = secretbox.open(
    encryptedMessage,
    oneTimeCode,
    privateKey
  ) as Uint8Array

  const plainText = encodeUtf8(decodedMessage)

  return {
    message: plainText,
  }
}

import { PayloadAction } from "@reduxjs/toolkit"
import { buffers, eventChannel } from "redux-saga"
import { put, take, cancelled, takeLatest } from "typed-redux-saga"
import { AppUsageAggregation } from "../../../../database/tracking/app-usage-aggregation/app-usage-aggregation.entity"
import { getSagaContext } from "../../../state/context"
import { performanceRoutines } from "../state"
import { WatchAppUsageInput } from "../state/types"

export function* watchAppUsageData(action: PayloadAction<WatchAppUsageInput>) {
  const { payload } = action

  const firebase = yield* getSagaContext("firebase")
  const channel = eventChannel<AppUsageAggregation[]>((emitter) => {
    const unsubscribe =
      firebase.collections.appUsageAggregationController.subscribeAppDailyUsage(
        payload.uid,
        payload.daysToWatch,
        (data) => {
          emitter(data.map((x) => x.data))
        }
      )
    return () => unsubscribe()
  }, buffers.sliding(1))

  try {
    yield* takeLatest(performanceRoutines.watchAppUsage.fulfill, function* () {
      channel.close()
    })
    while (true) {
      const data = yield* take(channel)
      yield* put(performanceRoutines.watchAppUsage.success(data))
    }
  } catch (error) {
    yield* put(performanceRoutines.watchAppUsage.failure(error))
  } finally {
    if (yield* cancelled()) {
      channel.close()
    }
  }
}

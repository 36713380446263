import { PreviewListItem } from "../../common"
import { getAvatarUserColor } from "@helpers/profiles"
import { ReadStatus } from "../../common/ReadConfirmation"
import { ChatPreviewEntity } from "@package/common/database/messaging/chat-preview/chat-preview.entity"
import { useRouting } from "@root/routing/useRouting"
import { useChat } from "@features/messaging/hooks/useChat"
import { useUserTypeLabel } from "@modules/app/hooks/useUserTypeLabel"
import { useUserPreviewLabel } from "@modules/app/hooks/useUserPreviewLabel"

const getReadStatus = (
  chatPreview: ChatPreviewEntity
): ReadStatus | undefined => {
  if (
    !chatPreview.data.lastMessage ||
    chatPreview.data.lastMessage.d === "in"
  ) {
    return undefined
  }

  if (
    chatPreview.data.lastMessage.id === chatPreview.data.targetLastReadMessageId
  ) {
    return "READ"
  }

  if (
    chatPreview.data.lastMessage.id ===
    chatPreview.data.targetLastReceivedMessageId
  ) {
    return "UNREAD"
  }

  return "SENT"
}

interface Props {
  chatId: string
}

const getMessagePreviewText = (chat: ChatPreviewEntity) => {
  // if (chat?.data?.lastMessage?.m) {
  //   return chat?.data?.lastMessage.m
  // }

  if (chat?.data?.lastMessage?.a) {
    return "Media"
  }

  return ""
}

const ChatPreviewListItem = ({ chatId }: Props) => {
  const { chat } = useChat(chatId)
  const { goTo } = useRouting()
  const { getUserLabel } = useUserPreviewLabel()

  if (!chat) {
    return <></>
  }

  return (
    <PreviewListItem
      onClick={() => goTo("chatDetail", { params: { chatId } })}
      title={chat.title}
      lastMessage={
        chat.lastMessage?.trim()
          ? chat.lastMessage
          : getMessagePreviewText(chat.entity)
      }
      lastMessageTime={chat.lastMessageTime}
      notifications={chat.entity.data.totUnreadMessages}
      readStatus={getReadStatus(chat.entity)}
      noMessagesPlaceholder={getUserLabel(chat.entity.data?.receiverInfo)}
      avatarImgUrl={chat.entity.data.receiverInfo?.profile?.avatarUrl}
      avatarColor={getAvatarUserColor(
        chat.entity.data.receiverInfo?.profile?.firstName
      )}
    />
  )
}

export { ChatPreviewListItem }

import { CustomClaims } from "@common/common/types/api"
import {
  signInWithEmailAndPassword,
  signOut,
  getIdToken,
  getIdTokenResult,
} from "firebase/auth"
import Firebase from "."

export function getToken(this: Firebase) {
  return this.token
}

export function getClaims(this: Firebase) {
  return this.claims
}

export function getUser(this: Firebase) {
  return this.user
}

export function getUid(this: Firebase) {
  return this.getImpersonatedUid() ?? this.user?.uid
}

export function getFirebaseToken(this: Firebase) {
  return this.token
}

export async function refreshFirebaseToken(this: Firebase) {
  await this.setTokenAndClaims()
  return this.getFirebaseToken()
}

export async function login(this: Firebase, { email, password }: LoginInfo) {
  const userCredential = await signInWithEmailAndPassword(
    this.auth,
    email,
    password
  )
  this.user = userCredential.user
  await this.setTokenAndClaims()
}
export async function logout(this: Firebase) {
  this.user = undefined
  this.token = undefined
  this.claims = undefined
  await signOut(this.auth)
}

export function watchIsLoggedIn(
  this: Firebase,
  callback: (isLoggedIn: boolean) => void
) {
  const unsub1 = this.auth.onIdTokenChanged(async (user) => {
    if (user) {
      this.user = user
      await this.setTokenAndClaims()
    }
    callback(user != null && !user.isAnonymous)
  })
  // const unsub2 = onAuthStateChanged(this.auth, async (user) => {
  //   if (user) {
  //     this.user = user;
  //     await this.setTokenAndClaims();
  //   }
  //   callback(user != null && !user.isAnonymous);
  // });
  // callback(this.user != null && !this.user.isAnonymous);
  return () => {
    unsub1()
    // unsub2();
  }
}

export async function setTokenAndClaims(this: Firebase) {
  if (this.user) {
    this.token = await getIdToken(this.user)
    const tokenResult = await getIdTokenResult(this.user)
    const claims: CustomClaims = tokenResult.claims as any
    if (claims.userType === "admin") {
      this.claims = {
        userType: "admin",
        temporaryPassword: claims.temporaryPassword,
      }
    } else {
      this.claims = {
        group: claims.userType,
        userType: claims.userType,
        temporaryPassword: claims.temporaryPassword,
      }
    }
  }
}

export type LoginInfo = {
  email: string
  password: string
}

import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
  Slice,
} from "@reduxjs/toolkit"
import { NoInfer } from "@reduxjs/toolkit/dist/tsHelpers"
import { createRoutine } from "redux-saga-routines"
import { NotificationsMarkReadInput } from "../../../../api/functions"
import { completed, empty, failure, loading } from "../../../../utils/state"
import { getSliceContextCreator } from "../../../state/context"
import {
  NotificationEntry,
  NotificationsState,
  PushNotificationPermission,
} from "./types"

const watchNotifications = createRoutine("NOTIFICATIONS|WATCH_NOTIFICATIONS", {
  success: (payload: NotificationEntry[]) => payload,
})

const notificationsMarkRead = createRoutine("NOTIFICATIONS|MARK_READ", {
  trigger: (payload: NotificationsMarkReadInput) => payload,
})

const initialState: NotificationsState = {
  notifications: empty(),
  device: {
    ftmToken: undefined,
  },
  pushPermission: "uninitialized",
}

type PerformancesSlice<T extends {} | undefined> = {
  extendState?: T
  extendExtraReducers?: (
    builder: ActionReducerMapBuilder<NoInfer<NotificationsState & T>>
  ) => void
}

const createNotificationsState = <T extends {}>(prop: PerformancesSlice<T>) => {
  return createSlice({
    name: "notifications",
    initialState: {
      ...initialState,
      ...prop.extendState,
    } as NotificationsState & T,
    reducers: {
      setFcmToken(state, action: PayloadAction<string>) {
        state.device.ftmToken = action.payload
      },
      setPushNotificationPermission(
        state,
        action: PayloadAction<PushNotificationPermission>
      ) {
        state.pushPermission = action.payload
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(watchNotifications.TRIGGER, (state) => {
          state.notifications = loading()
        })
        .addCase(watchNotifications.FULFILL, (state) => {})
        .addCase(
          watchNotifications.SUCCESS,
          (state, action: ReturnType<typeof watchNotifications.success>) => {
            state.notifications = completed(action.payload)
          }
        )
        .addCase(
          watchNotifications.FAILURE,
          (state, action: ReturnType<typeof watchNotifications.failure>) => {
            state.notifications = failure(action.payload)
          }
        )

      prop?.extendExtraReducers?.(builder as any)
    },
  })
}

const notificationsRoutines = {
  watchNotifications,
  notificationsMarkRead,
}

export type NotificationsSlice = ReturnType<typeof createNotificationsState>
export type NotificationsRoutines = typeof notificationsRoutines

export type NotificationsSliceContext = NotificationsSlice & {
  actions: NotificationsRoutines
}

export const createNotificationsSliceContext = getSliceContextCreator(
  notificationsRoutines
)

export { createNotificationsState, notificationsRoutines }

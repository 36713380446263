import { SplashScreen } from "@capacitor/splash-screen"
import { useEffect } from "react"

const hideSplashScreen = async () => {
  await SplashScreen.hide({ fadeOutDuration: 1000 })
}

export default (hide: boolean) => {
  useEffect(() => {
    if (hide == true) {
      hideSplashScreen()
    }
  }, [hide])
}

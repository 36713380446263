import React, { useMemo } from "react"
import { BarRounded } from "@visx/shape"
import { AxisBottom, AxisLeft } from "@visx/axis"
import { Group } from "@visx/group"
import { scaleBand, scaleLinear } from "@visx/scale"
import { getThemeColorVar, useSize } from "@helpers/ui"
import { pluck } from "ramda"
import { formatDistanceStrict } from "date-fns"
import { it } from "date-fns/locale"
;(window as any).formatDistanceStrict = formatDistanceStrict
interface Props {
  performanceData: PerformanceData[]
}
export interface PerformanceData {
  date: string
  minutes: number
}
const marginTop = 16.8
const marginBottom = 28
const marginLeft = 59
const marginRight = 0
const quantizeTime = 30 // rounds to 30 minutes

const PerformanceBars = (props: Props) => {
  const inputData = props.performanceData
  const [{ width, height }, setRef] = useSize()
  const xMax = width - marginLeft - marginRight
  const yMax = height - marginTop - marginBottom

  const { performanceData, xScale, yScale, ticks } = useMemo(() => {
    const performanceData = inputData.map((x) => ({
      ...x,
      minutes: Math.round(x.minutes / quantizeTime) * quantizeTime,
    }))
    let maxValue = Math.max.apply(Math, pluck("minutes", performanceData)) ?? 0
    const modulo = maxValue % 180
    if (modulo != 0) {
      maxValue = maxValue - modulo + 180
    }
    // const ticks = new Set();
    // return max / 30;
    const xScale = scaleBand<string>({
      range: [0, xMax ?? 0],
      round: true,
      domain: performanceData.map((x) => x.date),
      paddingInner: 0.7,
    })
    const yScale = scaleLinear<number>({
      range: [yMax, 0],
      round: true,
      domain: [0, maxValue],
    })
    const third = maxValue / 3
    const ticks = [
      0,
      Math.round(third / quantizeTime) * quantizeTime,
      Math.round((third * 2) / quantizeTime) * quantizeTime,
      maxValue,
    ]
    return {
      performanceData,
      xScale,
      yScale,
      ticks,
    }
  }, [xMax, width, inputData])

  return (
    <div className="relative w-full  h-[172px]" ref={setRef}>
      {width != 0 && height != 0 && (
        <svg width={width} height={height}>
          <Group top={marginTop} left={marginLeft}>
            {performanceData.map((d) => {
              const barWidth = xScale.bandwidth()
              const barHeight = yMax - (yScale(d.minutes) ?? 0)
              const barX = xScale(d.date) ?? 0
              const barY = yMax - barHeight
              return (
                <Group key={`bar-${d.date}`}>
                  <BarRounded
                    radius={10000}
                    all
                    x={barX}
                    y={0}
                    width={barWidth}
                    height={yMax}
                    fill="var(--ion-color-grey)"
                  />
                  {d.minutes != 0 && (
                    <BarRounded
                      radius={10000}
                      all
                      x={barX}
                      y={barY}
                      width={barWidth}
                      height={barHeight}
                      fill="var(--ion-color-red)"
                    />
                  )}
                </Group>
              )
            })}
          </Group>
          <AxisBottom
            left={marginLeft}
            top={yMax + marginTop}
            scale={xScale}
            hideAxisLine
            hideTicks
            axisClassName=" text-base "
            tickLabelProps={() => ({
              fill: getThemeColorVar("light"),
              textAnchor: "middle",
            })}
          />
          <AxisLeft
            left={marginLeft - 21}
            top={marginTop}
            tickValues={ticks}
            tickFormat={(x: any) => {
              // return (hoursMapping as any)[x]
              return formatDistanceStrict(0, x * 6e4, {
                locale: it,
                unit: "hour",
              })
            }}
            axisClassName=" text-base "
            tickLabelProps={() => ({
              fill: getThemeColorVar("light"),
              textAnchor: "middle",
            })}
            hideTicks
            hideAxisLine
            scale={yScale}
          ></AxisLeft>
        </svg>
      )}
    </div>
  )
}

export default PerformanceBars

import classNames from "classnames"
import { setThemeColorVar, ThemeColors } from "@helpers/ui"

import { DivProps } from "@ui/types"
import { ProgressCircle } from "../../atoms/ProgressCircle"

type Value = React.ComponentProps<typeof ProgressCircle>["value"]

interface Props extends DivProps {
  text?: string
  value: Value
  classes?: {
    container?: string
    title?: string
    ringContainer?: string
    circle?: string
    progress?: string
    text?: string
  }
}

const ProgressBox = ({ value, text, className, classes }: Props) => {
  return (
    <div
      className={classNames(
        "w-[132px] h-[125px] rounded-[12px] pt-[10px] text-white text-base leading-[16px]",
        className,
        classes?.container
      )}
    >
      {text && (
        <div className={classNames("text-center mb-[14px]", classes?.title)}>
          {text}
        </div>
      )}
      <div className="flex items-center justify-center" style={{}}>
        <ProgressCircle
          classes={{
            container: classNames("h-[65px] w-[65px]", classes?.ringContainer),
            progress: classNames("text-gold stroke-gold", classes?.progress),
            circle: classNames(classes?.circle),
            text: classNames(classes?.text),
          }}
          value={value}
        />
      </div>
    </div>
  )
}

export default ProgressBox

import {
  Firebase,
  FirestoreController,
  setServerTimestamp,
} from "../../../infrastructure/firebase/controller"
import {
  DocumentData,
  limit,
  orderBy,
  query,
  where,
  WriteBatch,
} from "firebase/firestore"
import { Entity } from "../../../infrastructure/firebase/types"
import { Chat, ImChatMessage } from "./chat.entity"
import { buildChatId } from "./chat.id"

const LATEST_MESSAGES_LIMIT = 10

export class ChatController extends FirestoreController<Chat> {
  readonly MessagesSubCollection = "messages"

  constructor(firebase: Firebase) {
    super(firebase, "ChatRepo")
  }

  async getOrCreateChat(senderId: string, receiverId: string) {
    if (!senderId) {
      throw new Error("Empty senderId")
    }

    if (!receiverId) {
      throw new Error("Empty receiverId")
    }

    return await this.getOrCreate({
      id: buildChatId(senderId, receiverId),
      data: {
        active: true,
        senderId,
        receiverId,
      },
    })
  }

  async appendMessage(
    chatId: string,
    message: ImChatMessage,
    batch?: WriteBatch
  ) {
    await this.createChildDocument(
      {
        childId: message.id,
        data: message,
        parentId: chatId,
        subCollectionName: this.MessagesSubCollection,
      },
      batch
    )
  }

  async updateChatTimestamp(chatId: string, batch?: WriteBatch) {
    await this.update(
      {
        id: chatId,
        data: {
          lastMessageTime: setServerTimestamp(),
        },
      },
      batch
    )
  }

  subscribeUserChats(
    senderId: string,
    callback: (items: Entity<Chat>[]) => void
  ) {
    return this.subscribeQuery(
      (collection) =>
        query(
          collection,
          where("active", "==", true),
          where("areaId", "==", senderId)
        ),
      callback
    )
  }

  subscribeChatNewMessages(
    chatId: string,
    callback: (items: DocumentData[]) => void
  ) {
    return this.subscribeSubCollectionQueryRaw(
      chatId,
      this.MessagesSubCollection,
      (collection) =>
        query(collection, orderBy("t", "desc"), limit(LATEST_MESSAGES_LIMIT)),
      callback
    )
  }

  async fetchChatMessagePageDesc(
    chatId: string,
    pageSize: number,
    cursor: string
  ) {
    if (!cursor) {
      throw new Error(`fetchChatMessagePageDesc -> missing cursor`)
    }
    return await this.fetchSubCollectionPaged({
      parentId: chatId,
      cursor,
      subCollection: this.MessagesSubCollection,
      sortProperty: "t",
      sortOrder: "desc",
      pageSize,
    })
  }

  async fetchNewMessages(channelId: string, startElementId: string) {
    if (!startElementId) {
      throw new Error(`fetchNewMessages -> missing startElementId`)
    }
    return await this.fetchSubCollectionElements({
      parentId: channelId,
      startElementId,
      subCollection: this.MessagesSubCollection,
      sortProperty: "t",
      sortOrder: "asc",
    })
  }
}

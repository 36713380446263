import { box } from "tweetnacl"
import { decodeBase64, encodeBase64 } from "../../utils/encoding"

export const generateKeyPair = () => box.keyPair()

export interface KeyPairStr {
  publicKey: string
  secretKey: string
}

export const encodeKey = (key: Uint8Array) => encodeBase64(key)
export const decodeKey = (key: string) => decodeBase64(key)

export const generateKeyPairStr = () => {
  const keyPair = generateKeyPair()
  return {
    publicKey: encodeKey(keyPair.publicKey),
    secretKey: encodeKey(keyPair.secretKey),
  }
}

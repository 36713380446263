import classNames from "classnames"
import { DivProps } from "@ui/types"
import { ProgressCircle } from "../../atoms/ProgressCircle"
import { format } from "date-fns"
import { it } from "date-fns/locale"
import { ReactNode } from "react"

interface Props extends DivProps {
  title: string
  typology: string
  dueDate: Date
  currentValue: number
  currentPercentage: number
  maxValue: number
  classes?: {
    container?: string
    ringContainer?: string
    circle?: string
    progress?: string
    circleText?: string
  }
  labels: {
    expiration: ReactNode
    progress: ReactNode
  }
}

const ObjectiveBox = ({
  currentValue,
  currentPercentage,
  dueDate,
  maxValue,
  title,
  typology,
  className,
  classes,
  labels,
}: Props) => {
  return (
    <div
      className={classNames(
        "w-[269px] h-[144px] rounded-[12px] pt-[14px] pb-[20px] pl-[14px] pr-[20px] text-white flex bg-dark text-left",
        className,
        classes?.container
      )}
    >
      <div className="flex flex-col justify-between flex-1">
        <div className="">
          <div className="bg-grey text-dark py-[10px] px-[18px] rounded-[8px] w-max text-[10px] leading-none tracking-[3px] font-bold">
            {title}
          </div>
          <div className="text-xs mt-[8px]">{typology}</div>
        </div>
        <div>
          <div className="text-2xs">{labels.expiration}</div>
          <div className="text-base capitalize">
            {format(dueDate, "dd MMMM yyyy", { locale: it })}
          </div>
        </div>
      </div>
      <div
        className={classNames("flex flex-col items-end", {
          "justify-between": labels.progress,
          "justify-center": !labels.progress,
        })}
      >
        <div>
          <ProgressCircle
            classes={{
              container: classNames(
                "!h-[75px] !w-[75px]",
                classes?.ringContainer
              ),
              progress: classNames("text-gold stroke-gold", classes?.progress),
              circle: classNames(classes?.circle),
              text: classNames(classes?.circleText),
            }}
            value={currentPercentage / 100}
          />
        </div>
        {labels.progress && (
          <div className="text-center text-2xs">{labels.progress}</div>
        )}
      </div>
    </div>
  )
}

export { ObjectiveBox }

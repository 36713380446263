import { Icon } from "@atoms"
import { IonContent } from "@ionic/react"
import { classNames } from "@punks/ui-core"
import { IconType } from "@ui/atoms/Icon"

export interface FrameLayoutProps {
  icon: IconType
  title: React.ReactNode
  children: React.ReactNode
  shrink?: boolean
  onHeadClick?: () => void
  className?: string
}

const FrameLayout = ({
  children,
  icon,
  title,
  shrink,
  onHeadClick,
  className,
}: FrameLayoutProps) => {
  return (
    <IonContent fullscreen>
      <div
        className={classNames(
          "flex flex-col items-center justify-center h-full px-[32px]",
          className
        )}
      >
        {!shrink && (
          <div onClick={onHeadClick}>
            <Icon
              iconName={icon}
              className="mb-[35px] w-[96px] h-[56px] mx-auto"
            />
            <div className="text-2xl leading-[32px] mb-[43px] text-center">
              {title}
            </div>
          </div>
        )}
        {children}
      </div>
    </IonContent>
  )
}

export default FrameLayout

import { IonIcon } from "@ionic/react"
import { closeOutline } from "ionicons/icons"

export interface IconProps {
  size?: string
  className?: string
}

export const CloseIcon = (props: IconProps) => (
  <IonIcon icon={closeOutline} {...props} />
)

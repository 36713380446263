import { useSelector } from "react-redux"
import CourseChip from "./CourseChip"
import { useRootSelector } from "@modules/store/redux"
import { ProgressBar } from "@atoms"
import {
  selectCourseById,
  selectCourseCompletionPercentageMap,
} from "@features/courses/state/selectors"
import { useMemo } from "react"
import CourseTime from "./CourseTime"
import CourseBrandName from "./CourseBrandName"
import { getCourseThumbUrl } from "../utils"
import { useRouting } from "@root/routing/useRouting"

interface Props {
  courseId: string
}

const CoursePreviewBox = ({ courseId }: Props) => {
  const selector = useMemo(() => selectCourseById(courseId), [courseId])
  const course = useSelector(selector)
  const percentageMap = useRootSelector(selectCourseCompletionPercentageMap)
  // const { getBrandName } = useSelector(brandsLabelSelector)
  const { title, brandCode } = course?.data.info ?? {}
  const { goTo } = useRouting()
  const thumb = course ? getCourseThumbUrl(course) : undefined
  const progress = Math.min(percentageMap[courseId], 1)
  return (
    <div
      className="flex gap-[17px] cursor-pointer"
      onClick={() => {
        goTo("courseDetail", { params: { courseId: courseId } })
      }}
    >
      <div
        className="w-[114px] h-[103px] bg-grey bg-cover bg-center rounded-xl"
        style={{ backgroundImage: `url(${thumb})` }}
      ></div>
      <div className="flex flex-col justify-between flex-1 gap-[8px] ">
        <div className="flex flex-col items-start gap-[4px] xxs:flex-row xxs:gap-[8px] xxs:items-center">
          <CourseChip info={course?.data.info} />
          <CourseTime info={course?.data.info} />
        </div>
        <div>
          <div className="text-base leading-[1.43]">{title}</div>
          <CourseBrandName brandName={course?.data?.info.brandName} />
        </div>
        <ProgressBar
          styles={{
            "--background": "var(--light)",
            "--progress-background": "var(--ion-color-red)",
          }}
          value={isNaN(progress) ? 0 : progress}
        />
      </div>
    </div>
  )
}

export default CoursePreviewBox

import { isPlatform } from "@ionic/react"
import { useEffect } from "react"

export const useDesktopLayout = () =>
  useEffect(() => {
    if (isPlatform("desktop")) {
      document
        .querySelector("body")
        ?.classList.add("!max-w-[var(--max-container-width)]")
    }
  }, [])

export interface RatingIconProps {
  width?: number
  fill?: string
}

export const NegativeRatingIcon = (props: RatingIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68.79"
      height="68.791"
      viewBox="0 0 68.79 68.791"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rettangolo_846"
            data-name="Rettangolo 846"
            width="68.79"
            height="68.791"
            transform="translate(0 -0.001)"
            fill={props.fill ?? "none"}
          />
        </clipPath>
      </defs>
      <g
        id="Raggruppa_1750"
        data-name="Raggruppa 1750"
        transform="translate(0 0)"
      >
        <g
          id="Raggruppa_1749"
          data-name="Raggruppa 1749"
          clip-path="url(#clip-path)"
        >
          <path
            id="Tracciato_4330"
            data-name="Tracciato 4330"
            d="M34.4,68.791a34.4,34.4,0,1,1,34.4-34.4,34.435,34.435,0,0,1-34.4,34.4m0-66.743A32.347,32.347,0,1,0,66.742,34.4,32.384,32.384,0,0,0,34.4,2.048"
            fill={props.fill}
          />
          <path
            id="Tracciato_4331"
            data-name="Tracciato 4331"
            d="M25.225,29.143a3.175,3.175,0,1,1-3.175-3.175,3.175,3.175,0,0,1,3.175,3.175"
            fill={props.fill}
          />
          <path
            id="Tracciato_4332"
            data-name="Tracciato 4332"
            d="M50.447,29.143a3.175,3.175,0,1,1-3.175-3.175,3.175,3.175,0,0,1,3.175,3.175"
            fill={props.fill}
          />
          <path
            id="Tracciato_4333"
            data-name="Tracciato 4333"
            d="M59.113,49.126a1.022,1.022,0,0,1-.849-.451,28.792,28.792,0,0,0-47.738,0,1.024,1.024,0,0,1-1.7-1.146,30.838,30.838,0,0,1,51.131,0,1.024,1.024,0,0,1-.847,1.6"
            fill={props.fill}
          />
        </g>
      </g>
    </svg>
  )
}

export const MediumRatingIcon = (props: RatingIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68.791"
      height="68.791"
      viewBox="0 0 68.791 68.791"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rettangolo_845"
            data-name="Rettangolo 845"
            width="68.791"
            height="68.791"
            transform="translate(0 -0.001)"
            fill={props.fill ?? "none"}
          />
        </clipPath>
      </defs>
      <g
        id="Raggruppa_1748"
        data-name="Raggruppa 1748"
        transform="translate(0 0)"
      >
        <g
          id="Raggruppa_1748-2"
          data-name="Raggruppa 1748"
          clip-path="url(#clip-path)"
        >
          <path
            id="Tracciato_4326"
            data-name="Tracciato 4326"
            d="M34.4,68.791A34.4,34.4,0,1,1,68.79,34.4,34.435,34.435,0,0,1,34.4,68.791m0-66.743A32.347,32.347,0,1,0,66.743,34.4,32.384,32.384,0,0,0,34.4,2.048"
            fill={props.fill}
          />
          <path
            id="Tracciato_4327"
            data-name="Tracciato 4327"
            d="M25.225,28.088a3.175,3.175,0,1,1-3.175-3.175,3.175,3.175,0,0,1,3.175,3.175"
            fill={props.fill}
          />
          <path
            id="Tracciato_4328"
            data-name="Tracciato 4328"
            d="M50.447,28.088a3.175,3.175,0,1,1-3.175-3.175,3.175,3.175,0,0,1,3.175,3.175"
            fill={props.fill}
          />
          <path
            id="Tracciato_4329"
            data-name="Tracciato 4329"
            d="M56.99,39.97H11.8a1.024,1.024,0,0,1,0-2.048H56.99a1.024,1.024,0,0,1,0,2.048"
            fill={props.fill}
          />
        </g>
      </g>
    </svg>
  )
}

export const PositiveRatingIcon = (props: RatingIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68.79"
      height="68.791"
      viewBox="0 0 68.79 68.791"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rettangolo_844"
            data-name="Rettangolo 844"
            width="68.79"
            height="68.791"
            transform="translate(0 -0.001)"
            fill={props.fill ?? "none"}
          />
        </clipPath>
      </defs>
      <g
        id="Raggruppa_1747"
        data-name="Raggruppa 1747"
        transform="translate(0 0)"
      >
        <g
          id="Raggruppa_1747-2"
          data-name="Raggruppa 1747"
          clip-path="url(#clip-path)"
        >
          <path
            id="Tracciato_4322"
            data-name="Tracciato 4322"
            d="M34.4,68.791a34.4,34.4,0,1,1,34.4-34.4,34.435,34.435,0,0,1-34.4,34.4m0-66.743A32.347,32.347,0,1,0,66.742,34.4,32.384,32.384,0,0,0,34.4,2.048"
            fill={props.fill}
          />
          <path
            id="Tracciato_4323"
            data-name="Tracciato 4323"
            d="M25.225,23.014a3.175,3.175,0,1,1-3.175-3.175,3.175,3.175,0,0,1,3.175,3.175"
            fill={props.fill}
          />
          <path
            id="Tracciato_4324"
            data-name="Tracciato 4324"
            d="M50.447,23.014a3.175,3.175,0,1,1-3.175-3.175,3.175,3.175,0,0,1,3.175,3.175"
            fill={props.fill}
          />
          <path
            id="Tracciato_4325"
            data-name="Tracciato 4325"
            d="M34.4,41.99A30.821,30.821,0,0,1,8.83,28.4a1.023,1.023,0,1,1,1.7-1.146,28.792,28.792,0,0,0,47.738,0,1.023,1.023,0,1,1,1.7,1.146A30.821,30.821,0,0,1,34.4,41.99"
            fill={props.fill}
          />
        </g>
      </g>
    </svg>
  )
}

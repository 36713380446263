import { selectChatPreviewIdList } from "@features/messaging/selectors/preview"
import { useSelector } from "react-redux"
import { ChatPreviewListItem } from ".."

const ChatPreviewList = () => {
  const chatPreviews = useSelector(selectChatPreviewIdList)
  // const isLoading = useRootSelector(state => state.messaging.chatPreviews.loading)
  // if (true) {
  //   return (
  //     <div className="flex items-center justify-center w-full h-full">
  //       <InlineLoader/>
  //     </div>
  //   )
  // }
  return (
    <div className="flex flex-col gap-[18px]">
      {chatPreviews.map((id) => (
        <ChatPreviewListItem key={id} chatId={id} />
      ))}
    </div>
  )
}

export { ChatPreviewList }

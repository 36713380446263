import { IonContent, IonHeader, IonToolbar } from "@ionic/react"
import Tabs from "@ui/molecules/Tabs"
import CourseDetailFileList from "@features/courses/components/detail/attachment/CourseDetailFileList"
import CourseDetailTestList from "@features/courses/components/detail/test/CourseDetailTestList"
import CourseDetailVideoBox from "@features/courses/components/detail/video/CourseDetailVideoBox"
import CourseDetailVideoList from "@features/courses/components/detail/video/CourseDetailVideoList"
import CourseDetailDescription from "@features/courses/components/courses/CourseDetailDescription"
import { useCourseDetail } from "@features/courses/hooks"
import { useRootSelector } from "@modules/store/redux"

const CourseDetailContainer = () => {
  const { goBack, isLoading, selectedTab, setSelectedTab, coursesDetailsTabs } =
    useCourseDetail()

  const { coursesLoaded } = useRootSelector((state) => state.courses)
  if (isLoading || !coursesLoaded) {
    return <></>
  }

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar className="relative">
          {/* <Header
            onClick={() => goBack()}
            showArrow
            paddings={{ top: 22 }}
          ></Header> */}
          <CourseDetailVideoBox onBackArrowClick={() => goBack()} />
          <Tabs
            activeTab={selectedTab}
            tabs={coursesDetailsTabs}
            setSelectedTab={setSelectedTab}
            className="mt-3"
          />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div>
          <CourseDetailDescription
            isVisible={selectedTab.id == "description"}
          />
          <CourseDetailVideoList isVisible={selectedTab.id == "description"} />
          <CourseDetailFileList isVisible={selectedTab.id == "attachments"} />
          <CourseDetailTestList isVisible={selectedTab.id == "test"} />
        </div>
      </IonContent>
    </>
  )
}

export default CourseDetailContainer

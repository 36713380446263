import { FirebaseStorage, getDownloadURL } from "firebase/storage"
import { uploadFile } from "../../../storage"
import {
  MessageAttachmentAsset,
  MessageAttachmentAssetMetadata,
} from "../types"

export interface AttachmentUploadInput {
  file: File
  attachmentId: string
  uploadDate: Date
  metadata: MessageAttachmentAssetMetadata
}

const ATTACHMENTS_BASE_PATH = "a"
const buildAttachmentPath = (
  date: Date,
  attachmentId: string,
  filename: string
) =>
  [
    ATTACHMENTS_BASE_PATH,
    date.getFullYear(),
    (date.getMonth() + 1).toString().padStart(2, "0"),
    date.getDate().toString().padStart(2, "0"),
    `${attachmentId}_${filename}`,
  ].join("/")

const toCustomData = (input: AttachmentUploadInput) => ({
  id: input.attachmentId,
  sid: input.metadata.senderId,
  rids: input.metadata.receiverIds?.join("|") ?? "",
  cids: input.metadata.channelIds?.join("|") ?? "",
  ofn: input.file.name,
})

export const attachmentUpload = async (
  storage: FirebaseStorage,
  input: AttachmentUploadInput
): Promise<MessageAttachmentAsset> => {
  const result = await uploadFile(storage, {
    file: input.file,
    contentType: input.file.type,
    path: buildAttachmentPath(
      input.uploadDate,
      input.attachmentId,
      input.file.name
    ),
    customData: toCustomData(input),
  })
  return {
    url: await getDownloadURL(result.ref),
    contentType: result.metadata.contentType,
    name: input.file.name,
  }
}

import { createDeepEqualsSelector } from "@helpers/selectors"
import {
  ChannelPreview,
  ChannelPreviewEntity,
} from "@common/common/database/messaging/channel-preview/channel-preview.entity"
import { ChatPreviewEntity } from "@common/common/database/messaging/chat-preview/chat-preview.entity"
import { decryptMessage } from "@common/common/features/messaging/common"
import { selectMessagingPreviews } from "@common/common/frontend/features/messaging/selectors/selectMessagingPreviews"
import { createSelector } from "@reduxjs/toolkit"
import { keysSelector } from "../keys"
import { sortBy } from "lodash"
import { MessagingPreview } from "@common/common/frontend/features/messaging/types"

const channelPreviewListSelector = createSelector(
  selectMessagingPreviews,
  (items) => items?.items?.filter((x) => x.ref.type === "channel") ?? []
)
const chatPreviewListSelector = createSelector(
  selectMessagingPreviews,
  (items) => items?.items?.filter((x) => x.ref.type === "chat") ?? []
)

const getChannelWeight = (item: MessagingPreview) => {
  switch ((item.item.data as ChannelPreview).scope) {
    case "store":
      return 0
    case "company":
      return 1
    case "area":
      return 3
    default:
      return 10
  }
}

export const selectChannelPreviewIdList = createDeepEqualsSelector(
  channelPreviewListSelector,
  (items) =>
    items
      ? sortBy(items, (x) => getChannelWeight(x), "asc").map((x) => x.item.id)
      : []
)

export const selectChannelPreviewById = (channelPreviewId: string) =>
  createSelector(
    channelPreviewListSelector,
    keysSelector,
    (channelList, keys) => {
      const channelEntity = channelList.find(
        (x) => x.item.id === channelPreviewId
      )?.item as ChannelPreviewEntity | undefined

      if (channelEntity?.data == null) {
        return undefined
      }

      if (!keys?.channels) {
        return undefined
      }

      const privateKey = keys?.channels?.find(
        (x) => x.channelId === channelEntity.data.channelId
      )?.privateKey

      if (privateKey == null) {
        console.error(
          `channel ${channelEntity.data.channelId} private key data not found`
        )
        return undefined
      }

      const channel = channelEntity.data
      const lastMessage =
        privateKey != null && channel.lastMessage != null
          ? decryptMessage({
              message: channel.lastMessage.m,
              subject: channel.lastMessage.s,
              oneTimeCode: channel.lastMessage.otc,
              privateKey,
            })
          : null

      return {
        entity: channelEntity,
        active: channel.active,
        title: `${channel.storeName}`,
        lastMessage: lastMessage?.message ?? "",
        lastMessageTime: channel.lastMessageTime,
        privateKey,
      }
    }
  )

export const selectChatPreviewIdList = createDeepEqualsSelector(
  chatPreviewListSelector,
  (items) => items.map((x) => x.item.id) ?? []
)

export const selectChatPreviewById = (chatId: string) =>
  createSelector(chatPreviewListSelector, keysSelector, (chatList, keys) => {
    const chatEntity = chatList.find((x) => x.item.id === chatId)?.item as
      | ChatPreviewEntity
      | undefined
    if (chatEntity?.data == null || keys?.userKey?.personalPrivateKey == null) {
      return undefined
    }
    const chat = chatEntity.data
    const privateKey = keys.userKey.personalPrivateKey
    const lastMessage =
      privateKey != null && chat.lastMessage != null
        ? decryptMessage({
            message: chat.lastMessage.m,
            subject: chat.lastMessage.s,
            oneTimeCode: chat.lastMessage.otc,
            privateKey,
          })
        : null
    return {
      entity: chatEntity,
      active: chat.active,
      title: `${chat.receiverInfo.profile.firstName} ${chat.receiverInfo.profile.lastName}`,
      initial: chat.receiverInfo.profile.firstName
        .substring(0, 1)
        .toUpperCase(),
      lastMessage: lastMessage?.message ?? "",
      lastMessageTime: chat.lastMessageTime,
      privateKey,
    }
  })

export const selectNewMessagesCount = createDeepEqualsSelector(
  chatPreviewListSelector,
  channelPreviewListSelector,
  (chats, channels) => {
    return (
      chats.reduce((tot, val) => (tot += val.item.data.totUnreadMessages), 0) +
      channels.reduce((tot, val) => (tot += val.item.data.totUnreadMessages), 0)
    )
  }
)

import { Icon } from "@atoms"
import { IonContent, IonPopover } from "@ionic/react"
import classNames from "classnames"
import React from "react"

interface SelectItem {
  label: React.ReactNode
  id: string
}

interface ItemsPopoverProps {
  selected?: SelectItem
  onSelect: (item: SelectItem) => void
  items: SelectItem[]
}

const ItemsPopover = ({ selected, onSelect, items }: ItemsPopoverProps) => {
  return (
    <div>
      <IonContent>
        <div className="p-[16px] bg-grey flex flex-col gap-[8px]">
          {items.map((item) => {
            return (
              <div
                className={classNames(
                  item.id === selected?.id
                    ? "text-dark font-semibold"
                    : "text-light",
                  "cursor-pointer"
                )}
                onClick={() => onSelect(item)}
              >
                {item.label}
              </div>
            )
          })}
        </div>
      </IonContent>
    </div>
  )
}

export interface ItemsSelectorProps {
  selected?: SelectItem
  onSelect: (item: SelectItem) => void
  items: SelectItem[]
  title: React.ReactNode
  id: string
}

const ItemsSelector = ({
  items,
  onSelect,
  selected,
  title,
  id,
}: ItemsSelectorProps) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const popoverProps: ItemsPopoverProps = {
    onSelect: (item) => {
      // dismiss()
      setIsOpen(false)
      onSelect(items.find((x) => x.id === item.id)!)
    },
    items,
    selected,
  }
  // const [present, dismiss] = useIonPopover(ItemsPopover, popoverProps)

  return (
    <>
      <div id={id}>
        <div className="font-semibold mb-[22px] leading-normal">{title}</div>
        <div
          className="flex items-center gap-[8px] cursor-pointer"
          onClick={() => setIsOpen(true)}
        >
          <div>{selected?.label ?? ""}</div>
          <Icon iconName="downCaret" className="w-[20px] h-[8px]" />
        </div>
      </div>
      <IonPopover
        // style={{
        //   "--offset-y": "6px",
        // }}
        isOpen={isOpen}
        onIonPopoverWillDismiss={() => setIsOpen(false)}
        trigger={id}
        reference="trigger"
      >
        <IonContent>
          <ItemsPopover {...popoverProps} />
        </IonContent>
      </IonPopover>
    </>
  )
}

export default ItemsSelector

import { Toast } from "@capacitor/toast"

export interface ShowToastInput {
  message: string
}

export const showToast = (input: ShowToastInput) =>
  Toast.show({
    text: input.message,
  })

import { toTitleCase } from "@helpers"
import { useMonthNames } from "@modules/app/hooks/useMonthNames"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import {
  useFullWeekDays,
  useShortWeekDays,
} from "@modules/app/hooks/useWeekDays"
import {
  isSameDay,
  isSameHour,
  isSameMinute,
  isSameMonth,
  isSameYear,
  subDays,
} from "date-fns"

const formatTime = (date: Date) =>
  date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })

export const useMessageDateFormatter = () => {
  const { t } = useTranslations()
  const { weekMap: fullWeekMap } = useFullWeekDays()
  // const { weekMap: shortWeekMap } = useShortWeekDays()
  const { getMonthFullName } = useMonthNames()

  const labels = {
    today: toTitleCase(t("common:dates.today")),
    yesterday: toTitleCase(t("common:dates.yesterday")),
    at: t("common:dates.at"),
  }

  const formatDayNameAndNumber = (date: Date) => {
    const dayName = fullWeekMap[date.getDay()]
    const day = date.toLocaleDateString([], { day: "numeric" })
    return `${dayName} ${day}`
  }

  const formatDayNameAndNumberMonth = (date: Date) => {
    const dayName = fullWeekMap[date.getDay()]
    const day = date.toLocaleDateString([], { day: "numeric" })
    return `${dayName} ${day} ${getMonthFullName(date.getMonth())}`
  }

  const formatDayNameAndNumberMonthAndYear = (date: Date) => {
    // const dayName = shortWeekMap[date.getDay()]
    const day = date.toLocaleDateString([], { day: "numeric" })
    return `${day} ${getMonthFullName(date.getMonth())} ${date.getFullYear()}`
  }

  const formatMessageDate = ({
    messageDate,
    nextMessageDate,
    now = new Date(),
  }: {
    now: Date | number
    messageDate: Date | number
    nextMessageDate?: Date | number
  }) => {
    try {
      const isTodaysMessage = isSameDay(messageDate, now)
      const isNextMessageFromSameDay =
        nextMessageDate && isSameDay(messageDate, nextMessageDate)
      // const isNextMessageFromSameMinute =
      //   nextMessageDate &&
      //   isSameHour(messageDate, nextMessageDate) &&
      //   isSameMinute(messageDate, nextMessageDate)

      if (isTodaysMessage) {
        if (!isNextMessageFromSameDay) {
          return `${labels.today} ${labels.at} ${formatTime(
            new Date(messageDate)
          )}`
        }

        // if (!isNextMessageFromSameMinute) {
        //   return formatTime(new Date(messageDate))
        // }

        return formatTime(new Date(messageDate))
      }

      const isYesterdaysMessage = isSameDay(messageDate, subDays(now, 1))
      if (isYesterdaysMessage) {
        if (!isNextMessageFromSameDay) {
          return `${labels.yesterday} ${labels.at} ${formatTime(
            new Date(messageDate)
          )}`
        }
        const isNextMessageFromSameMinute =
          nextMessageDate &&
          isSameHour(messageDate, nextMessageDate) &&
          isSameMinute(messageDate, nextMessageDate)
        // if (!isNextMessageFromSameMinute) {
        //   return formatTime(new Date(messageDate))
        // }

        return formatTime(new Date(messageDate))
      }

      const isNextMessageFromSameMonth =
        nextMessageDate && isSameMonth(messageDate, nextMessageDate)
      const isThisMontMessage = isSameMonth(messageDate, now)

      if (isNextMessageFromSameMonth || isThisMontMessage) {
        if (!isNextMessageFromSameDay) {
          return `${formatDayNameAndNumber(new Date(messageDate))} ${
            labels.at
          } ${formatTime(new Date(messageDate))}`
        }

        // if (!isNextMessageFromSameMinute) {
        //   return formatTime(new Date(messageDate))
        // }

        return formatTime(new Date(messageDate))
      }

      const isNextMessageFromSameYear =
        nextMessageDate && isSameYear(messageDate, nextMessageDate)
      const isThisYearMessage = isSameYear(messageDate, now)
      if (isNextMessageFromSameYear || isThisYearMessage) {
        if (!isNextMessageFromSameDay) {
          return `${formatDayNameAndNumberMonth(new Date(messageDate))} ${
            labels.at
          } ${formatTime(new Date(messageDate))}`
        }

        // if (!isNextMessageFromSameMinute) {
        //   return formatTime(new Date(messageDate))
        // }

        return formatTime(new Date(messageDate))
      }

      if (!isNextMessageFromSameDay) {
        return `${formatDayNameAndNumberMonthAndYear(new Date(messageDate))} ${
          labels.at
        } ${formatTime(new Date(messageDate))}`
      }

      // if (!isNextMessageFromSameMinute) {
      //   return formatTime(new Date(messageDate))
      // }

      return formatTime(new Date(messageDate))
    } catch (e) {
      console.error("Error formatting message date", e)
      return ""
    }
  }

  return {
    formatMessageDate,
  }
}

import { CoursePdf } from "@common/common/database/contents/course-attachment/course-attachment.entity"
import { CourseAttachments, CourseAttachmentPages } from "../types/courses"
import { Entity } from "@common/common/infrastructure/firebase/types"
import { CoursePdfPage } from "@common/common/database/contents/course-attachment-page/course-attachment-page.entity"

export const toCourseAttachments = (
  items: Entity<CoursePdf>[]
): CourseAttachments => {
  const attachments: CourseAttachments = {}
  for (const item of items) {
    if (!attachments[item.data.courseId]) {
      attachments[item.data.courseId] = {}
    }

    attachments[item.data.courseId][item.data.attachmentId] = item.data
  }
  return attachments
}

export const toCourseAttachmentsPages = (
  items: Entity<CoursePdfPage>[]
): CourseAttachmentPages => {
  const attachmentsPages: CourseAttachmentPages = {}
  for (const item of items) {
    if (!attachmentsPages[item.data.courseId]) {
      attachmentsPages[item.data.courseId] = {}
    }

    if (!attachmentsPages[item.data.courseId][item.data.attachmentId]) {
      attachmentsPages[item.data.courseId][item.data.attachmentId] = {}
    }

    attachmentsPages[item.data.courseId][item.data.attachmentId][
      item.data.pageIndex
    ] = item.data
  }

  return attachmentsPages
}

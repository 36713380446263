import { IonReactRouter } from "@ionic/react-router"
import { useSelector } from "react-redux"
import { selectAuthStatus } from "@modules/auth/state/selectors"
import { privateRouteList } from "./routes"
import { useCallback, useEffect, useState } from "react"
import { history } from "@services/index"
import { PrivateRoutes } from "./private/PrivateRoutes"
import { PublicRoutes } from "./public/PublicRoutes"
import { RouteKeys } from "./types"
import { RoutingContext, RoutingContextType } from "@modules/routing/context"

export const Routing = () => {
  const [selectedTab, setSelectedTab] = useState("")
  const [routingContext, setRoutingContext] = useState<RoutingContextType>({
    selectTab: (_tab) => {},
  })

  useEffect(() => {
    if (history.location) {
      const unlisten = history.listen((location) => {
        const routeData = privateRouteList.find(
          (x) => x.tab && location.pathname.startsWith(x.tab.route)
        )
        if (routeData) {
          const route = routeData.getRouteWithParams(
            routeData.defaultParam
          ) as RouteKeys
          setSelectedTab(route)
          // if(route === location.pathname){
          //   push(route, "root", "push")
          // }

          // history.(routeData.getRouteWithParams(routeData.defaultParam))
        }
      })
      return () => {
        unlisten()
      }
    }
  }, [history.location != null])

  const { isAuthenticated } = useSelector(selectAuthStatus)

  const setTabsRef = useCallback((node) => {
    if (node) {
      //wrapped in promise to avoid batched state update (wrong tab icon otherwise on initial load page)
      Promise.resolve().then(() => {
        setSelectedTab((node as any)?.ionTabContextState.activeTab)
        setRoutingContext({
          selectTab: (node as any).ionTabContextState.selectTab,
        })
      })
    }
  }, [])

  return (
    <>
      <RoutingContext.Provider value={routingContext}>
        <IonReactRouter history={history}>
          {isAuthenticated ? (
            <PrivateRoutes selectedTab={selectedTab} setTabsRef={setTabsRef} />
          ) : (
            <PublicRoutes />
          )}
        </IonReactRouter>
      </RoutingContext.Provider>
    </>
  )
}

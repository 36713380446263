import { ChatAttachmentItem } from "@features/messaging/types"
import { ImAttachment } from "@common/common/database/messaging/common/message.entity"

export const toChatAttachment = (item: ImAttachment): ChatAttachmentItem => ({
  id: item.id,
  url: item.u,
  extension: item.e,
  fileName: item.n,
  type: item.at,
  sizeMB: item.mb,
  thumbnailUrl: item.tu ?? undefined,
})

import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { selectCalendarDataByDate } from "../../selectors"
import { Event } from "../Event"
import { formatCalendarTime } from "../../../../utils/time"
import EventDayText from "../Event/EventDayText"

type Props = {
  date: Date
  eventId?: string
}

const step = 80

const DEFAULT_MIN_DURATION = 30

const DailyEvents = ({ date, eventId }: Props) => {
  // const { scrollToPosition } = useContext(ContentContext)
  const selector = useMemo(() => selectCalendarDataByDate(date), [date])
  const calendarInfo = useSelector(selector)
  const minDurationMinutes = calendarInfo
    .map((x) => x.duration.minutes ?? DEFAULT_MIN_DURATION)
    .filter((x) => x > 0)
    .reduce((a, b) => Math.min(a, b), DEFAULT_MIN_DURATION)

  // const timeInterval = eachMinuteOfInterval(
  //   {
  //     start: new Date(2000, 1, 1, 0),
  //     end: new Date(2000, 1, 1, 23, 59),
  //   },
  //   { step: minDurationMinutes }
  // ).map((x) => format(x, "H:mm"))

  const mapMinutes = (minutes: number) => {
    // step / 30 min = x / duration
    return (step / minDurationMinutes) * minutes
  }

  // useEffect(() => {
  //   const item = eventId
  //     ? calendarInfo.find((x) => x.id === eventId) ?? calendarInfo[0]
  //     : calendarInfo[0]
  //   if (item) {
  //     scrollToPosition(mapMinutes(item.startMinute))
  //   }
  // }, [date, eventId])

  return (
    <div className="px-[16px] pt-[16px]">
      <EventDayText startDate={date} />
      <div className="relative ">
        {/*
        style={{ height: step * 48 }}

        {timeInterval.map((x, i) => {
          return (
            <div
              key={i}
              className={classNames(
                "flex w-full gap-[9px] items-center after:bg-grey after:h-[1px] after:w-full after:block left-0 font-medium -translate-y-1/2"
              )}
              style={{ top: step * i }}
            >
              {x}
            </div>
          )
        })} */}
        {calendarInfo.map((d, i) => {
          return (
            <Event
              appointmentId={d.id}
              ratings={d.ratings}
              rating={d.rating}
              key={d.id}
              canReview={d.isPassed}
              className="left-0 --ml-[14.58%] --w-[calc(100%_-_14.58%)] bg-green/[0.45] my-4"
              duration={d.formattedDuration}
              name={d.name}
              extraFields={d.extraFields}
              startTime={formatCalendarTime(
                d.data.startHour,
                d.data.startMinute
              )}
              endTime={formatCalendarTime(d.data.endHour, d.data.endMinute)}
              // style={{
              //   height: mapMinutes(d.durationInMinutes),
              //   top: mapMinutes(d.startMinute),
              // }}
            />
          )
        })}
        {calendarInfo.length === 0 && (
          <div>Nessun evento nella data selezionata</div>
        )}
      </div>
    </div>
  )
}

export { DailyEvents }

import { useRootSelector } from "@modules/store/redux"
import CourseTestDetail from "../../../features/courses/components/detail/test/CourseTestDetail"
import { useSelector } from "react-redux"
import { selectTest } from "@features/courses/state/selectors/test"
import { useEffect } from "react"
import { useRouting } from "@root/routing/useRouting"
import { useRouteContext } from "@modules/routing/context"
import { CourseDetailParams } from "@pages/private"

const CourseTestContainer = () => {
  // const {goTo} =useRouting()
  // useEffect(() => {
  //   if(!canGoBack()){
  //     push(privateRoutes.courses.getRouteWithParams(), "root")
  //   }
  // }, [])

  // if(!canGoBack()){
  //   //
  //   return <></>
  // }

  const { coursesLoaded, coursesProgressLoaded } = useRootSelector(
    (state) => state.courses
  )
  const test = useSelector(selectTest)
  const router = useRouting()

  const {
    match: { params },
  } = useRouteContext<CourseDetailParams>()

  useEffect(() => {
    if (!test) {
      router.goTo("courseDetail", { params: { courseId: params.courseId } })
    }
  }, [])

  return (
    <>
      {coursesLoaded && coursesProgressLoaded && test && <CourseTestDetail />}
    </>
  )
}

export default CourseTestContainer

import { useSelector } from "react-redux"
import { selectIsAppInitialized, selectIsAppLoading } from "../state/selectors"

export const useAppInitialization = () => {
  const loading = useSelector(selectIsAppLoading)
  const initialized = useSelector(selectIsAppInitialized)

  return {
    loading,
    initialized,
  }
}

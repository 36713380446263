import { useChannelData } from "@features/messaging/hooks/useChannelData"
import { selectChannelMessagesById } from "@features/messaging/selectors/channel/detail"
import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { UseMessagesSelectData } from "../../common/MessageListItem/hooks/useMessagesSelect"
import { UseMessagingModeData } from "../../common/MessageListItem/hooks/useMessagingMode"
import ChannelMessageItem from "../ChannelMessageItem"
import { MessagingPanel } from "@organisms"
import { messagingActions } from "@features/messaging/state"

const PAGE_SIZE = 10

interface Props {
  channelId: string
  messagingMode: UseMessagingModeData
  selectedMessages: UseMessagesSelectData
}

const ChannelMessageList = ({
  channelId,
  messagingMode,
  selectedMessages,
}: Props) => {
  const { t } = useTranslations()
  const dispatch = useDispatch()
  const channelData = useChannelData(channelId)

  const selector = useMemo(
    () => selectChannelMessagesById(channelData?.entity.data.channelId ?? ""),
    [channelData?.entity.data.channelId]
  )
  const { messageList, isLoading, hasMoreMessages } = useSelector(selector)

  const handleLoadMore = () => {
    if (!channelId || messageList?.length === 0 || isLoading) {
      return
    }

    dispatch(
      messagingActions.channelMessagesLoadMore({
        channelId,
        pageSize: PAGE_SIZE,
      })
    )
  }

  return (
    <MessagingPanel
      isLoading={isLoading}
      hasMoreMessages={hasMoreMessages}
      totMessages={messageList?.length}
      firstMessageId={messageList?.[0]?.id}
      lastMessageId={messageList?.[messageList.length - 1]?.id}
      noMessagesPlaceholder={t("messaging:channel.empty.message")}
      onLoadMore={handleLoadMore}
    >
      {messageList?.map((m, i) => {
        return (
          <ChannelMessageItem
            message={m}
            previousMessage={messageList[i - 1]}
            nextMessage={messageList[i + 1]}
            key={m.id}
            isLastMessage={i === messageList.length - 1}
            onSelectToggle={() => selectedMessages.toggle(m)}
            onLongPress={() => messagingMode.setSelectMode()}
            selected={selectedMessages.isSelected(m)}
            mode={messagingMode.mode}
          />
        )
      })}
    </MessagingPanel>
  )
}

export default ChannelMessageList

import axios, { AxiosInstance } from "axios"

class Axios {
  client: AxiosInstance
  constructor(private getBearerToken: () => string | null | undefined) {
    this.client = axios.create({
      baseURL: process.env.NEXT_PUBLIC_PUBLIC_FIREBASE_CLOUD_FUNCTIONS_API_HOST,
      timeout: 1e4,
    })

    this.client.interceptors.request.use(
      (config: any) => {
        config.headers.Authorization = `Bearer ${this.getBearerToken()}`
        return config
      },
      (error) => {}
    )
  }
}
export default Axios

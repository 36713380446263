import IntroCarouselContainer from "@containers/editorial/IntroCarousel"
import PageRoot from "@root/pages/PageRoot"

const IntroCarouselPage = () => {
  return (
    <PageRoot>
      <IntroCarouselContainer />
    </PageRoot>
  )
}

export { IntroCarouselPage }

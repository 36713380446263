import React from "react"
import LogRocket from "logrocket"
import { useRootSelector } from "@modules/store/redux"

const LogRocketTracker = () => {
  const { user } = useRootSelector((state) => state.userProfile)

  React.useEffect(() => {
    if (process.env.REACT_APP_LOGROCKET_DISABLED === "true") {
      return
    }
    if (process.env.REACT_APP_LOGROCKET_PROJECT_ID) {
      console.log("logrocket init")
      LogRocket.init(process.env.REACT_APP_LOGROCKET_PROJECT_ID)
    }
  }, [])

  React.useEffect(() => {
    if (process.env.REACT_APP_LOGROCKET_DISABLED === "true") {
      return
    }

    if (user?.data?.email) {
      LogRocket.identify(user.data.email)
    }
  }, [user])

  return <></>
}

export default LogRocketTracker

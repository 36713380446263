import { NewsWidget } from "@containers/home/components/NewsWidget"
import { WidgetsContainer } from "@ui/structure/WidgetsContainer"
import { ProgressBoxes } from "@features/performance/components/ProgressBoxes"
import { WeeklySalesPerformances } from "@features/performance/components/WeeklySalesPerformances"
import { SectionsBanners } from "@modules/contents/components/SectionsBanners"
import { MainHeader } from "src/layout/components/MainHeader"

const HomeContainer = () => {
  return (
    <>
      <MainHeader />
      <WidgetsContainer>
        <NewsWidget />
        <ProgressBoxes />
        <WeeklySalesPerformances />
        <SectionsBanners />
      </WidgetsContainer>
    </>
  )
}

export default HomeContainer

import {
  Firebase,
  FirestoreController,
} from "../../../infrastructure/firebase/controller"
import { Entity } from "../../../infrastructure/firebase/types"
import { AppContents } from "./app-contents.entity"

export class AppContentsController extends FirestoreController<AppContents> {
  constructor(firebase: Firebase) {
    super(firebase, "AppContentsRepo")
  }

  subscribeContents(
    languageId: string,
    callback: (data: Entity<AppContents>) => void
  ) {
    return this.subscribeDocument(languageId, (data) => {
      if (data) {
        callback(data)
      }
    })
  }
}

import { Button } from "@atoms"

export interface UpdateBannerProps {
  link: string
  description: string
  cta: string
}

const UpdateBanner = ({ cta, link, description }: UpdateBannerProps) => {
  return (
    <div>
      <div className="my-4">{description}</div>
      <Button variant="primary" onClick={() => window.open(link, "_blank")}>
        {cta}
      </Button>
    </div>
  )
}

export default UpdateBanner

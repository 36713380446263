import ProfileContainer from "@containers/profile/ProfileInfo"
import PageRoot from "@root/pages/PageRoot"

const ProfilePage = () => {
  return (
    <PageRoot fullscreen>
      <ProfileContainer />
    </PageRoot>
  )
}

export { ProfilePage }

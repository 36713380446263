import AppInfoContainer from "@containers/info/AppInfoContainer"
import PageRoot from "@root/pages/PageRoot"

export const AppInfoPage = () => {
  return (
    <PageRoot fullscreen>
      <AppInfoContainer />
    </PageRoot>
  )
}

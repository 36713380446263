import { useDeviceFeatures } from "@modules/app/context/device"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { PasswordChangeForm } from "@modules/auth/components"
import FrameLayout from "@ui/layouts/FrameLayout"

const PasswordChangeContainer = () => {
  const { t } = useTranslations()
  const { keyboard } = useDeviceFeatures()
  return (
    <FrameLayout
      icon="login"
      title={t("pwdChange.title")}
      shrink={keyboard?.opened}
    >
      <PasswordChangeForm />
    </FrameLayout>
  )
}

export default PasswordChangeContainer

import { useTranslations } from "@modules/app/hooks/useTranslations"
import { ChatPreviewListItem } from "../Chat"
import { ChannelPreviewListItem } from "../Channel"
import { TextInput } from "@atoms"
import { useFilterableContacts } from "@features/messaging/hooks/useFilterableContacts"
import { useState } from "react"
import { ChatNewPreviewListItem } from "../Chat/ChatNewPreviewListItem"

const MessagingManagerControl = () => {
  const { t } = useTranslations()
  const [query, setQuery] = useState<string>("")
  const { filteredPreviews, filteredUsers } = useFilterableContacts(query)
  const isEmpty = filteredPreviews.length === 0 && filteredUsers?.length === 0

  return (
    <div className="">
      <div>
        <TextInput
          name="user"
          placeholder={t("messaging:control.contacts.filter.placeholder")}
          type="text"
          onChange={(e) => setQuery(e)}
        />
      </div>

      {!isEmpty && (
        <>
          <div className="text-base font-[500] mb-[20px]">
            {t("messaging:control.contacts.title")}
          </div>

          {filteredPreviews?.map((preview) => (
            <div key={preview.ref.id} className="my-6">
              {preview.ref.type === "chat" && (
                <ChatPreviewListItem chatId={preview.item.id} />
              )}
              {preview.ref.type === "channel" && (
                <ChannelPreviewListItem channelId={preview.item.id} />
              )}
            </div>
          ))}

          {filteredUsers?.map((user) => (
            <div key={user.id} className="my-6">
              <ChatNewPreviewListItem user={user} />
            </div>
          ))}
        </>
      )}

      {isEmpty && (
        <div className="text-base font-[500] mb-[20px]">
          {t("messaging:control.contacts.filter.empty")}
        </div>
      )}
    </div>
  )
}

export default MessagingManagerControl

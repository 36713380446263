import { IonHeader, IonToolbar } from "@ionic/react"
import { coursesActions } from "@features/courses/state"
import {
  selectPreviousTestQuestionById,
  selectTestQuestionId,
} from "@features/courses/state/selectors/testQuestion"
import { selectTest } from "@features/courses/state/selectors/test"
import { Header } from "@molecules"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { SlidePosition } from "@ui/atoms/SlidePosition"
import { useRouting } from "@root/routing/useRouting"

const CourseTestHeader = () => {
  const { goBack } = useRouting()
  const dispatch = useDispatch()
  const questionId = useSelector(selectTestQuestionId)!
  const prevQuestion = useSelector(selectPreviousTestQuestionById(questionId))
  const test = useSelector(selectTest)
  const currentIndex = test.questions.findIndex((x) => x.id === questionId)
  // const nextQuestion = useSelector(selectPreviousTestQuestionById(questionId))
  return (
    <IonHeader className="ion-no-border">
      <IonToolbar className="">
        <Header
          center
          showArrow
          onClick={() => {
            if (prevQuestion == null) {
              dispatch(coursesActions.setTestQuestionId(null))
              goBack()
            } else {
              dispatch(coursesActions.setTestQuestionId(prevQuestion.id))
            }
          }}
          paddings={{ bottom: 0 }}
          className={"bg-[transparent !absolute top-0 left-0 w-full"}
        >
          <div className="flex justify-center">
            <SlidePosition
              type="CIRCLES"
              currentSlideNumber={currentIndex}
              slidesCount={test.questions.length}
            />
          </div>
        </Header>
      </IonToolbar>
    </IonHeader>
  )
}

export default CourseTestHeader

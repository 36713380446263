import { PayloadAction } from "@reduxjs/toolkit"
import { buffers, eventChannel } from "redux-saga"
import { put, take, cancelled, takeLatest } from "typed-redux-saga"
import { CalendarDefinition } from "../../../../database/calendars/definition/calendar-definition.entity"
import { CalendarReservation } from "../../../../database/calendars/reservation/calendar-reservation.entity"
import { CalendarReservationCancellation } from "../../../../database/calendars/reservationCancellation/calendar-reservation-cancellation.entity"
import { AppUser } from "../../../../database/company/user/user.entity"
import { Entity } from "../../../../infrastructure/firebase/types"
import { Firebase, getSagaContext } from "../../../state/context"
import { calendarRoutines } from "../state"
import { CalendarEntry } from "../state/types"

const mapCalendarData = (
  calendar: Entity<CalendarDefinition>,
  reservations: Entity<CalendarReservation>[],
  cancellations: Entity<CalendarReservationCancellation>[]
): CalendarEntry => {
  return {
    definition: calendar,
    reservations: reservations.filter((x) => x.data.calendarId === calendar.id),
    cancellations: cancellations.filter(
      (x) => x.data.calendarId === calendar.id
    ),
  }
}

const subscribeCalendarEvents = (
  firebase: Firebase,
  calendars: Entity<CalendarDefinition>[],
  callback: (items: CalendarEntry[]) => void
) => {
  if (calendars.length === 0) {
    return undefined
  }

  const calendarIds = calendars.map((x) => x.data.id)
  const reservationsSubscription =
    firebase.collections.calendarReservations.subscribeCalendarReservations(
      calendarIds,
      (reservations) => {
        callback(calendars.map((x) => mapCalendarData(x, reservations, [])))
      }
    )

  const unsubscribe = () => {
    reservationsSubscription()
  }

  return unsubscribe
}

const subscribeCalendars = (
  firebase: Firebase,
  user: AppUser,
  callback: (items: CalendarEntry[]) => void
) => {
  let calendarEventsSubscription: any
  let calendarSubscription: any

  if (user.userType === "areaManager" && user.context.areaId) {
    calendarSubscription =
      firebase.collections.calendarDefinitions.subscribeAreaCalendars(
        [user.context.areaId],
        (items) => {
          if (calendarEventsSubscription) {
            calendarEventsSubscription()
          }
          calendarEventsSubscription = subscribeCalendarEvents(
            firebase,
            items,
            callback
          )
        }
      )
  }

  if (
    (user.userType === "user" || user.userType === "storeManager") &&
    user.context.storeId
  ) {
    calendarSubscription =
      firebase.collections.calendarDefinitions.subscribeStoreCalendars(
        [user.context.storeId],
        (items) => {
          if (calendarEventsSubscription) {
            calendarEventsSubscription()
          }
          calendarEventsSubscription = subscribeCalendarEvents(
            firebase,
            items,
            callback
          )
        }
      )
  }

  if (user.userType === "admin" || user.userType === "manager") {
    calendarSubscription =
      firebase.collections.calendarDefinitions.subscribeAllCalendars(
        (items) => {
          if (calendarEventsSubscription) {
            calendarEventsSubscription()
          }
          calendarEventsSubscription = subscribeCalendarEvents(
            firebase,
            items,
            callback
          )
        }
      )
  }

  const unsubscribe = () => {
    if (calendarSubscription) {
      calendarSubscription()
    }
    if (calendarEventsSubscription) {
      calendarEventsSubscription()
    }
  }

  return unsubscribe
}

export function* watchUserCalendarsSaga(action: PayloadAction<AppUser>) {
  const { payload: user } = action

  console.log("watchUserCalendarsSaga -> watching user calendars", user)

  const firebase = yield* getSagaContext("firebase")
  const channel = eventChannel<CalendarEntry[]>((emitter) => {
    const unsubscribe = subscribeCalendars(firebase, user, (data) => {
      console.log("user calendars", data)
      emitter(data)
    })
    return () => unsubscribe()
  }, buffers.sliding(1))
  try {
    yield* takeLatest(calendarRoutines.watchCalendars.fulfill, function* () {
      channel.close()
    })
    while (true) {
      const data = yield* take(channel)
      console.log("watchUserCalendarsSaga -> data received", data)
      yield* put(calendarRoutines.watchCalendars.success(data))
    }
  } catch (error) {
    yield* put(calendarRoutines.watchCalendars.failure(error))
  } finally {
    if (yield* cancelled()) {
      channel.close()
    }
  }
}

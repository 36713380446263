import { Chip } from "@atoms"
import { categoriesLabelSelector } from "@modules/contents/state/selectors"
import { useRootSelector } from "@modules/store/redux"
import { CourseInfo } from "@package/common/api/functions"
import classNames from "classnames"

type BaseProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "class" | "className" | "children" | "style" | "color"
>
interface Props extends BaseProps {
  info: CourseInfo
  classes?: {
    root?: string
    chip?: string
  }
  className?: string
}

const CourseChip = ({ info, classes, className, ...rest }: Props) => {
  const { categoryCode } = info ?? {}
  const { getCategoryName, getCategoryColor } = useRootSelector(
    categoriesLabelSelector
  )
  return (
    <div className={classNames(classes?.root, className)} {...rest}>
      <Chip
        className={classNames("text-white", classes?.chip)}
        text={getCategoryName(categoryCode)}
        backgroundColor={getCategoryColor(categoryCode)}
      />
    </div>
  )
}

export default CourseChip

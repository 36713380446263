import { selectAuthStatus } from "@modules/auth/state/selectors"
import { messagingActions } from "@features/messaging/state"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { profileActions } from "./state"

type Props = {
  children: React.ReactNode
}

export const ProfileProvider = ({ children }: Props) => {
  const dispatch = useDispatch()
  const { isAuthenticated, user, impersonation } = useSelector(selectAuthStatus)

  useEffect(() => {
    if (isAuthenticated && user) {
      dispatch(profileActions.watchUserProfile.trigger())
      dispatch(profileActions.watchUserResources.trigger())
      dispatch(
        messagingActions.watchUserKeys.trigger({
          uid: impersonation?.uid ?? user.uid,
        })
      )
    }
    return () => {
      if (isAuthenticated) {
        dispatch(profileActions.watchUserProfile.fulfill())
        dispatch(profileActions.watchUserResources.fulfill())
        dispatch(messagingActions.watchUserKeys.fulfill())
      }
    }
  }, [isAuthenticated, impersonation?.uid])

  return <>{children}</>
}

import { getSagaContext } from "@modules/store/redux/sagaContext"
import { PayloadAction } from "@reduxjs/toolkit"
import { call } from "typed-redux-saga"

export function* startImpersonationSaga(payload: PayloadAction<string>) {
  const firebase = yield* getSagaContext("firebase")
  yield* call(firebase.setUserImpersonation, payload.payload)
}

export function* stopImpersonationSaga() {
  const firebase = yield* getSagaContext("firebase")
  yield* call(firebase.clearUserImpersonation)
}

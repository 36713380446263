import { CapacitorUpdater } from "@capgo/capacitor-updater"
import { appActions } from "@modules/app/state"
import {
  setCurrentBuildHash,
  setCurrentBuildVersion,
} from "@modules/deployments/services/buildVersion"
import { Entity } from "@common/common/infrastructure/firebase/types"
import { PayloadAction } from "@reduxjs/toolkit"
import { call, put } from "typed-redux-saga"
import { deploymentsSlice } from "../store"
import { AppDeploymentChannel } from "@common/common/database/deployments/app-deployment.entity"

export function* liveUpdateExecuteSaga(
  action: PayloadAction<Entity<AppDeploymentChannel>>
) {
  try {
    const currentBuild = action.payload.data.current
    if (!currentBuild) {
      console.warn("LIVE UPDATE EXECUTE -> no current build found, skipping")
      return
    }

    yield* put(appActions.showSplashScreen("UPDATING"))
    const version = yield* call(CapacitorUpdater.download, {
      url: currentBuild.data.artifactUrl,
      version: currentBuild.data.version,
    })
    console.log(`LIVE UPDATE EXECUTE | newer versions downloaded`)

    yield* call(CapacitorUpdater.set, version)
    console.log(`LIVE UPDATE EXECUTE | newer versions set`)

    yield* call(setCurrentBuildHash, currentBuild.data.commitHash)
    yield* call(setCurrentBuildVersion, currentBuild.data.version)
    console.log(
      `LIVE UPDATE EXECUTE | newer version saved -> ${currentBuild.data.commitHash}`
    )

    yield* put(deploymentsSlice.actions.liveUpdateCompleted())
    console.log("LIVE UPDATE EXECUTE -> completed")
  } catch (e) {
    console.error("LIVE UPDATE EXECUTE -> failed", e)
    yield* put(deploymentsSlice.actions.liveUpdateFailed())
  } finally {
    yield* put(appActions.showSplashScreen("NONE"))
  }
}

import { DocumentData, limit, orderBy, query } from "firebase/firestore"
import {
  Firebase,
  FirestoreController,
} from "../../../infrastructure/firebase/controller"
import {
  ObjectiveStoreItem,
  ObjectiveStoreItemHistoryItem,
} from "./objective-store.entity"

const AGGREGATIONS_HISTORY_SUB_COLLECTION = "day_history"

export class ObjectivesStoreController extends FirestoreController<ObjectiveStoreItem> {
  constructor(firebase: Firebase) {
    super(firebase, "ObjectiveStoreRepo")
  }

  subscribeLastAggregationItems(
    parentId: string,
    maxDays: number,
    callback: (items: ObjectiveStoreItem[]) => void
  ) {
    console.log("subscribeLastAggregationItems -> called", parentId, maxDays)
    return this.subscribeSubCollectionQuery(
      parentId.toLowerCase().trim(),
      AGGREGATIONS_HISTORY_SUB_COLLECTION,
      (collection) =>
        query(collection, orderBy("updateTime", "desc"), limit(maxDays)),
      (items: DocumentData[]) => {
        console.log("subscribeLastAggregationItems -> results", items)
        return callback(items as ObjectiveStoreItem[])
      }
    )
  }
}

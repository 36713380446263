import { getSagaContext } from "@modules/store/redux/sagaContext"
import { AppContents } from "@common/common/database/contents/app/app-contents.entity"
import { Entity } from "@common/common/infrastructure/firebase/types"
import { buffers, eventChannel } from "redux-saga"
import { call, put, take, takeLatest } from "typed-redux-saga"
import { contentsActions } from "."
import { getDataCached, setDataCache } from "../fetch"

function* contentsLoadSaga() {
  const firebase = yield* getSagaContext("firebase")
  const cachedData = yield* call(getDataCached)
  if (cachedData) {
    yield* put(contentsActions.load.success(cachedData))
  }

  const channel = eventChannel<Entity<AppContents>>((emitter) => {
    const unsubscribe = firebase.collections.appContents.subscribeContents(
      "it_it",
      (data) => emitter(data)
    )
    return () => unsubscribe()
  }, buffers.sliding(1))

  try {
    yield* takeLatest(contentsActions.load.fulfill, function* () {
      channel.close()
    })

    while (true) {
      const data = yield* take(channel)
      const appData = {
        carouselData: data.data.data.carousel,
        iconsData: data.data.data.icons,
        labelsData: data.data.data.labels,
      }

      yield* put(contentsActions.load.success(appData))
      yield* call(setDataCache, appData)
    }
  } catch (error) {
    console.error("data load", error)
    yield* put(contentsActions.load.failure())
  } finally {
    yield* put(contentsActions.load.fulfill())
  }
}

export function* dataRootSaga() {
  yield* takeLatest(contentsActions.load.TRIGGER, contentsLoadSaga)
}

import { put, select, call } from "typed-redux-saga"
import { PayloadAction } from "@reduxjs/toolkit"
import { last } from "ramda"
import { getSagaContext } from "../../../state/context"
import { CommonState } from "../../../state/types"
import { ChannelMessageLoadMoreInput } from "../state/types"
import { decodeAndNormalizeChannelMsg } from "../state/converters/encryption"
import { ImChannelMessage } from "../../../../database/messaging/channel/channel.entity"

export const getCursor = (state: CommonState, channelId: string) =>
  state.messaging.channelMessages[channelId]?.messages.cursor

export const getChannelPrivateKey = (state: CommonState, channelId: string) =>
  state.messaging.keys?.channels?.find((x) => x.channelId === channelId)
    ?.privateKey

export function* fetchChannelMessagesPage(
  action: PayloadAction<ChannelMessageLoadMoreInput>
) {
  const state = yield* getSagaContext("state")
  const { channelId, pageSize } = action.payload
  const firebase = yield* getSagaContext("firebase")
  try {
    const cursor = yield* select(getCursor, channelId)
    if (!cursor) {
      console.warn(
        `CHANNEL | Fetch request for channel without cursor ${channelId}`
      )
      return
    }

    const privateKey = yield* select(getChannelPrivateKey, channelId)
    if (!privateKey) {
      throw new Error(`Cannot fetch private key for channel ${channelId}`)
    }

    const messages = yield* call(
      (channelId: string, cursor: string) =>
        firebase.collections.channels.fetchChannelMessagePage(
          channelId,
          pageSize,
          cursor
        ),
      channelId,
      cursor
    )

    yield* put(
      state.messaging.actions.channelMessagesAppend({
        channelId,
        messages: messages.docs.map((x) =>
          decodeAndNormalizeChannelMsg(privateKey, x.data() as ImChannelMessage)
        ),
        cursor:
          messages.docs.length === pageSize
            ? last(messages.docs)?.id
            : undefined,
      })
    )
  } catch (e) {
    console.error(`FETCH CHANNEL -> error`, e)
    yield* put(
      state.messaging.actions.channelMessagesSetFaulted({
        channelId,
        error: (e as any).message,
      })
    )
  }
}

import { isPlatform } from "@ionic/react"
import { DevicePlatform } from "../types/device"

export const isWebPlatform = () =>
  isPlatform("mobileweb") || isPlatform("desktop")

export const isMobileDevice = () => isPlatform("ios") || isPlatform("android")

export const isAndroidDevice = () => !isWebPlatform() && isPlatform("android")
export const isIosDevice = () => !isWebPlatform() && isPlatform("ios")

export const getPlatform = (): DevicePlatform =>
  isWebPlatform() ? "web" : isPlatform("ios") ? "ios" : "android"

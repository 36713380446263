import { MessagingToolbar } from "@organisms"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { useChannelToolbar } from "@features/messaging/hooks/useChannelToolbar"

interface Props {
  channelId: string
}

const ChannelToolbar = ({ channelId }: Props) => {
  const { onSendMessage, register, draft, removeFile, takePicture } =
    useChannelToolbar(channelId)

  const { t } = useTranslations()

  return (
    <MessagingToolbar>
      <MessagingToolbar.Attachments
        items={draft?.draft?.attachments?.items ?? []}
        onAttachmentRemove={(file) => removeFile(file.id)}
      />
      <MessagingToolbar.Input
        placeholder={t("messaging:channel.input.placeholder")}
        onSend={onSendMessage}
        onCameraClick={takePicture}
        inputAdditionalProps={{
          cameraUpload: register("files"),
          textArea: register("text"),
        }}
      />
    </MessagingToolbar>
  )
}

export { ChannelToolbar }

import { buffers, eventChannel } from "redux-saga"
import { put, take, cancelled, takeLatest } from "typed-redux-saga"
import { AppUser } from "../../../../database/company/user/user.entity"
import { Entity } from "../../../../infrastructure/firebase/types"
import { getSagaContext } from "../../../state/context"
import { userProfileRoutines } from "../state/state"

export function* watchUserProfileSaga() {
  const firebase = yield* getSagaContext("firebase")
  const channel = eventChannel<Entity<AppUser>>((emitter) => {
    const uid = firebase.getUid()
    if (!uid) {
      console.error(`Missing UID`)
      return () => {}
    }

    console.log(`watchUserProfileSaga -> uid`, uid)
    const unsubscribe = firebase.collections.users.subscribeUserProfile(
      uid,
      (data) => {
        if (!data) {
          console.error(`No user profile found for ${uid}`, data)
          return
        }
        console.log(`watchUserProfileSaga -> data`, data)
        emitter(data)
      }
    )
    return () => unsubscribe()
  }, buffers.sliding(1))
  try {
    yield* takeLatest(
      userProfileRoutines.watchUserProfile.fulfill,
      function* () {
        channel.close()
      }
    )
    while (true) {
      const data = yield* take(channel)
      yield* put(userProfileRoutines.watchUserProfile.success(data.data))
    }
  } catch (error) {
    yield* put(userProfileRoutines.watchUserProfile.failure(error))
  } finally {
    if (yield* cancelled()) {
      channel.close()
    }
  }
}

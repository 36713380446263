import classNames from "classnames"
import React from "react"

interface Props {
  text?: string | null
  className?: string | null
  backgroundColor?: string
  fullWidth?: boolean
}

const Chip = ({ className, text, backgroundColor, fullWidth }: Props) => {
  return (
    <div
      className={classNames(
        "rounded-[4px] min-h-[16px] uppercase text-xs items-center justify-center leading-normal text-white align-middle",
        {
          "flex px-2": fullWidth,
          "inline-flex px-6": !fullWidth,
        },
        className
      )}
      style={{ backgroundColor }}
    >
      <div>{text}</div>
    </div>
  )
}

export default Chip

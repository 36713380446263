import { SectionTitle, StoreSalesChart } from "@atoms"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { useShortWeekDays } from "@modules/app/hooks/useWeekDays"
import { useSelector } from "react-redux"
import { selectStoreProgress } from "../selectors"

type Props = {}

const WeeklySalesPerformances = (props: Props) => {
  const { weekDays, weekMap } = useShortWeekDays()
  const storeProgress = useSelector(
    selectStoreProgress({
      weekDays,
      weekMap,
    })
  )

  const { t } = useTranslations()

  return (
    <div>
      <SectionTitle text={t("performance:progress.stats.weeklySales.title")} />
      <StoreSalesChart storeSalesData={storeProgress} />
    </div>
  )
}

export { WeeklySalesPerformances }

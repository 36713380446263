import { put, call } from "typed-redux-saga"
import { getSagaContext } from "../../../state/context"
import { userProfileRoutines } from "../state/state"

export function* updateUserProfileImage({
  payload,
}: ReturnType<typeof userProfileRoutines.updateUserProfileImage.trigger>) {
  const api = yield* getSagaContext("api")
  try {
    yield* call(api.profileImageUpdate, { file: payload })
  } catch (error) {
    console.error("Update profile image error", error)
    yield* put(userProfileRoutines.updateUserProfileImage.failure({}))
  } finally {
    yield* put(userProfileRoutines.updateUserProfileImage.fulfill())
  }
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserProfile } from './UserProfile';

export type User = {
    id: string;
    email: string;
    userType: User.userType;
    brandGroupCode?: string;
    storeId?: string;
    storeCode?: string;
    areaCode?: string;
    areaId?: string;
    profile: UserProfile;
    disabled: boolean;
    verified: boolean;
    temporaryPassword: boolean;
};

export namespace User {

    export enum userType {
        ADMIN = 'admin',
        BRAND = 'brand',
        USER = 'user',
        MANAGER = 'manager',
        STORE_MANAGER = 'storeManager',
        AREA_MANAGER = 'areaManager',
    }


}


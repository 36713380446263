import {
  Firebase,
  FirestoreController,
} from "../../../infrastructure/firebase/controller"
import { Entity } from "../../../infrastructure/firebase/types"
import { ChannelPreview } from "./channel-preview.entity"
import { query, where } from "firebase/firestore"

export class ChannelPreviewController extends FirestoreController<ChannelPreview> {
  constructor(firebase: Firebase) {
    super(firebase, "ChannelPreviewRepo")
  }

  subscribeUserChannelPreviews(
    userId: string,
    callback: (items: Entity<ChannelPreview>[]) => void
  ) {
    return this.subscribeQuery(
      (collection) =>
        query(
          collection,
          where("active", "==", true),
          where("userId", "==", userId)
        ),
      callback
    )
  }
}

import { createAnimation, AnimationBuilder } from "@ionic/react"
import { TransitionOptions } from "./types"

export const rightToLeftSlide: AnimationBuilder = (
  baseEl: HTMLElement,
  opts: TransitionOptions
) => {
  // console.log({ baseEl })
  // console.log({ opts })
  if (opts.direction === "forward") {
    return createAnimation()
      .addElement(opts.enteringEl)
      .beforeStyles({
        opacity: 1,
      })
      .fromTo("transform", "translateX(100%)", "translateX(0)")
      .duration(500)
      .easing("cubic-bezier(0.16, 1, 0.3, 1)")
  } else {
    return createAnimation()
      .addAnimation(
        createAnimation()
          .addElement(opts.leavingEl ?? [])
          .fromTo("transform", "translateX(0)", "translateX(100%)")
          .duration(500)
          .easing("cubic-bezier(0.16, 1, 0.3, 1)")
      )
      .addAnimation(
        createAnimation().addElement(opts.enteringEl).beforeStyles({
          opacity: 1,
        })
      )
  }
}

// const animation = (baseEl: any, opts: any)=>{
//   console.log({baseEl})
//   console.log({opts})
//   if(opts.direction === "forward"){
//     return createAnimation()
//     .addElement(opts.enteringEl)
//     .beforeStyles({
//       opacity: 1
//     })
//     .fromTo("transform", "translateX(100%)", "translateX(0)")
//     .duration(500)
//     .easing("cubic-bezier(0.16, 1, 0.3, 1)")
//   } else {
//     return createAnimation()
//     .addAnimation(
//       createAnimation()
//       .addElement(opts.leavingEl)
//       .fromTo("transform", "translateX(0)", "translateX(100%)")
//       .duration(500)
//       .easing("cubic-bezier(0.16, 1, 0.3, 1)")
//     )
//     .addAnimation(
//       createAnimation()
//       .addElement(opts.enteringEl)
//       .beforeStyles({
//         opacity: 1
//       })
//     )
//   }
// }

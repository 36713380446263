import { IonContent } from "@ionic/react"
import classNames from "classnames"
import CourseCategoryBoxesSelector from "../../../features/courses/components/courses/CourseCategoryBoxesSelector"
import CoursePreviewBoxes from "../../../features/courses/components/courses/CoursePreviewBoxes"
import CoursesHeader from "../../../features/courses/components/courses/CoursesHeader"
import CourseShowAllLabel from "../../../features/courses/components/courses/CourseShowAllLabel"
import { useCourses } from "../../../features/courses/hooks"
import { NewestSection } from "../../../features/courses/components/courses/NewestSection"
import { CourseTab } from "../../../features/courses/types/courses"
import { useTranslations } from "@modules/app/hooks/useTranslations"

const CoursesContainer = () => {
  const {
    courses,
    runningCourses,
    selectedTab,
    setSelectedTab,
    coursesTabs,
    hasNewCourses,
  } = useCourses()
  const { t } = useTranslations()

  return (
    <>
      <CoursesHeader
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        tabs={coursesTabs}
        hideTabs={!hasNewCourses}
      />

      <IonContent fullscreen id="courses-content">
        <div
          className={classNames(
            "py-[14px] px-container mb-[var(--footer-height)]"
          )}
        >
          <div
            className={classNames(
              "flex flex-col gap-[39px]",
              selectedTab.id !== "news" && "hidden"
            )}
          >
            <NewestSection />
            <div>
              <CourseShowAllLabel
                showAll={() =>
                  setSelectedTab(
                    coursesTabs.find((x) => x.id === "list") as CourseTab
                  )
                }
                text={t("courses:categories.title")}
              />
              <CourseCategoryBoxesSelector
                showAll={() =>
                  setSelectedTab(
                    coursesTabs.find((x) => x.id === "list") as CourseTab
                  )
                }
              />
            </div>
            <div>
              {runningCourses.length > 0 && (
                <>
                  <CourseShowAllLabel
                    showAll={() =>
                      setSelectedTab(
                        coursesTabs.find((x) => x.id === "list") as CourseTab
                      )
                    }
                    text={t("courses:course.continue")}
                  />
                  <CoursePreviewBoxes courseIdList={runningCourses} />
                </>
              )}
            </div>
          </div>
          <div>
            <CoursePreviewBoxes
              isHidden={selectedTab.id !== "list"}
              courseIdList={courses}
            />
          </div>
        </div>
      </IonContent>
    </>
  )
}

export default CoursesContainer

import { StoreProvider } from "@modules/store"
import { useDesktopLayout } from "@ui/hooks/useDesktopLayout"
import { InnerAppProvider } from "./InnerAppProvider"
import { InnerAppTracking } from "./InnerAppTracking"
import { PrivacyScreenActivator } from "./privacyScreen"

export interface AppProviderProps {
  children: React.ReactNode
}

export const AppProvider = ({ children }: AppProviderProps) => {
  useDesktopLayout()

  return (
    <StoreProvider>
      <InnerAppProvider>{children}</InnerAppProvider>
      <InnerAppTracking />
      <PrivacyScreenActivator />
    </StoreProvider>
  )
}

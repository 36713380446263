import { Notification, NotificationData } from "./Notification"

type Props = {
  notifications: NotificationData<any>[]
  title: string
}

const NotificationList = ({ notifications, title }: Props) => {
  return (
    <div>
      {notifications.length > 0 && (
        <div className="px-container  font-semibold leading-loose pb-[6px]">
          {title}
        </div>
      )}
      <div>
        {notifications?.map((x) => {
          return (
            <Notification
              id={x.id}
              data={x.data}
              text={x.text}
              title={x.title}
              dateTime={x.dateTime}
              key={x.id}
              isNew={x.isNew}
              type={x.type}
            />
          )
        })}
      </div>
    </div>
  )
}

export default NotificationList

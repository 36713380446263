import { ChatController } from "../../database/messaging/chat/chat.controller"
import { ImChatMessageState } from "../../database/messaging/chat/chat.entity"
import { MessageAttachment } from "../../frontend/features/messaging/state/types"
import { setServerTimestamp } from "../../infrastructure/firebase/controller"
import { encryptMessage } from "./common"
import { toImAttachment } from "./converters"

export interface SendChatMessageInput {
  senderId: string
  messageId: string
  message: string
  subject?: string
  chatId: string
  privateKey: string
  attachments: MessageAttachment[]
}

export const sendChatMessage = async (
  controller: ChatController,
  input: SendChatMessageInput
) => {
  const messageData = encryptMessage({
    message: input.message,
    subject: input.subject,
    privateKey: input.privateKey,
  })

  const batch = await controller.initBatch()
  await controller.appendMessage(
    input.chatId,
    {
      t: setServerTimestamp(),
      si: input.senderId,
      m: messageData.message,
      s: messageData.subject as string,
      otc: messageData.oneTimeCode,
      e: "sym1",
      id: input.messageId,
      d: "out",
      r: false,
      a: input.attachments.filter((x) => x.asset).map((x) => toImAttachment(x)),
      st: ImChatMessageState.Pending,
      del: false,
    },
    batch
  )
  await controller.updateChatTimestamp(input.chatId, batch)
  await batch.commit()
}

import { ContentsState } from "."
import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "@modules/store/redux/rootReducer"

export const state = (state: RootState): ContentsState =>
  state && state.contents

const brandsState = (state: RootState) => ({
  courses: state.courses,
  contents: state.contents,
})

export const brandsLabelSelector = createSelector(
  brandsState,
  ({ contents, courses }) => {
    const brandsLabel = Object.values(contents.labelsData?.brandsMap)
    const coursesBrands =
      Object.values(courses.courses ?? {})
        .map((x) => x.data?.info?.brandCode as string)
        .filter((x) => x) ?? []

    const filteredBrandsLabels = brandsLabel.filter((x) =>
      coursesBrands.includes(x.code)
    )
    return {
      brandsLabel,
      filteredBrandsLabels,
      getBrandName: (id?: string | null) => {
        if (id == null) {
          return null
        }
        return contents.labelsData.brandsMap[id]?.data?.name ?? ""
      },
    }
  }
)

export const categoriesLabelSelector = createSelector(state, (contents) => {
  const categoryLabels =
    Object.values(contents?.labelsData?.categoriesMap) ?? []
  const getCategoryData = (id?: string | null) => {
    if (id == null) {
      return null
    }
    return contents.labelsData.categoriesMap[id]?.data
  }
  return {
    categoryLabels,
    getCategoryData,
    getCategoryName: (id?: string | null) => getCategoryData(id)?.name ?? "",
    getCategoryColor: (id?: string | null) =>
      getCategoryData(id)?.styles?.color,
    getCategoryTexture: (id?: string | null) =>
      getCategoryData(id)?.styles?.texture,
  }
})

export const introCarouselSelector = createSelector(state, (contents) => {
  return contents?.carouselData.find((x) => x.alias === "home")
})

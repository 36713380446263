import { IStore } from "@common/common/abstractions/storage"
import {
  clearStorage,
  persistObject,
  readObject,
  removeObject,
} from "../../../services/storage"

export class IonicAppStorage implements IStore {
  async save<T>(key: string, value: T): Promise<void> {
    await persistObject(key, value)
  }
  async get<T>(key: string): Promise<T | undefined> {
    return await readObject<T>(key)
  }
  async delete(key: string): Promise<void> {
    await removeObject(key)
  }
  async clear(): Promise<void> {
    await clearStorage()
  }
}

export const appStateStorage = new IonicAppStorage()

import { useEffect, useRef, useState } from "react"

export function useLongPress({
  callback,
  delay = 500,
  disabled,
}: {
  callback: () => void
  delay?: number
  disabled?: boolean
}) {
  const [longPressTriggered, setLongPressTriggered] = useState(false)
  const timeoutIdRef = useRef<number>()
  const ref = useRef<HTMLDivElement>(null)

  const handleStart = (event: MouseEvent | TouchEvent) => {
    timeoutIdRef.current = window.setTimeout(() => {
      setLongPressTriggered(true)
      console.log("long press triggered")
      callback()
    }, delay)
  }

  const handleEnd = () => {
    window.clearTimeout(timeoutIdRef.current)
    console.log("long press ended")
    setLongPressTriggered(false)
  }

  useEffect(() => {
    const target = ref.current
    if (!target) {
      return
    }

    if (disabled) {
      return
    }

    target.addEventListener("mousedown", handleStart)
    target.addEventListener("touchstart", handleStart)
    target.addEventListener("mouseup", handleEnd)
    target.addEventListener("touchend", handleEnd)

    return () => {
      target.removeEventListener("mousedown", handleStart)
      target.removeEventListener("touchstart", handleStart)
      target.removeEventListener("mouseup", handleEnd)
      target.removeEventListener("touchend", handleEnd)
    }
  }, [callback, delay, disabled])

  return { ref, triggered: longPressTriggered }
}

import { Icon } from "@atoms"
import { categoriesLabelSelector } from "@modules/contents/state/selectors"
import { useRootSelector } from "@modules/store/redux"
import classNames from "classnames"
import { useDispatch, useSelector } from "react-redux"
import { coursesActions } from "../../state"
import {
  selectedCompletedCategoryCode,
  selectedCategoryCode,
} from "../../state/selectors"

interface Props {
  categoryCode: string | null
  isCompletedCategory: boolean
  label?: string
}

const CourseCategoryButton = ({
  categoryCode,
  isCompletedCategory,
  label,
}: Props) => {
  const dispatch = useDispatch()
  const selectedCode = useSelector(
    isCompletedCategory ? selectedCompletedCategoryCode : selectedCategoryCode
  )
  const { getCategoryName } = useRootSelector(categoriesLabelSelector)
  const isSelected = categoryCode == selectedCode
  return (
    <div
      className={classNames(
        "flex gap-[10px] py-[8px] px-[16px] h-full rounded-full items-center justify-center text-md leading-none min-w-[80px] whitespace-nowrap cursor-pointer",
        isSelected ? "bg-gold/60 border-gold border-[3px]" : "bg-gold/30"
      )}
      onClick={() =>
        !isSelected &&
        dispatch(
          isCompletedCategory
            ? coursesActions.setCompletedCategoryCode(categoryCode)
            : coursesActions.setCategoryCode(categoryCode)
        )
      }
    >
      {isSelected && <Icon iconName="check" className="h-[12px] w-[18px]" />}
      <div>{label ? label : getCategoryName(categoryCode)}</div>
    </div>
  )
}

export default CourseCategoryButton

import { useState } from "react"
import { MessagingMode } from "../types"

export const useMessagingMode = () => {
  const [mode, setMode] = useState<MessagingMode>("default")

  return {
    mode,
    setDefaultMode: () => setMode("default"),
    setSelectMode: () => setMode("select"),
  }
}

export type UseMessagingModeData = ReturnType<typeof useMessagingMode>

import { Redirect, Route } from "react-router-dom"
import { publicRouteList, sharedRouteList } from "../routes"

export const PublicRoutes = () => {
  return (
    <>
      {[...sharedRouteList, ...publicRouteList].map(
        ({ component, rawRoute, exact }) => (
          <Route
            path={rawRoute}
            key={rawRoute}
            component={component}
            exact={exact}
          />
        )
      )}
      <Redirect to={"/login"} />
    </>
  )
}

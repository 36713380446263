import { SectionTitle } from "@atoms"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import { useRoutingContext } from "@modules/routing/context"
import { privateRoutes } from "@root/routing/routes"
import Banner from "./Banner"
import { useRootSelector } from "@modules/store/redux"

const SectionsBanners = () => {
  const { selectTab } = useRoutingContext()
  const { t } = useTranslations()
  const { courses } = useRootSelector((state) => state.courses)
  return (
    <div>
      <SectionTitle text={t("common:navigation.sectionsBanner.more")} />
      <div className="flex flex-col gap-[19px]">
        <Banner
          onClick={() =>
            selectTab(
              privateRoutes.chat.getRouteWithParams({
                communicationTab: "previews",
              })
            )
          }
          text={t("common:navigation.sections.communications")}
          iconName="bannerComunicazioni"
        />
        {Object.keys(courses).length > 0 && (
          <Banner
            onClick={() =>
              selectTab(privateRoutes.courses.getRouteWithParams())
            }
            text={t("common:navigation.sections.courses")}
            iconName="bannerFormazione"
          />
        )}
        <Banner
          onClick={() =>
            selectTab(privateRoutes.performances.getRouteWithParams())
          }
          text={t("common:navigation.sections.performance")}
          iconName="bannerPerformance"
        />
      </div>
    </div>
  )
}

export { SectionsBanners }

import { createDeepEqualsSelector as cds } from "@helpers/selectors"
import { RootState } from "@modules/store/redux/rootReducer"
import { CoursesProgressEntity } from "@common/common/types/firebase/coursesProgress"
import { compareDesc, parseISO, differenceInDays, compareAsc } from "date-fns"
import { values, sort, pipe, map, pluck, filter, uniq, innerJoin } from "ramda"
import { selectCourseCompletionPercentageMap } from "../selectors"

export const selectCourses = ({ courses }: RootState) => courses.courses

export const selectCourseId = ({ courses }: RootState) =>
  courses.selectedCourseId

export const selectSelectedCategoryCode = ({ courses }: RootState) =>
  courses.selectedCategoryCode

export const selectSelectedfilteredBrands = ({ courses }: RootState) =>
  courses.filteredBrands
export const selectSelectedOrderType = ({ courses }: RootState) =>
  courses.orderType

export const selectSelectedCompletedCategoryCode = ({ courses }: RootState) =>
  courses.selectedCompletedCategoryCode

export const selectCourseProgressMap = ({ courses }: RootState) =>
  courses.coursesProgress

export const selectChapters = ({ courses }: RootState) =>
  courses.courses?.[courses.selectedCourseId!]?.data?.contents?.chapters

export const selectAttachments = ({ courses }: RootState) =>
  courses.courses?.[courses.selectedCourseId!]?.data?.attachments?.items

export const selectTests = ({ courses }: RootState) =>
  courses.courses?.[courses.selectedCourseId!]?.data?.tests

export const selectCurrentViewIndex = ({ courses }: RootState) =>
  courses.coursesProgress?.[courses.selectedCourseId!]?.viewIndex ?? 0

export const selectCourseList = cds(
  selectCourses,
  selectCourseCompletionPercentageMap,
  selectSelectedOrderType,
  (courses, percentageMap, orderType) => {
    return values(courses)
      .map((x) => ({
        id: x.id,
        categoryCode: x.data.info.categoryCode ?? "",
        updated: parseISO(x.updatedAt),
        title: x.data.info.title,
      }))
      .sort((a, b) => {
        switch (orderType) {
          case "NEWEST_FIRST":
            return compareDesc(a.updated, b.updated)
          case "OLDEST_FIRST":
            return compareAsc(a.updated, b.updated)
          case "COMPLETION_FIRST":
            return (percentageMap[b.id] ?? 0) - (percentageMap[a.id] ?? 0)
          case "COMPLETION_LAST":
            return (percentageMap[a.id] ?? 0) - (percentageMap[b.id] ?? 0)
          case "ALPHABETIC":
            return a.title.localeCompare(b.title, "it")
          default:
            return 0
        }
      })
  }
)

export const selectCourseIdList = cds(selectCourseList, pipe(pluck("id")))

export const selectFilteredCourseIdList = cds(
  selectCourseIdList,
  selectCourses,
  selectSelectedCategoryCode,
  selectSelectedfilteredBrands,
  (courseIdList, courses, categoryId, brands) => {
    return filter((courseId) => {
      let bool = true
      bool &&=
        categoryId == null
          ? true
          : courses[courseId]?.data?.info?.categoryCode === categoryId
      if (Object.keys(brands).length > 0) {
        bool &&=
          bool && brands[courses[courseId]?.data?.info?.brandCode ?? ""] != null
      }
      return bool
    }, courseIdList)
  }
)

export const selectNewestCourseIdList = cds(
  selectCourseList,
  pipe((v) => {
    const now = new Date()
    return filter((x) => differenceInDays(now, x.updated) <= 7, v)
  }, pluck("id"))
)
export const selectChapterIdList = cds(selectChapters, pipe(pluck("id")))
export const selectAttachmentIdList = cds(selectAttachments, pipe(pluck("id")))
export const selectTestIdList = cds(selectTests, pipe(pluck("id")))

export const selectCompletedCourseIdList = cds(
  selectCourseProgressMap,
  pipe(
    values,
    (v) =>
      filter(
        (x) => x?.status === "completed" || x?.viewIndex !== 0,
        v
      ) as CoursesProgressEntity<number>[],
    pluck("courseId")
  )
)

export const selectFilteredCompletedCourseIdList = cds(
  selectCompletedCourseIdList,
  selectCourses,
  selectSelectedCompletedCategoryCode,
  pipe((courseIdList, courses, categoryId) =>
    filter(
      (courseId) =>
        categoryId == null
          ? true
          : courses[courseId].data?.info?.categoryCode === categoryId,
      courseIdList
    )
  )
)

export const selectRunningCourseIdList = cds(
  selectCourseProgressMap,
  pipe(
    values,
    (v) =>
      filter(
        (x) => x?.status === "running" || x?.viewIndex !== 0,
        v
      ) as CoursesProgressEntity<number>[],
    pluck("courseId")
  )
)

export const selectCourseCategoryCodeList = cds(
  selectCourseList,
  pipe(pluck("categoryCode"), uniq)
)

export const selectCompletedCategoryCodeList = cds(
  selectCourseList,
  selectCompletedCourseIdList,
  pipe(
    innerJoin((course, id) => course.id == id),
    pluck("categoryCode"),
    uniq
  )
)

import React, { useMemo } from "react"
import { curveMonotoneX } from "@visx/curve"
import { HtmlLabel } from "@visx/annotation"
import { LinePath, AreaClosed } from "@visx/shape"
import { AxisBottom, AxisLeft } from "@visx/axis"
import { Group } from "@visx/group"
import { scalePoint, scaleLinear, scaleTime } from "@visx/scale"
import { getThemeColorVar, useSize } from "@helpers/ui"
import { last } from "lodash"

interface Props {
  storeSalesData: StoreSalesData[]
}
export interface StoreSalesData {
  date: string
  percent: number
  isToday: boolean
}

const StoreSalesChart = ({ storeSalesData }: Props) => {
  // const width = 350;
  // const height = 600;
  const [{ width, height }, setRef] = useSize()
  // const marginTop = height * 0.02;
  const marginTop = 46
  // const marginBottom = height * 0.06;
  const marginBottom = 37
  // const marginLeft = width * 0.1;
  const marginLeft = 66
  // const marginRight = width * 0.1;
  const marginRight = 40

  const xMax = width - marginLeft - marginRight
  const yMax = height - marginTop - marginBottom
  const xScale = useMemo(
    () =>
      // scaleTime<string>({
      //     range: [0, xMax ?? 0],
      //     round: true,
      //     domain: performanceData.map(x=>x.date),
      //     paddingInner: 0.70
      //   }),
      scalePoint<string>({
        domain: storeSalesData.map((x) => x.date),
        range: [0, xMax ?? 0],
        round: true,
        padding: 0,
      }),
    [xMax, width, storeSalesData]
  )
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, 100],
      }),
    [yMax, height, storeSalesData]
  )

  const current = last(storeSalesData)

  return (
    <div
      className="relative w-full h-[209px] bg-grey/50 rounded-[12px]"
      ref={setRef}
    >
      {width != 0 && height != 0 && (
        <>
          <svg width={width} height={height}>
            {/* <defs>
            <linearGradient id="area-gradient" x1=".5" x2=".5" y2="1">
              <stop
                offset=".001"
                stopColor={getThemeColorVar("dark-blue")}
                stopOpacity=".15"
              />
              <stop
                offset="1.004"
                stopColor={getThemeColorVar("dark-blue")}
                stopOpacity=".0001"
              />
            </linearGradient>
          </defs> */}
            <Group top={marginTop} left={marginLeft}>
              <LinePath<StoreSalesData>
                curve={curveMonotoneX}
                data={storeSalesData}
                x={(d) => xScale(d.date) ?? 0}
                y={(d) => yScale(d.percent) ?? 0}
                stroke={getThemeColorVar("dark-blue")}
                strokeWidth={2}
                strokeOpacity={1}
                shapeRendering="geometricPrecision"
                markerMid="url(#marker-circle)"
              />
              {storeSalesData
                .filter((x) => x.isToday)
                .map((d, i) => (
                  <Group key={`today-group-${i}`}>
                    {/* <HtmlLabel
                      x={xScale(d.date) ?? 0}
                      y={yScale(d.percent) ?? 0}
                      anchorLineStroke=""
                      containerStyle={{
                        position: "relative",
                      }}
                    >
                      <div className="relative">
                        <div className="w-[51px] h-[32px] rounded-[8px] bg-dark flex items-center justify-center text-white text-xl translate-x-2/3 -translate-y-[7px]">
                          {`${d.percent}%`}
                        </div>
                      </div>
                    </HtmlLabel> */}
                    <circle
                      cx={xScale(d.date)}
                      cy={yScale(d.percent)}
                      r={4}
                      fill={getThemeColorVar("dark-blue")}
                    />
                  </Group>
                ))}
              {/* <AreaClosed<StoreSalesData>
              data={storeSalesData}
              x={(d) => xScale(d.date) ?? 0}
              y={(d) => yScale(d.percent) ?? 0}
              yScale={yScale}
              strokeWidth={1}
              stroke="transarent"
              fill="url(#area-gradient)"
              curve={curveMonotoneX}
            /> */}
            </Group>
            <AxisBottom
              left={marginLeft}
              top={yMax + marginTop + 3}
              scale={xScale}
              hideAxisLine
              hideTicks
              axisClassName=" text-base "
              tickLabelProps={() => ({
                fill: getThemeColorVar("light"),
                textAnchor: "middle",
              })}
            />
            <AxisLeft
              left={marginLeft - 24}
              top={marginTop}
              tickValues={[0, 20, 40, 60, 80, 100]}
              tickFormat={(x) => {
                return `${x}%`
              }}
              axisClassName=" text-base "
              tickLabelProps={() => ({
                fill: getThemeColorVar("light"),
                textAnchor: "middle",
              })}
              hideTicks
              hideAxisLine
              scale={yScale}
            ></AxisLeft>
          </svg>
          {current && (
            <div
              className="absolute w-[51px] h-[32px] rounded-[8px] bg-dark flex items-center justify-center text-white text-xl"
              style={{
                right: 14,
                bottom: (current.percent / 100) * 140 + 42,
              }}
            >
              {`${current.percent}%`}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export { StoreSalesChart }

import { buffers, eventChannel } from "redux-saga"
import { getSagaContext } from "@modules/store/redux/sagaContext"
import { call, put, take, cancelled, takeLatest } from "typed-redux-saga"
import { authActions } from "../state"

export function* watchFirebaseAuthStateSaga() {
  const firebase = yield* getSagaContext("firebase")
  const firebaseAuthChannel = eventChannel<boolean>((emitter) => {
    const unsubscribe = firebase.watchIsLoggedIn((isLoggedIn) => {
      emitter(isLoggedIn)
    })
    return () => unsubscribe()
  }, buffers.sliding(1))
  try {
    yield* takeLatest(authActions.watch.FULFILL, function* () {
      firebaseAuthChannel.close()
    })
    while (true) {
      const isLoggedIn = yield* take(firebaseAuthChannel)
      if (!isLoggedIn) {
        //first time is logging out...
        yield* put(authActions.logout.success())
        yield* put(authActions.setInitialized())
      } else {
        const user = yield* call(firebase.getUser)
        const claims = yield* call(firebase.getClaims)
        yield* put(
          authActions.login.success({
            displayName: user?.displayName ?? "",
            email: user?.email ?? "",
            phoneNumber: user?.phoneNumber ?? "",
            photoURL: user?.photoURL ?? "",
            claims,
            uid: user?.uid ?? "",
          })
        )
      }
    }
  } catch (error) {
    yield* put(authActions.watch.failure())
  } finally {
    if (yield* cancelled()) {
      firebaseAuthChannel.close()
    }
  }
}

import { setCssVar } from "@helpers/ui"
import classNames from "classnames"

export interface TabItem {
  id: string
  label: React.ReactNode
  hidden?: boolean
}

interface Props<T> {
  tabs: T[]
  setSelectedTab: (selectedTab: T) => void
  activeTab: T
  className?: string
}

const Tabs = <T extends TabItem>({
  setSelectedTab,
  tabs,
  activeTab,
  className,
}: Props<T>) => {
  const count = tabs.filter((x) => !x.hidden).length
  const selectedIndex = tabs
    .filter((x) => !x.hidden)
    .findIndex((x) => x.id === activeTab.id)
  return (
    <div
      style={{
        ...setCssVar("--tabs-bar-width", `${(1 / count) * 100}%`),
        ...setCssVar("--tabs-bar-left-pos", `${selectedIndex * 100}%`),
      }}
      className={classNames(
        "flex relative border-b border-light h-[42px] bg-white items-center cursor-pointer",
        "after:content-[''] after:h-[3px] after:bg-red after:absolute after:bottom-[0px]",
        "after:left-0 after:transition-transform",
        `after:translate-x-[var(--tabs-bar-left-pos)] after:w-[var(--tabs-bar-width)]`,
        className
      )}
    >
      {tabs.map((x) => (
        <div
          key={x.id}
          className={classNames(
            "flex-1 h-full items-center flex justify-center  text-lg leading-[1] font-medium",
            activeTab.id === x.id ? "text-red" : "text-light",
            {
              hidden: x.hidden,
            }
          )}
          onClick={() => setSelectedTab(x)}
        >
          {x.label}
        </div>
      ))}
    </div>
  )
}

export default Tabs

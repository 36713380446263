import { IonApp } from "@ionic/react"
import { ChatProvider } from "@features/messaging/Provider"
import { CourseProvider } from "@features/courses/Provider"
import React from "react"
import { useDispatch } from "react-redux"
import { DataProvider } from "@modules/contents/Provider"
import { ProfileProvider } from "@features/profile/Provider"
import { CalendarProvider } from "@features/calendar/Provider"
import { PerformanceProvider } from "@features/performance/Provider"
import { NotificationsProvider } from "@features/notifications/Provider"
import { DeploymentsProvider } from "@modules/deployments/Provider"
import { appActions } from "@modules/app/state"
import useHideCapacitorSplashScreen from "@modules/app/hooks/useHideCapacitorSplashScreen"
import { SplashScreenProvider } from "@modules/app/Provider/SplashScreenProvider"
import { useAppInitialization } from "@modules/app/hooks/useAppInitialization"
import { AuthProvider } from "@modules/auth/Provider"
import DeviceFeaturesProvider from "@modules/app/Provider/DeviceFeaturesProvider"

export interface InnerAppProviderProps {
  children: React.ReactNode
}

export const InnerAppProvider = ({ children }: InnerAppProviderProps) => {
  const { initialized: isInitialized, loading: isAppLoading } =
    useAppInitialization()
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(appActions.load())
  }, [])

  React.useEffect(() => {
    if (!isAppLoading) {
      dispatch(appActions.showSplashScreen("NONE"))
    }
  }, [isAppLoading])

  useHideCapacitorSplashScreen(isInitialized)

  return (
    <SplashScreenProvider>
      <DeviceFeaturesProvider>
        <DeploymentsProvider>
          <AuthProvider>
            <DataProvider>
              {isInitialized && (
                <ChatProvider>
                  <CourseProvider>
                    <ProfileProvider>
                      <CalendarProvider>
                        <PerformanceProvider>
                          <NotificationsProvider>
                            {!isAppLoading && <IonApp>{children}</IonApp>}
                          </NotificationsProvider>
                        </PerformanceProvider>
                      </CalendarProvider>
                    </ProfileProvider>
                  </CourseProvider>
                </ChatProvider>
              )}
            </DataProvider>
          </AuthProvider>
        </DeploymentsProvider>
      </DeviceFeaturesProvider>
    </SplashScreenProvider>
  )
}

import { classNames, useOperation } from "@punks/ui-core"
import { CloseIcon } from "@ui/icons"
import Modal from "@ui/structure/Modal"
import { Button } from "@atoms"
import { useState } from "react"
import { FunctionsClient } from "@package/common/api"
import { showToast } from "@animations"
import { useTranslations } from "@modules/app/hooks/useTranslations"
import {
  MediumRatingIcon,
  NegativeRatingIcon,
  PositiveRatingIcon,
} from "@ui/icons/ratings"

export interface EventReviewModalProps {
  appointmentId: string
  open: boolean
  onClose: () => void
  onSubmitted: () => void
}

type Rating = "positive" | "neutral" | "negative"

export const EventReviewModal = ({
  appointmentId,
  onClose,
  onSubmitted,
  open,
}: EventReviewModalProps) => {
  const { t } = useTranslations()
  const [rating, setRating] = useState<"positive" | "neutral" | "negative">()

  const submitOperation = useOperation({
    operation: FunctionsClient.pushAppointmentEvaluation,
    onError: () => {
      showToast({
        message: t("calendars:event.evaluation.error"),
      })
    },
    onSuccess: () => {
      showToast({
        message: t("calendars:event.evaluation.sent"),
      })
      onSubmitted()
    },
  })

  const clear = () => setRating(undefined)

  const handleSubmit = async () => {
    await submitOperation.invoke({
      appointmentId,
      rate: { value: rating as string },
    })
    clear()
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        clear()
        onClose?.()
      }}
      partial
    >
      <div className="flex justify-between">
        <p className="text-xl text-black font-bold">
          {t("calendars:event.evaluation.title")}
        </p>
        <button onClick={onClose} className="text-black">
          <CloseIcon className="text-[28px]" />
        </button>
      </div>
      <div className="flex justify-around items-center mt-10 mb-4 gap-4">
        {[
          {
            rating: "positive" as Rating,
            img: <PositiveRatingIcon width={80} />,
            imgSelected: <PositiveRatingIcon width={80} fill="#1b9f7d" />,
            label: t("calendars:event.evaluation.good"),
            selectedColor: "#1b9f7d",
          },
          {
            rating: "neutral" as Rating,
            img: <MediumRatingIcon width={80} />,
            imgSelected: <MediumRatingIcon width={80} fill="#f0b728" />,
            label: t("calendars:event.evaluation.medium"),
            selectedColor: "#f0b728",
          },
          {
            rating: "negative" as Rating,
            img: <NegativeRatingIcon width={80} />,
            imgSelected: <NegativeRatingIcon width={80} fill="#ed3858" />,
            label: t("calendars:event.evaluation.bad"),
            selectedColor: "#ed3858",
          },
        ].map((item) => (
          <button
            key={item.rating}
            onClick={() => setRating(item.rating)}
            className={classNames("rounded-[50%] p-4", {
              // [item.selectedClass]: rating === item.rating,
            })}
          >
            {rating === item.rating ? item.imgSelected : item.img}
            <p className="mt-4">{item.label}</p>
          </button>
        ))}
      </div>
      {rating && (
        <div className="mt-8">
          <Button
            color="primary"
            className="text-white"
            loading={submitOperation.state.loading}
            onClick={handleSubmit}
          >
            {t("calendars:event.evaluation.send")}
          </Button>
        </div>
      )}
    </Modal>
  )
}

import { buffers, eventChannel } from "redux-saga"
import { put, take, cancelled, takeLatest } from "typed-redux-saga"
import { NotificationEvent } from "../../../../database/notifications/notification-event.entity"
import { getSagaContext } from "../../../state/context"
import { notificationsRoutines } from "../state"
import { NotificationEntry } from "../state/types"

const MAX_NOTIFICATIONS = 20

export function* watchUserNotifications() {
  const firebase = yield* getSagaContext("firebase")
  const uid = firebase.getUid()
  if (!uid) {
    throw new Error("Uid is missing")
  }
  const channel = eventChannel<NotificationEntry[]>((emitter) => {
    const unsubscribe =
      firebase.collections.notificationEventsController.subscribeUserNotifications(
        {
          uid,
          limit: MAX_NOTIFICATIONS,
        },
        (data) => {
          emitter(
            data.map((x) => ({
              id: x.id,
              ...x.data,
            }))
          )
        }
      )
    return () => unsubscribe()
  }, buffers.sliding(1))

  try {
    yield* takeLatest(
      notificationsRoutines.watchNotifications.fulfill,
      function* () {
        channel.close()
      }
    )
    while (true) {
      const data = yield* take(channel)
      yield* put(notificationsRoutines.watchNotifications.success(data))
    }
  } catch (error) {
    yield* put(notificationsRoutines.watchNotifications.failure(error))
  } finally {
    if (yield* cancelled()) {
      channel.close()
    }
  }
}

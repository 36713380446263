import AndroidUpdateContainer from "@containers/update/AndroidUpdateContainer"
import PageRoot from "@root/pages/PageRoot"

const AndroidAppUpdate = () => {
  return (
    <PageRoot fullscreen>
      <AndroidUpdateContainer />
    </PageRoot>
  )
}

export default AndroidAppUpdate
